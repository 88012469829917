const labDetailsSystem: React.CSSProperties = {
  position: 'relative',
};

const centeredMessageStyle: React.CSSProperties = {
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  fontSize: '20px',
  color: 'var(--colorNeutralStroke1)',
};

export { centeredMessageStyle, labDetailsSystem };
