import React from 'react';
import { observer } from 'mobx-react-lite';

import StatusDonutChart from '@/components/Graphs/StatusDonut/StatusDonusTemplate';
import { SessionStatusType, SessionStepType } from '@/components/Sessions/SessionsTypes';
import { SessionStepChartVCType } from '@/components/SessionStepChart/SessionStepChartTypes';
import { StepStatusTypes } from '@/constants/ExperimentConstants';
import { ChartType } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';

import styles from '@/components/SessionStepChart/SessionStepChart.module.css';

const extractSteps = (steps: SessionStepType[]): number[] => {
  // Count the occurrences of each status type.
  const stepCounts = steps.reduce((accumulator, step: SessionStepType) => {
    const status = step.status;

    accumulator[status as string] = (accumulator[status as string] || 0) + 1;

    return accumulator;
  }, {});

  // The StatusDonut component takes an array of these 7 values, in order.
  const stepValues: number[] = [
    stepCounts[StepStatusTypes.SUCCEEDED] || 0,
    stepCounts[StepStatusTypes.PENDING] || 0,
    stepCounts[StepStatusTypes.IN_PROGRESS] || 0,
    stepCounts[StepStatusTypes.FAILED] || 0,
    stepCounts[StepStatusTypes.NOT_STARTED] || 0,
    stepCounts[StepStatusTypes.CANCELLED] || 0,
    stepCounts[StepStatusTypes.SYSTEM_CANCELLED] || 0,
  ];

  return stepValues;
};

const SessionStepChartTemplateFC: React.FC<SessionStepChartVCType> = (props: SessionStepChartVCType) => {
  const { type, data } = props;

  if (!data?.steps) {
    return <div className={styles['no-data']}>No data</div>;
  }

  const status: SessionStatusType = data.status;
  const seriesData: number[] = extractSteps(data.steps);
  const total: number = seriesData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (type === ChartType.DONUT) {
    const chart = <StatusDonutChart seriesData={seriesData} total={total} />;

    return <div className={styles['chart']}>{chart}</div>;
  } else {
    // Currently, we only support one type.
    const chart = <StatusDonutChart seriesData={seriesData} total={total} />;

    return <div className={styles['chart']}>{chart}</div>;
  }
};

const SessionStepChartTemplate = observer(SessionStepChartTemplateFC);

export default SessionStepChartTemplate;
