import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Dropdown } from '@fluentui/react';
import { DetailsRow } from '@fluentui/react';
import { CommandBarButton } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection } from '@fluentui/react/lib/Selection';
import { Divider } from '@fluentui/react-components';
import { t } from 'i18next';

import { ManageRackManager } from '@/components/_labs/Labs/ManageRackManager';
import { BasicModalComponent } from '@/components/_labs/LabsHelper/ModalComponents/BasicModalComponent';
import { LabIcons, SystemIcons } from '@/constants/IconConstants';
import { SytemColumnKeys } from '@/constants/LabDetailsConstants';
import { Labels } from '@/constants/LabsConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { ColumnEditorKeys, EnablePagination, Namespaces as NS, PageMemoryKeys } from '@/constants/SystemConstants';
import { Common, Default, Experiments, LabDetails, Labs } from '@/constants/TranslationConstants';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { AddSystemTag } from '@/partials/LabComponents/AddSystemTag/AddSystemTag';
import { LabManifestMetadataEditor } from '@/partials/LabComponents/LabManifestMetadataEditor/LabManifestMetadataEditor';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { getPaginationDefaults } from '@/partials/Pagination/Pagination';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { RenderRowType } from '@/types/TableTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import GroupByRenderer from '@/utils/GroupByRenderer/GroupByRenderer';
import { labDownloadHelper, navigationOnClick } from '@/utils/Helpers';
import ReadingPaneLayoutButton from '@/utils/ReadingPaneLayoutButton';

import config from './LabSystems.config.json';
import { LabSystemsTemplate } from './LabSystemsTemplate';
import { LabSystemsVMType } from './LabSystemsType';

import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface LabSystemsViewControllerProps {
  viewModel: LabSystemsVMType;
}

const LabSystemsViewControllerFC: React.FC<LabSystemsViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labDetailsStore, labSystemsStore, paginationStore, sessionsStore, systemMessageStore } = rootStore;
  const { isOutlookMode } = appSettingsStore;
  const { setPaginationType } = paginationStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { isPersistedLab, selectedLab } = labDetailsStore;
  const {
    doReset,
    filteredLabSystems,
    healthStatusValues,
    hideRackManagerEditor,
    hideTagEditor,
    ipAddressValues,
    ipAddressesList,
    isLoading,
    isRackManagerEditorOpen,
    isLabManifestMetadataDeleteOpen,
    isLabManifestMetadataEditorOpen,
    isSystemSelected,
    isTagEditorOpen,
    labSystems,
    machineTypeValues,
    machineTypesList,
    setFilteredLabSystems,
    setHealthStatusValues,
    setIpAddressValues,
    setMachineTypeValues,
    setTableGroups,
    showLabSystemsColumnEditor,
    showRackManagerEditor,
  } = labSystemsStore;

  // Props Const
  const {
    assignTagsToSystems,
    fetchLabManifest,
    fetchLabSystems,
    fetchColumnDetails,
    getIpAddressList,
    getAddSystemTagOpen,
    getLabManifestMetadataDeleteOpen,
    getLabManifestMetadataEditorOpen,
    handleClearAllFilters,
    searchData,
    selectRow,
  } = viewModel;

  // State Const
  // To notify the GroupBy Component about the filter value change
  const [filtersChanged, setFiltersChanged] = React.useState<boolean>(false);

  // Other Const
  const columnEditorKey = ColumnEditorKeys.LAB_SYSTEMS;
  const history = useHistory();

  React.useEffect(() => {
    const paginationDefaults = getPaginationDefaults(handlePaginationChange, EnablePagination.LAB_DETAILS_SYSTEM, true);

    setPaginationType(paginationDefaults);
  }, []);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (fetchLabSystems && fetchLabManifest) {
      fetchLabSystems();
      fetchLabManifest();
      setFiltersChanged(!filtersChanged);
    }

    getIpAddressList();
  }, [fetchLabSystems, fetchLabManifest, selectedLab]);

  React.useEffect(() => {
    fetchColumnDetails();
  }, [labSystems, selectedLab]);

  React.useEffect(() => {
    if (selectedLab && !isPersistedLab) {
      doReset();
    }
  }, [selectedLab, isPersistedLab]);

  React.useEffect(() => {
    if (searchData) {
      searchData();
      setFiltersChanged(!filtersChanged);
    }
  }, [labSystems, ipAddressValues, healthStatusValues, machineTypeValues]);

  const handlePaginationChange = (currentPage: number): void => {
    searchData(currentPage);
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      selectRow(selection);
    },
  });

  const rackSystemsOnRenderRow: RenderRowType = (props: any) => {
    if (props) {
      return (
        <div data-selection-disabled={isLoading}>
          <DetailsRow {...props} />
        </div>
      );
    }

    return null;
  };

  const commandBarExperimentItems: ICommandBarItemProps[] = [
    {
      key: Default.CREATE_EXPERIMENT,
      text: t(Default.CREATE_EXPERIMENT, { ns: NS.DEFAULT }),
      title: t(Default.CREATE_EXPERIMENT, { ns: NS.DEFAULT }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        navigationOnClick(event, Navigation.GANYMEDE.EXPERIMENT_EDITOR, history);
      },
    },
    {
      key: Experiments.VIEW_EXPERIMENT_STATUS,
      text: t(Experiments.VIEW_EXPERIMENT_STATUS, { ns: NS.EXPERIMENTS }),
      title: t(Experiments.VIEW_EXPERIMENT_STATUS, { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.VIEW_STATUS },
      onClick: () => {
        sessionsStore.setLocationValue(selectedLab.LabId.toString());

        history.push({
          pathname: `${Navigation.GANYMEDE.SESSIONS}`,
        });
      },
    },
  ];

  const commandBarDownloadInstallerItems: ICommandBarItemProps[] = [
    {
      key: Labs.DOWNLOAD_INSTALLER,
      text: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      title: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      subMenuProps: {
        items: [
          {
            key: Labs.DOWNLOAD_EXE,
            text: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_EXE },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, true),
          },
          {
            key: Labs.DOWNLOAD_MSI,
            text: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_MSI },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, false),
          },
        ],
      },
    },
  ];

  // we will enable rest items once we have implementation
  const commandBarLabActionItems: ICommandBarItemProps[] = [
    {
      key: LabDetails.LAB_ACTIONS,
      text: t(LabDetails.LAB_ACTIONS, { ns: NS.LAB_DETAILS }),
      title: t(LabDetails.LAB_ACTIONS, { ns: NS.LAB_DETAILS }),
      iconProps: { iconName: LabIcons.LAB_ACTIONS },
      subMenuProps: {
        items: [
          {
            key: LabDetails.MANAGE_RACK_MANGER,
            text: t(LabDetails.MANAGE_RACK_MANGER, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.MANAGE_RACK_MANGER, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: SystemIcons.RACK },
            onClick: (event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>) => {
              event.preventDefault();
              showRackManagerEditor();
            },
          },
          // {
          //   key: LabDetails.MANAGE_PREBOOTED_SUT,
          //   text: t(LabDetails.MANAGE_PREBOOTED_SUT, { ns: NS.LAB_DETAILS }),
          //   title: t(LabDetails.MANAGE_PREBOOTED_SUT, { ns: NS.LAB_DETAILS }),
          //   iconProps: { iconName: LabIcons.PRE_BOOTED_SUT },
          // },
          {
            key: LabDetails.SARM_UPDATE,
            text: t(LabDetails.SARM_UPDATE, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.SARM_UPDATE, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: LabIcons.ADDEDIT_SARMIP },
            disabled: !isSystemSelected,
            onClick: () => {
              getLabManifestMetadataEditorOpen(true);
            },
          },
          {
            key: LabDetails.SARM_DELETE,
            text: t(LabDetails.SARM_DELETE, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.SARM_DELETE, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: LabIcons.DELETE_SARMPIP },
            disabled: !isSystemSelected,
            onClick: () => {
              getLabManifestMetadataDeleteOpen(true);
            },
          },
          {
            key: LabDetails.JBOD_UPDATE,
            text: t(LabDetails.JBOD_UPDATE, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.JBOD_UPDATE, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: LabIcons.ADDEDIT_JBOD },
            disabled: !isSystemSelected,
            onClick: () => {
              getLabManifestMetadataEditorOpen(false);
            },
          },
          {
            key: LabDetails.JBOD_DELETE,
            text: t(LabDetails.JBOD_DELETE, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.JBOD_DELETE, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: LabIcons.DELETE_JBOD },
            disabled: !isSystemSelected,
            onClick: () => {
              getLabManifestMetadataDeleteOpen(false);
            },
          },
          {
            key: LabDetails.ASSIGN_SYSTEM_TAG,
            text: t(LabDetails.ASSIGN_SYSTEM_TAG, { ns: NS.LAB_DETAILS }),
            title: t(LabDetails.ASSIGN_SYSTEM_TAG, { ns: NS.LAB_DETAILS }),
            iconProps: { iconName: SystemIcons.TAG },
            disabled: !isSystemSelected,
            onClick: () => {
              //event.preventDefault();
              getAddSystemTagOpen();
            },
          },
          // {
          //   key: LabDetails.REPAIR_LAB,
          //   text: t(LabDetails.REPAIR_LAB, { ns: NS.LAB_DETAILS }),
          //   title: t(LabDetails.REPAIR_LAB, { ns: NS.LAB_DETAILS }),
          //   iconProps: { iconName: LabIcons.REPAIR_LAB },
          //   subMenuProps: {
          //     items: [
          //       {
          //         key: LabDetails.CORRECT_MISMATCHING_IP,
          //         text: t(LabDetails.CORRECT_MISMATCHING_IP, { ns: NS.LAB_DETAILS }),
          //         title: t(LabDetails.CORRECT_MISMATCHING_IP, { ns: NS.LAB_DETAILS }),
          //         iconProps: { iconName: LabIcons.REPAIR_IP_ADDRESS },
          //       },
          //       {
          //         key: LabDetails.CORRECT_MISMATCHING_MAC,
          //         text: t(LabDetails.CORRECT_MISMATCHING_MAC, { ns: NS.LAB_DETAILS }),
          //         title: t(LabDetails.CORRECT_MISMATCHING_MAC, { ns: NS.LAB_DETAILS }),
          //         iconProps: { iconName: LabIcons.REPAIR_MAC_ADDRESS },
          //       },
          //     ],
          //   },
          // },
        ],
      },
    },
  ];

  const commandBarRefreshItems: ICommandBarItemProps[] = [
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Common.REFRESH,
      text: t(Common.REFRESH, { ns: NS.COMMON }),
      title: t(Common.REFRESH, { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.REFRESH },
      onClick: () => {
        fetchColumnDetails();
      },
      itemDivider: true,
    },
  ];

  const layoutButton: ICommandBarItemProps = ReadingPaneLayoutButton();

  // Below lines will be uncommented once the functions are implemented
  const commandBarItems = [
    ...commandBarExperimentItems,
    ...commandBarDownloadInstallerItems,
    // ...commandBarLabActionItems,
    ...commandBarLabActionItems,
    ...commandBarRefreshItems,
    ...(isOutlookMode ? [layoutButton] : []),
  ];

  const groupByButton: ICommandBarItemProps = GroupByRenderer({
    tableData: filteredLabSystems,
    tableColumns: config.labSystemsColumnDefinitions,
    setData: setFilteredLabSystems,
    setTableGroups: setTableGroups,
    pageKey: PageMemoryKeys.LAB_SYSTEMS,
    filtersChanged: filtersChanged,
    defaultGroupByColumn: SytemColumnKeys.RACK_MANGER_NAME,
  });

  const farItems: ICommandBarItemProps[] = [
    {
      key: Common.RESET,
      onRender: () => (
        <CommandBarButton
          text={t(Common.RESET, { ns: NS.COMMON })}
          title={t(Common.RESET, { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET }}
          onClick={handleClearAllFilters}
        />
      ),
    },
    groupByButton,

    {
      key: 'divider2',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Common.EDIT_COLUMNS,
      text: t(Common.EDIT_COLUMNS, { ns: NS.COMMON }),
      title: t(Common.EDIT_COLUMNS, { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        showLabSystemsColumnEditor();
      },
    },
  ];

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: LabDetails.MACHINE_TYPE,
      onRender: () => (
        <Dropdown
          placeholder={t(LabDetails.MACHINE_TYPE, { ns: NS.LAB_DETAILS })}
          title={t(LabDetails.MACHINE_TYPE, { ns: NS.LAB_DETAILS })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={machineTypeValues}
          options={machineTypesList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setMachineTypeValues(
              option.selected
                ? [...machineTypeValues, option.key as string]
                : machineTypeValues.filter((type: string) => type !== option.key),
            )
          }
        />
      ),
    },
    {
      key: Common.SELECT_IPADDRESS,
      onRender: () => (
        <Dropdown
          placeholder={t(Common.SELECT_IPADDRESS, { ns: NS.COMMON })}
          title={t(Common.SELECT_IPADDRESS, { ns: NS.COMMON })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={ipAddressValues}
          options={ipAddressesList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setIpAddressValues(
              option.selected
                ? [...ipAddressValues, option.key as string]
                : ipAddressValues.filter((ip: string) => ip !== option.key),
            )
          }
        />
      ),
    },
    // Below  will be uncommented once the machine status update to table data change is implemented
    // {
    //   key: LabDetails.MACHINE_STATUS,
    //   onRender: () => (
    //     <Dropdown
    //       placeholder={t(LabDetails.MACHINE_STATUS, { ns: NS.LAB_DETAILS })}
    //       title={t(LabDetails.MACHINE_STATUS, { ns: NS.LAB_DETAILS })}
    //       multiSelect
    //       dropdownWidth="auto"
    //       selectedKeys={healthStatusValues}
    //       options={HealthStatusOptions}
    //       className={filterBarStyles['pagefilterbar-item']}
    //       styles={filterBar.dropdown}
    //       onChange={(event, option) =>
    //         setHealthStatusValues(
    //           option.selected
    //             ? [...healthStatusValues, option.key as string]
    //             : healthStatusValues.filter((status: string) => status !== option.key),
    //         )
    //       }
    //     />
    //   ),
    // },
  ];

  // #region
  // "Below code block is interim to manage the existing Rack Manager
  // and will be removed once after the new implementation"

  const [rackManagerInfo, setRackManagerInfo] = React.useState(null);
  const [rackManagerList, setRackManagerList] = React.useState<any[]>([]);
  const [allSystemData, setAllSystemData] = React.useState<any[]>([]);
  //  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const failGroupId = 'lab-control-fail';

  const getHeartBeat = async (item) => {
    const agentId = `${item.LabControllerId},${item.MacAddress},${item.SlotNumber},${item.IPAddress}`;
    const date = await labsRequestService
      .getAgentHeartbeat(agentId)
      .then((result) => {
        return result.lastModified;
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);

        return null;
      });

    return date;
  };

  const fetchRackManager = React.useCallback(async () => {
    if (selectedLab.IsVirtualLab) {
      await ganymedeLabRequestService
        .getVirtualRackManager(selectedLab.LabId)
        .then((result) => {
          if (result.length > 0) {
            setRackManagerInfo(JSON.parse(result[0].FirmwareInfo));
            setRackManagerList(result);
          }
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            failGroupId,
          };

          HandleError(handleErrorProps);
        });
      return;
    }

    await ganymedeLabRequestService
      .getRackManager(selectedLab.LabId)
      .then((result) => {
        if (result.length > 0) {
          setRackManagerInfo(JSON.parse(result[0].FirmwareInfo));
          setRackManagerList(result);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, [selectedLab]);

  const fetchSystem = React.useCallback(async () => {
    if (selectedLab.IsVirtualLab) {
      await ganymedeLabRequestService
        .getVirtualSystem(selectedLab.LabId)
        .then(async (result) => {
          fetchRackManager();

          // Gather heartbeats here
          for (let i = 0; i < result.length; i++) {
            const data = result[i as number];
            await getHeartBeat(data).then((result) => {
              data.LastHeartBeat = result;
            });

            result[i as number] = data;
          }

          setAllSystemData(result);
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            failGroupId,
          };

          HandleError(handleErrorProps);
        });

      return;
    }

    await ganymedeLabRequestService
      .getSystem(selectedLab.LabId)
      .then(async (result) => {
        fetchRackManager();
        result.sort((item1: any, item2: any) => item1.SlotNumber - item2.SlotNumber);

        // Gather heartbeats here
        for (let i = 0; i < result.length; i++) {
          const data = result[i as number];
          await getHeartBeat(data).then((result) => {
            data.LastHeartBeat = result;
          });

          result[i as number] = data;
        }

        setAllSystemData(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
        };

        HandleError(handleErrorProps);
      });
  }, [selectedLab, fetchRackManager]);

  //#endregion

  const getModalComponent = () => {
    if (isRackManagerEditorOpen) {
      return (
        <BasicModalComponent
          isModalOpen={isRackManagerEditorOpen}
          hideModal={hideRackManagerEditor}
          labId={selectedLab.LabId}
          page={ManageRackManager}
          fetchLists={fetchSystem}
          action={Labels.addRackManager}
          title={t('rack-manager-info', { ns: NS.TITLES })}
        />
      );
    }

    if (isTagEditorOpen) {
      return (
        <DialogBox
          title={t(LabDetails.ASSIGN_SYSTEM_TAG, { ns: NS.LAB_DETAILS })}
          subtext={t(LabDetails.ASSIGN_SYSTEM_TAG_SUB_TITLE, { ns: NS.LAB_DETAILS })}
          minWidth={'40%'}
          body={<AddSystemTag hideModal={hideTagEditor} handleTagChanges={handleTagChanges} />}
          hidden={!isTagEditorOpen}
          modalProps={{
            isBlocking: true,
          }}
          onDismiss={hideTagEditor}
        />
      );
    }

    // Common editor to add / delete SARM IP / JBOD Slot
    if (isLabManifestMetadataEditorOpen || isLabManifestMetadataDeleteOpen) {
      return <LabManifestMetadataEditor />;
    }
  };

  const handleTagChanges = async (event: any, selectedTags: string[]) => {
    event.preventDefault();
    assignTagsToSystems(selectedTags);
  };

  return (
    <>
      <LabSystemsTemplate
        commandBarItems={commandBarItems}
        farItems={farItems}
        filterItems={filterItems}
        columnEditorKey={columnEditorKey}
        rackSystemsOnRenderRow={rackSystemsOnRenderRow}
        selection={selection}
      ></LabSystemsTemplate>
      {getModalComponent()}
    </>
  );
};

const LabSystemsViewController = observer(LabSystemsViewControllerFC);

export default LabSystemsViewController;
