import React from 'react';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import LabDetailsPanelViewController from '@/components/ManageLab/LabDetailsPanel/LabDetailsPanelViewController';
import { centeredMessageStyle, labDetailsSystem } from '@/components/SplitPanel/SplitPanelStyles';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { PageMemoryKeys, ReadingPaneStateKeys, SortedColumnPageKeys } from '@/constants/SystemConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { LabDetails } from '@/constants/TranslationConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import config from './LabSystems.config.json';
import { LabSystemsVCType } from './LabSystemsType';

import '@/styles/OutlookContainer.css';
import styles from './LabSystems.module.css';

const LabSystemsTemplateFC: React.FC<LabSystemsVCType> = (props: LabSystemsVCType) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labSystemsStore, paginationStore } = rootStore;
  const { groupByColumn, isReadingPaneHidden, isOutlookMode, readingPaneKey } = appSettingsStore;
  const { paginationType } = paginationStore;
  const {
    filteredLabSystems,
    hideLabSystemsColumnEditor,
    isLabSystemsColumnEditorOpen,
    isLoading,
    labSystemsColumnList,
    labSystemsEntireColumns,
    selectedSystem,
    setLabSystemsColumnList,
    tableGroups,
  } = labSystemsStore;

  // Props Const
  const { commandBarItems, farItems, filterItems, columnEditorKey, rackSystemsOnRenderRow, selection } = props;

  return (
    <>
      <div className={`outlook-container ${readingPaneKey as string}`}>
        <SplitPanel
          style={labDetailsSystem}
          index={0}
          isAllowResize={isOutlookMode}
          readingPaneStateHorizonKey={ReadingPaneStateKeys.LAB_SYSTEMS_HORIZONTAL}
          readingPaneStateVerticalKey={ReadingPaneStateKeys.LAB_SYSTEMS_VERTICAL}
        >
          <div className="content-list">
            <PageCommandBar items={commandBarItems} farItems={farItems}></PageCommandBar>
            <PageFilterBar items={filterItems}></PageFilterBar>

            <TableViewController
              columns={modifiedColumnConfiguration(labSystemsColumnList)}
              items={filteredLabSystems}
              displayColumns={config.labSystemsDisplayColumns}
              groups={tableGroups}
              groupByColumn={groupByColumn[PageMemoryKeys.LAB_SYSTEMS as string]}
              groupByColumns={config.labSystemsColumnDefinitions}
              selection={selection}
              selectionMode={SelectionMode.multiple}
              selectionPreservedOnEmptyClick={false}
              checkboxVisibility={CheckboxVisibility.always}
              enableToolBar={false}
              isStickyHeader={true}
              isCompact={true}
              isLoading={isLoading}
              paginationType={paginationType}
              onRenderRow={rackSystemsOnRenderRow}
              pageName={SortedColumnPageKeys.LAB_DETAILS_SYSTEMS_PAGE}
              tableStyle={`${styles['systems-table']} ${styles['full-height']}`}
            />
          </div>
          {isOutlookMode && !isReadingPaneHidden && (
            <>
              {selectedSystem ? (
                <div className="reading-pane">
                  <LabDetailsPanelViewController />
                </div>
              ) : (
                <div style={centeredMessageStyle}>{t(LabDetails.SELECT_SYSTEM, { ns: NS.LAB_DETAILS })}</div>
              )}
            </>
          )}
        </SplitPanel>
        {!isOutlookMode && <LabDetailsPanelViewController></LabDetailsPanelViewController>}
        <ColumnEditorPanelTemplate
          isColumnEditorOpen={isLabSystemsColumnEditorOpen}
          columnsList={labSystemsColumnList}
          setColumnsList={setLabSystemsColumnList}
          hideColumnEditor={hideLabSystemsColumnEditor}
          entireColumns={labSystemsEntireColumns}
          columnEditorKey={columnEditorKey}
        ></ColumnEditorPanelTemplate>
      </div>
    </>
  );
};

export const LabSystemsTemplate = observer(LabSystemsTemplateFC);
