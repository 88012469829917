import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { KeyTextPair, SystemConstants } from '@/constants/SystemConstants';

import { OSImageType } from './CreateLabType';

@singleton()
class CreateLabStore {
  // List of data
  public companies: KeyTextPair[] = [];
  public osImages: KeyTextPair[] = [];
  public osImageList: OSImageType[] = [];
  public osImageFilteredList: OSImageType[] = [];
  public labUsers: string[] = [];
  // Selected values
  public labNameValue: string;
  public companyValue: number;
  public adminsValue: string[]; //Owners
  public osImageValue = '';

  public isCreatingLab = false;
  public isInternal: boolean;

  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      companies: observable,
      osImages: observable,
      osImageList: observable,
      isInternal: observable,
      labUsers: observable,
      companyValue: observable,
      adminsValue: observable,
      osImageValue: observable,
      labNameValue: observable,
      osImageFilteredList: observable,
      isCreatingLab: observable,

      // Actions modify the state.
      setCompanies: action,
      setOsImages: action,
      setOsImageList: action,
      setCompanyValue: action,
      setAdminsValue: action,
      setOsImageValue: action,
      setIsInternal: action,
      setLabUsers: action,
      setLabNameValue: action,
      setOsImageFilteredList: action,
      setIsCreatingLab: action,
      doReset: action,
    });

    this.companyValue = SystemConstants.MS_COMPANY_ID;
  }

  public setCompanies = (companies: KeyTextPair[]) => {
    this.companies = companies || [];
  };

  public setOsImages = (images: KeyTextPair[]) => {
    this.osImages = images || [];
  };

  public setOsImageList = (images: OSImageType[]) => {
    this.osImageList = images || [];
  };

  public setCompanyValue = (company?: number) => {
    this.companyValue = company || SystemConstants.MS_COMPANY_ID;
  };

  public setAdminsValue = (admins: string[]) => {
    this.adminsValue = admins || [];
  };

  public setOsImageValue = (image?: string) => {
    this.osImageValue = image || '';
  };

  public setIsInternal = (internal: boolean) => {
    this.isInternal = internal;
  };

  public setOsImageFilteredList = (images: OSImageType[]) => {
    this.osImageFilteredList = images || [];
  };

  public setLabUsers = (users: string[]) => {
    this.labUsers = users || [];
  };

  public setLabNameValue = (name: string) => {
    this.labNameValue = name || '';
  };

  public setIsCreatingLab = (creating: boolean) => {
    this.isCreatingLab = creating;
  };

  public doReset = (): void => {
    this.setCompanyValue();
    this.setOsImageValue();
  };
}

export default CreateLabStore;
