import React from 'react';
import { CommandBarButton } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS, ReadingPaneKeys, ReadingPanePositionType } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

const ReadingPaneLayoutButton = (): ICommandBarItemProps => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isOutlookMode, readingPanePosition, setPreviewPanePosition } = appSettingsStore;

  const isRightChecked = isOutlookMode && readingPanePosition === ReadingPanePositionType.RIGHT;
  const isBottomChecked = isOutlookMode && readingPanePosition === ReadingPanePositionType.BOTTOM;
  const isHideChecked = !(isRightChecked || isBottomChecked);

  const onLayoutClick = (event?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
    event && event.preventDefault();

    if (item) {
      if (item.key === ReadingPaneKeys.SHOW_RIGHT) {
        setPreviewPanePosition(ReadingPanePositionType.RIGHT);
      } else if (item.key === ReadingPaneKeys.SHOW_BOTTOM) {
        setPreviewPanePosition(ReadingPanePositionType.BOTTOM);
      } else if (item.key === ReadingPaneKeys.HIDE) {
        setPreviewPanePosition(ReadingPanePositionType.HIDE);
      }
    }
  };

  const layoutItems: IContextualMenuProps = {
    items: [
      {
        key: 'readingPane',
        text: 'Reading Pane',
        subMenuProps: {
          items: [
            {
              key: ReadingPaneKeys.SHOW_RIGHT,
              text: t(ReadingPaneKeys.SHOW_RIGHT, { ns: NS.EXPERIMENTS }),
              canCheck: true,
              isChecked: isRightChecked,
              onClick: onLayoutClick,
            },
            {
              key: ReadingPaneKeys.SHOW_BOTTOM,
              text: t(ReadingPaneKeys.SHOW_BOTTOM, { ns: NS.EXPERIMENTS }),
              canCheck: true,
              isChecked: isBottomChecked,
              onClick: onLayoutClick,
            },
            {
              key: ReadingPaneKeys.HIDE,
              text: t(ReadingPaneKeys.HIDE, { ns: NS.EXPERIMENTS }),
              canCheck: true,
              isChecked: isHideChecked,
              onClick: onLayoutClick,
            },
          ],
        },
      },
    ],
  };

  const layoutButton: ICommandBarItemProps = {
    key: Common.LAYOUT,
    onRender: () => (
      <CommandBarButton
        text={t(Common.LAYOUT, { ns: NS.COMMON })}
        iconProps={{ iconName: SystemIcons.READING_PANE }}
        menuProps={layoutItems}
        role="menuitem"
        className={filterBarStyles['pagefilterbar-dropdown']}
        styles={filterBar.dropdown}
      />
    ),
  };

  return layoutButton;
};

export default ReadingPaneLayoutButton;
