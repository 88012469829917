const lightBase = {
  accentTextColor: '#0078d4', // Air and Labs
  iconColor: '#0078d4', // Used for Action Icons
  backgroundColor: '#ffffff', // Air and Labs - Investigate
  badgeColor: '#bbbbbb', // Badge in Footer
  borderColor: '#808080', // Documentation, Labs, and Air
  buttonBorderColor: '#323130', // Documentation, Labs, and Air
  dividerColor: '#e0e0e0', // colorNeutralStroke2
  scrollBarColor: '#f0f0f0', // colorNeutralStroke3
  scrollTabColor: '#b3b3b3', // colorNeutralStroke1Pressed
  btnLinkDisabledColor: '#00000040', // Labs
  focusBorderColor: '#0078d4', // Nav and Documentation Focus Custom Component
  footerBackground: '#efefef', // Footer Component Ganymede
  footerBorderColor: '#aaaaaa', // Footer Component Ganymede
  graphFailedColor: '#c90d00', // Donut Graph Chart
  graphFifthColor: '#b3b3b3', // Donut Graph Chart
  graphInProgressColor: '#2d1b94', // Donut Graph Chart
  graphNotStartedColor: '#0078d4', // Donut Graph Chart
  graphSuccessColor: '#228b22', // Donut Graph Chart
  hoverBackgroundColor: '#f3f2f1', // Air and Labs
  labelBackGround: '#fafafa', // Labs
  lineColor: '#eeeff1', // Labs and Air
  linkColor: '#0078d4', // Documentation Component Ganymede
  linkHoverColor: '#004578', // Documentation Component Ganymede
  menuBorderColor: '#d6d6d6', // SideBar Component Ganymede
  menuIconColor: '#333333', // NavLinkItem.module.css
  menuTextColor: '#000000', // NavLinkItem.module.css
  navbarClosedColor: '#f4f4f5', // Investigate App.css (Along side Labs and Air)
  placeHolderTextColor: '#808080', // Investigate PageFilterBar
  primary: '#0078d4', // Labs and Air
  rowEvenBackgroundColor: '#ffffff', // Labs
  rowHoverBackgroundColor: '#f1f1f1', // Labs
  rowSelectBackgroundColor: '#e0e0e0', // Sessions
  rowIconHoverBackGround: '#dad9d8', // Labs
  rowOddBackgroundColor: '#0000000d', // Labs
  sideBarActive: '#cccccc', // NavLinkItem.module.css
  sideBarBackground: '#ffffff', // SideBar Component Ganymede
  sideBarHoverBackground: '#f3f2f1', // Documentation Component and NavLinkItem.module.css
  sideBarSelected: '#edebe9', // Documentation Component and NavLinkItem.module.css
  textColor: '#000000', // ForeColor of titles and labels
  teachingBubbleColor: '#ffffff', // ForeColor and BorderColor in TeachingBubble
  dangerButtonBackground: '#DC3545', // Used in Delte Lab
  dangerButtonTextColor: '#FFFFFF', // Used in Delte Lab
};

const darkBase = {
  accentTextColor: '#1e94ff',
  iconColor: '#1e94ff',
  backgroundColor: '#292929',
  badgeColor: '#242424',
  borderColor: '#525252',
  buttonBorderColor: '#aaaaaa', // Documentation, Labs, and Air
  dividerColor: '#4e4e4e',
  scrollBarColor: '#3d3d3d', // colorNeutralStroke3
  scrollTabColor: '#6b6b6b', // colorNeutralStroke1Pressed
  btnLinkDisabledColor: '#8d8787',
  focusBorderColor: '#2899f5',
  footerBackground: '#515151',
  footerBorderColor: '#aaaaaa',
  graphFailedColor: '#c90d00', // Donut Graph Chart
  graphFifthColor: '#b3b3b3', // Donut Graph Chart
  graphInProgressColor: '#2d1b94', // Donut Graph Chart
  graphNotStartedColor: '#0078d4', // Donut Graph Chart
  graphSuccessColor: '#228b22', // Donut Graph Chart
  hoverBackgroundColor: '#3f3c3c',
  labelBackGround: '#22211f',
  linkColor: '#1890f1',
  linkHoverColor: '#3aa0f3',
  menuBorderColor: '#808080',
  menuIconColor: '#ffffff',
  menuTextColor: '#ffffff',
  placeHolderTextColor: '#a19f9d',
  primary: '#1e94ff',
  rowEvenBackgroundColor: '#454141',
  rowHoverBackgroundColor: '#323130',
  rowSelectBackgroundColor: '#3b3a39', // Sessions
  rowIconHoverBackGround: '#2d2c2b',
  rowOddBackgroundColor: '#0000000d',
  sideBarActive: '#3b3a39',
  sideBarBackground: '#292929',
  sideBarHoverBackground: '#323130',
  sideBarSelected: '#3b3a39',
  textColor: '#ffffff',
  teachingBubbleColor: '#ffffff',
  dangerButtonBackground: '#DC3545',
  dangerButtonTextColor: '#FFFFFF',
};

export { lightBase, darkBase };
