import { action, makeObservable, observable } from 'mobx';
import { inject, singleton } from 'tsyringe';

import LocalStorageService from '@/services/LocalStorageService';
import { ActionTypeWithParam, ActionTypeWithParamReturn, BooleanTypeAny } from '@/types/AppSettingsTypes';

@singleton()
class ExperimentEditorStore {
  public static readonly EXPERIMENT_EDITOR_LABS_TOGGLE = 'editor-labs-toggle';
  public static readonly DEFAULT_FALSE_VALUE: boolean = false;

  public airTemplatesCatalogue: any[] = [];
  public experimentEditorLabsToggle: boolean;
  public fileHandle: FileSystemFileHandle;
  public fileName: string;
  public experiment;
  public getLabs: Map<any, any[]>;
  public getMachinesCatalogue: PublishMachineCatalogue[] = [];
  public isExperimentLoading: boolean;
  public isExperimentSaved: boolean;
  public isTemplateLoaded: boolean;
  public isTemplateLoading: boolean;
  public labsTemplatesCatalogue: any[] = [];
  public updateExperiment: boolean;

  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    this.experimentEditorLabsToggle =
      this.localStorage.getValue(ExperimentEditorStore.EXPERIMENT_EDITOR_LABS_TOGGLE) || ExperimentEditorStore.DEFAULT_FALSE_VALUE;

    this.experiment = '';
    this.isExperimentLoading = false;
    this.isExperimentSaved = true;
    this.isTemplateLoaded = false;
    this.isTemplateLoading = false;

    makeObservable(this, {
      airTemplatesCatalogue: observable,
      experiment: observable,
      fileHandle: observable,
      fileName: observable,
      getLabs: observable,
      getMachinesCatalogue: observable,
      getTemplateCatalogue: observable,
      isExperimentLoading: observable,
      isTemplateLoaded: observable,
      isTemplateLoading: observable,
      labsTemplatesCatalogue: observable,
      updateExperiment: observable,

      setExperiment: action,
      setExperimentSaved: action,
      setFileHandle: action,
      setFileName: action,
      setTemplates: action,
      setUpdateExperiment: action,
    });
  }

  public setExperiment: ActionTypeWithParam = (experiment: string) => {
    this.experiment = experiment;
  };

  public setExperimentEditorLabsToggle: BooleanTypeAny = (value: boolean) => {
    this.experimentEditorLabsToggle = value;
    return this.localStorage.setValue(ExperimentEditorStore.EXPERIMENT_EDITOR_LABS_TOGGLE, value);
  };

  public setExperimentSaved: ActionTypeWithParam = (isExperimentSaved: boolean) => {
    this.isExperimentSaved = isExperimentSaved;
  };

  public getTemplateCatalogue: ActionTypeWithParamReturn = (labs: boolean) => {
    return labs ? this.labsTemplatesCatalogue : this.airTemplatesCatalogue;
  };

  public setFileHandle = (fileHandle: FileSystemFileHandle) => {
    this.fileHandle = fileHandle;
  };

  public setFileName = (fileName: string) => {
    this.fileName = fileName;
  };

  public setMachinesCatalogue: ActionTypeWithParam = (Labs: PublishMachineCatalogue[]) => {
    this.getMachinesCatalogue = Labs;
  };

  public setLab: ActionTypeWithParam = (Lab: any) => {
    this.getLabs.set(Lab, []);
  };

  public setLabMachines: ActionTypeWithParam = (Lab: any, Machines: any[]) => {
    this.getLabs.set(Lab, Machines);
  };

  public setLoadedTemplate: ActionTypeWithParam = (loadedTemplate: boolean) => {
    this.isTemplateLoaded = loadedTemplate;
  };

  public setLoadingExperiment: ActionTypeWithParam = (loadingExperiment: boolean) => {
    this.isExperimentLoading = loadingExperiment;
  };

  public setLoadingTemplates: ActionTypeWithParam = (loadingTemplates: boolean) => {
    this.isTemplateLoading = loadingTemplates;
  };

  public setTemplates: ActionTypeWithParam = (templates: any[], labs: boolean, oldAIRTemplates = false) => {
    // To handle the old AIR templates coming from Juno, need to support this for at least a while
    if (oldAIRTemplates) {
      this.airTemplatesCatalogue = this.airTemplatesCatalogue.concat(
        templates.map((template) => ({
          id: template.id,
          description: template.definition.description,
          experiment: template,
        })),
      );

      return;
    }

    if (labs) {
      this.labsTemplatesCatalogue = templates;
    } else {
      this.airTemplatesCatalogue = templates;
    }
  };

  public setUpdateExperiment: ActionTypeWithParam = (updateExperiment: boolean) => {
    this.updateExperiment = updateExperiment;
  };
}

export class PublishMachineCatalogue {
  public Name: string;
  public Ip: string;
  public Id: string;
  public LabId: string;

  constructor(Name: string, Ip: string, Id: string, LabId: string) {
    this.Name = Name;
    this.Ip = Ip;
    this.Id = Id;
    this.LabId = LabId;
  }
}

export default ExperimentEditorStore;
