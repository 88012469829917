import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, PanelType } from '@fluentui/react';
import { t } from 'i18next';

import { LabSystemDetailsViewController } from '@/components/ManageLab/LabSystemDetails/LabSystemDetailsViewController';
import LabSystemDetailsViewModel from '@/components/ManageLab/LabSystemDetails/LabSystemDetailsViewModel';
import { PanelPage } from '@/constants/LabDetailsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { SidePanelUserSettingsType } from '@/partials/SidePanel/SidePanelTypes';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import LabDetailsPanelTemplate from './LabDetailsPanelTemplate';

const LabDetailsPanelViewControllerFC: React.FC = () => {
  // Store const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labDetailsPanelStore, labSystemsStore } = rootStore;
  const { isOutlookMode } = appSettingsStore;
  const { closeLabDetailsPanel, isLabDetailsPanelOpen, pageContent, panelPage, setPageContent, setUserSettings } =
    labDetailsPanelStore;
  const { selectedSystem } = labSystemsStore;

  const viewModel = React.useMemo(() => {
    if (panelPage === PanelPage.MACHINE_DETAILS) {
      return new LabSystemDetailsViewModel(rootStore);
    }

    return null;
  }, [panelPage, rootStore]);

  React.useEffect(() => {
    let content: JSX.Element = null;

    if (panelPage === PanelPage.MACHINE_DETAILS) {
      content = <LabSystemDetailsViewController viewModel={viewModel}></LabSystemDetailsViewController>;
    }

    setPageContent(content);
  }, [panelPage, setPageContent, viewModel]);

  React.useEffect(() => {
    if (!isOutlookMode) {
      const onRenderFooterContent = () => (
        <div>
          <DefaultButton onClick={closeLabDetailsPanel}>{t(Common.CLOSE, { ns: NS.COMMON })}</DefaultButton>
        </div>
      );

      const panelUserSettings: SidePanelUserSettingsType = {
        headerText: selectedSystem?.systemName,
        type: PanelType.custom,
        isOpen: isLabDetailsPanelOpen,
        onDismiss: closeLabDetailsPanel,
        styles: SidePanelStyles.standard,
        onRenderFooterContent: onRenderFooterContent,
      };

      setUserSettings(panelUserSettings);
    }
  }, [closeLabDetailsPanel, selectedSystem, isLabDetailsPanelOpen]);

  if (isOutlookMode) {
    return <>{pageContent}</>;
  }

  return <LabDetailsPanelTemplate />;
};

const LabDetailsPanelViewController = observer(LabDetailsPanelViewControllerFC);

export default LabDetailsPanelViewController;
