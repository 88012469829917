/*----------------------------------------------------------------------------------------------------------------------------
# ExperimentEditorTemplate.tsx: 
# This Template is our ExperimentEditor Page. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import ExperimentEditorBetaViewController from '@/components/AssetManagement/AssetManagementViewController';
import ExperimentEditorBetaViewModel from '@/components/AssetManagement/AssetManagementViewModel';
import { LabUpgradeTemplateInfo } from '@/components/ExperimentEditor/ExperimentEditorTypes';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const ExperimentEditorFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages, addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const experimentEditorViewModel = new ExperimentEditorBetaViewModel(rootStore);

  const labUpgradeTemplateInfo = useLocation<LabUpgradeTemplateInfo>();
  const title = t('asset-management', { ns: NS.TITLES });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top padding-bottom">
          <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.ASSET_MANAGEMENT]}>{title}</PageHeader>
          <PageContent>
            <ExperimentEditorBetaViewController viewModel={experimentEditorViewModel} />
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const ExperimentEditor = observer(ExperimentEditorFC);

export default ExperimentEditor;
