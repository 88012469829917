import React from 'react';
import { ReactI18NextChild } from 'react-i18next';
import { Table } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { Icon, IconButton } from '@fluentui/react';
import { IIconProps } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { BasicModalComponent } from '@/components/_labs/LabsHelper/ModalComponents/BasicModalComponent';
import { ConfirmationComponent } from '@/components/_labs/LabsHelper/ModalComponents/ConfirmationComponent';
import { SystemIcons } from '@/constants/IconConstants';
import { Buttons, Labels, TableHead } from '@/constants/LabsConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { labsRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';

import { AddRackManager } from './AddRackManager';

import '@/styles/Labs.css';

const ManageRackManagerFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [ipAddress, setIpAddress] = React.useState<string>('');
  const [loginId, setLoginId] = React.useState<string>('');
  const [addedRack, setAddedRack] = React.useState<any>();
  const [selectedId, setSelectedId] = React.useState<string>('');
  const [isAddRackManagerOpen, { setTrue: showAddRackManagerModal, setFalse: hideAddRackManagerModal }] = useBoolean(false);
  const [isEditRackManagerOpen, { setTrue: showEditRackManagerModal, setFalse: hideEditRackManagerModal }] = useBoolean(false);
  const [isDeleteRackManagerOpen, { setTrue: showDeleteRackManagerModal, setFalse: hideDeleteRackManagerModal }] =
    useBoolean(false);
  const linkRef = React.useRef(null);

  const editIcon: IIconProps = { iconName: SystemIcons.EDIT };
  const DeleteIcon: IIconProps = { iconName: SystemIcons.DELETE };

  const modals = [isAddRackManagerOpen, isEditRackManagerOpen];
  const isChildModalOpen = modals.some((modal) => modal);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, isChildModalOpen]);

  const handleDelete = (id: string, ipAddress: string, loginId: string) => {
    setSelectedId(id);
    setIpAddress(ipAddress);
    setLoginId(loginId);
    showDeleteRackManagerModal();
  };

  const handleEditButtonClick = (id: string, ipAddress: string, loginId: string) => {
    setSelectedId(id);
    setIpAddress(ipAddress);
    setLoginId(loginId);
    showEditRackManagerModal();
  };

  const getAddedRackManagerState = async () => {
    labsRequestService
      .getAddRackManagerState(props.labId)
      .then((response) => {
        if (response) {
          setAddedRack(response);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore: appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  };

  React.useEffect(() => {
    getAddedRackManagerState();
  }, []);

  const getModalComponent = () => {
    if (isAddRackManagerOpen) {
      return (
        <BasicModalComponent
          isModalOpen={isAddRackManagerOpen}
          hideModal={hideAddRackManagerModal}
          labId={props.labId}
          page={AddRackManager}
          action={Labels.addRackManager}
          fetchLists={getAddedRackManagerState}
          title={t('add-rack-manager', { ns: NS.TITLES })}
        />
      );
    }

    if (isEditRackManagerOpen) {
      return (
        <BasicModalComponent
          isModalOpen={isEditRackManagerOpen}
          hideModal={hideEditRackManagerModal}
          labId={props.labId}
          selectedId={selectedId}
          ipAddress={ipAddress}
          loginId={loginId}
          page={AddRackManager}
          action={Labels.editRackManager}
          fetchLists={getAddedRackManagerState}
          title={t('edit-rack-manager', { ns: NS.TITLES })}
        />
      );
    }

    if (isDeleteRackManagerOpen) {
      return (
        <ConfirmationComponent
          isConfirmModalOpen={isDeleteRackManagerOpen}
          hideConfirmModal={hideDeleteRackManagerModal}
          labId={props.labId}
          selectedItemKey={selectedId}
          ipAddress={ipAddress}
          loginId={loginId}
          action={Labels.deleteAddedRackManager}
          fetchLists={getAddedRackManagerState}
        />
      );
    }
  };

  const populateTable = () => (
    <Table striped>
      <thead>
        <tr>
          <th>IP Address</th>
          <th>Login ID</th>
          <th>Status</th>
          <th>Added By</th>
          <th>Error</th>
          <th>{TableHead.actions}</th>
        </tr>
      </thead>
      <tbody>
        {addedRack &&
          addedRack
            .filter(
              (item: { MessageType: string; Status: string }) =>
                item.MessageType !== Labels.deleteRackManagerAndSystems ||
                (item.MessageType === Labels.deleteRackManagerAndSystems && item.Status !== Labels.completed),
            )
            .map(
              (
                item: {
                  Message: string;
                  Status:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | Iterable<ReactI18NextChild>
                    | null
                    | undefined;
                  UserEmail:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | Iterable<ReactI18NextChild>
                    | null
                    | undefined;
                  // eslint-disable-next-line @typescript-eslint/ban-types
                  Error: {} | null | undefined;
                  MessageType: string;
                  LabControllerMessageId: string;
                },
                index: React.Key | null | undefined,
              ) => {
                const parsedMessage = JSON.parse(item.Message);

                return (
                  <tr key={index}>
                    <td>{parsedMessage.IpAddress}</td>
                    <td>{parsedMessage.LoginId}</td>
                    <td>{item.Status}</td>
                    <td>{item.UserEmail}</td>
                    <td>{item.Error}</td>
                    <td>
                      {item.MessageType === Labels.deleteRackManagerAndSystems ? (
                        item.Error === null ? null : (
                          <>
                            <IconButton
                              iconProps={DeleteIcon}
                              title="Delete"
                              ariaLabel="delete"
                              onClick={() =>
                                handleDelete(item.LabControllerMessageId, parsedMessage.IpAddress, parsedMessage.LoginId)
                              }
                            />
                          </>
                        )
                      ) : (
                        <>
                          <IconButton
                            iconProps={editIcon}
                            title={t('edit', { ns: NS.COMMON })}
                            onClick={() =>
                              handleEditButtonClick(item.LabControllerMessageId, parsedMessage.IpAddress, parsedMessage.LoginId)
                            }
                          />
                          <IconButton
                            iconProps={DeleteIcon}
                            title={t('delete', { ns: NS.COMMON })}
                            onClick={() =>
                              handleDelete(item.LabControllerMessageId, parsedMessage.IpAddress, parsedMessage.LoginId)
                            }
                          />
                        </>
                      )}
                    </td>
                  </tr>
                );
              },
            )}
      </tbody>
    </Table>
  );

  const systemMessages = globalMessages.filter((i: SystemMessageType) => i.showInPopup);

  return (
    <div>
      {!isChildModalOpen && <MessageBarTemplate>{systemMessages}</MessageBarTemplate>}
      <div className="text-left" id="list-view-container">
        <div className="row blue-bar text-right">
          <div className="col-sm-12 float-right">
            <button
              ref={linkRef}
              aria-label={Labels.iconButton}
              className="icon-buttons"
              title={Buttons.addRackManager}
              onClick={showAddRackManagerModal}
            >
              <Icon iconName="ExploreContentSingle" className="ms-IconErrorBadge" />
            </button>
          </div>
        </div>
        {populateTable()}
        {getModalComponent()}
      </div>
    </div>
  );
};

export const ManageRackManager = observer(ManageRackManagerFC);
