import { LabIcons } from './IconConstants';

const Navigation = {
  ROOT: '/',
  AIR: {
    HOME: '/air',
    // CREATE EXPERIMENT
    CREATE_EXPERIMENT: '/air/createExperiment/experimentTemplate/create',
    SUCCESS_PAGE: '/air/createExperiment/experimentTemplate/create/successPage',
    // EXPERIMENT RESULTS
    VIEW_STATUS: '/air/experimentResults',
    SCENARIO: '/air/experimentResults/scenario',
    // MANAGE EXPERIMENTS
    MANAGE_EXPERIMENT: '/air/manageExperiments',
    SCHEDULER_STATUS: '/air/manageExperiments/schedulerStatus',
    INSTANCE_FAILURE: '/air/manageExperiments/experimentInstanceFailureAnalysis',
    EXECUTION_GOAL_FAILURE: '/air/manageExperiments/executionGoalFailureAnalysis',
    TARGET_GOAL_STATUS: '/air/manageExperiments/targetGoalStatus',
    INSTANCE_STATUS: '/air/manageExperiments/experimentInstancesStatus',
    FAILURE_ANALYSIS_DETAILS: '/air/manageExperiments/failureAnalysisFullDetails',
    // OTHERS
    PAGE_NOT_FOUND: '/air/404',
    WORKLOAD_PROFILE: '/air/workloadProfile',
    WORKLOAD_INFO: '/air/workloadInfo',
    WORKLOAD_INFO_COLOR: '/air/workloadInfoForColor',
  },
  DEPRECATE: {
    // These endpoints should occasionally be cleaned up and removed.
    ACCESSIBILITY_REPORT: '/accessibilityReport',
    RELEASE_NOTES: '/releasenotes',
    RESULT_EXPLORER: '/resultExplorer',
    SESSIONS: '/new-experiments',
  },
  DIVIDERS: {
    ROOT: 'root-divider',
    AIR: 'air-divider',
    DEV: 'dev-divider',
    LABS: 'labs-divider',
    EDITOR: 'editor-divider',
  },
  GANYMEDE: {
    ABOUT: '/about',
    ACCESSIBILITY_REPORT: '/accessibility-report',
    ALERTS: '/alerts',
    ASSET_MANAGEMENT: '/assets-management',
    BUGS: '/issues',
    CHANGE_HISTORY: '/changehistory',
    DASHBOARDS: '/dashboards',
    DASHBOARDS_MYDASHBOARD: '/dashboards/my-dashboard',
    EXPERIMENTS: '/legacy-experiments',
    EXPERIMENT_EDITOR: '/experiment-editor',
    FIRMWARE: '/firmware',
    FAVORITES: '/favorites',
    FAVORITES_MYFAVORITES: '/favorites/my-favorites',
    HACKATHON_DASHBOARD: '/hackdash',
    HOME: '/home',
    INSIGHTS: '/insights',
    ISSUES: '/issues',
    LAB_DETAILS: '/lab-details',
    LABS: '/labs',
    LABS_BETA: '/labs-beta',
    PAYLOADS: '/payloads',
    PLACEHOLDER: '/placeholder',
    PLAYGROUND: '/playground',
    PLAYGROUND_AIR: '/playground/air',
    PLAYGROUND_LABS: '/playground/labs',
    PROJECT_DOCUMENTATION: '/documentation',
    PROJECTS: '/projects',
    RESULT_EXPLORER: '/result-explorer',
    RESULTS: '/results',
    RELEASE_NOTES: '/release-notes',
    SESSIONS: '/experiments',
    SETTINGS: '/settings',
    VIEW_LAB: '/labs/view',
  },
  HOME: '/home', // Redirects to the root.
  JUNO: {
    HOME: '/juno', // Redirects to AIR.
  },
  LABS: {
    HOME: '/labs',
    // MY LABS
    // MY_LABS: '/labs/myLabs', // TBR
    CREATE_NEW_LAB: '/labs/myLabs/modal/createLab',
    CREATE_VIRTUAL_LAB: '/labs/myLabs/modal/createVirtualLab',
    LAB_CONTROL: '/labs/myLabs/manage/lab',
    LAB_EXPERIMENTS: '/labs/myLabs/manage/experiments',
    // MY DASHBOARD
    MY_DASHBOARD: '/labs/myDashboard',
    RELEASE_TOP_RESULTS: '/labs/myDashboard/releaseResults',
    RESULT_SEARCH: '/labs/myDashboard/resultSearch',
    TEST_RUN: '/labs/myDashboard/resultSearch/testRun',
    VIEW_TEST_REPORTS: '/labs/myDashboard/reports',
    VIEW_REPORT: '/labs/myDashboard/reports/view',
    LOG_FOLDER_VIEW: '/labs/logFolderView',
    RESULT_TRIAGE: '/labs/resultTriage',
    ADD_RULE: '/labs/addRule',
    // MANAGE PACKAGES
    MANAGE_PACKAGES: '/labs/managePackages',
    // OTHERS
    PAGE_NOT_FOUND: '/labs/404',
    OVERAGE: '/labs/overage',
  },
  PAGE_NOT_FOUND: '/404',
  RELIABILITY: '/reliability',
  VEGA: {
    HOME: '/vega', // Redirects to LABS.
  },
};

// NOTE: Use icons from: https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
// A larger set of icons can be found here: https://iconcloud.design/search/filter/Full%20MDL2%20Assets

const NavigationIcon = {
  // Routes based icons.
  [Navigation.HOME]: 'HomeSolid',
  [Navigation.ROOT]: 'HomeSolid',

  // AIR based icons.
  [Navigation.AIR.HOME]: 'PageData',
  [Navigation.AIR.CREATE_EXPERIMENT]: 'AddIn',
  [Navigation.AIR.VIEW_STATUS]: 'ViewDashboard',
  [Navigation.AIR.MANAGE_EXPERIMENT]: 'StackedLineChart',

  // Ganymede based icons.
  [Navigation.GANYMEDE.ACCESSIBILITY_REPORT]: 'ComplianceAudit',
  [Navigation.GANYMEDE.ASSET_MANAGEMENT]: 'DocumentSet',
  [Navigation.GANYMEDE.BUGS]: 'BugSolid',
  [Navigation.GANYMEDE.CHANGE_HISTORY]: 'FullHistory',
  [Navigation.GANYMEDE.DASHBOARDS]: 'ViewDashboard',
  [Navigation.GANYMEDE.EXPERIMENTS]: 'Home', // The legacy icon for the application (which is a home outline).
  [Navigation.GANYMEDE.EXPERIMENT_EDITOR]: 'AddIn',
  [Navigation.GANYMEDE.FIRMWARE]: 'PageData',
  [Navigation.GANYMEDE.INSIGHTS]: 'CRMCustomerInsightsApp',
  [Navigation.GANYMEDE.ISSUES]: 'IssueSolid',
  [Navigation.GANYMEDE.LABS]: 'TestBeakerSolid',
  [Navigation.GANYMEDE.LABS_BETA]: LabIcons.RACK,
  [Navigation.GANYMEDE.PLAYGROUND]: 'Game',
  [Navigation.GANYMEDE.PROJECTS]: 'Folder',
  [Navigation.GANYMEDE.RESULT_EXPLORER]: 'ExploreData',
  [Navigation.GANYMEDE.RESULTS]: 'BarChartHorizontal',
  [Navigation.GANYMEDE.SESSIONS]: 'HomeSolid', // The main icon for the application (which is a solid home).

  // Labs based icons.
  [Navigation.LABS.HOME]: 'TestBeakerSolid',
  [Navigation.LABS.MANAGE_PACKAGES]: 'DocumentSet',
  [Navigation.LABS.MY_DASHBOARD]: 'ViewDashboard',
  // [Navigation.LABS.MY_LABS]: 'TestBeakerSolid', // TBR
  [Navigation.LABS.VIEW_TEST_REPORTS]: 'BookmarkReport',
  [Navigation.LABS.VIEW_REPORT]: 'BookmarkReport',
  [Navigation.LABS.RELEASE_TOP_RESULTS]: 'CheckList',
  [Navigation.LABS.RESULT_SEARCH]: 'SearchAndApps',
  [Navigation.LABS.CREATE_NEW_LAB]: 'Label', // TBR
  [Navigation.LABS.CREATE_VIRTUAL_LAB]: 'CloudAdd', // TBR
};

export { Navigation, NavigationIcon };
