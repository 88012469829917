import React from 'react';
import { Namespace } from 'react-i18next';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { DetailsRow, Sticky, StickyPositionType, TooltipHost } from '@fluentui/react';
import { ScrollablePane } from '@fluentui/react';
import { IDetailsCheckboxProps, IDetailsHeaderProps } from '@fluentui/react/lib/DetailsList';
import { IDropdownOption, IDropdownProps } from '@fluentui/react/lib/Dropdown';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { Checkbox } from '@fluentui/react-components';
import { t } from 'i18next';
import format from 'string-template';
import { container } from 'tsyringe';

import { DataType, EventKeys, Namespaces as NS } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import LocalStorageService from '@/services/LocalStorageService';
import { QColumn, RenderRowType, SortedColumnInfo, TableState, TableType } from '@/types/TableTypes';
import { setTableDataGroupBy } from '@/utils/GroupBy';

import { numberDefaults, renderStyles, stringDefaults, tableDefaults } from './TableView.config';
import TableViewStore from './TableViewStore';
import TableTemplate from './TableViewTemplateInfiniteScroll';

// Do i18n translations on columns here.
const checki18column = (column: QColumn, autoTranslations: boolean) => {
  if (!autoTranslations) {
    return column;
  }

  column.name = t(column.name, { ns: NS.TABLE });

  if (column.sortAscendingAriaLabel && column.data === 'string') {
    column.sortAscendingAriaLabel = t(column.sortAscendingAriaLabel, { ns: NS.TABLE });
  }

  if (column.sortDescendingAriaLabel && column.data === 'string') {
    column.sortDescendingAriaLabel = t(column.sortDescendingAriaLabel, { ns: NS.TABLE });
  }

  return column;
};

export const i18columns = (columns: QColumn[], autoTranslations: boolean): QColumn[] => {
  return columns.map((column: QColumn) => checki18column(column, autoTranslations));
};

// Allows us to apply a set of default values for our Table configurations.
const applyColumnDefaults = (column: QColumn) => {
  // Allows for a QuickColumn to become an IColumn, by filling in any missing required values.
  if (column.data === 'number') {
    column.minWidth = column.minWidth !== undefined ? column.minWidth : numberDefaults.minWidth;
    column.maxWidth = column.maxWidth !== undefined ? column.maxWidth : numberDefaults.maxWidth;
  }

  // If we have a string without a minimum width declared, use the default.
  if (!column.minWidth && (!column.data || column.data === 'string')) {
    column.minWidth = stringDefaults.minWidth;
  }

  // Allows us to auto-fill the key field, when not providing, by assigning it to the field.
  if (column.key === undefined) {
    column.key = column.name;
  }

  return { ...tableDefaults, ...column };
};

export const setColumnDefaults = (columns: QColumn[], sortedColumn?: SortedColumnInfo) => {
  if (sortedColumn) {
    const updatedCol: QColumn[] = columns.map((col: QColumn) => {
      if (col.fieldName === sortedColumn.column) {
        return { ...col, isSortedDescending: sortedColumn.isSortedDescending, isSorted: true };
      }

      return { ...col, isSorted: false };
    });

    return updatedCol?.map((column: QColumn) => applyColumnDefaults(column));
  }

  return columns?.map((column: QColumn) => applyColumnDefaults(column));
};

// Replace click events if a column is not sortable.
const cleanColumnClickEvent = (
  column: QColumn,
  enableColumnSort: boolean,
  groupByColumn: string,
  groupByColumns: any[],
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: QColumn,
    groupByColumn: string,
    groupByColumns: any[],
  ) => void,
) => {
  if (column.onColumnClick || !enableColumnSort || !column['isColumnSort']) {
    return column;
  }

  return {
    ...column,
    onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onColumnClick(ev, column, groupByColumn, groupByColumns);
    },
  };
};

export const cleanClickEvents = (
  columns: QColumn[],
  enableColumnSort: boolean,
  groupByColumn: string,
  groupByColumns: any[],
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: QColumn,
    groupByColumn: string,
    groupByColumns: any[],
  ) => void,
) => {
  return columns.map((column: QColumn) =>
    cleanColumnClickEvent(column, enableColumnSort, groupByColumn, groupByColumns, onColumnClick),
  );
};

class TableViewControllerFC extends React.Component<TableType, TableState> {
  constructor(props: TableType) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      items: [],
      columns: [],
      selectedColumnKeys: [],
      groups: [],
      isLoadingMore: false,
      isReloaded: true,
    };
  }

  tableViewStore = new TableViewStore(container.resolve(LocalStorageService));
  detailsListContentElement = '.ms-DetailsList-contentWrapper';

  componentDidMount(): void {
    const { items, columns, displayColumns, enableToolBar, pageName, fetchData } = this.props;
    const sortedColumn = pageName ? this.tableViewStore.sortedColumnMemory[pageName as string] : null;

    this.setState({
      items: sortedColumn ? this._copyAndSort(items, sortedColumn.column, sortedColumn.isSortedDescending) : items,
      columns: setColumnDefaults(columns, sortedColumn),
      selectedColumnKeys: displayColumns ?? [],
      isLoadingMore: false,
      isReloaded: true,
    });

    this.gatherGroups();

    if (displayColumns && enableToolBar) {
      this._onColumnOption(displayColumns);
    }
  }

  componentDidUpdate(prevProps: Readonly<TableType>): void {
    const { isLoadingMore, items: stateItems } = this.state;
    const { items, columns, pageName } = this.props;

    if (isLoadingMore) {
      return;
    }

    const sortedColumn: SortedColumnInfo = pageName ? this.tableViewStore.sortedColumnMemory[pageName as string] : null;
    const areItemsDifferentFromState: boolean = items !== stateItems;
    const areItemsDifferentFromPrevProps: boolean = prevProps.items !== items;
    const haveColumnsChanged: boolean = prevProps.columns !== columns;

    if (areItemsDifferentFromState || areItemsDifferentFromPrevProps || haveColumnsChanged) {
      this.setState({
        items: sortedColumn ? this._copyAndSort(items, sortedColumn.column, sortedColumn.isSortedDescending) : items,
        columns: setColumnDefaults(columns, sortedColumn),
        isReloaded: stateItems?.length === 0,
      });

      this.gatherGroups();
    }

    if (items?.length > 0) {
      const scrollableElement: Element = document.querySelector(this.detailsListContentElement);

      if (scrollableElement) {
        scrollableElement.addEventListener(EventKeys.SCROLL, this.handleScroll);
      }
    }
  }

  componentWillUnmount() {
    const scrollableElement: Element = document.querySelector(this.detailsListContentElement);

    if (scrollableElement) {
      scrollableElement.removeEventListener(EventKeys.SCROLL, this.handleScroll);
    }
  }

  handleScroll(event: any) {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    const scroll_threshold_ratio = 0.1;
    const scrollPosition: number = scrollHeight - scrollTop - clientHeight;
    const threshold: number = scrollHeight * scroll_threshold_ratio;

    if (scrollPosition <= threshold) {
      this.loadMoreItems();
    }
  }

  loadMoreItems = async (): Promise<void> => {
    const { items, isLoadingMore } = this.state;
    const { fetchData, setTableItems, itemsCount } = this.props;

    const allItemsLoaded: boolean = items?.length === itemsCount;

    if (allItemsLoaded || isLoadingMore) return;

    this.setState({ isLoadingMore: true });

    try {
      const newItems: any = (await fetchData(items?.length)) || 0;

      if (newItems?.length > 0) {
        const updatedItems: any[] = [...items, ...newItems];

        this.gatherGroups(updatedItems);
        this.setState({
          isLoadingMore: false,
          isReloaded: false,
        });

        setTableItems(updatedItems);
      } else {
        this.setState({
          isLoadingMore: false,
          isReloaded: false,
        });
      }
    } catch (error) {
      console.error('Error loading more items:', error);
    }
  };

  // Gather the groups for the table since the state items may be different than what was saved in the state.
  gatherGroups = (allItems?: any[]) => {
    const { items, columns, pageName, groupByColumn, groupByColumns } = this.props;
    const sortedColumn = pageName ? this.tableViewStore.sortedColumnMemory[pageName as string] : null;

    const tableItems = allItems || items;

    if (groupByColumn) {
      const { returnData, groups } = setTableDataGroupBy(tableItems, groupByColumn, groupByColumns, true);

      this.setState({
        columns: setColumnDefaults(columns, sortedColumn),
        items: returnData,
        groups: groups,
      });
    }
  };

  onRenderDetailsHeader = (headerProps: IDetailsHeaderProps | undefined, defaultRender: any) => {
    const { isStickyHeader = true } = this.props;

    if (!headerProps || !defaultRender) {
      return null;
    }

    const defRender = defaultRender({
      ...headerProps,
      styles: renderStyles,
    });

    if (isStickyHeader) {
      return (
        <Sticky stickyPosition={StickyPositionType.Header} stickyBackgroundColor="transparent">
          {defRender}
        </Sticky>
      );
    }

    return defRender;
  };

  onRenderCheckbox(props: IDetailsCheckboxProps) {
    return (
      <div>
        <Checkbox checked={props.checked} />
      </div>
    );
  }

  _copyAndSort<T extends object>(items: T[], columnKey?: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;

    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key as string] < b[key as string] : a[key as string] > b[key as string]) ? 1 : -1,
      );
  }

  _onColumnClick = async (
    ev: React.MouseEvent<HTMLElement>,
    column: QColumn,
    groupByColumn?: string,
    groupByColumns?: any[],
  ): Promise<void> => {
    const { columns, items } = this.state;
    const { pageName } = this.props;
    const newColumns: QColumn[] | undefined = columns?.slice();
    const currColumn: QColumn | undefined = newColumns?.filter((currCol) => column.key === currCol.key)[0];

    newColumns?.forEach((newCol: QColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    // TODO: @Nick - can you see if you can remove this use of the non-null assertion that was added 6 months ago?
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newItems = this._copyAndSort(items, currColumn && currColumn.fieldName!, currColumn?.isSortedDescending);

    if (pageName) {
      this.tableViewStore.setSortedColumnMemory(pageName, currColumn?.fieldName ?? '', currColumn?.isSortedDescending);
    }

    if (groupByColumn) {
      const { returnData, groups } = setTableDataGroupBy(newItems, groupByColumn, groupByColumns);

      this.setState({
        columns: setColumnDefaults(newColumns),
        items: returnData,
        groups: groups,
      });
    } else {
      this.setState({
        columns: setColumnDefaults(newColumns),
        items: newItems,
      });
    }
  };

  _onColumnOption = (selectedColumns: string[]) => {
    const { columns } = this.props;

    const newColumns: QColumn[] = [];

    columns?.forEach((currCol: QColumn) => {
      if (selectedColumns.includes(currCol.name)) {
        newColumns.push(currCol);
      }
    });

    this.setState({
      columns: setColumnDefaults(newColumns),
      selectedColumnKeys: selectedColumns,
    });
  };

  _formatToDropdownOptions = (list: Array<QColumn>): IDropdownOption[] => {
    if (list) {
      const Options: IDropdownOption[] = list.map((item) => {
        return {
          key: item.name,
          text: item.name,
        };
      });
      return Options;
    }

    return [];
  };

  onRenderRow: RenderRowType = (props: any) => {
    if (props) {
      return (
        <div>
          <DetailsRow {...props} />
        </div>
      );
    }

    return null;
  };

  _containsFilter = (i: any, filter: string) => {
    for (const key in i) {
      if (i[key as string].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        return true;
      }
    }

    return false;
  };

  _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
    const { items } = this.props;

    if (text) {
      const newItems = items.filter((i) => this._containsFilter(i, text));

      this.setState({
        items: newItems,
      });
    } else {
      this.setState({
        items: items,
      });
    }
  };

  render() {
    const {
      autoTranslations = true, // Set to false when translations are not wanted (displaying raw data)
      checkboxVisibility,
      columns,
      enableColumnSort = true,
      enableToolBar = true,
      fetchData,
      groupByColumn,
      groupByColumns,
      groups,
      isCompact,
      isLoading,
      isSelectedOnFocus = true,
      itemsCount,
      namespace = NS.TABLE, // The default namespace used for tables.
      onFilter,
      onRenderCheckbox,
      onRenderDetailsHeader,
      onRenderRow,
      paginationType,
      selection,
      selectionMode = SelectionMode.single,
      selectionPreservedOnEmptyClick,
      tableStyle,
      title,
    } = this.props;

    const { items: stateItems, columns: stateColumns, selectedColumnKeys, isReloaded } = this.state;
    const displayTitle: string = autoTranslations && title ? t(title, { ns: namespace }) : title || '';

    const orderedColumns = (): QColumn[] => {
      const defaultColumns: QColumn[] = stateColumns.filter((column: any) => !column.isOptional && !column.isHidden);

      return defaultColumns.sort((a: any, b: any) => a.index - b.index);
    };

    // Define the function to add title tag
    const addTitleTag = (columnsList: any): any => {
      return columnsList.map((column: QColumn) => {
        return {
          ...column,
          onRender: (item: any) => {
            const data = item[column.fieldName];

            if (column.onRender == null) {
              if (typeof data === DataType.STRING || typeof data === DataType.NUMBER) {
                // Check if the data is serializable
                // If it is a string or number, set the title
                return <span title={data}>{data}</span>;
              } else {
                // Otherwise return the original data
                return data;
              }
            } else {
              return column.onRender(item);
            }
          },
        };
      });
    };

    // Use the function in the columnsList definition
    const columnsList = addTitleTag(
      i18columns(
        setColumnDefaults(cleanClickEvents(orderedColumns(), enableColumnSort, groupByColumn, groupByColumns, this._onColumnClick)),
        autoTranslations,
      ),
    );

    const columnOptionsProp: IDropdownProps = {
      placeholder: t('column-options', { ns: namespace }),
      options: this._formatToDropdownOptions(columns),
      onRenderTitle: () => {
        return t('column-options', { ns: namespace });
      },
    };

    const showPagination = paginationType?.showPagination && stateItems?.length > 0;

    /* 
    NOTE: This does a better job at rendered Tooltips, however we believe it needs more research 
    as it's using the onRender method, which could possibly override a user that is passing a custom method. 
    We will hold off from using this method for a future sprint.
    */
    const columnsWithTooltip = columnsList.map((column: QColumn) => {
      return {
        ...column,
        onRender: (item: any) => {
          return (
            <TooltipHost content={item[column.fieldName]}>
              <span>{item[column.fieldName]}</span>
            </TooltipHost>
          );
        },
      };
    });

    const allRecordsMessage: string =
      fetchData &&
      stateItems?.length > 0 &&
      format(t(Common.STANDARD_INFO_TEMPLATE, { ns: NS.COMMON }), {
        startIndex: 1,
        endIndex: stateItems?.length,
        totalItems: itemsCount,
      });

    // const filteredRecordsMessage: string = format(t(Common.STANDARD_FILTER_TEMPLATE, { ns: NS.COMMON }), {
    //   startIndex: 1,
    //   endIndex: stateItems?.length,
    //   totalItems: itemsCount,
    // });

    const tableGroups: any[] = fetchData ? this.state.groups : groups;

    return (
      <TableTemplate
        tableStyle={tableStyle ? tableStyle : undefined}
        items={stateItems}
        columns={columnsList} // We do our i18n translations to all columns provided.
        groups={tableGroups}
        enableToolBar={enableToolBar}
        selection={selection}
        selectionMode={selectionMode}
        isSelectedOnFocus={isSelectedOnFocus}
        selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
        checkboxVisibility={checkboxVisibility}
        onRenderCheckbox={onRenderCheckbox ? onRenderCheckbox : this.onRenderCheckbox}
        title={displayTitle}
        onFilter={onFilter ? onFilter : this._onFilter}
        onRenderDetailsHeader={onRenderDetailsHeader ? onRenderDetailsHeader : this.onRenderDetailsHeader}
        onRenderRow={onRenderRow ? onRenderRow : this.onRenderRow}
        columnsDropdown={{
          dropdownProps: columnOptionsProp,
          multi: true,
          selectedMultiKeys: selectedColumnKeys,
          translationNamespace: namespace as Namespace,
          callBack: this._onColumnOption,
        }}
        displayColumns={selectedColumnKeys}
        showPagination={showPagination}
        paginationType={paginationType}
        isCompact={isCompact}
        isLoading={isLoading}
        isReloaded={isReloaded}
        fetchData={fetchData}
        recordsInformation={allRecordsMessage}
      />
    );
  }
}
const TableViewController = observer(TableViewControllerFC);

export default TableViewController;
