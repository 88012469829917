import React from 'react';
import { Icon } from '@fluentui/react';

import { ResultWithSubTitleProps } from './ResultMessageTypes';

import styles from './ResultMessage.module.css';

const ResultWithSubTitle: React.FC<ResultWithSubTitleProps> = ({ mainTitle, subTitle, iconName }) => (
  <div className={styles['message-wrapper']}>
    {iconName && (
      <div className={styles['larger-icon']}>
        <Icon iconName={iconName} />
      </div>
    )}
    <div className={styles['title']}>{mainTitle}</div>
    {subTitle && <div className={styles['sub-title']}>{subTitle}</div>}
  </div>
);

export { ResultWithSubTitle };
