import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pivot } from '@fluentui/react';
import { PivotItem } from '@fluentui/react';

import { LabDetailsVCType } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import { Namespaces as NS, PageMemoryKeys } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from '@/components/ManageLab/LabDetails/LabDetails.module.css';

const LabDetailsTemplateFC: React.FC<LabDetailsVCType> = (props: LabDetailsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;

  const { components, activeTab } = props;
  const pageName = PageMemoryKeys.LAB_DETAILS;
  const { setTabMemory } = appSettingsStore;

  return (
    <Pivot
      defaultSelectedKey={activeTab}
      onLinkClick={(item) => setTabMemory(pageName, item.props.itemKey)}
      className={styles['pivot-group']}
    >
      {components.map(({ headerText, viewModel, parentViewModel, component: Component }, index) => (
        <PivotItem key={index} itemKey={headerText} headerText={headerText}>
          <Component viewModel={viewModel} parentViewModel={parentViewModel} />
        </PivotItem>
      ))}
    </Pivot>
  );
};

export const LabDetailsTemplate = observer(LabDetailsTemplateFC);
