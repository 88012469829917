import React from 'react';
import { Link } from 'react-router-dom';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { AxiosError, CancelToken } from 'axios';
import { t } from 'i18next';
import moment from 'moment-timezone';
import format from 'string-template';
import { container } from 'tsyringe';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import { Company, UserRole } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import SessionModel from '@/components/Sessions/SessionModel';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { DateFormats } from '@/constants/DateFormatConstants';
import { Statuses } from '@/constants/ExperimentConstants';
import { Labels } from '@/constants/LabsConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { Delimiters, FilterOptions, KeyTextPair, Namespaces as NS } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import { PaginationType } from '@/partials/Pagination/PaginationTypes';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { GanymedeSessionRequestService } from '@/services/request-services/SessionRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { formatDate, getDuration } from '@/utils/Dates';
import { setTableDataGroupBy } from '@/utils/GroupBy';
import { statusCell } from '@/utils/Helpers';

import {
  LocationType,
  MachineTags,
  SessionFailureType,
  SessionFilterType,
  SessionGUIType,
  SessionMachineType,
  SessionType,
} from './SessionsTypes';

import sessionDetailsStyles from '@/components/SessionDetails/SessionDetails.module.css';

class SessionsViewModel {
  public addGlobalMessage;
  public isDebugMode: boolean;

  protected appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);
  protected paginationStore: PaginationStore = container.resolve(PaginationStore);
  protected systemMessageStore: SystemMessageStore = container.resolve(SystemMessageStore);
  protected sessionsStore: SessionsStore = container.resolve(SessionsStore);
  protected userSettingsStore: UserSettingsStore = container.resolve(UserSettingsStore);

  protected _endDate: Date;
  protected _ipAddresses: string[];
  protected _ipAddressPairs: KeyTextPair[];
  protected _lastRunTime: string;
  protected _locationValue: string;
  protected _requestCounter: number;
  protected _searchValue: string;
  protected _startDate: Date;
  protected _statusValues: string[];

  constructor() {
    const { isDebugMode } = this.appSettingsStore;
    const { endDate, ipAddressPairs, ipAddresses, lastRunTimeRange, locationValue, searchValue, startDate, statusValues } =
      this.sessionsStore;

    const { addGlobalMessage } = this.systemMessageStore;

    this.addGlobalMessage = addGlobalMessage;
    this.isDebugMode = isDebugMode;

    this._endDate = endDate;
    this._ipAddresses = ipAddresses;
    this._ipAddressPairs = ipAddressPairs;
    this._lastRunTime = lastRunTimeRange;
    this._locationValue = locationValue;
    this._requestCounter = 0;
    this._searchValue = searchValue;
    this._startDate = startDate;
    this._statusValues = statusValues;
  }

  protected buildDataMapping = (results: any): SessionMachineType[] => {
    const mappedData: SessionMachineType[] = results.map((result: any) => ({
      ipAddress: result.IPAddress,
      name: result.Name,
      tags: result.Tags.map(
        (tag: any): MachineTags => ({
          tagId: tag.TagId,
          tagName: tag.TagName,
        }),
      ),
    }));

    return mappedData;
  };

  static buildSessionViewModel = (session: SessionModel | null): SessionGUIType | null => {
    const userSettingsStore: UserSettingsStore = container.resolve(UserSettingsStore);
    const { timeZone } = userSettingsStore;

    if (session) {
      // Formatted fields for display purposes.
      const displayName = (
        <div>
          <Link
            className={sessionDetailsStyles['title-link']}
            to={{
              pathname: `${Navigation.GANYMEDE.SESSIONS}/${session.id}`,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {session.name}
          </Link>
        </div>
      );
      return Object.assign(session, {
        createdTime: session.created ? formatDate(session.created, timeZone) : '',
        displayInstances: session.instances?.join(', ') || '',
        displayLocation: this.getDisplayLocation(session.location),
        displayName,
        displayStatus: statusCell(session.status.final),
        executedBy: session.owner,
        lastModifiedTime: session.lastModified ? formatDate(session.lastModified, timeZone) : '',
        startedTime: session.started ? formatDate(session.started, timeZone) : '',
      });
    }

    return null;
  };

  static buildExperimentFailure = (rootStore: RootStore, data: SessionFailureType): SessionFailureType => {
    if (data) {
      const diagnosticIssueType: string = data.diagnosticIssueType;
      const experimentName: string = data.experimentName;
      const environment: string = data.environment;
      const experimentId: string = data.experimentId;
      const failureSignature: string = data.failureSignature;
      const failureDetails: string[] = data.failureDetails;
      const key: string = data.key;
      const mitigationStep: string = data.mitigationStep;
      const rca: string = data.rca;
      const stepId: string = data.stepId;
      const timestamp: string = data.timestamp;

      const failure: SessionFailureType = {
        diagnosticIssueType,
        environment,
        experimentId,
        experimentName,
        failureSignature,
        failureDetails,
        key,
        mitigationStep,
        rca,
        stepId,
        timestamp,
      };

      return failure;
    }

    return null as SessionFailureType;
  };

  static getDisplayLocation = (value: LocationType): string => {
    const { labId, labName, location, machineIds, machineNames } = value;

    if (labId) {
      return labId.toString();
    }

    if (labName) {
      return labName;
    }

    if (location) {
      return location;
    }

    if (machineIds) {
      return machineIds.join(',');
    }

    if (machineNames) {
      return machineNames.join(',');
    }

    return Delimiters.DASH;
  };

  public bumpRequestCounter = (requestId: React.MutableRefObject<number>): number => {
    ++this._requestCounter;

    requestId.current = this._requestCounter;

    return this._requestCounter;
  };

  public isRecentSession = (date: string, days: number): boolean => {
    const currentTime = moment.tz(this.userSettingsStore.timeZone);
    const compareDate = moment.tz(date, DateFormats.STANDARD_DATE_TIME, this.userSettingsStore.timeZone);

    const diff = moment.duration(currentTime.diff(compareDate));

    return diff.asDays() < days;
  };

  public calculateStatus = (data: any): string => {
    let finalStatus = t('not-available', { ns: NS.COMMON });

    if (data.length > 0) {
      if (data.every((i) => i.status?.toLowerCase() === Statuses.PENDING)) {
        finalStatus = t('pending', { ns: NS.COMMON });
      } else if (
        data.some((i) => i.status?.toLowerCase() === Statuses.INPROGRESS) ||
        data.some((i) => i.status?.toLowerCase() === Statuses.PENDING)
      ) {
        finalStatus = t('in-progress', { ns: NS.COMMON });
      } else if (data.some((i) => i.status?.toLowerCase() === Statuses.FAILED)) {
        finalStatus = t('failed', { ns: NS.COMMON });
      } else if (data.some((i) => i.status?.toLowerCase() === Statuses.CANCELLED)) {
        finalStatus = t('cancelled', { ns: NS.COMMON });
      } else if (data.every((i) => i.status?.toLowerCase() === Statuses.SUCCEEDED)) {
        finalStatus = t('succeeded', { ns: NS.COMMON });
      }
    }

    return finalStatus;
  };

  // Let us know when any filters have been applied.
  public hasFiltersApplied = (): boolean => {
    const dateFilter = this._lastRunTime !== FilterOptions.ALL;
    const ipAddressFilter = this._ipAddresses.length > 0;
    const locationFilter = !!this._locationValue;
    const searchFilter = !!this._searchValue.trim();
    const statusFilter = this._statusValues.length > 0;

    return searchFilter || locationFilter || statusFilter || ipAddressFilter || dateFilter;
  };

  public requestCounter = (): number => {
    return this._requestCounter;
  };

  public getLabWithLatestHeartbeat = (labs: LabType[]): LabType => {
    const latestLab: LabType = labs.reduce((latestLab: LabType, currentLab: LabType) => {
      const currentHeartbeat = currentLab.LastHeartBeat ? new Date(currentLab.LastHeartBeat) : new Date(0);
      const latestHeartbeat = latestLab.LastHeartBeat ? new Date(latestLab.LastHeartBeat) : new Date(0);

      return currentHeartbeat > latestHeartbeat ? currentLab : latestLab;
    });

    return latestLab;
  };

  public loadIpAddresses = async (cancelToken: CancelToken) => {
    const { isDebugMode } = this.appSettingsStore;
    const { locationValue, setIsIpAddressLoading, setIpAddressPairs } = this.sessionsStore;

    const labId = Number(locationValue);

    setIsIpAddressLoading(true);

    isDebugMode && console.log(`[SessionsViewModel:loadIpAddresses] Loading IP Addresses for Lab=${locationValue}.`);

    try {
      if (!isNaN(labId)) {
        const ipAddresses: string[] = await GanymedeSessionRequestService.getIPAddress(labId, cancelToken);
        const ipAddressKeyTextPair: KeyTextPair[] = ipAddresses.map((ip: string) => ({ key: ip, text: ip }));

        setIpAddressPairs(ipAddressKeyTextPair);
      } else {
        setIpAddressPairs([]);
      }
    } catch (error) {
      console.error('[SessionsViewModel:loadIpAddresses] Error fetching IP Address:', error);

      // Append an error to the message bar.
      const message: SystemMessageType = {
        message: t('experiments-api-error-ipaddress'),
        id: 'experiments-api-error-ipaddress',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'experiments-api-group',
      };

      setIpAddressPairs([]);
    } finally {
      setIsIpAddressLoading(false);
    }
  };

  public loadLocations = async (labId: string, isPartnerMode: boolean) => {
    const { addGlobalMessage } = this.systemMessageStore;
    const { isDebugMode } = this.appSettingsStore;
    const { locationValue, setIsLocationsLoading, setLabs, setLocationValue, setLocations } = this.sessionsStore;

    setIsLocationsLoading(true);

    isDebugMode && console.log('[SessionsViewModel:loadLocations] Loading lab locations.');

    const allLabs: LabType[] = [];
    const allLocations: KeyTextPair[] = [];

    // Disabling this as Sessions don't work for AIR
    /* if (!isPartnerMode) {
      const location: KeyTextPair = { key: FilterOptions.TEAM_NAME, text: FilterOptions.TEAM_NAME };

      allLocations.push(location);
       isDebugMode && console.log(`[SessionsViewModel:loadLocations] Added ${FilterOptions.TEAM_NAME}.`);
    } */

    try {
      let labs: LabType[] = await ganymedeLabRequestService.getLabs();

      if (!isPartnerMode) {
        labs = await this.filterLabsByCompanyId(labs);
      }

      allLabs.push(...labs);

      if (labs?.length > 0) {
        const labLocations = labs.map((lab: LabType) => ({ key: String(lab.LabId), text: lab.LabName }));

        allLocations.push(...labLocations);
      }
    } catch (error) {
      const apiErrorMessage: string = format(t('sessions-labs-error', { ns: NS.ERRORS }));

      console.error('[SessionsViewModel:loadLocations]', apiErrorMessage, error.message, error);

      // Append an error to the message bar.
      const message: SystemMessageType = {
        message: apiErrorMessage,
        id: 'sessions-labs-error',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'sessions-labs-error',
      };

      addGlobalMessage(message);
    } finally {
      setIsLocationsLoading(false);
    }

    allLocations.sort((a: KeyTextPair, b: KeyTextPair) => a.text.localeCompare(b.text));

    setLabs(allLabs);
    setLocations(allLocations);

    if (isPartnerMode && typeof locationValue === 'string' && !locationValue.trim() && allLocations.length > 0) {
      setLocationValue(allLocations[0].key);
    }

    isDebugMode && console.log(`[SessionsViewModel:loadLocations] Total ${allLabs.length} labs, ${allLocations.length} locations.`);
  };

  public loadMachines = async (): Promise<void> => {
    const { locationValue, setMachines } = this.sessionsStore;

    // TODO: Please convert this to use try/catch, rather than then/catch, similar to getLabOwners.
    await GanymedeSessionRequestService.getSystemDetails(Number(locationValue))
      .then((result) => {
        if (result?.length > 0) {
          const mappedMachines: SessionMachineType[] = this.buildDataMapping(result);

          setMachines(mappedMachines);
        }
      })
      .catch((error) => {
        console.error('[SessionsViewModel:loadMachines] Error while fetching machine data:', error);
      });
  };

  public loadSessions = async (
    latestRequestIdRef: React.MutableRefObject<number>,
    requestId: number,
    pageSize: number,
    pageNumber: number,
    filter: SessionFilterType,
    cancelToken: CancelToken,
  ): Promise<SessionType[]> => {
    const { isDebugMode } = this.appSettingsStore;
    const { locationValue, setSessions, setIsSessionsLoading } = this.sessionsStore;
    const { addGlobalMessage } = this.systemMessageStore;
    const sessions: SessionType[] = [];

    isDebugMode && console.log(`[SessionsViewModel:loadSessions] Fetching Session ${requestId}.`);

    try {
      const labId: string = locationValue.toString();
      const isValidLab = !isNaN(Number(labId));

      if (!isValidLab) {
        // No data to load. Clear the list.
        setSessions(sessions);
      } else {
        // Start both API calls in parallel
        const sessionsPromise = GanymedeSessionRequestService.getExperimentSessions(
          labId,
          filter,
          pageSize,
          pageNumber,
          cancelToken,
        );

        const statusesPromise = GanymedeSessionRequestService.getExperimentSessionsStatus(
          labId,
          filter,
          pageSize,
          pageNumber,
          cancelToken,
        );

        // Await the sessions data to display immediately
        await sessionsPromise
          .then(async (sessionsResponse) => {
            await this.loadSessionsHelper(latestRequestIdRef, requestId, pageSize, pageNumber, sessionsResponse, true);
          })
          .catch((error) => {
            console.error('[SessionsViewModel:loadSessions] Error fetching session statuses data:', error);
          });

        // Await the status data and update UI once it's available
        let sessions: SessionType[];
        await statusesPromise
          .then(async (statusesResponse) => {
            sessions = await this.loadSessionsHelper(latestRequestIdRef, requestId, pageSize, pageNumber, statusesResponse, false);
          })
          .catch((error) => {
            console.error('[SessionsViewModel:loadSessions] Error fetching session statuses data:', error);
          });

        return sessions;
      }
    } catch (error) {
      console.error('[SessionsViewModel:loadSessions] Error fetching Sessions:', error);

      const message: string = error.message;
      const systemMessage: SystemMessageType = {
        id: 'sessions-api-error',
        message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'sessions-api-group',
      };

      setSessions(sessions);
      setIsSessionsLoading(false);
      addGlobalMessage(systemMessage);

      return [];
    }
  };

  public setCompanyName = async () => {
    const { labs, locationValue, sessions, setLabCompanyName } = this.sessionsStore;

    if (sessions?.length > 0 && labs?.length > 0) {
      const labExists: boolean = labs.some((f) => String(f.LabId) === locationValue);

      if (labExists) {
        const selectedLab: LabType = labs?.find((f) => String(f.LabId) === locationValue);

        const companies: any[] = await ganymedeLabRequestService.getCompanies();
        const labCompany: any = companies.find((activeCompany) => activeCompany.CompanyId === selectedLab.CompanyId);

        setLabCompanyName(labCompany?.CompanyName);
      }
    }
  };

  public setGroupByData = (tableData: any[], groupByField?: string) => {
    const columnDefinitions = SessionDetailsStore.SESSION_COLUMN_DEFINITIONS;
    const { setSessions, setSessionGroups, setGroupByColumn, groupByColumn } = this.sessionsStore;

    const groupByValue: string = groupByField ?? (groupByColumn || t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON }));
    const { returnData, groups } = setTableDataGroupBy(tableData, groupByValue, columnDefinitions, true);

    setSessions(returnData);
    setSessionGroups(groups);
    setGroupByColumn(groupByValue);
  };

  private loadSessionsHelper = (
    latestRequestIdRef: React.MutableRefObject<number>,
    requestId: number,
    pageSize: number,
    pageNumber: number,
    sessionsResponse: any,
    clearSessionsData: boolean,
  ) => {
    const { paginationType, setPaginationType } = this.paginationStore;
    const { setIsSessionsLoading, setSessions, setTotalSessionsCount } = this.sessionsStore;
    const sessions: SessionType[] = [];

    // If we have not previously selected a session, clear the list and behave normally.
    if (clearSessionsData) {
      setIsSessionsLoading(true);
      setSessions([]);
    }

    // Ignore all non-current requests.
    if (latestRequestIdRef.current === requestId) {
      const sessionsCount: number = sessionsResponse.count;

      setTotalSessionsCount(sessionsCount);

      if (sessionsCount > 0) {
        for (const row of sessionsResponse.experimentSessions) {
          const session: SessionType & SessionGUIType = SessionsViewModel.buildSessionViewModel(row);

          if (session) {
            sessions.push(session);
          }
        }
      }

      const { paginationProps, ...prevPaginationDefaults } = paginationType || {};

      const updatedPaginationType: PaginationType = {
        ...prevPaginationDefaults,
        paginationProps: {
          ...paginationProps,
          pageSize,
          currentPage: pageNumber,
          totalItems: sessionsCount,
          hasFiltersApplied: this.hasFiltersApplied(),
        },
      };

      setPaginationType(updatedPaginationType);
      this.setGroupByData(sessions);
      setIsSessionsLoading(false);

      return sessions;
    }
  };

  public getLabOwners = async (): Promise<void> => {
    const { locationValue, setLabOwners } = this.sessionsStore;
    const { addGlobalMessage } = this.systemMessageStore;

    try {
      const response = await ganymedeLabRequestService.getLabsUser(locationValue);

      if (response) {
        const labOwners: KeyTextPair[] = response.map((user: UserRole) => ({ key: user.UserName, text: user.UserName }));

        setLabOwners(labOwners);
      }

      return;
    } catch (error) {
      console.error('[SessionsViewModel:getLabOwners] Error fetching lab owners data:', error);

      const message = t('user-logs-api-issues', { ns: NS.EXPERIMENTS });
      const systemMessage: SystemMessageType = {
        id: 'session-api-error',
        message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'session-api-group',
      };

      addGlobalMessage(systemMessage);

      return;
    }
  };

  public onCompanyChange = (event: React.FormEvent<HTMLDivElement>, item: KeyTextPair): void => {
    const { resetCompany, setIsSessionsLoading, resetLocation } = this.sessionsStore;

    setIsSessionsLoading(true);
    resetCompany(item.key);
    resetLocation('');
    this.loadLocations(null, false);
  };

  public loadCompanies = async (): Promise<void> => {
    ganymedeLabRequestService
      .getCompanies()
      .then((result) => {
        if (result.length > 0) {
          // this.populateCompanyDropdown(result);
        }
      })
      .catch((error) => {
        console.error('[SessionsViewModel:loadCompanies] Error fetching companies :', error);
      });
  };

  private populateCompanyDropdown = (companies: Company[]): void => {
    const { companyValue, setCompanies, setCompanyValue } = this.sessionsStore;

    const options: KeyTextPair[] = companies.map((item: any) => ({ key: item.CompanyId, text: item.CompanyName }));

    if (!companyValue) {
      const defaultCompany = options.filter((x) => x.text == Labels.adminCompany);

      if (defaultCompany) {
        setCompanyValue(defaultCompany[0].key);
      }
    }

    setCompanies(options);
  };

  private async filterLabsByCompanyId(labs: LabType[]): Promise<LabType[]> {
    const { isDebugMode } = this.appSettingsStore;
    const { companyValue, locationValue, resetLocation, setIsSessionsLoading, setSelectedSession, setSessions } =
      this.sessionsStore;

    isDebugMode && console.log(`[SessionsViewModel:loadLocations] Found ${labs.length} labs.`);

    const companyId = Number(companyValue);

    if (!companyId) {
      return labs;
    }

    const filteredLabs = labs.filter((lab) => lab.CompanyId === companyId);

    setSelectedSession(null);

    if (filteredLabs.length > 0) {
      const firstLabId = filteredLabs[0]?.LabId;

      resetLocation(locationValue || String(firstLabId));
    } else {
      resetLocation('');
      setSessions([]);
      setIsSessionsLoading(false);
    }

    return filteredLabs;
  }
}

export default SessionsViewModel;
