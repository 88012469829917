import { action, computed, makeObservable, observable } from 'mobx';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { inject, singleton } from 'tsyringe';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { ActionTypeNull, ActionTypeWithParamReturn } from '@/types/AppSettingsTypes';
import { SystemMessageType } from '@/types/SystemMessageTypes';

@singleton()
class SystemMessageStore {
  // Actions
  public globalMessages: SystemMessageType[] = []; // Messages for a Global context.

  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    makeObservable(this, {
      // Observables for system-wide variables.
      globalMessages: observable,

      // Computed values that derive information from other observables.
      globalMessageGroupIds: computed,
      globalMessageNonGroupIds: computed,

      // Actions modify the state.
      addGeneralFadingMessage: action,
      addGlobalMessage: action,
      addHelpMessage: action,
      clearAllGlobalMessages: action,
      clearNonPersistentGlobalMessages: action,
      deleteMessage: action,
      deleteGlobalMessage: action,

      doReset: action,
    });
  }

  public get globalMessageGroupIds(): string[] {
    // EXAMPLE:
    // [
    //   { message: '', groupId: 'a' }, 0
    //   { message: '', groupId: 'b' }, 1
    //   { message: '', groupId: 'a' }, 2
    //   { message: '', groupId: '' }, 3
    // ];
    const groupMessages: SystemMessageType[] = this.globalMessages.filter((message) => message.groupId !== ''); // 0, 1, 2
    const groupIds: string[] = groupMessages.map((message) => message.groupId || ''); // ['a', 'b', 'a']
    const uniqueGroupIds: string[] = [...new Set(groupIds)]; // ['a', 'b']

    return uniqueGroupIds; // ['a, b']
  }

  public get globalMessageNonGroupIds(): string[] {
    // EXAMPLE:
    // [
    //   { message: '', groupId: 'a', id: 'alpha' }, 0
    //   { message: '', groupId: 'b', id: 'beta'}, 1
    //   { message: '', groupId: 'a', id: 'gamma' }, 2
    //   { message: '', groupId: '', id: 'delta' }, 3
    //   { message: '', groupId: '', id: 'epsilon' }, 4
    //   { message: 'example', groupId: '', id: 'example' }, 5 (id auto set)
    //   { message: 'example', groupId: '', id: 'example' }, 6 (id auto set)
    // ];
    const groupMessages: SystemMessageType[] = this.globalMessages.filter((message) => message.groupId === ''); // 3, 4, 5, 6

    return groupMessages.map((message) => message.id || ''); // ['delta', 'epsilon', 'example', 'example']
  }

  public addGeneralFadingMessage: ActionTypeWithParamReturn = (message: string, groupId = message) => {
    // Adds a message found in the GENERAL Namespace of the Translations file.
    const generalMessage: SystemMessageType = {
      message,
      mode: MessageBarMode.fadeAway,
      type: FluentMessageBarType.information,
      groupId,
    };

    return this.addGlobalMessage(generalMessage);
  };

  public addGlobalMessage: ActionTypeWithParamReturn = (props: SystemMessageType) => {
    const {
      id = props.message.toString(),
      groupId = '',
      type = FluentMessageBarType.warning,
      message,
      namespace = '',
      mode = MessageBarMode.normal,
      persistent = false,
      multiline = true,
      showInPopup = false,
    } = props;

    const globalMessage: SystemMessageType = {
      ...props,
      message,
      id,
      groupId,
      type,
      namespace,
      mode,
      persistent,
      multiline,
      showInPopup,
    };

    if (!this.checkMessageExists(id) && !this.checkMessageSeen(id)) {
      this.globalMessages.push(globalMessage);
    }

    return this.globalMessages;
  };

  public addHelpMessage: ActionTypeWithParamReturn = (message: string) => {
    // Adds a message found in the HELP Namespace of the Translations file.
    const helpMessage: SystemMessageType = {
      message,
      id: message,
      namespace: NS.HELP,
      mode: MessageBarMode.rememberState,
    };

    return this.addGlobalMessage(helpMessage);
  };

  public checkMessageExists: ActionTypeWithParamReturn = (id: string) => {
    return this.globalMessages.some((message) => message.id === id);
  };

  public checkMessageSeen: ActionTypeWithParamReturn = (id: string) => {
    return this.localStorage.getValue(AppSettingsService.MESSAGEBAR_SEEN_KEY)?.includes(id);
  };

  public deleteGroupMessages: ActionTypeWithParamReturn = (groupId: string) => {
    this.globalMessages = this.globalMessages.filter((message) => message.groupId !== groupId);

    return this.globalMessages;
  };

  public deleteMessage: ActionTypeWithParamReturn = (id: string, type?: string) => {
    this.globalMessages = this.globalMessages.filter((message) => message.id !== id);

    return this.globalMessages;
  };

  public deleteGlobalMessage: ActionTypeWithParamReturn = (id: string) => {
    return this.deleteMessage(id);
  };

  // Clear every single message in the stack.
  public clearAllGlobalMessages: ActionTypeWithParamReturn = () => {
    return this.clearGlobalMessages(false);
  };

  // Clear all messages which are non-persistent.
  public clearNonPersistentGlobalMessages: ActionTypeWithParamReturn = () => {
    return this.clearGlobalMessages();
  };

  private clearGlobalMessages: ActionTypeWithParamReturn = (keep = true) => {
    this.globalMessages = keep ? this.globalMessages.filter((message) => message.persistent) : ([] as SystemMessageType[]);

    return this.globalMessages;
  };

  public doReset: ActionTypeNull = () => {
    this.localStorage.setValue(AppSettingsService.MESSAGEBAR_SEEN_KEY, ''); // We need to reset any seen MessageBars.

    this.save();

    return null;
  };

  protected save: ActionTypeNull = () => {
    // Currrently not saving anything.

    return null;
  };
}

export default SystemMessageStore;
