import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { LabSystemType } from '@/components/ManageLab/LabSystems/LabSystemsType';

@singleton()
class LabSystemDetailsGeneralStore {
  public isLoading: boolean;
  public systemGeneralInfo: LabSystemType;

  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      isLoading: observable,
      systemGeneralInfo: observable,

      // Actions modify the state.
      setIsLoading: action,
      setSystemGeneralInfo: action,
    });
  }

  public setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  public setSystemGeneralInfo = (systemInfo: LabSystemType) => {
    this.systemGeneralInfo = systemInfo;
  };
}

export default LabSystemDetailsGeneralStore;
