import { action, makeObservable, observable } from 'mobx';
import { inject, singleton } from 'tsyringe';

import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { ActionTypeNull } from '@/types/AppSettingsTypes';
import { SortedColumnMemoryType } from '@/types/TableTypes';

@singleton()
class TableViewStore {
  public sortedColumnMemory: SortedColumnMemoryType;

  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    makeObservable(this, {
      sortedColumnMemory: observable,

      setSortedColumnMemory: action,
      doResetTableSort: action,
    });

    this.sortedColumnMemory =
      this.localStorage.getValue(AppSettingsService.SORTED_COLUMN_MEMORY_KEY) ||
      AppSettingsService.DEFAULT_SORTED_COLUMN_MEMORY_VALUE;

    this.save();
  }

  public setSortedColumnMemory = (page: string, column: string, isSortedDescending: boolean): void => {
    this.sortedColumnMemory[page as string] = { column, isSortedDescending };
    this.localStorage.setValue(AppSettingsService.SORTED_COLUMN_MEMORY_KEY, this.sortedColumnMemory);

    return;
  };

  public doResetTableSort = (page: string): void => {
    delete this.sortedColumnMemory[page as string];
    this.save();
  };

  protected save: ActionTypeNull = () => {
    this.localStorage.setValue(AppSettingsService.SORTED_COLUMN_MEMORY_KEY, this.sortedColumnMemory);

    return null;
  };
}

export default TableViewStore;
