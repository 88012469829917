import { action, computed, makeObservable, observable } from 'mobx';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { container, inject, singleton } from 'tsyringe';

import config from '@/components/Sessions/Sessions.config.json';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionLogResultsType } from '@/components/Sessions/SessionsTypes';
import { CancellableStatuses, StatusesWithLogs } from '@/constants/ExperimentConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { SystemType } from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import EventBus from '@/services/EventBus';
import LocalStorageService from '@/services/LocalStorageService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { TableColumnType } from '@/types/TableTypes';

@singleton()
class SessionDetailsStore {
  public static SESSION_COLUMN_DEFINITIONS = config.sessionsColumnDefinitions;
  public static SESSION_STEPS_COLUMN_DEFINITIONS = config.stepsColumnDefinitions;
  public static SESSION_EXPERIMENT_FAILURE_COLUMN_DEFINITIONS = config.sessionExperimentFailureDefinitions;

  public isCancelButtonDisabled: boolean;
  public isDownloadButtonDisabled: boolean;
  public isSessionColumnEditorOpen: boolean;
  public isSessionLoading: boolean;
  public isSessionModalOpen: boolean;
  public isSessionPanelOpen: boolean;
  public sessionColumnList: TableColumnType[];
  public sessionEntireColumns: TableColumnType[];
  public sessionLogs: SessionLogResultsType[];
  public sessionPanelMessages: SystemMessageType[];
  private eventBus: EventBus = container.resolve(EventBus);

  constructor(
    @inject(LocalStorageService) protected localStorage: LocalStorageService,
    @inject(AppSettingsStore) protected appSettingsStore: AppSettingsStore,
    @inject(SessionsStore) protected sessionsStore: SessionsStore,
  ) {
    makeObservable(this, {
      // Observables for individual Sessions.
      isCancelButtonDisabled: observable,
      isDownloadButtonDisabled: observable,
      isSessionColumnEditorOpen: observable,
      isSessionLoading: observable,
      isSessionModalOpen: observable,
      isSessionPanelOpen: observable,
      sessionLogs: observable,
      sessionColumnList: observable,
      sessionEntireColumns: observable,
      sessionPanelMessages: observable,

      // Actions modify the state.
      addSessionPanelMessage: action,
      clearSessionPanelMessages: action,
      closeSessionColumnEditor: action,
      closeSessionModal: action,
      closeSessionPanel: action,
      disableCancelButton: action,
      disableDownloadButton: action,
      enableCancelButton: action,
      enableDownloadButton: action,
      openSessionColumnEditor: action,
      openSessionModal: action,
      openSessionPanel: action,
      setIsCancelButtonDisabled: action,
      setIsDownloadButtonDisabled: action,
      setIsSessionColumnEditorOpen: action,
      setIsSessionLoading: action,
      setIsSessionModalOpen: action,
      setIsSessionPanelOpen: action,
      setSessionColumnList: action,
      setSessionEntireColumns: action,
      setSessionLogs: action,
      toggleSessionPanel: action,
      toggleSessionColumnEditor: action,
      unmountSessionDetails: action,

      // Set computed properties.
      canCancelSession: computed,
      canViewInstanceResults: computed,
      hasPanelMessages: computed,
      isAirSession: computed,
      isLabsSession: computed,
      panelMessageCount: computed,
      selectedSessionRoutePath: computed,
      sessionHasLogs: computed,
      stepCount: computed,
    });

    // Set initial state for these observables.
    this.isCancelButtonDisabled = false;
    this.isDownloadButtonDisabled = false;
    this.isSessionColumnEditorOpen = false;
    this.isSessionLoading = false;
    this.isSessionModalOpen = false;
    this.isSessionPanelOpen = false;
    this.sessionColumnList = [] as TableColumnType[];
    this.sessionEntireColumns = SessionDetailsStore.SESSION_COLUMN_DEFINITIONS;
    this.sessionLogs = undefined;
    this.sessionPanelMessages = [] as SystemMessageType[];
  }

  public addSessionPanelMessage = (message: string | SystemMessageType) => {
    if (typeof message === 'string') {
      const systemMessage: SystemMessageType = {
        message: message,
        type: FluentMessageBarType.success,
        mode: MessageBarMode.normal,
        groupId: 'session-panel-message',
      };

      this.sessionPanelMessages.push(systemMessage as SystemMessageType);
    } else if (message) {
      this.sessionPanelMessages.push(message as SystemMessageType);
    }
  };

  public clearSessionPanelMessages = () => {
    this.sessionPanelMessages = [] as SystemMessageType[];
  };

  public closeSessionColumnEditor = () => {
    this.setIsSessionColumnEditorOpen(false);
  };

  public closeSessionModal = () => {
    this.setIsSessionModalOpen(false);
  };

  public closeSessionPanel = () => {
    this.setIsSessionPanelOpen(false);
    this.closeSessionStepsColumnEditor(); // Since the Panel is closed, close any open Column Editors in the Panel.
    this.clearSessionPanelMessages();
    this.enableCancelButton();
    this.enableDownloadButton();
  };

  public closeSessionStepsColumnEditor = () => {
    this.eventBus.emit('closeSessionStepsColumnEditor');
  };

  public disableCancelButton = () => {
    this.setIsCancelButtonDisabled(true);
  };

  public disableDownloadButton = () => {
    this.setIsDownloadButtonDisabled(true);
  };

  public enableCancelButton = () => {
    this.setIsCancelButtonDisabled(false);
  };

  public enableDownloadButton = () => {
    this.setIsDownloadButtonDisabled(false);
  };

  public openSessionColumnEditor = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionColumnEditorOpen(true);
    this.closeSessionPanel(); // Since the user wants to Edit Columns for the parent page, close the Panel.

    closeSettings();
  };

  public openSessionModal = () => {
    this.closeSessionPanel();
    this.setIsSessionModalOpen(true);
  };

  public openSessionPanel = () => {
    const { closeSettings } = this.appSettingsStore;

    this.setIsSessionPanelOpen(true);
    this.closeSessionColumnEditor(); // Since the Panel is open, close any open Column Editors in the main page.

    closeSettings();
  };

  public setIsCancelButtonDisabled = (value: boolean) => {
    this.isCancelButtonDisabled = value;
  };

  public setIsDownloadButtonDisabled = (value: boolean) => {
    this.isDownloadButtonDisabled = value;
  };

  public setIsSessionLoading = (value: boolean) => {
    this.isSessionLoading = value;
  };

  public setIsSessionModalOpen = (value: boolean) => {
    this.isSessionModalOpen = value;
  };

  public setIsSessionPanelOpen = (value: boolean) => {
    this.isSessionPanelOpen = value;
  };

  public setIsSessionColumnEditorOpen = (value: boolean) => {
    this.isSessionColumnEditorOpen = value;
  };

  public setSessionColumnList = (value: TableColumnType[]) => {
    this.sessionColumnList = value;
  };

  public setSessionEntireColumns = (value: TableColumnType[]) => {
    this.sessionEntireColumns = value;
  };

  public setSessionLogs = (value: SessionLogResultsType[]) => {
    this.sessionLogs = value;
  };

  public toggleSessionPanel = () => {
    this.isSessionPanelOpen ? this.closeSessionPanel() : this.openSessionPanel();
  };

  public toggleSessionColumnEditor = () => {
    this.isSessionColumnEditorOpen ? this.closeSessionColumnEditor() : this.openSessionColumnEditor();
  };

  public unmountSessionDetails = () => {
    // Cleanup Session Details when we unmount.
    this.closeSessionModal();
    this.closeSessionPanel();
    this.closeSessionStepsColumnEditor();
    this.clearSessionPanelMessages();
  };

  public get canCancelSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    if (!selectedSession || this.isAirSession) {
      return false;
    }

    const status = isSessionSelected && selectedSession.status?.data['Succeeded']?.toString().toLowerCase();
    const canCancel = CancellableStatuses.includes(status);

    return canCancel;
  }

  public get canViewInstanceResults(): boolean {
    // If the Experiment Instance has logs, and the status is failed or succeeded,
    // the results can be viewed.
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    if (!isSessionSelected) {
      return false;
    }

    const hasLogs = !!this.sessionHasLogs;
    const status = selectedSession.status?.data['Succeeded']?.toString().toLowerCase();
    const validStatus = StatusesWithLogs.includes(status);
    const canView = hasLogs && validStatus;

    return canView;
  }

  public get hasPanelMessages(): boolean {
    const hasMessages = this.sessionPanelMessages.length > 0;

    return hasMessages;
  }

  public get isAirSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    return (isSessionSelected && selectedSession.location?.location === SystemType.AIR) || false;
  }

  public get isLabsSession(): boolean {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;

    return (isSessionSelected && selectedSession.location?.location === SystemType.LABS) || false;
  }

  public get selectedSessionRoutePath(): string {
    const sessionsStore = this.sessionsStore;
    const { selectedSession } = sessionsStore;

    if (!selectedSession) {
      // A session must be selected to build the path.
      return '';
    }

    const experimentPathPrefix: string = Navigation.GANYMEDE.SESSIONS;
    const experimentId: string = selectedSession.id;

    return `${experimentPathPrefix}/${experimentId}`;
  }

  public get sessionHasLogs(): boolean {
    // If the logs object is not empty, we have some logs.
    const hasLogs = this.sessionLogs?.length > 0 || false;

    return hasLogs;
  }

  public get stepCount(): number {
    const sessionsStore = this.sessionsStore;
    const { selectedSession, isSessionSelected } = sessionsStore;
    const count = (isSessionSelected && selectedSession.steps?.length) || 0;

    return count;
  }

  public get panelMessageCount(): number {
    const count = this.sessionPanelMessages.length;

    return count;
  }
}

export default SessionDetailsStore;
