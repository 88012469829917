import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

@singleton()
class LabsControlStore {
  public isSARMAddEditOpen = false;
  public isSARMAddEditClose = true;
  public selectedMachines: any[];
  public selectedLabId: string;

  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      isSARMAddEditOpen: observable,
      selectedMachines: observable,
      selectedLabId: observable,

      // Actions modify the state.
      setIsSARMAddEditOpen: action,
      setSelectedMachines: action,
      setSelectedLabId: action,
    });
  }

  public setIsSARMAddEditOpen = () => {
    this.isSARMAddEditOpen = true;
  };

  public setIsSARMAddEditClose = () => {
    this.isSARMAddEditOpen = false;
  };

  public setSelectedMachines = (machines: any[]) => {
    this.selectedMachines = machines || [];
  };

  public setSelectedLabId = (labId: string) => {
    this.selectedLabId = labId;
  };
}

export default LabsControlStore;
