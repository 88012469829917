import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection } from '@fluentui/react/lib/Selection';

import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import { ActionTypeVoid, ActionTypeWithParam } from '@/types/AppSettingsTypes';
import { Asset } from '@/types/AssetTypes';

enum AccordionKeys {
  ASSETS_METADATA = 'asset-metadata',
  ASSETS_TYPE_METADATA = 'asset-type-metadata',
  CONTENT_METADATA = 'content-metadata',
  CONTENT = 'content-data',
  VERSION_SELECTION = 'version',
}

type AssetManagementVMType = {
  loadAsset: ActionTypeWithParam;
};

type AssetManagementVCType = {
  accordionOpenItems: AccordionKeys[];
  assetName: IOverflowSetItemProps[];
  assetSelection: Selection;
  dataStyle: any;
  filterItems: IOverflowSetItemProps[];
  jsonStyle: any;
  splitPanelConfig: SplitPanelConfigType;

  accordionToggle: ActionTypeWithParam;
  loadAsset: ActionTypeWithParam;
};

type AssetTableType = {
  asset: Asset | null;
  id: string;
  releaseDate: string;
  version: string;
};

export { AccordionKeys, AssetTableType, AssetManagementVCType, AssetManagementVMType };
