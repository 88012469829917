import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

@singleton()
class RawLogsTreeViewStore {
  public isLargeFile = false;
  public experimentDefintionId = '';
  public experimentInstanceId = '';
  public experimentName = '';
  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      isLargeFile: observable,
      experimentDefintionId: observable,
      experimentInstanceId: observable,
      experimentName: observable,

      // These values are persisted.
      setIsLargeFile: action,
      setExperimentDefinitionId: action,
      setExperimentInstanceId: action,
      setExperimentName: action,
    });
  }
  public setIsLargeFile = (largeFile: boolean) => {
    this.isLargeFile = largeFile;
  };
  public setExperimentDefinitionId = (id: string) => {
    this.experimentDefintionId = id;
  };
  public setExperimentInstanceId = (id: string) => {
    this.experimentInstanceId = id;
  };
  public setExperimentName = (name: string) => {
    this.experimentName = name;
  };
}

export default RawLogsTreeViewStore;
