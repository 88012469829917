import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import ReactJson from 'react-json-view';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, SelectionMode } from '@fluentui/react';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import { t } from 'i18next';

import config from '@/components/AssetManagement/AssetManagement.config.json';
import { AccordionKeys, AssetManagementVCType, AssetTableType } from '@/components/AssetManagement/AssetManagementTypes';
import AssetTreeViewerViewController from '@/components/AssetTreeViewer/AssetTreeViewerViewController';
import AssetTreeViewerViewModel from '@/components/AssetTreeViewer/AssetTreeViewerViewModel';
import customStyle from '@/components/SessionDetails/SessionDetailsStyles';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { Namespaces as NS, SplitPanelDirectionType } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from '@/components/AssetManagement/AssetManagement.module.css';

const AssetManagementBetaTemplateFC: React.FC<AssetManagementVCType> = (props: AssetManagementVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { assetManagementStore } = rootStore;

  const { contentTableItems, isLoadingAsset, selectedAsset } = assetManagementStore;
  const {
    accordionOpenItems,
    assetName,
    assetSelection,
    dataStyle,
    filterItems,
    jsonStyle,
    splitPanelConfig,
    accordionToggle,
    loadAsset,
  } = props;

  const assetTreeViewerViewModel = new AssetTreeViewerViewModel(rootStore, loadAsset);

  return (
    <>
      <div className="fullscreen ee-py4">
        <div className="fullscreen ee-indent">
          <SplitPanel config={splitPanelConfig} index={0} isAllowResize={false} split={SplitPanelDirectionType.VERTICAL}>
            <div className={styles['primary-pane']}>
              <AssetTreeViewerViewController viewModel={assetTreeViewerViewModel} />
            </div>
            <div className={styles['reading-pane']}>
              <PageCommandBar items={assetName} farItems={filterItems} />
              <PageDivider />
              {isLoadingAsset ? (
                <LoadingSpinner />
              ) : selectedAsset ? (
                <Accordion
                  className={styles.full}
                  collapsible={true}
                  multiple={true}
                  openItems={accordionOpenItems}
                  data-id={'experiments-panel-accordion'}
                  onToggle={accordionToggle}
                >
                  <AccordionItem
                    className={`${styles['accordion-item']}  ${
                      accordionOpenItems.includes(AccordionKeys.ASSETS_METADATA) && styles['expanded']
                    }`}
                    value={AccordionKeys.ASSETS_METADATA}
                  >
                    <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                      {t('asset-metadata', { ns: NS.ASSETS })}
                    </AccordionHeader>
                    <AccordionPanel className={styles['accordion-panel']}>
                      <div className={styles['block-grid']}>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>name</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.name}</div>
                        </div>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>description</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.description}</div>
                        </div>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>owner</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.owner}</div>
                        </div>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>created-by</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.createdBy}</div>
                        </div>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>support</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.support}</div>
                        </div>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>tags</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.tags.join(', ')}</div>
                        </div>
                        <div className={styles['row']}>
                          <div className={styles['label']}>
                            <Trans ns={NS.ASSETS as Namespace}>type</Trans>
                          </div>
                          <div className={styles['text']}>{selectedAsset.metadata.type}</div>
                        </div>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    className={`${styles['accordion-item']}  ${
                      accordionOpenItems.includes(AccordionKeys.ASSETS_TYPE_METADATA) && styles['expanded']
                    }`}
                    value={AccordionKeys.ASSETS_TYPE_METADATA}
                  >
                    <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                      {t('asset-type-metadata', { ns: NS.ASSETS })}
                    </AccordionHeader>
                    <AccordionPanel className={styles['accordion-panel']}>
                      <div className={styles['block-grid']}></div>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    className={`${styles['accordion-item']}  ${
                      accordionOpenItems.includes(AccordionKeys.VERSION_SELECTION) && styles['expanded']
                    }`}
                    value={AccordionKeys.VERSION_SELECTION}
                  >
                    <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                      {t('version-selection', { ns: NS.ASSETS })}
                    </AccordionHeader>
                    <AccordionPanel className={styles['accordion-panel']}>
                      <TableViewViewController
                        columns={config.contentColumns}
                        items={contentTableItems}
                        enableColumnSort={true}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selectionMode={SelectionMode.single}
                        tableStyle={`${styles['content-table']}`}
                        selection={assetSelection}
                        isSelectedOnFocus={false}
                        enableToolBar={false}
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    className={`${styles['accordion-item']}  ${
                      accordionOpenItems.includes(AccordionKeys.CONTENT_METADATA) && styles['expanded']
                    }`}
                    value={AccordionKeys.CONTENT_METADATA}
                  >
                    <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                      {t('content-metadata', { ns: NS.ASSETS })}
                    </AccordionHeader>
                    <AccordionPanel className={styles['accordion-panel']}>
                      <div>
                        <div className={styles['block-grid']}>
                          <div className={styles['row']}>
                            <div className={styles['label']}>
                              <Trans ns={NS.ASSETS as Namespace}>date</Trans>
                            </div>
                            <div className={styles['text']}>{selectedAsset.currentContent.date}</div>
                          </div>
                          <div className={styles['row']}>
                            <div className={styles['label']}>
                              <Trans ns={NS.ASSETS as Namespace}>created-by</Trans>
                            </div>
                            <div className={styles['text']}>{selectedAsset.currentContent.createdBy}</div>
                          </div>
                          <div className={styles['row']}>
                            <div className={styles['label']}>
                              <Trans ns={NS.ASSETS as Namespace}>status</Trans>
                            </div>
                            <div className={styles['text']}>{selectedAsset.currentContent.status}</div>
                          </div>
                          <div className={styles['row']}>
                            <div className={styles['label']}>
                              <Trans ns={NS.ASSETS as Namespace}>version</Trans>
                            </div>
                            <div className={styles['text']}>{selectedAsset.currentContent.version}</div>
                          </div>
                          <div className={styles['row']}>
                            <div className={styles['label']}>
                              <Trans ns={NS.ASSETS as Namespace}>properties</Trans>
                            </div>
                            <div className={styles['text']}>{JSON.stringify(selectedAsset.currentContent.properties, null, 4)}</div>
                          </div>
                        </div>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    className={`${styles['accordion-item']}  ${
                      accordionOpenItems.includes(AccordionKeys.CONTENT) && styles['expanded']
                    }`}
                    value={AccordionKeys.CONTENT}
                  >
                    <AccordionHeader expandIconPosition={'start'} className={styles['accordion-title']}>
                      {t('content-data', { ns: NS.ASSETS })}
                    </AccordionHeader>
                    <AccordionPanel className={styles['accordion-panel']}>
                      <SyntaxHighlighter language="json" style={jsonStyle} customStyle={customStyle.jsonBackground} showLineNumbers>
                        {typeof selectedAsset.currentContent.data === 'string'
                          ? selectedAsset.currentContent.data
                          : JSON.stringify(selectedAsset.currentContent.data, null, 4)}
                      </SyntaxHighlighter>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                <div className={styles['selection-text-outer']}>
                  <div className={styles['selection-text-inner']}>
                    <Trans ns={NS.ASSETS as Namespace}>selection-text</Trans>
                  </div>
                </div>
              )}
            </div>
          </SplitPanel>
        </div>
      </div>
    </>
  );
};

const AssetManagementBetaTemplate = observer(AssetManagementBetaTemplateFC);

export default AssetManagementBetaTemplate;

function setSelectedLab(assetSelection: Selection) {
  throw new Error('Function not implemented.');
}
