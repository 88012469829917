import { action, computed, makeObservable, observable } from 'mobx';
import { IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { Selection } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';
import { inject, singleton } from 'tsyringe';

import { SytemColumnKeys } from '@/constants/LabDetailsConstants';
import { KeyTextPair, Namespaces as NS, PageMemoryKeys } from '@/constants/SystemConstants';
import { LabDetails } from '@/constants/TranslationConstants';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { TableColumnType } from '@/types/TableTypes';

import { LabManifest, LabSystemType } from './LabSystemsType';

@singleton()
class LabSystemsStore {
  public agentIds: string[] = [];
  public filteredLabSystems: LabSystemType[] = [];
  public healthStatusValues: string[] = [];
  public ipAddressValues: string[] = [];
  public ipAddressesList: KeyTextPair[] = [];
  public isHealthStatusRefreshed: boolean;
  public isLabManifestMetadataDeleteOpen: boolean;
  public isLabManifestMetadataEditorOpen: boolean;
  public isLabSystemsColumnEditorOpen: boolean;
  public isLoading = false;
  public isRackManagerEditorOpen: boolean;
  public isSarmIp: boolean; // To differentiate between SARM IP & JBOD Slot
  public isTagEditorOpen: boolean;
  public labItems: Selection<IObjectWithKey> = null; // All items present in the DetailsList
  public labSystems: LabSystemType[] = [];
  public labSystemsColumnList: TableColumnType[] = [];
  public labSystemsEntireColumns: TableColumnType[] = [];
  public machineTypeValues: string[] = [];
  public machineTypesList: KeyTextPair[] = [];
  public selectedLabManifest: LabManifest = null;
  public selectedRacksIndices: number[] | [] = [];
  public selectedSystem: LabSystemType | null = null;
  public selectedSystems: LabSystemType[] = [];
  public selectedVirtualMachineIndices: number[] | [] = [];
  public tableGroups: any[] | null = null;

  constructor(
    @inject(LocalStorageService) protected localStorage: LocalStorageService,
    @inject(AppSettingsStore) protected appSettingsStore: AppSettingsStore,
  ) {
    makeObservable(this, {
      // Observables not persisted.
      agentIds: observable,
      filteredLabSystems: observable,
      ipAddressesList: observable,
      isHealthStatusRefreshed: observable,
      isLabManifestMetadataDeleteOpen: observable,
      isLabManifestMetadataEditorOpen: observable,
      isLabSystemsColumnEditorOpen: observable,
      isLoading: observable,
      isRackManagerEditorOpen: observable,
      isSarmIp: observable,
      isTagEditorOpen: observable,
      labItems: observable,
      labSystems: observable,
      labSystemsColumnList: observable,
      labSystemsEntireColumns: observable,
      machineTypesList: observable,
      selectedLabManifest: observable,
      selectedRacksIndices: observable,
      selectedSystem: observable,
      selectedSystems: observable,
      selectedVirtualMachineIndices: observable,
      tableGroups: observable,

      // These values are persisted.
      healthStatusValues: observable,
      ipAddressValues: observable,
      machineTypeValues: observable,

      // Actions modify the state.
      clearSelectedSystems: action,
      hideLabSystemsColumnEditor: action,
      hideRackManagerEditor: action,
      hideTagEditor: action,
      setAgentIds: action,
      setFilteredLabSystems: action,
      setHealthStatusValues: action,
      setIpAddressValues: action,
      setIpAddressesList: action,
      setIsHealthStatusRefreshed: action,
      setIsLoading: action,
      setIsSarmIp: action,
      setLabItems: action,
      setLabSystems: action,
      setLabSystemsColumnList: action,
      setLabSystemsEntireColumns: action,
      setMachineTypeValues: action,
      setMachineTypesList: action,
      setSelectedLabManifest: action,
      setSelectedRacksIndices: action,
      setSelectedSystem: action,
      setSelectedSystems: action,
      setSelectedVirtualMachineIndices: action,
      setTableGroups: action,
      showLabManifestMetadataDelete: action,
      showLabManifestMetadataEditor: action,
      showLabSystemsColumnEditor: action,
      showRackManagerEditor: action,
      showTagEditor: action,

      // Set computed properties.
      isSystemSelected: computed,
    });

    this.ipAddressValues = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_IPADDRESS_KEY) || [];
    this.machineTypeValues = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_MACHINE_TYPE_KEY) || [];
    this.healthStatusValues = this.localStorage.getValue(AppSettingsService.LAB_SYSTEMS_HEALTH_STATUS_KEY) || [];
  }

  public setIpAddressesList = (values: KeyTextPair[]): void => {
    this.ipAddressesList = values;
  };

  public setIsLoading = (loading: boolean): void => {
    this.isLoading = loading;
  };

  public setIsHealthStatusRefreshed = (refreshed: boolean): void => {
    this.isHealthStatusRefreshed = refreshed;
  };

  public setMachineTypesList = (types: KeyTextPair[]): void => {
    this.machineTypesList = types;
  };

  public setLabItems = (items: Selection<IObjectWithKey>): void => {
    this.labItems = items;
  };

  public setLabSystems = (systems: LabSystemType[]): void => {
    this.labSystems = systems || [];
  };

  public setAgentIds = (ids: string[]): void => {
    this.agentIds = ids;
  };

  public setFilteredLabSystems = (items: LabSystemType[]): void => {
    this.filteredLabSystems = items || [];
  };

  public setLabSystemsColumnList = (columns: TableColumnType[]): void => {
    this.labSystemsColumnList = columns;
  };

  public setLabSystemsEntireColumns = (columns: TableColumnType[]): void => {
    this.labSystemsEntireColumns = columns;
  };

  public showLabSystemsColumnEditor = (): void => {
    this.isLabSystemsColumnEditorOpen = true;
  };

  public hideLabSystemsColumnEditor = (): void => {
    this.isLabSystemsColumnEditorOpen = false;
  };

  public showRackManagerEditor = (): void => {
    this.isRackManagerEditorOpen = true;
  };

  public hideRackManagerEditor = (): void => {
    this.isRackManagerEditorOpen = false;
  };

  public showTagEditor = (): void => {
    this.isTagEditorOpen = true;
  };

  public hideTagEditor = (): void => {
    this.isTagEditorOpen = false;
  };

  public showLabManifestMetadataEditor = (): void => {
    this.isLabManifestMetadataEditorOpen = true;
  };

  public hideLabManifestMetadataEditor = (): void => {
    this.isLabManifestMetadataEditorOpen = false;
  };

  public setIsSarmIp = (value: boolean): void => {
    this.isSarmIp = value;
  };

  public showLabManifestMetadataDelete = (): void => {
    this.isLabManifestMetadataDeleteOpen = true;
  };

  public hideLabManifestMetadataDelete = (): void => {
    this.isLabManifestMetadataDeleteOpen = false;
  };

  public setTableGroups = (groups: any[] | null): void => {
    this.tableGroups = groups;
  };

  public setMachineTypeValues = (values: string[]): void => {
    this.machineTypeValues = values;

    this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_MACHINE_TYPE_KEY, values);
  };

  public setIpAddressValues = (values: string[]): void => {
    this.ipAddressValues = values;

    this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_IPADDRESS_KEY, values);
  };

  public setHealthStatusValues = (values: string[]): void => {
    this.healthStatusValues = values;

    this.localStorage.setValue(AppSettingsService.LAB_SYSTEMS_HEALTH_STATUS_KEY, values);
  };

  public setSelectedLabManifest = (labManifest: LabManifest | null): void => {
    this.selectedLabManifest = labManifest || null;
  };

  public setSelectedRacksIndices = (indices: number[] | []): void => {
    this.selectedRacksIndices = indices || [];
  };

  public setSelectedVirtualMachineIndices = (indices: number[] | []): void => {
    this.selectedVirtualMachineIndices = indices || [];
  };

  public setSelectedSystem = (row: LabSystemType | null): void => {
    this.selectedSystem = row;
  };

  public setSelectedSystems = (rows: LabSystemType[] | []): void => {
    this.selectedSystems = rows || [];
  };

  public get selectedSystemTitle(): string {
    const system: LabSystemType = this.selectedSystem;
    const systemId: number = system?.systemId;
    const systemName: string = system?.systemName || '';

    if (!system) {
      return '';
    }

    const title: string =
      `${t(LabDetails.SYSTEM, { ns: NS.LAB_DETAILS })}` +
      (system ? ` ${TITLE_DIVIDER} ${systemName} ${TITLE_DIVIDER} ${systemId}` : '');

    return title;
  }

  public clearSelectedSystems = (): void => {
    this.setSelectedSystem(null);
    this.setSelectedSystems([]);
  };

  public get isSystemSelected(): boolean {
    const match = !!this.selectedSystem;

    return match;
  }

  public doReset = (isPageRefresh = false): void => {
    const { setGroupByColumn } = this.appSettingsStore;
    const pageKey: string = PageMemoryKeys.LAB_SYSTEMS;

    this.setHealthStatusValues([]);
    this.setMachineTypeValues([]);
    this.setIpAddressValues([]);
    this.setSelectedSystem(null);
    this.setSelectedSystems([]);

    if (!isPageRefresh) {
      setGroupByColumn(pageKey, SytemColumnKeys.RACK_MANGER_NAME);
    }
  };
}

export default LabSystemsStore;
