import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  ChoiceGroup,
  CommandBarButton,
  DatePicker,
  DefaultButton,
  Dropdown,
  MessageBarType as FluentMessageBarType,
  SearchBox,
} from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { CheckboxVisibility, IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { Divider } from '@fluentui/react-components';
import { useBoolean } from '@fluentui/react-hooks';
import { CancelToken } from 'axios';
import { t } from 'i18next';
import format from 'string-template';

import { TimeRangeOptions } from '@/constants/DateFormatConstants';
import { CancellableStatuses, StatusOptions, ViewMode } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import {
  ColumnEditorKeys,
  Delimiters,
  EnablePagination,
  FilterOptions,
  KeyTextPair,
  Namespaces as NS,
  Pagination,
  SortedColumnPageKeys,
  SystemType,
} from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { setAdditionsToPagination } from '@/partials/Pagination/Pagination';
import { experimentStatusRequestService } from '@/services/_air/request-services/ExperimentStatusRequestService';
import { experimentRequestService, labsRequestService } from '@/services/_labs/request-services';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { useCancellationToken } from '@/utils/_air/Hooks/UseCancellationToken';
import { formatDate, formatDateTime, getDuration, getRunTimeRangeDates, sortByDate } from '@/utils/Dates';
import { createGroupByNoneItem, setTableDataGroupBy } from '@/utils/GroupBy';
import { navigationOnClick, statusCell } from '@/utils/Helpers';

import config from './Experiments.config.json';
import ExperimentTemplate from './ExperimentsTemplate';
import {
  ExperimentDetailsType,
  ExperimentRequestType,
  ExperimentRouteType,
  ExperimentServicesType,
  ExperimentType,
  ExperimentVMType,
  LabType,
  StepsType,
} from './ExperimentsTypes';

import styles from './Experiments.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface ExperimentsViewControllerProps {
  viewModel: ExperimentVMType;
  openPanelOnRowSelect: boolean;
}

let requestIdCounter = 0;

const generateRequestId = () => {
  return ++requestIdCounter;
};

const ExperimentsViewControllerFC: React.FC<ExperimentsViewControllerProps> = ({ viewModel, openPanelOnRowSelect }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const {
    appSettingsStore,
    editColumnsStore,
    experimentsStore,
    experimentDetailsStore,
    paginationStore,
    systemMessageStore,
    userSettingsStore,
    tableViewStore,
  } = rootStore;
  const { isDebugMode, isPartnerMode } = appSettingsStore;
  const { editorColumns } = editColumnsStore;
  const {
    canCancelSelectedExperiments,
    searchValue,
    pageSize,
    locationValue,
    statusValues,
    ipAddressValues,
    groupByValue,
    startDate,
    endDate,
    lastRunTimeRange,
    setSearchValue,
    setLocationValue,
    setStatusValues,
    setIpAddressValues,
    setGroupByValue,
    setStartDate,
    setEndDate,
    setLastRunTimeRange,
    doReset,
    clearSelectedExperiment,
    selectedExperiment,
    setSelectedExperiment,
    selectedExperiments,
    setSelectedExperiments,
  } = experimentsStore;
  const { experimentDetails, setExperimentDetails, openExperimentPanel, closeExperimentPanel, isExperimentStepPopupOpen } =
    experimentDetailsStore;
  const { addGlobalMessage } = systemMessageStore;
  const { timeZone } = userSettingsStore;
  const { setPaginationType } = paginationStore;
  const { doResetTableSort } = tableViewStore;

  const history = useHistory();
  const { labId, instanceId, type } = useParams<ExperimentRouteType>();
  const { experimentAirQuery, experimentLabsQuery, getQueryParams, hasFiltersApplied } = viewModel;

  // TODO: If we have a LabId provided, we need to tell the ExperimentDetails store that we are working
  // with a certain lab type. It needs to be updated to know the type from this point forward.
  const viewMode = labId && instanceId ? ViewMode.DETAILS_VIEW : ViewMode.LIST_VIEW;
  const isAirExperiment = type === SystemType.AIR;
  const isLabsExperiment = type === SystemType.LABS;
  const zeroValue = 0;
  const pageName = SortedColumnPageKeys.EXPERIMENT_PAGE;

  const [isColumnEditorOpen, { setTrue: showColumnEditor, setFalse: hideColumnEditor }] = useBoolean(false);
  const columnEditorKey = ColumnEditorKeys.EXPERIMENTS;

  // TODO: All these views on the full data, filtered and grouped, etc. should ideally come from
  // the Experiments Store. Over the next few weeks, we will slowly migrate and test this migration.
  const [allData, setAllData] = React.useState<ExperimentType[]>([]);
  const [tableGroups, setTableGroups] = React.useState<any[] | null>(null);
  const [columnsList, setColumnsList] = React.useState<any[]>([]);
  const [groupByColumn, setGroupByColumn] = React.useState<string>(null);
  const [title, setTitle] = React.useState<string>('');
  const [labList, setLabList] = React.useState<LabType[]>(null);
  const [locationList, setLocationList] = React.useState<KeyTextPair[]>([]);
  const [ipAddressList, setIpAddressList] = React.useState<KeyTextPair[]>([]);
  const [panelMessage, setPanelMessage] = React.useState<string>(null);
  const [entireColumns, setEntireColumns] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isIpAddressLoading, setIsIpAddressLoading] = React.useState(false);

  const activeModeColumns = config.experimentsColumnDefinitions;
  const latestRequestIdRef = React.useRef(0);
  const groupByNoneKey = t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });

  const cancellationToken = useCancellationToken();
  const services: ExperimentServicesType = {
    air: experimentStatusRequestService,
    labs: labsRequestService,
    ganymede: ganymedeExperimentRequestService,
  };

  // Reset the data to the original state without filters
  const handleClearAllFilters = () => {
    doResetTableSort(pageName);
    doReset();
  };

  const onLocationChange = (locationValue: string) => {
    setLocationValue(locationValue);
    setSearchValue('');
    setIpAddressValues([]);
    setStatusValues([]);
  };

  React.useEffect(() => {
    const title =
      (isExperimentStepPopupOpen ? `${t('step-details', { ns: NS.EXPERIMENTS })}` : `${t('experiment', { ns: NS.DEFAULT })}`) +
      (experimentDetails ? ` ${TITLE_DIVIDER} ${experimentDetails.name} ${TITLE_DIVIDER} ${experimentDetails.instanceId}` : '');

    setTitle(title);
  }, [isExperimentStepPopupOpen, experimentDetails]);

  const getExperimentDetails = async (
    services: ExperimentServicesType,
    cancellationToken: CancelToken,
    type: SystemType,
    labId: number,
    companyName: string,
    instanceId: string,
    teamName: string,
  ) => {
    let response: ExperimentDetailsType = {
      json: undefined,
      agentSteps: [],
      fullSteps: [],
    };

    if (type === SystemType.LABS) {
      isDebugMode && console.log('[ExperimentsViewModel:getExperimentDetails] Querying Labs information.');
      response = await experimentLabsQuery(services.labs, services.ganymede, instanceId, labId, instanceId, cancellationToken);
      isDebugMode && console.log('[ExperimentsViewModel:getExperimentDetails] Labs information collected.');
    } else {
      isDebugMode && console.log('[ExperimentsViewModel:getExperimentDetails] Querying AIR information.');
      response = await experimentAirQuery(services.air, teamName, instanceId, cancellationToken);
      isDebugMode && console.log('[ExperimentsViewModel:getExperimentDetails] AIR information collected.');
    }

    const json = (isAirExperiment ? response.json : response?.json?.[0]) || {};

    if (response.agentSteps) {
      response.agentSteps.map((step: StepsType) => {
        step.startTime = formatDate(step.stepJSON.startTime, timeZone);
        step.endTime = formatDate(step.stepJSON.endTime, timeZone);
      });
    }

    const details: ExperimentType = {
      type,
      labId,
      companyName,
      instanceId,
      definitionId: json.definition?.metadata?.experimentDefinitionId || FilterOptions.NA,
      sessionId: json.definition?.metadata?.experimentSessionId || FilterOptions.NA,
      location: json.definition?.metadata?.labName,
      name: json.definition?.name || json?.definition?.experimentName || json.definition?.metadata?.['experiment.name'],
      displayName: json.definition?.name,
      description: json.definition?.description,
      ipAddress: json.definition?.metadata?.ipAddress || FilterOptions.NA,
      status: json.status || json.definition?.status || FilterOptions.NO_STATUS,
      displayStatus: statusCell(json.status || json.definition?.status || FilterOptions.NA),
      owner: json.definition?.metadata?.userEmail || FilterOptions.NA,
      startTime: json.created ? formatDate(json?.created, timeZone) : '0',
      lastModified: json.lastModified ? formatDate(json.lastModified, timeZone) : '0',
      duration: getDuration(json.created, json.lastModified),
      ...response,
    };

    return details;
  };

  const getStatusList = (agentSteps: StepsType[]) => {
    const status = agentSteps?.map((item: StepsType) => item.status) ?? [];
    const statusSet = new Set(status);
    const statusArray = Array.from(statusSet);
    const uniqueStatus = statusArray.map((status: string) => ({
      key: status,
      text: status === Delimiters.DASH ? t('no-status', { ns: NS.DEFAULT }) : status,
    }));

    return uniqueStatus;
  };

  const getStepTypeList = (agentSteps: StepsType[]) => {
    const stepTypes = agentSteps?.map((item: StepsType) => item.stepType) ?? [];
    const stepTypesSet = new Set(stepTypes);
    const stepTypesArray = Array.from(stepTypesSet);
    const uniqueStepTypes = stepTypesArray.map((stepType: string) => ({
      key: stepType,
      text: stepType,
    }));

    return uniqueStepTypes;
  };

  const handleSingleRowClick = async (row: ExperimentType) => {
    setExperimentDetails(row as ExperimentType);

    if (row) {
      const instanceId: string = row.instanceId;
      const labId: number = row.labId;
      const teamName: string = FilterOptions.TEAM_NAME;

      try {
        const details = await getExperimentDetails(
          services,
          cancellationToken,
          row.type,
          labId,
          row.companyName,
          instanceId,
          teamName,
        );

        const agentStatusList: KeyTextPair[] = details?.agentSteps ? getStatusList(details.agentSteps) : [];
        const agentStepTypeList: KeyTextPair[] = details?.agentSteps ? getStepTypeList(details.agentSteps) : [];

        if (details) {
          const newRow: ExperimentType | null = {
            ...row,
            json: details.json,
            agentSteps: details.agentSteps,
            agentStatusList: agentStatusList,
            agentStepTypeList: agentStepTypeList,
          };

          setExperimentDetails(newRow as ExperimentType);
        }
      } catch (error) {
        console.error(error);
        setPanelMessage(error);
      }
    }
  };

  const onSelectRange = (key: string) => {
    setLastRunTimeRange(key);

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      const { runTimeStartDate, runTimeEndDate } = getRunTimeRangeDates(key, timeZone);

      setStartDate(runTimeStartDate);
      setEndDate(runTimeEndDate);
    }
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      return selectRow(selection);
    },
  });

  const selectRow = (item: Selection) => {
    const selectedItems: IObjectWithKey[] = item.getSelection();
    const selection: ExperimentType | null = selectedItems[0] as ExperimentType | null;
    const isOneRowSelected = selectedItems?.length === 1 || 0;
    const multipleRowsSelected = selectedItems.length > 1;

    // To be eventually deprecated with methods from the Experiment Store.
    setSelectedExperiments(selectedItems as ExperimentType[]);

    if (!multipleRowsSelected) {
      setSelectedExperiment(selection as ExperimentType);
      setExperimentDetails(selection as ExperimentType);
      handleSingleRowClick(selection);
    } else {
      clearSelectedExperiment();
    }

    if (openPanelOnRowSelect) {
      if (isOneRowSelected) {
        openExperimentPanel();
      } else {
        closeExperimentPanel();
      }
    }
  };

  const getLabWithLatestHeartbeat = (labs: LabType[]) => {
    const latestLab: LabType = labs.reduce((latestLab: LabType, currentLab: LabType) => {
      const latestHeartbeat = latestLab.LastHeartBeat ? new Date(latestLab.LastHeartBeat) : new Date(0);
      const currentHeartbeat = currentLab.LastHeartBeat ? new Date(currentLab.LastHeartBeat) : new Date(0);

      return currentHeartbeat > latestHeartbeat ? currentLab : latestLab;
    });

    return latestLab;
  };

  const loadSystemLocations = React.useCallback(async () => {
    const locations: KeyTextPair[] = [];

    if (!isPartnerMode) {
      const airLocation: KeyTextPair = { key: FilterOptions.TEAM_NAME, text: FilterOptions.TEAM_NAME };

      locations.push(airLocation);
    }

    try {
      const labs: LabType[] = await ganymedeLabRequestService.getLabs();

      setLabList(labs);

      if (labs?.length > 0) {
        const labLocations = labs.map((lab: LabType) => ({ key: String(lab.LabId), text: lab.LabName }));

        locations.push(...labLocations);

        if (labId) {
          setLocationValue(labId);
        } else if (locationValue === '') {
          const latestLab = getLabWithLatestHeartbeat(labs);
          setLocationValue(String(latestLab?.LabId));
        }
      }
    } catch (error) {
      console.error('Error fetching labs:', error);
    }

    locations.sort((a: any, b: any) => a.text.localeCompare(b.text));
    setLocationList(locations);
  }, [isPartnerMode]);

  const cancelSelectedExperiments = async () => {
    const tolerance = 5; // Max number of Instance Ids to show in a Confirmation Dialog.
    const overTolerance = selectedExperiments.length > tolerance;
    const displayRows = overTolerance ? selectedExperiments.slice(0, tolerance) : selectedExperiments;
    const ids: string[] = displayRows.map((row: ExperimentType) => row.id);
    const idList: string = ids.join('\n');
    const toleranceMessage = overTolerance ? format(t('showing-tolerance-template', { ns: NS.EXPERIMENTS }), { tolerance }) : '';
    const confirmation = format(t('cancel-bulk-confirmation-template', { ns: NS.EXPERIMENTS }), { ids: idList, toleranceMessage });

    if (!confirm(confirmation)) {
      // The user does not want to cancel the selected experiments.
      isDebugMode && console.log('[ExperimentsViewControllerFC] User has aborted bulk cancellation.');
      return;
    }

    const successIds: string[] = [];
    const failedIds: string[] = [];
    const skippedIds: string[] = [];

    for (const item of selectedExperiments) {
      const instanceId = item.instanceId.toString();

      try {
        if (CancellableStatuses.includes(item.status.toLowerCase())) {
          await ganymedeExperimentRequestService.cancelExperiment(instanceId);

          successIds.push(instanceId);
        } else {
          skippedIds.push(instanceId);
        }
      } catch (error) {
        failedIds.push(instanceId);
      }
    }

    const message: SystemMessageType = {
      message: format(t('cancel-experiment-message-template', { ns: NS.EXPERIMENTS }), {
        successCount: successIds.length,
        failedCount: failedIds.length,
        skippedCount: skippedIds.length,
      }),
      type: FluentMessageBarType.success,
      mode: MessageBarMode.normal,
      groupId: 'experiments-labs-group',
    };

    if (successIds.length !== 0) {
      await fetchExperiments();

      addGlobalMessage(message);
    } else if (failedIds.length !== 0) {
      message.type = FluentMessageBarType.error;
      addGlobalMessage(message);
    } else if (skippedIds.length !== 0) {
      message.type = FluentMessageBarType.info;
      addGlobalMessage(message);
    }
  };

  React.useEffect(() => {
    loadSystemLocations();
  }, [isPartnerMode]);

  React.useEffect(() => {
    loadIpAddresses();
  }, [locationValue]);

  React.useEffect(() => {
    const storedColumns = editorColumns(columnEditorKey);

    if (storedColumns?.length > 0) {
      const filteredColumns = activeModeColumns
        .filter((item) => storedColumns.includes(item.key))
        .map((item) => {
          item.isOptional = false;

          return item;
        });
      setColumnsList(filteredColumns);
    } else {
      setColumnsList(activeModeColumns);
    }

    setEntireColumns(activeModeColumns);

    if (columnsList?.length > 0) {
      const updatedColumnsList = columnsList.map((item) => {
        const matchingItem = activeModeColumns.find((activeColumn) => activeColumn.key === item.key);

        if (matchingItem && matchingItem.name !== item.name) {
          return { ...item, name: matchingItem.name };
        }

        return item;
      });

      setColumnsList(updatedColumnsList);
    }
  }, []);

  const setGroupByData = (tableData: any[], groupByField?: string) => {
    const groupByColumn = groupByField ?? groupByValue;
    const { returnData, groups } = setTableDataGroupBy(tableData, groupByColumn, activeModeColumns);

    setAllData(returnData);
    setTableGroups(groups);
    setGroupByColumn(groupByColumn);
  };

  React.useEffect(() => {
    fetchExperiments(null);
  }, [pageSize]);

  const getLocationType = () => {
    if (labList?.length > 0) {
      const labExists: boolean = labList.some((f) => String(f.LabId) === locationValue);

      if (labExists) {
        return SystemType.LABS;
      }
    }

    if (locationList?.length > 0) {
      const locationExists: boolean = locationList.some((f: KeyTextPair) => f.key === locationValue);

      if (locationExists) {
        return SystemType.AIR;
      }
    }

    return null;
  };

  const assignExperiment = (experiment: any, locationType: SystemType, lab?: LabType, labCompanyName?: string) => {
    const lastModifiedAirField = 'lastModifiedTime ';

    if (experiment) {
      const instanceId = experiment.id || FilterOptions.NA;
      const sessionId = experiment.definition?.metadata?.experimentSessionId || experiment.id || FilterOptions.NA;
      const revision = experiment.definition?.metadata?.revision || experiment.metadata?.revision || FilterOptions.NA;
      const name = experiment.definition?.name || experiment.experimentName || Delimiters.DASH;
      const displayName = experiment.definition?.name || experiment.experimentName || Delimiters.DASH;
      const description = experiment.definition?.description || experiment.description;
      const ipAddress = experiment.definition?.metadata?.ipAddress || FilterOptions.NA;
      const status = experiment.status || FilterOptions.NA;
      const owner = experiment.definition?.metadata?.userEmail || experiment.definition?.owner || FilterOptions.NA;
      const goalId = experiment.definition?.metadata?.executionGoalId || experiment.id || '';
      const definitionId = experiment.definition?.metadata?.experimentDefinitionId || FilterOptions.NA;

      const type = locationType;
      const companyName = labCompanyName || null;
      const lastModifiedTime = experiment.lastModified || experiment[lastModifiedAirField as string];
      const createdTime = experiment.created || experiment.createdTime;
      const startTime = createdTime ? formatDate(createdTime, timeZone) : FilterOptions.NA;
      const lastModified = lastModifiedTime ? formatDate(lastModifiedTime, timeZone) : FilterOptions.NA;
      const duration = getDuration(createdTime, lastModifiedTime);
      const location = lab?.LabName || experiment.teamName;
      const labId = lab?.LabId || null;
      const displayStatus: React.ReactElement = type === SystemType.LABS ? statusCell(status) : <>-</>;

      const experimentType: ExperimentType = {
        type,
        instanceId,
        definitionId,
        sessionId,
        revision,
        name,
        displayName,
        description,
        companyName,
        location,
        ipAddress,
        status,
        displayStatus,
        owner,
        startTime,
        duration,
        lastModified,
        goalId,
        labId,
      };

      return experimentType;
    }
  };

  const fetchExperiments = React.useCallback(
    async (currentPage?: number, currentSearchValue?: string) => {
      const experimentData: ExperimentType[] = [];
      let locationName = '';

      const selectedPageSize = pageSize || Pagination.DEFAULT_PAGE_SIZE;
      currentPage = currentPage || Pagination.CURRENT_PAGE;

      setIsLoading(true);

      const requestId = generateRequestId();
      latestRequestIdRef.current = requestId;

      try {
        isDebugMode && console.log('[ExperimentsViewControllerFC] Fetching Data.');

        if (locationList?.length > 0 && locationValue) {
          locationName = locationList.find((loc: KeyTextPair) => loc.key === locationValue)?.text;

          const locationType = getLocationType();

          if (locationType) {
            const queryParams: ExperimentRequestType = getQueryParams(
              currentPage,
              endDate,
              ipAddressValues,
              lastRunTimeRange,
              locationType,
              locationValue,
              selectedPageSize,
              startDate,
              statusValues,
              timeZone,
            );
            const experiments: any = await ganymedeExperimentRequestService.getExperimentDetails(queryParams, cancellationToken);

            if (latestRequestIdRef.current === requestId && experiments) {
              const paginationType = setAdditionsToPagination(
                handlePaginationChange,
                EnablePagination.EXPERIMENTS,
                false,
                selectedPageSize,
                currentPage,
                experiments.count,
                hasFiltersApplied,
              );

              setPaginationType(paginationType);

              const experimentsList = experiments.results;

              if (experimentsList?.length > 0 && locationType) {
                let lab: LabType = null;
                let labCompanyName: string = null;

                if (locationType === SystemType.LABS) {
                  lab = labList?.find((f) => String(f.LabId) === locationValue);

                  const companies: any[] = await ganymedeLabRequestService.getCompanies();
                  const labCompany: any = companies.find((activeCompany) => activeCompany.CompanyId === lab.CompanyId);

                  labCompanyName = labCompany.CompanyName;
                }

                for (const response of experimentsList) {
                  const experimentType = assignExperiment(response, locationType, lab, labCompanyName);

                  if (experimentType) {
                    experimentData.push(experimentType);
                  }
                }
              }

              const filteredData: ExperimentType[] = sortByDate(experimentData);

              setGroupByData(filteredData);
              setIsLoading(false);
            }
          }
        } else {
          setGroupByData([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('[ExperimentsViewControllerFC] Error fetching Experiments:', error);

        const apiErrorMessage = format(t('experiments-api-error-template', { ns: NS.ERRORS }), {
          location: locationName,
        });

        // Append an error to the message bar.
        const message: SystemMessageType = {
          message: apiErrorMessage,
          id: 'experiments-api-error',
          type: FluentMessageBarType.error,
          mode: MessageBarMode.normal,
          namespace: NS.ERRORS,
          groupId: 'experiments-api-group',
        };

        addGlobalMessage(message);
        setAllData([]);
        setIsLoading(false);
        return [];
      }
    },
    [isPartnerMode, startDate, endDate, lastRunTimeRange, statusValues, ipAddressValues, locationList, labList, locationValue],
  );

  const getLabCompanyName = async (labId: number) => {
    const lab: any = isLabsExperiment ? await ganymedeLabRequestService.getLabDetails(labId) : null;
    const companies: any[] = await ganymedeLabRequestService.getCompanies();
    const company: { CompanyId: string; CompanyName: string } =
      companies.find((activeCompany) => activeCompany.CompanyId === lab.CompanyId) || {};
    const companyName = company?.CompanyName;

    return companyName;
  };

  const fetchExperiment = React.useCallback(async () => {
    isDebugMode && console.log('[ExperimentsViewControllerFC] Fetching Experiment Instance:', labId, instanceId);

    const type = isAirExperiment ? SystemType.AIR : SystemType.LABS;
    const teamName = FilterOptions.TEAM_NAME;
    const companyName: string = isLabsExperiment ? await getLabCompanyName(Number(labId)) : null;

    const experimentDetails: ExperimentType = await getExperimentDetails(
      services,
      cancellationToken,
      type,
      Number(labId),
      companyName,
      instanceId,
      teamName,
    );

    if (isLabsExperiment) {
      const agentStatusList: KeyTextPair[] = experimentDetails?.agentSteps ? getStatusList(experimentDetails.agentSteps) : [];
      const agentStepTypeList: KeyTextPair[] = experimentDetails?.agentSteps ? getStepTypeList(experimentDetails.agentSteps) : [];

      experimentDetails.agentStatusList = agentStatusList;
      experimentDetails.agentStepTypeList = agentStepTypeList;
    }

    setSelectedExperiment(experimentDetails as ExperimentType);
    setExperimentDetails(experimentDetails as ExperimentType);
    setIsLoading(false);

    isDebugMode && console.log('[ExperimentsViewControllerFC] Fetching Done');
  }, [isPartnerMode]);

  const loadIpAddresses = async () => {
    setIsIpAddressLoading(true);

    const labId = Number(locationValue);

    try {
      if (!isNaN(labId)) {
        const ipAddresses: string[] = await ganymedeExperimentRequestService.getIPAddress(labId, cancellationToken);
        const ipAddressKeyTextPair: KeyTextPair[] = ipAddresses.map((ip: string) => ({ key: ip, text: ip }));

        setIpAddressList(ipAddressKeyTextPair);
      } else {
        setIpAddressList([]);
      }
    } catch (error) {
      console.error('[ExperimentsViewControllerFC] Error fetching IP Address:', error);

      // Append an error to the message bar.
      const message: SystemMessageType = {
        message: t('experiments-api-error-ipaddress'),
        id: 'experiments-api-error-ipaddress',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'experiments-api-group',
      };

      setIpAddressList([]);
    } finally {
      setIsIpAddressLoading(false);
    }
  };

  React.useEffect(() => {
    if (viewMode === ViewMode.DETAILS_VIEW) {
      // New Tab (Direct Link) Mode
      fetchExperiment();
    } else {
      // Regular Modal Mode
      fetchExperiments();
    }
  }, [fetchExperiment, fetchExperiments]);

  if (!allData && !experimentDetails) {
    return <LoadingSpinner />;
  }

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'create-experiment',
      text: t('create-experiment', { ns: NS.EXPERIMENTS }),
      title: t('create-experiment', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.ADD },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        navigationOnClick(event, Navigation.GANYMEDE.EXPERIMENT_EDITOR, history);
      },
    },
    {
      key: 'refresh',
      text: t('refresh', { ns: NS.COMMON }),
      title: t('refresh', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.REFRESH },
      onClick: () => {
        setIsLoading(true);
        fetchExperiments();
      },
      itemDivider: true,
    },
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: 'cancel-selected-experiments',
      text: t('cancel-selected-experiments', { ns: NS.EXPERIMENTS }),
      title: t('cancel-selected-experiments', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.CANCEL },
      disabled: !canCancelSelectedExperiments,
      onClick: (event) => {
        event.preventDefault();
        cancelSelectedExperiments();
      },
    },
  ];

  const fetchExperimentsByName = (currentSearchValue: string, isFetchExperiment: boolean) => {
    setSearchValue(currentSearchValue);

    if (isFetchExperiment || currentSearchValue === '') {
      fetchExperiments(null, currentSearchValue);
    }
  };

  const dateFilterMenuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={lastRunTimeRange}
            options={TimeRangeOptions}
            onChange={(ev, option) => {
              onSelectRange(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={lastRunTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setStartDate}
              value={startDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDateTime(startDate.toString(), lastRunTimeRange, timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={lastRunTimeRange !== FilterOptions.CUSTOM}
              onSelectDate={setEndDate}
              value={endDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDateTime(endDate.toString(), lastRunTimeRange, timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={t('search-name-instance-id', { ns: NS.EXPERIMENTS })}
          title={t('search-name-instance-id', { ns: NS.EXPERIMENTS })}
          role={'none'}
          value={searchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event, value) => fetchExperimentsByName(value, false)}
          onSearch={(value) => {
            fetchExperimentsByName(value, true);
          }}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          spellCheck="false"
        />
      ),
    },
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('select-last-runtime', { ns: NS.COMMON })}
          title={t('select-last-runtime', { ns: NS.COMMON })}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.defaultButton}
          menuProps={dateFilterMenuProps}
          onMenuClick={(ev, option) => {
            onSelectRange(lastRunTimeRange);
          }}
        />
      ),
    },
    {
      key: 'location-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-location', { ns: NS.COMMON })}
          title={t('select-location', { ns: NS.COMMON })}
          dropdownWidth="auto"
          selectedKey={locationValue}
          options={locationList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) => onLocationChange(option.key)}
        />
      ),
    },
    {
      key: 'status-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-status', { ns: NS.COMMON })}
          title={t('select-status', { ns: NS.COMMON })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={statusValues}
          options={StatusOptions}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setStatusValues(
              option.selected ? [...statusValues, option.key as string] : statusValues.filter((status) => status !== option.key),
            )
          }
        />
      ),
    },

    {
      key: 'ipaddress-filter',
      onRender: () => (
        <Dropdown
          placeholder={t('select-ipaddress', { ns: NS.COMMON })}
          title={t('select-ipaddress', { ns: NS.COMMON })}
          multiSelect
          dropdownWidth="auto"
          selectedKeys={ipAddressValues}
          options={ipAddressList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setIpAddressValues(
              option.selected ? [...ipAddressValues, option.key as string] : ipAddressValues.filter((ip) => ip !== option.key),
            )
          }
          disabled={isIpAddressLoading}
        />
      ),
    },
    {
      key: 'clear-all-filters',
      onRender: () => (
        <DefaultButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.RESET }}
          onClick={handleClearAllFilters}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
  ];

  const groupByProps: ICommandBarItemProps[] = activeModeColumns
    .filter((item: any) => item.isGrouped)
    .map((item) => {
      const groupByName = t(item.name, { ns: NS.TABLE });

      return {
        key: item.key,
        text: groupByName,
        onClick: () => handleGroupBySelected(item.key),
      };
    });

  const handleGroupBySelected = (groupByColumn: string) => {
    const groupedExperiments = allData || [selectedExperiment];

    setGroupByValue(groupByColumn);
    setGroupByData(groupedExperiments, groupByColumn);
  };

  const groupByNoneItem = createGroupByNoneItem(handleGroupBySelected);

  const farItems: ICommandBarItemProps[] = [
    {
      key: 'edit-columns',
      text: t('edit-columns', { ns: NS.EXPERIMENTS }),
      title: t('edit-columns', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        showColumnEditor();
      },
    },
    {
      key: 'divider2',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: 'group-by-column',
      onRender: () => (
        <CommandBarButton
          text={t(groupByValue, { ns: NS.TABLE }) || groupByNoneKey}
          iconProps={{ iconName: SystemIcons.GROUP_LIST }}
          menuProps={{ items: [groupByNoneItem, ...groupByProps] }}
        />
      ),
    },
  ];

  const tableItems: ExperimentType[] = allData || [selectedExperiment];

  const handlePaginationChange = (pageIndex: number): void => {
    fetchExperiments(pageIndex);
  };

  const overflowItems: ICommandBarItemProps[] = [];

  return (
    <ExperimentTemplate
      title={title}
      setTitle={setTitle}
      labId={labId}
      instanceId={instanceId}
      tableItems={tableItems}
      tableGroups={tableGroups}
      groupByColumn={groupByColumn}
      groupByColumns={activeModeColumns}
      overflowItems={overflowItems}
      commandBarItems={commandBarItems}
      filterItems={filterItems}
      farItems={farItems}
      config={config}
      panelLoadErrorMessage={panelMessage}
      selection={selection}
      selectionMode={SelectionMode.multiple}
      selectionPreservedOnEmptyClick={false}
      checkboxVisibility={CheckboxVisibility.always}
      isColumnEditorOpen={isColumnEditorOpen}
      hideColumnEditor={hideColumnEditor}
      columnsList={columnsList}
      setColumnsList={setColumnsList}
      entireColumns={entireColumns}
      columnEditorKey={columnEditorKey}
      isLoading={isLoading}
      pageName={pageName}
    ></ExperimentTemplate>
  );
};

const ExperimentsViewController = observer(ExperimentsViewControllerFC);

export default ExperimentsViewController;
