import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { SidePanelUserSettingsType } from '@/partials/SidePanel/SidePanelTypes';

@singleton()
class LabDetailsPanelStore {
  public isLabDetailsPanelOpen: boolean;
  public pageContent: JSX.Element;
  public panelPage: string;
  public userSettings: SidePanelUserSettingsType;

  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      isLabDetailsPanelOpen: observable,
      pageContent: observable,
      panelPage: observable,
      userSettings: observable,

      // Actions modify the state.
      closeLabDetailsPanel: action,
      openLabDetailsPanel: action,
      setPageContent: action,
      setPanelPage: action,
      setUserSettings: action,
    });
  }

  public openLabDetailsPanel = (): void => {
    this.setLabDetailsPanelOpen(true);
  };

  public closeLabDetailsPanel = (): void => {
    this.setLabDetailsPanelOpen(false);
  };

  protected setLabDetailsPanelOpen = (open: boolean): void => {
    this.isLabDetailsPanelOpen = open;
  };

  public setPageContent = (content: JSX.Element): void => {
    this.pageContent = content;
  };

  public setPanelPage = (page: string): void => {
    this.panelPage = page;
  };

  public setUserSettings = (settings: SidePanelUserSettingsType): void => {
    this.userSettings = settings;
  };
}

export default LabDetailsPanelStore;
