import configData from '@/config.json';
import { EnvironmentNames, EnvironmentSuffix, StorageDevEnvKey } from '@/constants/EnvironmentConstants';
import LocalStorageService from '@/services/LocalStorageService';

//File Constants
const baseUri = window.location.origin.toLowerCase();
const localStorageService = new LocalStorageService();

const EnvironmentConfig = {
  // Do not change order, comparison is made in order of declaration
  [EnvironmentSuffix.LOCALHOST]: EnvironmentNames.DEV,
  [EnvironmentSuffix.DEV]: EnvironmentNames.DEV,
  [EnvironmentSuffix.STAGING]: EnvironmentNames.STAGING,
  [EnvironmentSuffix.PROD_01]: EnvironmentNames.PROD,
  [EnvironmentSuffix.CRC_PORTAL]: EnvironmentNames.PROD,
};

// Generic configuration
const azureDevOpsTemplate = configData.AZURE_DEV_OPS_TEMPLATE;
const azureDevOpsTemplateExternal = configData.AZURE_DEV_OPS_TEMPLATE_EXTERNAL;

// Environment-specific configuration
const env = (() => {
  for (const [envName, config] of Object.entries(EnvironmentConfig)) {
    if (baseUri.includes(envName)) {
      return config;
    }
  }

  //NOTE: for the code, as it exists right now, if not explicitly declared in EnvironmentConfig, the env will end up as development
  // this includes all development slots.
  return EnvironmentNames.DEV;
})();

const configEnv: any =
  env === EnvironmentNames.PROD || env === EnvironmentNames.STAGING ? configData.PROD_ENV : configData.NONPROD_ENV;

const authTenantId = configEnv.AUTH_TENANT_ID;
const accessiblityInsightsEndpoint = configEnv.ACCESSIBILITY_INSIGHTS_URI;
const appInsightConnectionString = configEnv.APPINSIGHTS_CONNECTION_STRING;
const backendScope = configEnv.GANYMEDE_SCOPE;
const clientId = configEnv.CLIENT_ID;
const loggingConfig = configEnv.LOGGING;
const tenantId = configEnv.TENANT_ID;
const testPassUri = configEnv.VEGA_TEST_PASS_API_URL;
const documentationUri = configEnv.DOCUMENTATION_URI;
const releaseNotesUri = configEnv.RELEASE_NOTES_URI;

// Backend configuration
let devEnv: string | null = null;
let isDevSlot = false;
let selectedBackend = configEnv.BACKENDS.DEV;

if (env === EnvironmentNames.PROD) {
  // Production environment
  selectedBackend = configEnv.BACKENDS.PROD;
} else if (env === EnvironmentNames.STAGING) {
  // Staging environment
  selectedBackend = configEnv.BACKENDS.STAGING;
} else {
  // Development environment
  devEnv = localStorageService.getValue(StorageDevEnvKey) || localStorageService.setValue(StorageDevEnvKey, EnvironmentNames.DEV);

  if (devEnv === EnvironmentNames.DEV) {
    // Dev environment
    selectedBackend = configEnv.BACKENDS.DEV;
  } else if (devEnv === EnvironmentNames.VEGA_DEV) {
    // Vega Dev environment
    selectedBackend = configEnv.BACKENDS.VEGA_DEV;
  } else {
    // Development slot
    isDevSlot = true;
    selectedBackend = configEnv.BACKENDS.DEVELOPER_SLOT;
  }
}

const backendEndpoint = isDevSlot // Replace the placeholder with the dev slot name for development slots
  ? selectedBackend.GANYMEDE_API_URL.replace('<>', devEnv.trim().toLowerCase())
  : selectedBackend.GANYMEDE_API_URL;
const labsBackendEndpoint = selectedBackend.VEGA_API_URL;
const resultsExplorerEndpoint = selectedBackend.RESULTS_EXPLORER_URI;

// Microsoft Graph Endpoint (currently unused)
const graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me/memberOf';

const getResultExplorerAuthData = () => {
  return {
    scope: configEnv.RESULTS_EXPLORER_SCOPE,
  };
};

const isDevEnv = () => {
  return env === EnvironmentNames.DEV;
};

export {
  accessiblityInsightsEndpoint,
  appInsightConnectionString,
  authTenantId,
  azureDevOpsTemplate,
  azureDevOpsTemplateExternal,
  backendEndpoint,
  backendScope,
  clientId,
  documentationUri,
  env,
  graphMeEndpoint,
  labsBackendEndpoint,
  loggingConfig,
  releaseNotesUri,
  resultsExplorerEndpoint,
  tenantId,
  testPassUri,
  getResultExplorerAuthData,
  isDevEnv,
};
