import { SplitPanelDirectionType } from '@/constants/SystemConstants';
import LocalStorageService from '@/services/LocalStorageService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';

import { SplitPanelConfigType, SplitPanelLayoutConfigType, SplitPanelSplitType } from './SplitPanelTypes';

class SplitPanelViewModel {
  public addGlobalMessage;
  public isDebugMode;

  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;
  protected appSettingsStore: AppSettingsStore;

  public config: SplitPanelConfigType;

  public static getWindowSizes = (): { width: number; height: number } => {
    return {
      width: Math.round(window.innerWidth / 2),
      height: Math.round(window.innerHeight / 2),
    };
  };

  constructor(rootStore: RootStore) {
    const { appSettingsStore, localStorage } = rootStore;
    const { isDebugMode } = appSettingsStore;

    this.rootStore = rootStore;
    this.localStorage = localStorage;
    this.appSettingsStore = appSettingsStore;
    this.isDebugMode = isDebugMode;
  }

  // Save the value to the local storage.
  saveValue = (position: number, value: number, config: SplitPanelConfigType): number => {
    const key: string = config?.keys[position as number];
    const savedValue: number = Math.round(value);

    return this.localStorage.setValue(key, savedValue);
  };

  // Validate the size of the pane and handle edge cases.
  validateSize = (position: number, config: SplitPanelConfigType): number => {
    const isDebugMode = this.isDebugMode;
    const key: string = config.keys[position as number];
    const rawValue: string = this.localStorage.getValue(key);
    const parsedValue: number = parseInt(rawValue, 10);
    const storedValue: number = isNaN(parsedValue) ? undefined : Math.round(parsedValue);
    const isValueTooSmall = storedValue < config.minSize[position as number];
    const windowSize =
      position === 0
        ? SplitPanelViewModel.getWindowSizes().width - config.padding
        : SplitPanelViewModel.getWindowSizes().height - config.padding;
    const isValueTooLarge =
      config.maxSize[position as number] < 0
        ? storedValue > windowSize + config.maxSize[position as number]
        : storedValue > config.maxSize[position as number];

    // If the stored value is not set or is less than the minSize, set it to the default size.
    // This allows us to cleanse bad or invalid values from the local storage.
    // These checks are important to prevent the pane from being resized to an invalid size,
    // which can happen if the window height changes after the value is stored.
    if (!storedValue || isValueTooSmall) {
      const defaultValue = config.defaultSize[position as number];
      const savedValue: number = Math.round(defaultValue);

      isDebugMode && console.log('[validateSize]', position, storedValue, '==>', savedValue);

      return this.saveValue(position, savedValue, config);
    }

    if (isValueTooLarge) {
      const savedValue: number = config.defaultSize[position as number];

      isDebugMode && console.log('[validateSize]', position, storedValue, '==>', savedValue);

      return this.saveValue(position, savedValue, config);
    }

    // Make sure the storedValue is a integer.
    this.saveValue(position, storedValue, config);

    isDebugMode && console.log('[validateSize]', position, storedValue, ' OK');

    return storedValue;
  };

  getSplitDirection = (): SplitPanelSplitType | null => {
    const { isReadingPaneBottom, isReadingPaneRight } = this.appSettingsStore;

    if (isReadingPaneBottom) {
      return SplitPanelDirectionType.HORIZONTAL;
    } else if (isReadingPaneRight) {
      return SplitPanelDirectionType.VERTICAL;
    }

    return null;
  };

  getDimensions = (windowSize): number[] => {
    const horizontal = windowSize.height;
    const vertical = windowSize.width;
    const coordinates: number[] = [Math.round(horizontal / 2), Math.round(vertical / 2)];

    return coordinates;
  };

  generateLayoutConfig = (
    windowSize: any,
    horizonReadingPaneStateKeys: string,
    verticalReadingPaneStateKeys: string,
  ): SplitPanelLayoutConfigType => {
    return {
      // Layout for Outlook mode when the Reading Pane is on the bottom.
      horizontal: {
        defaultSize: [this.getDimensions(windowSize)[0]],
        minSize: [250],
        maxSize: [-250], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
        keys: [horizonReadingPaneStateKeys],
        offModeSize: ['100%'],
        padding: 250, // Padding to prevent the pane from being resized to the full height of the window.
      },

      // Layout for Outlook mode when the Reading Pane is on the right.
      vertical: {
        defaultSize: [this.getDimensions(windowSize)[1]],
        minSize: [250],
        maxSize: [-250], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
        keys: [verticalReadingPaneStateKeys],
        offModeSize: ['100%'],
        padding: 250, // Padding to prevent the pane from being resized to the full height of the window.
      },
    };
  };
}

export default SplitPanelViewModel;
