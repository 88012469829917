import { action, makeObservable, observable } from 'mobx';
import { inject, singleton } from 'tsyringe';

import { UserSettingsDefaults, UserSettingsKeys } from '@/constants/UserSettingsConstants';
import LocalStorageService from '@/services/LocalStorageService';

@singleton()
class UserSettingsStore {
  public timeZone: string;
  public devEnvironment: string;

  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    makeObservable(this, {
      timeZone: observable,

      setTimeZone: action,
    });

    this.timeZone = this.localStorage.getValue(UserSettingsKeys.USER_TIME_ZONE_KEY) || UserSettingsDefaults.DEFAULT_USER_TIME_ZONE;

    this.save(); // We shall persist for first-hit scenarios.
  }

  public setTimeZone = (timeZone: string) => {
    this.timeZone = timeZone;

    return this.localStorage.setValue(UserSettingsKeys.USER_TIME_ZONE_KEY, timeZone);
  };

  private save = () => {
    this.localStorage.setValue(UserSettingsKeys.USER_TIME_ZONE_KEY, this.timeZone);
  };
}

export default UserSettingsStore;
