import { t } from 'i18next';

import { AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { Delimiters, FilterOptions, KeyTextPair, Namespaces as NS } from '@/constants/SystemConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeVoid, ActionTypeWithParam } from '@/types/AppSettingsTypes';
import { AssetMetadata } from '@/types/AssetTypes';

class AssetTreeViewerViewModel {
  protected _assetsTreeData: AssetNode[] = [];

  protected _setFilterOptions: ActionTypeWithParam;
  protected _loadAsset: ActionTypeWithParam;
  protected _setAssetTreeData: ActionTypeWithParam;
  protected _setFilterType: ActionTypeWithParam;
  protected _setFilterTreeData: ActionTypeWithParam;
  protected _setLoadingAsset: ActionTypeWithParam;

  constructor(rootStore: RootStore, loadAsset: ActionTypeWithParam) {
    const { assetTreeViewerStore } = rootStore;
    const { assetsTreeData, setAssetTreeData, setFilterTreeData, setFilterType, setFilterTypeOptions, setLoadingAsset } =
      assetTreeViewerStore;

    this._assetsTreeData = assetsTreeData;

    this._loadAsset = loadAsset;
    this._setAssetTreeData = setAssetTreeData;
    this._setFilterTreeData = setFilterTreeData;
    this._setFilterType = setFilterType;
    this._setFilterOptions = setFilterTypeOptions;
    this._setLoadingAsset = setLoadingAsset;
  }

  public filter = (value: string, type: string) => {
    if (!value.trim() && type.toLowerCase() === FilterOptions.ALL) {
      this._setFilterTreeData(this._assetsTreeData);
      return;
    }

    this._setLoadingAsset(true);

    const copiedData = JSON.parse(JSON.stringify(this._assetsTreeData)); // Deep copy the data
    const filteredData = copiedData.filter((node) => this.searchChildren(node, value, type));

    this._setFilterTreeData(filteredData);

    this._setLoadingAsset(false);
  };

  private searchChildren = (node: AssetNode, value: string, type: string): boolean => {
    const matchesFilter =
      (node.metadata.name.toLowerCase().includes(value.toLowerCase()) && node.metadata.type.toLowerCase() === type.toLowerCase()) ||
      (node.metadata.name.toLowerCase().includes(value.toLowerCase()) && type.toLowerCase() == FilterOptions.ALL) ||
      (node.metadata.type.toLowerCase() === type.toLowerCase() && !value.trim());

    if (matchesFilter && node.isLeaf) {
      return true;
    }

    if (node.children && node.children.length > 0) {
      node.children = node.children.filter((child) => this.searchChildren(child, value, type));

      if (node.children.length > 0) {
        return true;
      }
    }

    return false;
  };

  private sortNodes = (a: AssetNode, b: AssetNode) => {
    if (a.isLeaf && !b.isLeaf) {
      return 1;
    }

    if (!a.isLeaf && b.isLeaf) {
      return -1;
    }

    const diff = a.metadata.name.toLowerCase().localeCompare(b.metadata.name.toLowerCase());

    return diff;
  };

  private searchTree = (node: AssetNode[], value: string): AssetNode => {
    for (let i = 0; i < node.length; i++) {
      if (node[i].name.toLowerCase().includes(value.toLowerCase())) {
        return node[i];
      }

      if (node[i].children) {
        const found = this.searchChildrenByName(node[i], value);

        if (found) {
          return found;
        }
      }
    }
  };

  private searchChildrenByName = (node: AssetNode, value: string): AssetNode => {
    if (node.name.toLowerCase().includes(value.toLowerCase())) {
      return node;
    }

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const found = this.searchChildrenByName(node.children[i], value);

        if (found) {
          return found;
        }
      }
    }

    return null;
  };

  private generateAssetTree = (node: AssetNode, tree: AssetNode[]) => {
    const path = node.path.split(Delimiters.FORWARD_SLASH);

    if (path.length === 0) {
      return;
    }

    let currentNode = this.searchTree(tree, path[0]);

    if (currentNode) {
      currentNode = this.addNodeToTree(path.slice(1), node, currentNode);
    } else {
      if (path.length === 1) {
        tree.push(node);
      } else {
        let newNode: AssetNode = {
          metadata: node.metadata,
          content: node.content,
          path: path[0],
          name: path[0],
          key: path[0],
          children: [],
          isLeaf: false,
          createdBy: '',
          description: '',
          isSaved: true,
        };
        tree.push(newNode);

        newNode = this.addNodeToTree(path.slice(1), node, newNode);
      }
    }

    return tree;
  };

  private addNodeToTree = (path: string[], node: AssetNode, tree: AssetNode) => {
    if (path.length === 0) {
      return;
    }

    if (path.length === 1) {
      tree.children.push(node);
      tree.children.sort(this.sortNodes);
      return;
    }

    const currentNode = this.searchChildrenByName(tree, path[0]);

    if (currentNode) {
      this.addNodeToTree(path.slice(1), node, currentNode);
    } else {
      const newNode: AssetNode = {
        metadata: node.metadata,
        content: node.content,
        path: path[0],
        key: path[0],
        name: path[0],
        children: [],
        isLeaf: false,
        createdBy: '',
        description: '',
        isSaved: true,
      };

      tree.children.push(newNode);
      tree.children.sort(this.sortNodes);
      this.addNodeToTree(path.slice(1), node, newNode);
    }

    return currentNode;
  };

  private convertAssetsToTree = (assets: AssetMetadata[]): void => {
    let treeData: AssetNode[] = [];

    const filterTypes: Set<string> = new Set<string>();
    filterTypes.add(t(FilterOptions.ALL, { ns: NS.COMMON }));

    assets.forEach((asset: AssetMetadata) => {
      const path: string = asset.name;
      const assetName: string[] = asset.name.split(Delimiters.FORWARD_SLASH);
      asset.name = assetName[assetName.length - 1];

      filterTypes.add(asset.type);

      const assetNode: AssetNode = {
        metadata: asset,
        content: null,
        createdBy: asset.createdBy,
        description: asset.description,
        isLeaf: true,
        key: asset.id,
        path: path,
        name: asset.name,
        isSaved: true,
      };

      treeData = this.generateAssetTree(assetNode, treeData);
    });

    treeData.sort(this.sortNodes);

    const filterOptions: KeyTextPair[] = [];

    filterTypes.forEach((type) => {
      filterOptions.push({
        key: type,
        text: type,
      });
    });

    this._setFilterOptions(filterOptions);
    this._setFilterType(filterOptions[0].key);
    this._setAssetTreeData(treeData);
    this._setFilterTreeData(treeData);
  };

  public loadAssetsMetadata = async () => {
    this._setLoadingAsset(true);

    const data: AssetMetadata[] = await assetsRequestService.getAllAssets();

    this.convertAssetsToTree(data);

    this._setLoadingAsset(false);
  };

  public loadAsset = (template: AssetNode) => {
    this._loadAsset(template);
  };
}

export default AssetTreeViewerViewModel;
