import React from 'react';
import ReactJson from 'react-json-view';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { t } from 'i18next';

import SessionDetailsStepsViewController from '@/components/SessionDetails/Sections/SessionDetailsSteps/SessionDetailsStepsViewController';
import config from '@/components/Sessions/Sessions.config.json';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import TableViewViewController from '@/partials/TableView/TableViewViewController';

import styles from '@/components/SessionDetails/SessionDetails.module.css';

type SessionDetailsStepsContentPanePartialProps = {
  viewController: SessionDetailsStepsViewController;
  checkboxVisibility: CheckboxVisibility;
};

const SessionDetailsStepsContentPanePartial: React.FC<SessionDetailsStepsContentPanePartialProps> = (
  props: SessionDetailsStepsContentPanePartialProps,
) => {
  const { checkboxVisibility, viewController } = props;

  const {
    isFailurePaneSelected,
    isLogsPaneSelected,
    isStepSelected,
    isStepsPaneSelected,
    labCompanyName,
    selectedExperimentFailure,
    selectedStep,
    selectedStepJson,
    sessionExperimentFailureColumns,
    showFailureAnalysisTable,
    stepName,
  } = viewController;

  return (
    <div className={`${styles['step-details-content-pane']}`}>
      <div className={`${styles['flex-column-container']} ${styles['breakable-block']}`}>
        {isStepsPaneSelected && (
          <>
            {!isStepSelected && (
              <div className={styles['centered-message']}>{t('select-step-for-json', { ns: NS.EXPERIMENTS })}</div>
            )}
            {isStepSelected && (
              <>
                <div className={`${styles['title']}`}>{stepName}</div>
                <div className={`${styles['breakable-block']} ${styles['scrollable-block']}`}>
                  {<ReactJson src={selectedStepJson} />}
                </div>
              </>
            )}
          </>
        )}
        {isLogsPaneSelected && (
          <>
            {selectedStep && <FilePreview companyName={labCompanyName} />}
            {!selectedStep && <div className={styles['centered-message']}>{t('select-step-for-logs', { ns: NS.EXPERIMENTS })}</div>}
          </>
        )}
        {isFailurePaneSelected && showFailureAnalysisTable && (
          <TableViewViewController
            items={selectedExperimentFailure as unknown as any[]}
            columns={sessionExperimentFailureColumns}
            displayColumns={config.sessionExperimentFailureDisplayFields}
            enableToolBar={false}
            enableColumnSort={true}
            tableStyle={styles['session-steps-table']}
            checkboxVisibility={checkboxVisibility}
            isCompact={true}
            isStickyHeader={true}
          />
        )}
      </div>
    </div>
  );
};

const SessionDetailsStepsContentPane = observer(SessionDetailsStepsContentPanePartial);

export default SessionDetailsStepsContentPane;
