import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Label, MessageBarType, PrimaryButton, SpinnerSize, TextField } from '@fluentui/react';
import { t } from 'i18next';
import format from 'string-template';

import { LabEntity, LabManifest, LabSystemType } from '@/components/ManageLab/LabSystems/LabSystemsType';
import { Buttons } from '@/constants/LabsConstants';
import { FailGroupIds, InfoGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { Common, Default, LabDetails } from '@/constants/TranslationConstants';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeLabDetailsRequestService } from '@/services/request-services/LabDetailsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import { isOnlyNumeric, isValidIp } from '@/utils/Helpers';

import style from './LabManifestMetadataEditor.module.css';

const LabManifestMetadataEditorFC: React.FC = () => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labDetailsStore, labSystemsStore, systemMessageStore } = rootStore;
  const { selectedLabId } = labDetailsStore;
  const {
    hideLabManifestMetadataDelete,
    hideLabManifestMetadataEditor,
    isLabManifestMetadataDeleteOpen,
    isLabManifestMetadataEditorOpen,
    isSarmIp,
    selectedLabManifest,
    selectedSystems,
    setSelectedLabManifest,
  } = labSystemsStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  // State Const
  const [metadataFieldValue, setMetadataFieldValue] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Other Const
  const updateTitle: string = isSarmIp ? LabDetails.SARM_UPDATE : LabDetails.JBOD_UPDATE;
  const deleteTitle: string = isSarmIp ? LabDetails.SARM_DELETE : LabDetails.JBOD_DELETE;
  const title: string = t(isLabManifestMetadataEditorOpen ? updateTitle : deleteTitle, { ns: NS.LAB_DETAILS });
  const ipAddress: string = t(Default.IP_ADDRESS, { ns: NS.DEFAULT });
  const jbodSlotId: string = t(LabDetails.JBOD_SLOT_ID, { ns: NS.LAB_DETAILS });
  const labelCaption: string = isSarmIp ? ipAddress : jbodSlotId;

  const agentIds: string[] = selectedSystems.map((system: LabSystemType) => system.agentId);
  const hidden: boolean = !isLabManifestMetadataEditorOpen && !isLabManifestMetadataDeleteOpen;
  const minWidth: string = isLabManifestMetadataEditorOpen ? '30%' : '20%';
  const onDismiss: () => void = isLabManifestMetadataEditorOpen ? hideLabManifestMetadataEditor : hideLabManifestMetadataDelete;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (selectedSystems?.length > 0) {
      getExistingMetadataField();
    }
  }, [selectedSystems]);

  const getExistingMetadataField = () => {
    const labManifest: LabManifest = selectedLabManifest;
    let prevMetadataField: string = null;
    let finalMetadataField = '';
    let doesMetadataFieldExist = false;

    if (labManifest) {
      for (const system of selectedSystems) {
        const labEntity: LabEntity = labManifest.labEntities?.find(
          (labEntity: LabEntity) => labEntity?.id.toUpperCase() === system.agentId.toUpperCase(),
        );
        const storedMetadataField: string = isSarmIp ? labEntity?.metadata?.acCycleRackId : labEntity?.metadata?.jbodSlotId;

        if (isLabManifestMetadataDeleteOpen && storedMetadataField) {
          doesMetadataFieldExist = true;
        }

        // Check if it's the first iteration
        if (prevMetadataField === null) {
          prevMetadataField = storedMetadataField;
        }

        if (storedMetadataField !== prevMetadataField) {
          finalMetadataField = '';
          break;
        }

        finalMetadataField = storedMetadataField || '';
      }
    }

    if (isLabManifestMetadataDeleteOpen && !doesMetadataFieldExist) {
      const infoMessage: SystemMessageType = {
        message: t(isSarmIp ? LabDetails.SARM_NOT_EXIST : LabDetails.JBOD_NOT_EXIST, { ns: NS.LAB_DETAILS }),
        type: MessageBarType.info,
        groupId: InfoGroupIds.LAB_DETAILS,
      };

      addGlobalMessage(infoMessage);
      hideLabManifestMetadataDelete();
    }

    setMetadataFieldValue(finalMetadataField);
  };

  const validateInput = (): boolean => {
    let message = '';

    if (metadataFieldValue.length === 0) {
      message = t(Common.ENTER_FIELD, { ns: NS.COMMON });
    } else {
      const isValidInput: boolean = isSarmIp ? isValidIp(metadataFieldValue) : isOnlyNumeric(metadataFieldValue);

      if (!isValidInput) {
        message = t(isSarmIp ? Common.INVALID_IP : Common.INVALID_SLOT, { ns: NS.COMMON });
      }
    }

    if (message) {
      const failMessage: SystemMessageType = {
        message: message,
        type: MessageBarType.error,
        groupId: FailGroupIds.LAB_SYSTEMS,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);

      return false;
    }

    return true;
  };

  const updateLabManifest = async () => {
    try {
      const labManifest: LabManifest = await ganymedeLabDetailsRequestService.getLabManifest(selectedLabId);

      if (labManifest) {
        setSelectedLabManifest(labManifest);
      } else {
        setSelectedLabManifest(null);
      }
    } catch (error) {
      setSelectedLabManifest(null);

      const handleErrorProps = {
        error,
        systemMessageStore: systemMessageStore,
        appSettingsStore: appSettingsStore,
        FailGroupIds: FailGroupIds.LAB_DETAILS,
      };

      HandleError(handleErrorProps);
      console.error('[LabManifestMetadata:updateLabManifest] Error updating lab manifest:', error);
    }
  };

  const getMetadataFieldAddMessage = (): string => {
    const singleMachineSuccessTemplate = t(isSarmIp ? LabDetails.SARM_ADD_SUCCESS_TEMPLATE : LabDetails.JBOD_ADD_SUCCESS_TEMPLATE, {
      ns: NS.LAB_DETAILS,
    });
    const singleMachineMessage: string = format(singleMachineSuccessTemplate, { item: selectedSystems[0]?.systemName });

    const multipleMachineMessage: string = t(isSarmIp ? LabDetails.SARM_ADD_SUCCESS : LabDetails.JBOD_ADD_SUCCESS, {
      ns: NS.LAB_DETAILS,
    });

    return selectedSystems?.length === 1 ? singleMachineMessage : multipleMachineMessage;
  };

  const saveLabManifestMetadataField = async (event: any): Promise<void> => {
    event.preventDefault();

    const isValidInput: boolean = validateInput();

    if (isValidInput) {
      const labEntities: string[] = agentIds;

      setIsLoading(true);
      labEntities.push(metadataFieldValue);

      await ganymedeLabDetailsRequestService
        .saveLabManifestMetadata(labEntities)
        .then(async (response: any) => {
          if (response) {
            const successMessage: SystemMessageType = {
              message: getMetadataFieldAddMessage(),
              type: MessageBarType.success,
              groupId: SuccessGroupIds.LAB_DETAILS,
            };

            addGlobalMessage(successMessage);
          }

          labEntities.pop();

          await updateLabManifest();
          setMetadataFieldValue(response);
          setIsLoading(false);
          hideLabManifestMetadataEditor();
        })
        .catch((error) => {
          labEntities.pop();
          setIsLoading(false);

          const handleErrorProps = {
            error,
            systemMessageStore: systemMessageStore,
            appSettingsStore: appSettingsStore,
          };

          HandleError(handleErrorProps);
          console.error('[LabManifestMetadata:saveLabManifestMetadataField] Error saving lab manifest metadata:', error);
        });
    }
  };

  const getManifestFieldDeleteMessage = (): string => {
    const singleMachineSuccessTemplate = t(
      isSarmIp ? LabDetails.SARM_DELETE_SUCCESS_TEMPLATE : LabDetails.JBOD_DELETE_SUCCESS_TEMPLATE,
      { ns: NS.LAB_DETAILS },
    );
    const singleMachineMessage: string = format(singleMachineSuccessTemplate, { item: selectedSystems[0]?.systemName });

    const multipleMachineMessage: string = t(isSarmIp ? LabDetails.SARM_DELETE_SUCCESS : LabDetails.JBOD_DELETE_SUCCESS, {
      ns: NS.LAB_DETAILS,
    });

    return selectedSystems?.length === 1 ? singleMachineMessage : multipleMachineMessage;
  };

  const deleteLabManifestMetadataField = async (): Promise<void> => {
    setIsLoading(true);

    await ganymedeLabDetailsRequestService
      .deleteLabManifestMetadata(selectedLabId, agentIds, isSarmIp)
      .then(async (response: any) => {
        if (response) {
          await updateLabManifest();

          const successMessage: SystemMessageType = {
            message: getManifestFieldDeleteMessage(),
            type: MessageBarType.success,
            groupId: SuccessGroupIds.LAB_CONTROL,
          };

          addGlobalMessage(successMessage);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore: systemMessageStore,
          appSettingsStore: appSettingsStore,
          FailGroupIds: FailGroupIds.LAB_DETAILS,
        };

        HandleError(handleErrorProps);
      })
      .finally(() => {
        setIsLoading(false);
        hideLabManifestMetadataDelete();
      });
  };

  const deleteConfirmationMessage: string = format(
    t(isSarmIp ? LabDetails.SARM_DELETE_CONFIRMATION_TEMPLATE : LabDetails.JBOD_DELETE_CONFIRMATION_TEMPLATE, {
      ns: NS.LAB_DETAILS,
    }),
    {
      value: metadataFieldValue,
    },
  );

  const validationMessages: SystemMessageType[] = globalMessages.filter((f) => f.showInPopup);

  const editorBody: JSX.Element = (
    <div className={style['metadata-wrapper']}>
      <MessageBarTemplate>{validationMessages}</MessageBarTemplate>
      <div>
        <div className={style['row']}>
          <Label className={style['label']}>{labelCaption}</Label>
          <TextField
            required
            type="text"
            title={labelCaption}
            value={metadataFieldValue}
            onChange={(e) => setMetadataFieldValue((e.target as any).value)}
            className={style['input']}
          />
        </div>
        <div className={`${style['row']}`}>
          <PrimaryButton className={`${style['button']}`} onClick={saveLabManifestMetadataField}>
            {t(Common.SAVE, { ns: NS.COMMON })}
          </PrimaryButton>
          <DefaultButton className={`${style['button']}`} onClick={hideLabManifestMetadataEditor}>
            {Buttons.cancel}
          </DefaultButton>
        </div>
      </div>
    </div>
  );

  const deleteBody: JSX.Element = (
    <div className={style['metadata-wrapper']}>
      <MessageBarTemplate>{validationMessages}</MessageBarTemplate>
      <div>
        {deleteConfirmationMessage}
        <div className={`${style['row']}`}>
          <DefaultButton
            className={`${style['default-button']} ${style['danger-button']}`}
            onClick={deleteLabManifestMetadataField}
          >
            {t(Common.DELETE, { ns: NS.COMMON })}
          </DefaultButton>
          <DefaultButton onClick={hideLabManifestMetadataDelete}>{t(Common.CLOSE, { ns: NS.COMMON })}</DefaultButton>
        </div>
      </div>
    </div>
  );

  return (
    <DialogBox
      title={title}
      minWidth={minWidth}
      body={
        <div>
          {isLabManifestMetadataEditorOpen ? editorBody : deleteBody}
          {isLoading && (
            <div className={style['overlay-spinner']}>
              <LoadingSpinner size={SpinnerSize.medium} />
            </div>
          )}
        </div>
      }
      hidden={hidden}
      modalProps={{
        isBlocking: true,
      }}
      onDismiss={onDismiss}
    />
  );
};

export const LabManifestMetadataEditor = observer(LabManifestMetadataEditorFC);
