import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

@singleton()
class DocumentationStore {
  public endDate: Date = new Date();
  public sasToken = '';

  constructor() {
    makeObservable(this, {
      sasToken: observable,
      endDate: observable,

      // Actions modify the state.
      setEndDate: action,
      setSasToken: action,
    });
  }

  public setSasToken = (token: string) => {
    this.sasToken = token;
  };

  public setEndDate = (date: Date) => {
    this.endDate = new Date(date);
  };
}

export default DocumentationStore;
