import React from 'react';
import { ThemeKeys } from 'react-json-view';
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { observer } from 'mobx-react-lite';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import AssetManagementBetaTemplate from '@/components/AssetManagement/AssetManagementTemplate';
import { AccordionKeys, AssetManagementVMType, AssetTableType } from '@/components/AssetManagement/AssetManagementTypes';
import { AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import { Namespaces as NS, ReadingPaneStateKeys } from '@/constants/SystemConstants';
import { darkBase, lightBase } from '@/layouts/Themes/ThemeConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { AssetContent } from '@/types/AssetTypes';

import styles from '@/components/ExperimentEditor/ExperimentEditor.module.css';

// This contains the sub view controllers like FileIO, and also manages the high-level business logic between the sub VCs
const AssetManagementBetaViewControllerFC: React.FC<{ viewModel: AssetManagementVMType }> = (props: {
  viewModel: AssetManagementVMType;
}) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { assetManagementStore, appSettingsStore } = rootStore;
  const { assetName, selectedAsset, setAssetName, setContentTableItems, setLoadingAsset, setSelectedAsset } = assetManagementStore;
  const { isDarkMode } = appSettingsStore;

  const { viewModel } = props;
  const { loadAsset } = viewModel;

  const [accordionOpenItems, setAccordionOpenItems] = React.useState<AccordionKeys[]>([
    AccordionKeys.ASSETS_METADATA,
    AccordionKeys.ASSETS_TYPE_METADATA,
    AccordionKeys.VERSION_SELECTION,
    AccordionKeys.CONTENT_METADATA,
    AccordionKeys.CONTENT,
  ]);

  const accordionToggle = (event, data) => {
    setAccordionOpenItems(data.openItems);
  };

  const [jsonStyle, setJsonStyle] = React.useState<any>(vs);

  React.useEffect(() => {
    setJsonStyle(isDarkMode ? vs2015 : vs);
  }, [isDarkMode]);

  const assetNameItem: ICommandBarItemProps[] = [
    {
      key: 'title',
      onRender: () => <div className={styles['subtitle']}>{t(assetName, { ns: NS.COMMON })}</div>,
    },
  ];

  const [assetSelection] = React.useState(
    new Selection({
      onItemsChanged: () => {
        if (!selectedAsset) {
          return;
        }

        const index = assetSelection
          .getItems()
          .findIndex((item) => (item as AssetTableType).id === selectedAsset.currentContent.id);

        if (index !== -1) {
          assetSelection.setIndexSelected(index, true, false);
        }
      },
      onSelectionChanged: () => {
        const selectedContent = assetSelection.getSelection()[0] as AssetTableType;

        if (!selectedContent) {
          return;
        }

        const newAsset = selectedContent.asset;

        assetsRequestService.getContent(selectedContent.asset.metadata.id, selectedContent.id).then((data: AssetContent) => {
          newAsset.currentContent = data;
        });

        return setSelectedAsset(newAsset);
      },
    }),
  );

  const [dataStyle] = React.useState<ThemeKeys>('bright:inverted');

  const filterItems: IOverflowSetItemProps[] = [];

  const loadAssetData = async (template: AssetNode) => {
    setLoadingAsset(true);

    // Load the template from the new asset database using id
    setAssetName(template.metadata.name);

    await loadAsset(template);

    setLoadingAsset(false);
  };

  const splitPanelConfig: SplitPanelConfigType = {
    defaultSize: [500],
    minSize: [200],
    maxSize: [-200], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
    keys: [ReadingPaneStateKeys.EXPERIMENT_EDITOR_VERTICAL],
    offModeSize: [500],
    padding: 200, // Padding to prevent the pane from being resized to the full height of the window.
  };

  return (
    <AssetManagementBetaTemplate
      accordionOpenItems={accordionOpenItems}
      assetName={assetNameItem}
      assetSelection={assetSelection}
      dataStyle={dataStyle}
      filterItems={filterItems}
      jsonStyle={jsonStyle}
      splitPanelConfig={splitPanelConfig}
      accordionToggle={accordionToggle}
      loadAsset={loadAssetData}
    />
  );
};

const AssetManagementBetaViewController = observer(AssetManagementBetaViewControllerFC);

export default AssetManagementBetaViewController;
