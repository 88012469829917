import { ICommandBarItemProps } from '@fluentui/react-experiments/lib/CommandBar';

enum LabSystemDetailsGeneralAccordionKeys {
  ESSENTIALS = 'essentials',
}

type LabSystemDetailsGeneralVMType = {
  refreshRackManagerInfo: () => Promise<void>;
};

type LabSystemDetailsGeneralVCType = {
  commandBarItems: ICommandBarItemProps[];
};

export { LabSystemDetailsGeneralAccordionKeys, LabSystemDetailsGeneralVCType, LabSystemDetailsGeneralVMType };
