import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { container } from 'tsyringe';

import SessionStepModel from '@/components/SessionDetails/SessionStepModel';
// import { LocationType, SessionStatusType, SessionType } from '@/components/Sessions/SessionsTypes';
import { SessionType } from '@/components/Sessions/SessionsTypes';
import { Statuses } from '@/constants/ExperimentConstants';
import { GanymedeSessionRequestService } from '@/services/request-services/SessionRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';

class SessionModel {
  @observable id: string;
  @observable description: string;
  @observable instances?: string[];
  @observable location?;
  @observable name: string;
  @observable owner: string;
  @observable schedule: string;
  @observable status?;

  // Timestamp related properties.
  @observable created: string;
  @observable duration?: string;
  @observable lastModified: string;
  @observable started: string;

  // Steps related properties.
  @observable steps?: SessionStepModel[];

  // Json related properties.
  @observable json?: unknown;
  @observable rawJson?: string;

  // Other hidden properties.
  @observable partition?: unknown;

  constructor(session: SessionType) {
    this.description = session.description;
    this.id = session.id;
    this.instances = session.instances;
    this.location = session.location;
    this.name = session.name;
    this.owner = session.owner;
    this.schedule = session.schedule;
    this.status = session.status;

    // Timestamp related properties.
    this.created = session.created;
    this.duration = session.duration;
    this.lastModified = session.lastModified;
    this.started = session.started;

    // Json related properties.
    this.json = session.json;
    this.rawJson = session.rawJson;

    // Steps related properties.
    this.steps = []; // Will be loaded later.

    // Other hidden properties.
    this.partition = session.partition;

    makeObservable(this);
  }

  @action
  public static async load(sessionId: string): Promise<SessionModel | null> {
    const appSettings = container.resolve(AppSettingsStore);

    const { isDebugMode } = appSettings;

    isDebugMode && console.log('[SessionModel:load] Loading Session SessionId:', sessionId);

    const cancelToken = axios.CancelToken.source().token;
    const sessionResponse = await GanymedeSessionRequestService.getExperimentSession(sessionId, cancelToken);
    const data: SessionType = sessionResponse?.data as SessionType;

    isDebugMode && console.log('[SessionModel:load] Data received:', data);

    if (data) {
      isDebugMode && console.log('[SessionModel:load] Session loaded:', sessionId);
      return new SessionModel(data);
    }

    isDebugMode && console.warn('[SessionModel:load] Session not loaded', sessionId);
    return null;
  }

  @action.bound
  setDescription(description: string) {
    this.description = description;
  }

  @action.bound
  setId(id: string) {
    this.id = id;
  }

  @action.bound
  setInstances(instances?: string[]) {
    this.instances = instances;
  }

  @action.bound
  setLocation(location?: string) {
    this.location = location;
  }

  @action.bound
  setName(name: string) {
    this.name = name;
  }

  @action.bound
  setOwner(owner: string) {
    this.owner = owner;
  }

  @action.bound
  setSchedule(schedule: string) {
    this.schedule = schedule;
  }

  @action.bound
  setStatus(status) {
    this.status = status;
  }

  @action.bound
  setCreated(created: string) {
    this.created = created;
  }

  @action.bound
  setStarted(started: string) {
    this.started = started;
  }

  @action.bound
  setLastModified(lastModified: string) {
    this.lastModified = lastModified;
  }

  @action.bound
  setDuration(duration?: string) {
    this.duration = duration;
  }

  @action.bound
  setSteps(steps?: SessionStepModel[]) {
    this.steps = steps;
  }

  @action.bound
  setJson(json?: unknown) {
    this.json = json;
  }

  @action.bound
  setRawJson(rawJson?: string) {
    this.rawJson = rawJson;
  }

  @action.bound
  setPartition(partition?: unknown) {
    this.partition = partition;
  }

  @computed
  get isFailed(): boolean {
    const status = this.status;
    return status?.final?.toLocaleLowerCase() === Statuses.FAILED.toLocaleLowerCase();
  }
}

export default SessionModel;
