import { CancelToken } from 'axios';

import { CreateLabType, LabUser } from '@/components/CreateLab/CreateLabType';
import { RepairLabMachineRequest } from '@/partials/LabComponents/RepairLabMachine/RepairLabMachineTypes';

import BaseRequestService from './RequestService';

class LabRequestService extends BaseRequestService {
  async createNewLab(payload: CreateLabType) {
    const url = 'labs';

    try {
      const { data } = await this.post(url, payload);

      return data;
    } catch (error) {
      console.error('[LabRequestService.createNewLab] An error has occurred:', error);

      throw error;
    }
  }

  async getLabs() {
    const url = 'labs';

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getLabs] An error has occurred:', error);

      throw error;
    }
  }

  async updateLabName(labId: any, labName: string) {
    const url = `labs/${labId}?labName=${labName}`;

    try {
      const { data } = await this.put(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.updateLabName] An error has occurred:', error);

      throw error;
    }
  }

  async deleteLab(labId: any) {
    const url = `labs/${labId}`;

    try {
      const { data } = await this.delete(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.deleteLab] An error has occurred:', error);

      throw error;
    }
  }

  async getLabDetails(labId: any) {
    const url = `labs/${labId}`;

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getLabDetails] An error has occurred:', error);

      throw error;
    }
  }

  async getCompanies() {
    const url = 'companies';

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getCompanies] An error has occurred:', error);

      throw error;
    }
  }

  async getOSImages() {
    const url = 'labs/os-images';

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getOSImages] An error has occurred:', error);

      throw error;
    }
  }

  async getUsers() {
    const url = 'labs/emails';

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getUsers] An error has occurred:', error);

      throw error;
    }
  }

  async addUsers(labId: number, labUsers: LabUser[]) {
    const url = `labs/${labId}/users`;

    try {
      const { data } = await this.post(url, labUsers);

      return data;
    } catch (error) {
      console.error('[LabRequestService.addUsers] An error has occurred:', error);

      throw error;
    }
  }

  async addUser(labId: number, labUser: LabUser) {
    const url = `labs/${labId}/user`;

    try {
      const { data } = await this.post(url, labUser);

      return data;
    } catch (error) {
      console.error('[LabRequestService.addUser] An error has occurred:', error);

      throw error;
    }
  }

  async deleteLabUser(labId: any, user: any) {
    const url = `labs/${labId}/users?userEmail=${user}`;

    try {
      const { data } = await this.delete(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.deleteLabUser] An error has occurred:', error);

      throw error;
    }
  }

  async getUserLabs() {
    const url = 'labs/users';

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getUserLabs] An error has occurred:', error);

      throw error;
    }
  }

  async getLabsUser(labId: any) {
    const url = `labs/${labId}/users`;

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getLabsUser] An error has occurred:', error);

      throw error;
    }
  }

  async getSystem(labId: any) {
    const url = `lab-systems/${labId}`;

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getSystem] An error has occurred:', error);

      throw error;
    }
  }

  async getVirtualSystem(labId: any) {
    const url = `lab-systems/${labId}/virtual-systems`;

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getVirtualSystem] An error has occurred:', error);

      throw error;
    }
  }

  async updateLab(labId: any, osImageId: any) {
    const url = `labs/${labId}/os-images/${osImageId}`;

    try {
      const { data } = await this.put(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.updateLab] An error has occurred:', error);

      throw error;
    }
  }

  async repairLabMachine(labId: string, isIPAddressFix: boolean, requestBody: RepairLabMachineRequest, cancelToken?: CancelToken) {
    const url = `lab-systems/${labId}/repair?isIPAddressFix=${isIPAddressFix}`;

    try {
      const { data } = await this.post(url, requestBody, { cancelToken });

      return data;
    } catch (error) {
      console.error('[LabRequestService.repairLabMachine] An error has occurred:', error);

      throw error;
    }
  }

  async getLabManifest() {
    const url = 'manifest-version';

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getLabManifest] An error has occurred:', error);

      throw error;
    }
  }

  async getRackManager(labId: any) {
    const url = `labs/${labId}/racks`;

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getRackManager] An error has occurred:', error);

      throw error;
    }
  }

  async getVirtualRackManager(labId: any) {
    const url = `labs/${labId}/virtual-racks`;

    try {
      const { data } = await this.get(url);

      return data;
    } catch (error) {
      console.error('[LabRequestService.getVirtualRackManager] An error has occurred:', error);

      throw error;
    }
  }
}

export const ganymedeLabRequestService = new LabRequestService();
