/* eslint-disable @typescript-eslint/ban-types */
import { action, makeObservable, observable } from 'mobx';
import { container, singleton } from 'tsyringe';

import AppSettingsStore from '@/stores/AppSettingsStore';

@singleton()
export default class EventBus {
  @observable
  private events: Record<string, Function[]> = {};

  private appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);

  constructor() {
    makeObservable(this);
  }

  @action
  on(eventName: string, callback: Function) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);
  }

  @action
  off(eventName: string, callback: Function) {
    if (!this.events[eventName]) {
      return;
    }

    this.events[eventName] = this.events[eventName].filter((_callback) => _callback !== callback);
  }

  emit(eventName: string, ...args: any[]) {
    this.appSettingsStore.isAdvancedMode && console.log('eventName received', eventName);

    if (!this.events[eventName]) {
      return;
    }

    this.events[eventName].forEach((callback: Function) => callback(...args));
  }
}
