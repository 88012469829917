import { MessageBarType } from '@fluentui/react';
import { t } from 'i18next';

import LabSystemsStore from '@/components/ManageLab/LabSystems/LabSystemsStore';
import { FailGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { LabDetails } from '@/constants/TranslationConstants';
import { firmwareRequestService } from '@/services/_labs/request-services';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';

import LabSystemDetailsGeneralStore from './LabSystemDetailsGeneralStore';

class LabSystemDetailsGeneralViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected labSystemDetailsGeneralStore: LabSystemDetailsGeneralStore;
  protected labSystemsStore: LabSystemsStore;
  protected systemMessageStore: SystemMessageStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, labSystemDetailsGeneralStore, labSystemsStore, systemMessageStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.labSystemDetailsGeneralStore = labSystemDetailsGeneralStore;
    this.labSystemsStore = labSystemsStore;
    this.systemMessageStore = systemMessageStore;
  }

  refreshRackManagerInfo = async (): Promise<void> => {
    const { setIsLoading } = this.labSystemDetailsGeneralStore;
    const { selectedSystem } = this.labSystemsStore;
    const { addGlobalMessage } = this.systemMessageStore;

    setIsLoading(true);

    try {
      // The service function (refreshRMInfo) needs to be written in ganymedeLabDetailsRequestService
      const refreshInfo: any = await firmwareRequestService.refreshRMInfo(selectedSystem.systemId);

      if (refreshInfo) {
        const message: string = t(LabDetails.REFRESH_RACK_MANAGER_SUCCESS, { ns: NS.LAB_DETAILS });
        const successMessage: SystemMessageType = {
          message: message,
          type: MessageBarType.success,
          groupId: SuccessGroupIds.LAB_DETAILS,
        };

        addGlobalMessage(successMessage);
      }
    } catch (error) {
      const handleErrorProps = {
        error,
        systemMessageStore: this.systemMessageStore,
        appSettingsStore: this.appSettingsStore,
        failGroupId: FailGroupIds.LAB_DETAILS,
      };

      HandleError(handleErrorProps);
    } finally {
      setIsLoading(false);
    }
  };
}

export default LabSystemDetailsGeneralViewModel;
