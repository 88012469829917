import { t } from 'i18next';

import { AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { Namespaces as NS } from '@/constants/SystemConstants';

const newAssetNode: AssetNode = {
  content: {
    id: '',
    createdBy: '',
    data: undefined,
    date: '',
    formId: '',
    assetId: '',
    partition: '',
    properties: undefined,
    schema: '',
    status: '',
    version: '',
  },
  createdBy: '',
  description: '',
  isLeaf: true,
  metadata: {
    createdBy: '',
    description: '',
    id: '',
    name: t('untitled-asset', { ns: NS.ASSETS }),
    owner: '',
    partition: '',
    schema: '',
    support: '',
    tags: [],
    type: '',
    versions: [],
  },
  name: t('untitled-asset', { ns: NS.ASSETS }),
  key: t('untitled-asset', { ns: NS.ASSETS }),
  path: t('untitled-asset', { ns: NS.ASSETS }),
  isSaved: false,
};

export { newAssetNode };
