const Defaults = {
  LABNAME: 'labName',
  PROGRAM: 'program',
  LOCATION: 'location',
  OSIMAGE: 'osImage',
};

const Labels = {
  NEW: 'New',
};

const SytemColumnKeys = {
  RACK_MANGER_NAME: 'rack-manager-name',
};

const PanelPage = {
  MACHINE_DETAILS: 'machine-details',
};

export { Defaults, Labels, PanelPage, SytemColumnKeys };
