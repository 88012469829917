import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { LabSystemType } from '@/components/ManageLab/LabSystems/LabSystemsType';

import { LabSystemDetailsComponentType } from './LabSystemDetailsType';

@singleton()
class LabSystemDetailsStore {
  public components: LabSystemDetailsComponentType[] = [];
  public systemGeneralInfo: LabSystemType;

  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      components: observable,

      // Actions modify the state.
      setComponents: action,
    });
  }

  public setComponents = (components: LabSystemDetailsComponentType[]): void => {
    this.components = components;
  };
}

export default LabSystemDetailsStore;
