import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton, SpinnerSize } from '@fluentui/react';
import { Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components';
import { t } from 'i18next';
import mime from 'mime';

import { ResultWithSubTitle } from '@/components/Experiments/ResultMessage/ResultMessage';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { DataType, Delimiters, FileOptions, FileType, MimeType, Namespaces as NS, TreeNodeType } from '@/constants/SystemConstants';
import { LogsView, Results } from '@/constants/TranslationConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import { logManagementRequestService } from '@/services/_labs/request-services';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { logDownloadHelper } from '@/utils/Helpers';

import RawLogsTreeViewStore from './RawLogsTreeViewStore';
import { RawLogsTreeViewProps, TreeFolderData, TreeNode } from './RawLogsTreeViewTypes';

import styles from './RawLogsTreeView.module.css';

const RawLogsTreeViewFC: React.FC<RawLogsTreeViewProps> = (props: RawLogsTreeViewProps) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore, rawLogsTreeViewStore } = rootStore;
  const {
    setPreviewFileName,
    setPreviewFilePath,
    setFilePreviewContent,
    setFileContentLoading,
    setIsLogError,
    isLogError,
    isDebugMode,
  } = appSettingsStore;

  const { setIsLargeFile, setExperimentDefinitionId, setExperimentInstanceId, setExperimentName } = rawLogsTreeViewStore;
  const { executedBy, id, companyName, testName, path, showLogText, isTitleHighlighted, onTreeClick } = props;

  let pNode: string[] = [];

  const [pathData, setPathData] = React.useState<TreeFolderData[]>();
  const [treeData, setTreeData] = React.useState<TreeNode[]>();
  const [expandedValues, setExpandedValues] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [loadingNodes, setLoadingNodes] = React.useState<{ [key: string]: boolean }>({});
  const downloadLogs = t(Results.DOWNLOAD_LOGS, { ns: NS.RESULTS });
  const logsTabHeaderText = t(LogsView.LOGS, { ns: NS.LOGS_VIEW });
  const logsNotFoundTitle = t(LogsView.LOGS_NOT_FOUND, { ns: NS.LOGS_VIEW });

  const isAFile = (fileName: string) => {
    // MimeType.VND_LOTUS This is validated due to bug (28696018)
    // IP (739,946daea41114,14,192.168.1.123) ends with .123 somehow to be considered as this mime type
    const mimeType = mime.getType(fileName) !== MimeType.VND_LOTUS && mime.getType(fileName) !== null;

    return mimeType;
  };

  const maskParentPath = (folderData: TreeFolderData[]) => {
    const tempParts: string[] = folderData[0]?.name?.split('/');

    if (tempParts) {
      setExperimentDefinitionId(tempParts[0]);
      setExperimentName(tempParts[1]);
      setExperimentInstanceId(tempParts[2]);
    }

    const updatedData: TreeFolderData[] = folderData.map((item: TreeFolderData) => {
      const parts: string[] = item.name?.split('/');
      item.name = parts?.slice(3).join('/');
      return item;
    });

    return updatedData;
  };

  const fullPath = (filePath: string) => {
    filePath = `${rawLogsTreeViewStore.experimentDefintionId}/${rawLogsTreeViewStore.experimentName}/${rawLogsTreeViewStore.experimentInstanceId}/${filePath}`;
    return filePath;
  };

  const getFolderData = async (type: string, path: string, companyName: string) => {
    let folderData: TreeFolderData[] = [];

    if (type === FileType.FILE) {
      folderData = await logManagementRequestService.getSpecificFileByPath(path, companyName, false);
    } else {
      folderData = await logManagementRequestService.getFilePathsById(path, companyName, false);
    }

    if (executedBy === LogsView.SESSION_DETAIS_STEPS_TEMPLATE) {
      const updatedData = maskParentPath(folderData);
      return updatedData;
    }

    return folderData;
  };

  const insert = (pathsInfo: TreeFolderData[], children: TreeNode[], [head, ...tail]: any) => {
    let child: TreeNode = children.find((child: TreeNode) => child.title === head);

    pNode.push([head][0]);

    if (!child) {
      children.push(
        (child = {
          title: head,
          key: `${pNode.join(Delimiters.FORWARD_SLASH)}`,
          children: [],
          isLeaf: isAFile(head),
        }),
      );
      pNode = [];
    }

    if (tail.length > 0 && typeof child !== DataType.UNDEFINED) {
      insert(pathsInfo, child.children, tail);
    }

    return children;
  };

  const generateDirectoryTree = (pathsInfo: TreeFolderData[]) => {
    const allPaths = pathsInfo.map((pathInfo: TreeFolderData) => pathInfo.name);

    const objectArray = allPaths
      .map((path: string) => `/${path}`.split(Delimiters.FORWARD_SLASH).slice(1))
      .reduce((children: TreeNode[], path: any) => insert(pathsInfo, children, path), []);

    return objectArray;
  };

  const getDetails = (type: string, folder: string) => {
    setIsLoading(true);
    setFilePreviewContent('');

    getFolderData(type, folder, companyName)
      .then((result: TreeFolderData[]) => {
        let directoryTree = generateDirectoryTree(result);

        if (testName) {
          directoryTree = directoryTree[0].children.filter((tree: TreeNode) => tree.title === testName);
        }

        setPathData(result);
        setTreeValues(directoryTree, result);
        setIsLogError(false);
      })
      .catch((error: any) => {
        setIsLogError(true);
        console.error('Error loading tree data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (id) {
      const fileType: string = path ? FileType.FILE : FileType.FOLDER;
      const folder: string = path || id;

      getDetails(fileType, folder);
    }
  }, [id, path]);

  const updateTreeData = (list: TreeNode[], key: React.Key, children: TreeNode[]): TreeNode[] =>
    list.map((node: TreeNode) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }

      return node;
    });

  const onLazyLoadData = (node: TreeNode, pathsInfo?: TreeFolderData[], loadedTreeData?: TreeNode[]) =>
    new Promise<void>((resolve) => {
      if (node.children?.length !== 0) {
        resolve();
        setFilePreviewContent('');
        return;
      }

      const currentTreeData = loadedTreeData || treeData;
      const pathInformation = pathsInfo || pathData;
      let filePath = pathInformation.find((pathObject) => pathObject.name.includes(node.key))?.name;

      if (executedBy === LogsView.SESSION_DETAIS_STEPS_TEMPLATE) {
        filePath = fullPath(filePath);
      }

      if (!filePath) {
        resolve();
        return;
      }

      if (node.isLeaf) {
        resolve();
        return;
      }

      node.open = true;
      setLoadingNodes((prevLoadingNodes) => ({ ...prevLoadingNodes, [node.key]: true }));
      setPreviewFilePath(filePath);

      setTimeout(() => {
        getFolderData(FileType.FOLDER, filePath, companyName).then((result: TreeFolderData[]) => {
          const temp = result.map((fileDetail: TreeFolderData) => ({
            title: fileDetail.name.split('/').pop(),
            key: fileDetail.name,
            isLeaf: isAFile(fileDetail.name.split('/').pop()),
            children: [],
          }));

          node.children = temp;

          setTreeData(updateTreeData(currentTreeData, node.key, temp));

          setPathData((prevPathData) => [...prevPathData, ...result]);
          setLoadingNodes((prevLoadingNodes) => ({ ...prevLoadingNodes, [node.key]: false }));

          resolve();
        });
      }, TimeInterval.ONE_SECOND);
    });

  const getContentData = (nodeKey: string, isLeaf: boolean) => {
    if (isLeaf) {
      const keySplit = nodeKey.split(Delimiters.FORWARD_SLASH);
      const key = keySplit[keySplit.length - 1];
      const filteredPathData = pathData.filter((pathObject) => pathObject.name.includes(nodeKey));

      if (filteredPathData.length > 0) {
        let filePath = filteredPathData[0].name;

        if (executedBy === LogsView.SESSION_DETAIS_STEPS_TEMPLATE) {
          filePath = fullPath(filePath);
        }

        setPreviewFilePath(filePath);

        if (isAFile(key)) {
          setPreviewFileName(key);
          setFileContentLoading(true);

          logManagementRequestService.downloadLogFile(filePath, companyName).then((downloadedContent) => {
            if (key.endsWith(FileType.CSV)) {
              const sizeInBytes = new Blob([downloadedContent]).size;
              const sizeInMB = sizeInBytes / FileOptions.BYTES_IN_MB;

              if (sizeInMB > FileOptions.CSV_FILE_SIZE) {
                setIsLargeFile(true);
                isDebugMode &&
                  console.log(`The downloaded content exceeds the size limit of ${FileOptions.CSV_FILE_SIZE}MB.`, sizeInMB);
              }
            }

            setFilePreviewContent(downloadedContent);
            setFileContentLoading(false);
          });
        }
      }
    }
  };

  const setTreeValues = (data: TreeNode[], pathsInfo: TreeFolderData[]) => {
    const treeExpandValues: string[] = [];

    const traverse = (node: TreeNode) => {
      treeExpandValues.push(node.title);
      node.open = true;

      if (node.children?.length > 0) {
        node.children.forEach((child) => traverse(child));
      } else {
        onLazyLoadData(node, pathsInfo, data);
      }
    };

    if (Array.isArray(data)) {
      data.forEach((item: TreeNode) => traverse(item));
    } else {
      traverse(data);
    }

    setTreeData(data);
    setExpandedValues(treeExpandValues);
  };

  const renderLogsHeader = (
    <div className={`${styles['logs-header-text']}${isTitleHighlighted ? ` ${styles['hot']}` : ''}`}>{logsTabHeaderText}</div>
  );

  const renderNoLogFound = (
    <>
      {showLogText && renderLogsHeader}
      <ResultWithSubTitle mainTitle={logsNotFoundTitle} />
    </>
  );

  const getIconName = (node: TreeNode) => {
    return { iconName: node.isLeaf ? SystemIcons.PAGE : node.open ? SystemIcons.FOLDER_OPEN : SystemIcons.FOLDER };
  };

  const renderTreeNodes = (nodes: TreeNode[]) => {
    if (nodes) {
      return nodes.map((node: TreeNode) => (
        <TreeItem
          key={node.title}
          itemType={node.isLeaf ? TreeNodeType.LEAF : TreeNodeType.BRANCH}
          value={node.title}
          onOpenChange={() => onLazyLoadData(node)}
          onClick={() => getContentData(node.key, node.isLeaf)}
          expand={true}
        >
          <TreeItemLayout
            className={styles['tree-view']}
            expandIcon={loadingNodes[node.key] ? <LoadingSpinner size={SpinnerSize.xSmall} /> : undefined}
          >
            <IconButton iconProps={getIconName(node)} />
            <span>{node.title}</span>
          </TreeItemLayout>
          {node.children && <Tree>{renderTreeNodes(node.children)}</Tree>}
        </TreeItem>
      ));
    }
  };

  if (isLoading) {
    return <LoadingSpinner size={SpinnerSize.medium} />;
  } else if (isLogError) {
    return renderNoLogFound;
  }

  return (
    <div className={styles['tree-view-wrapper']}>
      <div className={styles['tree-view-container']}>
        <div className={styles['logs-header']}>
          {showLogText && (
            <>
              {renderLogsHeader}
              <IconButton
                aria-label={downloadLogs}
                title={downloadLogs}
                iconProps={{ iconName: SystemIcons.DOWNLOAD }}
                onClick={() => logDownloadHelper(id, systemMessageStore, companyName, true)}
              />
            </>
          )}
        </div>
        <Tree
          defaultOpenItems={expandedValues}
          className={styles['tree-view']}
          aria-label={t('tree-view', { ns: NS.COMMON })}
          onClick={onTreeClick}
        >
          {renderTreeNodes(treeData)}
        </Tree>
      </div>
    </div>
  );
};

export const RawLogsTreeView = observer(RawLogsTreeViewFC);
