import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CommandBar, Dropdown, IconButton, SearchBox, SpinnerSize, TooltipHost } from '@fluentui/react';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { Tree, TreeItem, TreeItemLayout, treeItemLevelToken } from '@fluentui/react-components';
import { t } from 'i18next';

import assetStyles from '@/components/AssetTreeViewer/AssetTreeViewerStyles';
import { AssetNode, AssetTreeViewerVCType } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { SystemIcons } from '@/constants/IconConstants';
import { Labels, Namespaces as NS, TreeNodeType } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import '@/styles/AssetTreeViewer.css';
import styles from './AssetTreeViewer.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

const AssetTreeViewerTemplateFC: React.FC<AssetTreeViewerVCType> = (props: AssetTreeViewerVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { assetTreeViewerStore } = rootStore;
  const { filterType, filterTypeOptions, filterValue, loadingAssets, setFilterType, setFilterValue } = assetTreeViewerStore;

  const { farItems, showLeavePrompt, treeData, generateActions, selectTemplate, filter } = props;

  const getIconName = (node: AssetNode) => {
    return { iconName: node.isLeaf ? SystemIcons.PAGE : SystemIcons.FOLDER };
  };

  const overflowProps: IButtonProps = { ariaLabel: t('more-commands', NS.COMMON) };

  const renderTreeItem = (node: AssetNode) => {
    return (
      <div className={styles['tree-node']}>
        {node.isLeaf && <IconButton iconProps={getIconName(node)} />}
        <div className={styles['tree-item-right']}>{node.name}</div>
        <div>
          {node.isLeaf && (
            <CommandBar
              className={styles['pagecommandbar']}
              overflowItems={generateActions(node)}
              overflowButtonProps={overflowProps}
            />
          )}
        </div>
      </div>
    );
  };

  const renderTreeNodes = (nodes: AssetNode[]) => {
    if (nodes) {
      return nodes.map((node: AssetNode) => (
        <TooltipHost
          key={node.name + '-tooltip'}
          content={<span>{node.name}</span>}
          id={`tooltip${node.key}`}
          styles={assetStyles.tooltip}
        >
          <TreeItem
            key={node.name}
            itemType={node.isLeaf ? TreeNodeType.LEAF : TreeNodeType.BRANCH}
            value={node.name}
            expand={true}
            onClick={() => {
              selectTemplate(node);
            }}
          >
            <TreeItemLayout className={`asset-tree ${styles['tree-view-layout']}`}>{renderTreeItem(node)}</TreeItemLayout>
            {node.children && <Tree className={styles['tree-view']}>{renderTreeNodes(node.children)}</Tree>}
          </TreeItem>
        </TooltipHost>
      ));
    }
  };

  return (
    <>
      <Prompt when={showLeavePrompt} message={t('unsaved-changes', { ns: NS.ASSETS })} />
      <PageCommandBar items={farItems} />
      <PageDivider />
      <div>
        <div className={styles['header']}>
          <Trans ns={NS.TITLES as Namespace}>explorer</Trans>
        </div>
        <SearchBox
          placeholder={t('search-name', { ns: NS.EXPERIMENTS })}
          title={t('search-name', { ns: NS.EXPERIMENTS })}
          role={'none'}
          value={filterValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(event, value) => {
            setFilterValue(value);
            filter(value, filterType);
          }}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.searchBox}
          spellCheck="false"
        />
        <Dropdown
          placeholder={t('select-type', { ns: NS.ASSETS })}
          title={t('select-type', { ns: NS.ASSETS })}
          dropdownWidth="auto"
          selectedKey={filterType}
          options={filterTypeOptions}
          className={filterBarStyles['pagefilterbar-item']}
          styles={assetStyles.dropdown}
          onChange={(event: any, option: any) => {
            setFilterType(option.text);
            filter(filterValue, option.text);
          }}
        />
      </div>
      {loadingAssets ? (
        <LoadingSpinner size={SpinnerSize.large} />
      ) : (
        <Tree className={styles['tree-view']} aria-label={t('tree-view', { ns: NS.COMMON })}>
          {renderTreeNodes(treeData)}
        </Tree>
      )}
    </>
  );
};

const AssetTreeViewerTemplate = observer(AssetTreeViewerTemplateFC);

export default AssetTreeViewerTemplate;
