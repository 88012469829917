import React from 'react';
import { t } from 'i18next';

import AssetManagementStore from '@/components/AssetManagement/AssetManagementStore';
import { AssetTableType } from '@/components/AssetManagement/AssetManagementTypes';
import { AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore } from '@/stores/RootStore';
import { Asset } from '@/types/AssetTypes';

class AssetManagementViewModel {
  protected assetManagementStore: AssetManagementStore;

  constructor(rootStore: RootStore) {
    const { assetManagementStore } = rootStore;

    this.assetManagementStore = assetManagementStore;
  }

  private buildAssetMenuItems = (asset: Asset): AssetTableType[] => {
    const output: AssetTableType[] = [];

    if (!asset) {
      return output;
    }

    for (let i = 0; i < asset.metadata.versions.length; i++) {
      const version = asset.metadata.versions[i];
      output.push({
        asset: asset,
        version: version.version,
        releaseDate: version.releaseDate,
        id: version.content,
      });
    }

    return output;
  };

  public loadAsset = async (template: AssetNode) => {
    if (template.metadata.name === t('untitled-asset', { ns: NS.ASSETS })) {
      const newAsset: Asset = {
        metadata: template.metadata,
        currentContent: {
          id: '',
          createdBy: '',
          data: undefined,
          date: '',
          formId: '',
          assetId: '',
          partition: '',
          properties: undefined,
          schema: '',
          status: '',
          version: '',
        },
      };

      this.assetManagementStore.setContentTableItems([]);
      this.assetManagementStore.setSelectedAsset(newAsset);

      return;
    }

    try {
      await assetsRequestService
        .getContent(template.metadata.id, template.metadata.versions[template.metadata.versions.length - 1].content)
        .then(async (data) => {
          const asset: Asset = { metadata: template.metadata, currentContent: data };
          this.assetManagementStore.setSelectedAsset(asset);

          const items = this.buildAssetMenuItems(asset);
          this.assetManagementStore.setContentTableItems(items);
        });
    } catch (error) {
      console.error('AssetManagementViewModel.loadAsset]: ' + error.message);
    }
  };
}

export default AssetManagementViewModel;
