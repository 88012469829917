import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pivot, PivotItem } from '@fluentui/react';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';

import LogsViewPanelViewController from '@/components/LogsViewPanel/LogsViewPanelViewController';
import { ResultExplorerVCType } from '@/components/ResultExplorer/ResultExplorerTypes';
import { PivotItems } from '@/constants/ResultExplorerConstants';
import { PageMemoryKeys } from '@/constants/SystemConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import pageContentStyles from '@/partials/PageContent/PageContentStyles';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

const ResultExplorerTemplateFC: React.FC<ResultExplorerVCType> = (props: ResultExplorerVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, paginationStore, resultExplorerStore } = rootStore;
  const { paginationType } = paginationStore;
  const { tabMemory, setTabMemory } = appSettingsStore;
  const { displayColumns, columnsList, setColumnsList, filteredData } = resultExplorerStore;

  const {
    columnEditorKey,
    commandBarItems,
    entireColumns,
    farItems,
    filterItems,
    hideColumnEditor,
    isColumnEditorOpen,
    isLoading,
    selection,
    tabHeaders,
    tabPanesKey,
    token,
  } = props;

  const pageName = PageMemoryKeys.RESULT_EXPLORER;
  const activeTab: string = tabMemory[pageName as string] || PivotItems.MY_RESULTS;

  return (
    <>
      <PageCommandBar items={commandBarItems} farItems={farItems}></PageCommandBar>
      <PageDivider />
      <PageFilterBar items={filterItems}></PageFilterBar>
      {token ? (
        <Pivot
          defaultSelectedKey={activeTab}
          onLinkClick={(item) => setTabMemory(pageName, item.props.itemKey)}
          styles={pageContentStyles.pivot}
          className="ant-table-hack"
        >
          {tabHeaders.map((text, index) => (
            <PivotItem
              key={tabPanesKey[index as number]}
              headerText={text}
              itemKey={tabPanesKey[index as number]}
              styles={pageContentStyles.pivotItem}
            >
              <TableViewController
                columns={modifiedColumnConfiguration(columnsList)}
                items={filteredData}
                displayColumns={displayColumns}
                selection={selection}
                selectionMode={SelectionMode.single}
                checkboxVisibility={CheckboxVisibility.hidden}
                enableToolBar={false}
                isStickyHeader={true}
                isLoading={isLoading}
                selectionPreservedOnEmptyClick={true}
                paginationType={paginationType}
                pageName={`${pageName}_${tabPanesKey[index as number]}`}
              />
              <LogsViewPanelViewController></LogsViewPanelViewController>
              <ColumnEditorPanelTemplate
                isColumnEditorOpen={isColumnEditorOpen}
                columnsList={columnsList}
                setColumnsList={setColumnsList}
                hideColumnEditor={hideColumnEditor}
                entireColumns={entireColumns}
                columnEditorKey={columnEditorKey}
              ></ColumnEditorPanelTemplate>
            </PivotItem>
          ))}
        </Pivot>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export const ResultExplorerTemplate = observer(ResultExplorerTemplateFC);
