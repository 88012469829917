import { Navigation } from '@/constants/NavigationConstants';
import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import { NavLinkType } from '@/partials/NavLinks/NavLinksTypes';

const ROOT_LINKS: NavLinkItemType[] = [
  { key: Navigation.GANYMEDE.SESSIONS },
  //{ key: Navigation.GANYMEDE.EXPERIMENTS } // Commented out as we are deprecating the legacy home page
];

const LABS_LINKS: NavLinkItemType[] = [
  { key: Navigation.DIVIDERS.LABS, type: NavLinkType.Divider },
  { key: Navigation.GANYMEDE.LABS },
  { key: Navigation.GANYMEDE.LABS_BETA },
  { key: Navigation.LABS.VIEW_TEST_REPORTS },
  { key: Navigation.LABS.RELEASE_TOP_RESULTS },
  { key: Navigation.GANYMEDE.RESULT_EXPLORER },
  { key: Navigation.LABS.RESULT_SEARCH },
  { key: Navigation.LABS.MANAGE_PACKAGES },
];

const PARTNER_LINKS: NavLinkItemType[] = [
  { key: Navigation.DIVIDERS.LABS, type: NavLinkType.Divider },
  { key: Navigation.GANYMEDE.LABS },
  { key: Navigation.GANYMEDE.LABS_BETA },
  { key: Navigation.LABS.MY_DASHBOARD },
  { key: Navigation.LABS.MANAGE_PACKAGES },
];

const AIR_LINKS: NavLinkItemType[] = [
  { key: Navigation.DIVIDERS.AIR, type: NavLinkType.Divider },
  { key: Navigation.AIR.VIEW_STATUS },
  { key: Navigation.AIR.MANAGE_EXPERIMENT },
];

const DEFAULT_PORTAL_NAVLINKS: NavLinkItemType[] = [...ROOT_LINKS, ...LABS_LINKS, ...AIR_LINKS];

const DEVELOPER_LINKS: NavLinkItemType[] = [
  { key: Navigation.GANYMEDE.LABS_BETA },
  { key: Navigation.GANYMEDE.EXPERIMENTS },
  { key: Navigation.GANYMEDE.ASSET_MANAGEMENT },
];

const GANYMEDE_LINKS: NavLinkItemType[] = [...ROOT_LINKS, ...LABS_LINKS, ...AIR_LINKS];

export { DEFAULT_PORTAL_NAVLINKS, AIR_LINKS, DEVELOPER_LINKS, GANYMEDE_LINKS, LABS_LINKS, PARTNER_LINKS, ROOT_LINKS };
