import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';

class LabSystemDetailsViewModel {
  protected appSettingsStore: AppSettingsStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
  }

  // This file will have the functions while implementing other tabs.
}

export default LabSystemDetailsViewModel;
