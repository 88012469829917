import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import {
  ExperimentFailedReportType,
  ExperimentSuccessReportType,
  LabsType,
  MachinesType,
} from '@/components/MachineSelect/MachineSelectTypes';

@singleton()
class MachineSelectStore {
  public searchValue: string;
  public windowState: number;

  public labs: LabsType[];
  public isLabsLoading: boolean;
  public machines: MachinesType[];
  public machinesGroups: any[];
  public isMachinesLoading: boolean;

  public createdName: string;
  public creationMessage: string;
  public successfulExperiments: ExperimentSuccessReportType[];
  public failedExperiments: ExperimentFailedReportType[];
  public isExperimentLoading: boolean;

  constructor() {
    this.isLabsLoading = false;
    this.isMachinesLoading = false;
    this.isExperimentLoading = false;

    this.successfulExperiments = [];
    this.failedExperiments = [];

    makeObservable(this, {
      searchValue: observable,
      windowState: observable,
      labs: observable,
      isLabsLoading: observable,
      machines: observable,
      machinesGroups: observable,
      isMachinesLoading: observable,

      createdName: observable,
      creationMessage: observable,
      successfulExperiments: observable,
      failedExperiments: observable,
      isExperimentLoading: observable,

      // Actions modify the state
      setSearchValue: action,
      setWindowState: action,
      setLabs: action,
      setLabsLoading: action,
      setMachines: action,
      setMachinesGroups: action,
      setMachinesLoading: action,
      setCreatedName: action,
      setCreationMessage: action,
      setSuccessfulExperiments: action,
      setFailedExperiments: action,
      setExperimentLoading: action,
    });
  }

  public setSearchValue = (searchOpt: string) => {
    this.searchValue = searchOpt;

    return this.searchValue;
  };

  public setWindowState = (windowState: number) => {
    this.windowState = windowState;

    return this.windowState;
  };

  public setLabs = (labs: LabsType[]) => {
    this.labs = labs;

    return this.labs;
  };

  public setLabsLoading = (isLoading: boolean) => {
    this.isLabsLoading = isLoading;

    return this.isLabsLoading;
  };

  public setMachines = (machines: MachinesType[]) => {
    this.machines = machines;

    return this.machines;
  };

  public setMachinesGroups = (machinesGroups: any[]) => {
    this.machinesGroups = machinesGroups;

    return this.machinesGroups;
  };

  public setMachinesLoading = (isLoading: boolean) => {
    this.isMachinesLoading = isLoading;

    return this.isMachinesLoading;
  };

  public setCreatedName = (name: string) => {
    this.createdName = name;

    return this.createdName;
  };

  public setCreationMessage = (message: string) => {
    this.creationMessage = message;

    return this.creationMessage;
  };

  public setSuccessfulExperiments = (successful: ExperimentSuccessReportType[]) => {
    this.successfulExperiments = successful;

    return this.successfulExperiments;
  };

  public setFailedExperiments = (failed: ExperimentFailedReportType[]) => {
    this.failedExperiments = failed;

    return this.failedExperiments;
  };

  public setExperimentLoading = (isLoading: boolean) => {
    this.isExperimentLoading = isLoading;

    return this.isExperimentLoading;
  };
}

export default MachineSelectStore;
