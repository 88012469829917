import React from 'react';
import { observer } from 'mobx-react-lite';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import SessionDetailsStepsFilterTemplate from '@/components/SessionDetails/Sections/SessionDetailsSteps/Components/Filter/SessionDetailsStepsFilterTemplate';
import SessionDetailsStepsFilterViewController from '@/components/SessionDetails/Sections/SessionDetailsSteps/Components/Filter/SessionDetailsStepsFilterViewController';
import SessionDetailsStepsContentPane from '@/components/SessionDetails/Sections/SessionDetailsSteps/Partials/SessionDetailsStepsContentPanePartial';
import SessionDetailsStepsLinksPane from '@/components/SessionDetails/Sections/SessionDetailsSteps/Partials/SessionDetailsStepsLinksPanePartial';
import SessionDetailsStepsViewController from '@/components/SessionDetails/Sections/SessionDetailsSteps/SessionDetailsStepsViewController';
import config from '@/components/Sessions/Sessions.config.json';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { Namespaces as NS, SplitPanelDirectionType } from '@/constants/SystemConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import TableViewViewController from '@/partials/TableView/TableViewViewController';

import '@/styles/SplitPanel.css';
import styles from '@/components/SessionDetails/SessionDetails.module.css';

type SessionDetailsStepsVCType = {
  viewController: SessionDetailsStepsViewController;
};

const SessionDetailsStepsTemplateFC: React.FC<SessionDetailsStepsVCType> = (props: SessionDetailsStepsVCType) => {
  const { viewController } = props;

  const {
    allowResizePanel,
    checkboxVisibility,
    closeColumnEditor,
    columnEditorUserSettings,
    columns,
    columnsList,
    columnsEditorKey,
    detailsSplitDirection,
    entireColumns,
    filters,
    filteredSteps,
    groupByColumns,
    groupStepsBy,
    handleGroupBySelected,
    isLoading,
    isStepsColumnEditorOpen,
    openColumnEditor,
    selection,
    setColumnsList,
    showStepTitle,
    splitPanelConfig,
    tableGroups,
  } = viewController;

  const filterViewController: SessionDetailsStepsFilterViewController = React.useMemo(() => {
    return new SessionDetailsStepsFilterViewController(handleGroupBySelected, groupStepsBy, filters, openColumnEditor);
  }, []);

  if (isLoading) {
    return (
      <div className={styles['flex-column-container']}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <SessionDetailsStepsFilterTemplate viewController={filterViewController} />
      <div className={`${styles['flex-column-container']} ${styles['filtered-steps-container']}`}>
        <SplitPanel config={splitPanelConfig} index={0} isAllowResize={allowResizePanel} split={detailsSplitDirection}>
          <div className={`${styles['details-primary-pane']} steps-list`}>
            <TableViewViewController
              checkboxVisibility={checkboxVisibility}
              columns={columns}
              displayColumns={config.stepsDisplayFields}
              enableToolBar={false}
              tableStyle={styles['session-steps-table']}
              selection={selection}
              selectionMode={SelectionMode.single}
              groups={tableGroups}
              groupByColumn={groupStepsBy}
              groupByColumns={groupByColumns}
              isCompact={true}
              isStickyHeader={true}
              items={filteredSteps}
            />
          </div>
          <div className={`${styles['details-secondary-pane']}`}>
            {showStepTitle && <div className={styles['step-details-title']}>{t('step-details', { ns: NS.EXPERIMENTS })}</div>}
            <div className={styles['step-details-container']}>
              <SplitPanel
                config={splitPanelConfig}
                index={1}
                isAllowResize={allowResizePanel}
                split={SplitPanelDirectionType.VERTICAL}
              >
                <SessionDetailsStepsLinksPane viewController={viewController} />
                <SessionDetailsStepsContentPane viewController={viewController} checkboxVisibility={checkboxVisibility} />
              </SplitPanel>
            </div>
          </div>
        </SplitPanel>
        <ColumnEditorPanelTemplate
          columnEditorKey={columnsEditorKey}
          columnsList={columnsList}
          entireColumns={entireColumns}
          hideColumnEditor={closeColumnEditor}
          isColumnEditorOpen={isStepsColumnEditorOpen}
          setColumnsList={setColumnsList}
          userSettings={columnEditorUserSettings}
        />
      </div>
    </>
  );
};

const SessionDetailsStepsTemplate = observer(SessionDetailsStepsTemplateFC);

export default SessionDetailsStepsTemplate;
