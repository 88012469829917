import { LabDetailConfigType } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';

import BaseRequestService from './RequestService';

class LabConfigurationRequestService extends BaseRequestService {
  async updateLabDetail(payload: LabDetailConfigType) {
    const url = 'lab-configs';
    const { data } = await this.post(url, payload);

    return data;
  }

  async getLabDetail(labId: number) {
    const url = `lab-configs/${labId}`;
    const { data } = await this.get(url);

    return data;
  }
}

export const ganymedeLabConfigurationRequestService = new LabConfigurationRequestService();
