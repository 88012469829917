import React from 'react';
import Chart from 'react-apexcharts';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { ChartType, Namespaces as NS } from '@/constants/SystemConstants';

interface Props {
  seriesData: number[];
  total: number;
}

const StatusDonutChartFC: React.FC<Props> = ({ seriesData, total }: Props): JSX.Element => {
  // DOCUMENTATION: https://apexcharts.com/docs/chart-types/pie-donut/
  const chartType = ChartType.DONUT;
  const labels = [
    t('success', { ns: NS.COMMON }),
    t('pending', { ns: NS.COMMON }),
    t('in-progress', { ns: NS.COMMON }),
    t('failed', { ns: NS.COMMON }),
    t('not-started', { ns: NS.COMMON }),
    t('cancelled', { ns: NS.COMMON }),
    t('system-cancelled', { ns: NS.COMMON }),
  ];
  // This is the colour palette, where each one is taken, in order, and then restarts at the beginning.
  const colors = [
    'var(--colorPaletteGreenForeground3)',
    'var(--colorPaletteYellowForeground2)',
    'var(--colorPaletteBlueBorderActive)',
    'var(--colorPaletteRedForeground3)',
    'var(--colorPaletteMarigoldBackground3)',
    'var(--colorNeutralStroke1)',
    'var(--colorNeutralStroke2)',
  ];
  const textColor = 'var(--textColor)';
  const dimensions = { height: '300px', width: '100%' };
  const optionsData = {
    chart: {
      type: chartType,
    },
    dataLabels: {
      enabled: false,
      formatter: (value: number) => {
        return value + '%';
      },
      dropShadow: { enabled: false },
    },
    labels,
    colors,
    legend: {
      fontSize: '13px',
      fontFamily:
        'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
      offsetY: -15,
      offsetX: 5,
      labels: {
        colors: textColor,
      },
      formatter: (seriesName, opts): string => {
        const name = seriesName;
        const value = opts.w.globals.series[opts.seriesIndex] || 0;

        return `${name}: ${value}`;
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: '50%' /* Donut hole Size */,
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
              fontSize: '24px',
              color: [textColor],
              formatter: (value: number) => {
                const percent = (value / total) * 100;
                const display = parseFloat(percent.toFixed(1)) + '%';

                return display;
              },
            },
          },
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 1.75,
        },
      },
      active: {
        filter: {
          type: 'darken',
          value: 1.75,
        },
      },
    },
  };

  const [series, setSeries] = React.useState(seriesData);
  const [options] = React.useState<any>(optionsData);

  React.useEffect(() => {
    setSeries(seriesData);
  }, [seriesData]);

  return (
    <div>
      <Chart options={options} series={series} type={chartType} {...dimensions} />
    </div>
  );
};

const StatusDonutChart = observer(StatusDonutChartFC);

export default StatusDonutChart;
