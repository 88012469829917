import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { PaginationType } from '@/partials/Pagination/PaginationTypes';

@singleton()
class PaginationStore {
  public paginationType: PaginationType;

  constructor() {
    makeObservable(this, {
      // Observables not persisted.
      paginationType: observable,

      // Actions modify the state.
      setPaginationType: action,
    });
  }

  public setPaginationType = (pagination: PaginationType) => {
    this.paginationType = pagination;
  };
}

export default PaginationStore;
