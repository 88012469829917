import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { container } from 'tsyringe';

import SessionsStore from '@/components/Sessions/SessionsStore';
import { MachineTags, SessionMachineType, SessionStepType } from '@/components/Sessions/SessionsTypes';
import { Delimiters } from '@/constants/SystemConstants';
import { GanymedeSessionRequestService } from '@/services/request-services/SessionRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { formatDate } from '@/utils/Dates';

class SessionStepModel {
  private machine: SessionMachineType;
  private userSettingsStore: UserSettingsStore = container.resolve(UserSettingsStore);

  // Identifiers
  @observable experimentId: string;
  @observable id: string;

  // Experiment Info
  @observable displayName?: string;
  @observable experimentGroup?: string;
  @observable name?: string;

  // Step Info
  @observable agentId?: string;
  @observable stepName?: string;
  @observable stepType?: string;
  @observable status?: string;
  @observable sequence?: number;
  @observable stepJSON?: string | unknown;

  // Time Info
  @observable endTime?: string;
  @observable lastModifiedTime?: string;
  @observable startTime?: string;

  // Machine/Location Info
  @observable ipAddress?: string;
  @observable location?: string;

  constructor(sessionStep: SessionStepType, machine?: SessionMachineType) {
    this.initialize(sessionStep, machine);
    makeObservable(this);
  }

  private initialize(data: SessionStepType, machine?: SessionMachineType) {
    const { timeZone } = this.userSettingsStore;

    this.agentId = data.agentId;
    this.endTime = formatDate(data.endTime, timeZone);
    this.experimentGroup = data.experimentGroup;
    this.experimentId = data.experimentId;
    this.id = data.id;
    this.ipAddress = data.ipAddress;
    this.lastModifiedTime = data.lastModifiedTime;
    this.location = data.location || Delimiters.DASH;
    this.machine = machine;
    this.name = data.name;
    this.displayName = data.name;
    this.sequence = data.sequence;
    this.startTime = formatDate(data.startTime, timeZone);
    this.status = data.status;
    this.stepJSON = data.stepJSON || '{}';
    this.stepName = data.stepName;
    this.stepType = data.stepType;
  }

  @computed
  public get machineName(): string {
    return this.machine?.name || '';
  }

  @computed
  public get tagsName(): string {
    return this.machine?.tags?.map((tag: MachineTags) => tag.tagName).join(', ') || '';
  }

  @action
  public static async load(): Promise<SessionStepModel[]> {
    const appSettings = container.resolve(AppSettingsStore);
    const sessionsStore = container.resolve(SessionsStore);

    const { isDebugMode } = appSettings;
    const { machines, selectedSession } = sessionsStore;
    const sessionId: string = selectedSession.id;

    isDebugMode && console.log('[SessionStep:load] Loading Session Steps for SessionId:', sessionId);

    if (selectedSession?.steps?.length > 0) {
      isDebugMode && console.log('[SessionStep:load] Session Steps already loaded for SessionId:', sessionId);
      return selectedSession.steps as SessionStepModel[];
    }

    const cancelToken = axios.CancelToken.source().token;

    try {
      const data: SessionStepType[] = await GanymedeSessionRequestService.getSessionSteps(sessionId, cancelToken);
      isDebugMode && console.log('[SessionStep:load] Steps Data received for SessionId:', sessionId, data);
      isDebugMode && console.log('[SessionStep:load] Machine Data received for labId:', machines);

      if (data) {
        isDebugMode && console.log('[SessionStep:load] Session Steps loaded and done for SessionId:', sessionId);

        const getStepData: any = (step: SessionStepType): SessionStepModel => {
          const ipMatchedMachine: SessionMachineType = machines.find(
            (machine: SessionMachineType) => machine.ipAddress === step.ipAddress,
          );
          const sessionStep = new SessionStepModel(step, ipMatchedMachine);
          isDebugMode && console.log('[SessionStep:load] Returning SessionStep:', sessionStep);

          return sessionStep;
        };

        const updatedSessionData: SessionStepModel[] = data.map(getStepData);

        return updatedSessionData;
      }
    } catch (error) {
      console.error('[SessionStep:load] Error loading session steps or machine data:', error);
    }

    return [];
  }
}

export default SessionStepModel;
