import { MessageBarType } from '@fluentui/react';
import { t } from 'i18next';

import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import { Defaults } from '@/constants/LabsConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { Delimiters, FailGroupIds, Files, InfoGroupIds, Namespaces as NS } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import { paginateData } from '@/partials/Pagination/Pagination';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { ActionTypeWithParam, ActionTypeWithParamReturn } from '@/types/AppSettingsTypes';
import { formatDate } from '@/utils/Dates';
import { backendEndpoint } from '@/utils/Env';
import { createNavigationLink, downloadUrl } from '@/utils/Helpers';

import LabListStore from './LabListStore';
import { LabType } from './LabListTypes';

class LabListViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected systemMessageStore: SystemMessageStore;
  protected labListStore: LabListStore;
  protected labDetailsStore: LabDetailsStore;
  protected paginationStore: PaginationStore;

  protected addGlobalMessage: ActionTypeWithParamReturn;
  protected setLabs: ActionTypeWithParam;
  protected setIsLoading: ActionTypeWithParam;

  protected timeZone: string;
  public failGroupId = FailGroupIds.LAB;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, labDetailsStore, labListStore, paginationStore, systemMessageStore, userSettingsStore } = rootStore;
    const { addGlobalMessage } = systemMessageStore;
    const { timeZone } = userSettingsStore;
    const { setIsLoading } = labListStore;

    this.appSettingsStore = appSettingsStore;
    this.systemMessageStore = systemMessageStore;
    this.labDetailsStore = labDetailsStore;
    this.labListStore = labListStore;
    this.paginationStore = paginationStore;

    this.addGlobalMessage = addGlobalMessage;
    this.setIsLoading = setIsLoading;

    this.timeZone = timeZone;
  }

  protected getCompanyName = (companies: any, companyId: number): string | null => {
    const company: any = companies.filter((value) => value.CompanyId === companyId);

    if (company) {
      const companyName: string = company[0].CompanyName;

      return companyName;
    }

    return null;
  };

  protected handleLabLinkClick = (labId: number) => {
    const { setSelectedLabId } = this.labDetailsStore;

    if (labId) {
      setSelectedLabId(labId);
    }
  };

  protected buildDataMapping = async (results: any): Promise<LabType[]> => {
    const companies: any = await ganymedeLabRequestService.getCompanies();
    const relativePath = window.location.pathname;
    let navigationLink: JSX.Element = null;

    const mappedData: LabType[] = await Promise.all(
      results.map(async (result: any) => {
        const resultLabId = result.LabId;
        const isVirtualLab = result.IsVirtualLab;
        const labName = result.LabName;

        // While removing lab details(control) legacy, change the below code appropriately
        if (relativePath === Navigation.GANYMEDE.LABS_BETA) {
          navigationLink = createNavigationLink(labName, Navigation.GANYMEDE.LAB_DETAILS, () =>
            this.handleLabLinkClick(resultLabId),
          );
        } else {
          navigationLink = createNavigationLink(
            labName,
            `${Navigation.LABS.LAB_CONTROL}/labId=${resultLabId}&isVirtual=${isVirtualLab}`,
          );
        }

        return {
          labId: resultLabId,
          labDisplayName: navigationLink,
          labName: labName,
          labStatus: result.LabStatus,
          companyName: this.getCompanyName(companies, result.CompanyId),
          lastHeartBeat: formatDate(result.LastHeartBeat, this.timeZone),
          version: result.Version,
          isVirtualLab: isVirtualLab,
        };
      }),
    );

    return mappedData;
  };

  protected getLabAdmins = async (labId: number): Promise<string> => {
    const response = await ganymedeLabRequestService.getLabsUser(labId);

    if (response) {
      const admins = response
        .filter((user) => user.RoleName === Defaults.LAB_ADMIN)
        .map((user) => user.UserName)
        .join(Delimiters.COMMA_WITH_SPACE);

      return admins;
    }

    return '';
  };

  public fetchLabs = async (): Promise<void> => {
    const { setIsLabRefreshed, setLabs } = this.labListStore;

    try {
      this.setIsLoading(true);

      const results: any = await ganymedeLabRequestService.getLabs();

      if (results?.length > 0) {
        const mappedData: LabType[] = await this.buildDataMapping(results);

        setLabs(mappedData);
        setIsLabRefreshed(false);

        this.searchData();
      }
    } catch (error) {
      console.error('Error while fetching labs :', error);
    } finally {
      this.setIsLoading(false);
    }
  };

  public labDownloadHelper = (isExe: boolean): void => {
    const { selectedLab } = this.labListStore;

    const labId: number = selectedLab.labId;
    const baseUrl: string = backendEndpoint;

    const download = (path: string, file: string) => {
      downloadUrl(`${baseUrl}${path}`, file, this.systemMessageStore, FailGroupIds.LAB_DOWNLOAD);
    };

    if (isExe) {
      this.addGlobalMessage({
        message: t(Labs.DOWNLOAD_WAIT_TIME_REQUEST, { ns: NS.LABS }),
        type: MessageBarType.info,
        groupId: InfoGroupIds.LAB_DOWNLOAD,
      });

      download(`labs/${labId}/installer/LabInstaller.exe`, Files.LAB_INSTALLER_EXE);
    } else {
      download(`labs/${labId}/installer/CRCLabsSetup.msi`, Files.CRC_LABS_SETUP_MSI);
    }

    download(`labs/${labId}/installer/config`, Files.CRC_LABS_CONFIG_JSON);
  };

  public getFilteredResult = (): LabType[] => {
    const { searchValue, labs } = this.labListStore;

    const lowerCaseValue: string = searchValue?.toLowerCase();
    let filteredResult: LabType[] = labs;

    if (lowerCaseValue?.length) {
      filteredResult = filteredResult.filter(
        (e) =>
          e.labName?.toLowerCase().includes(lowerCaseValue) ||
          e.labStatus?.toLowerCase().includes(lowerCaseValue) ||
          e.companyName?.toLowerCase().includes(lowerCaseValue) ||
          e.version?.toLowerCase().includes(lowerCaseValue) ||
          e.owner?.toLowerCase().includes(lowerCaseValue),
      );
    }

    return filteredResult;
  };

  public searchData = (currentPage?: number) => {
    const { labs, searchValue, setFilteredLabs } = this.labListStore;
    const { paginationType, setPaginationType } = this.paginationStore;

    if (labs?.length > 0) {
      const hasFiltersApplied = searchValue.length > 0;
      const filteredResult: LabType[] = this.getFilteredResult();

      const { paginatedItems, updatedPaginationType } = paginateData(
        filteredResult,
        paginationType,
        hasFiltersApplied,
        currentPage,
      );

      setFilteredLabs(paginatedItems);
      setPaginationType(updatedPaginationType);
    }
  };
}

export default LabListViewModel;
