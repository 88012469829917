import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { useWindowSize } from '@/utils/UseWindowSize';

import SplitPanelTemplate from './SplitPanelTemplate';
import { SplitPanelConfigType, SplitPanelLayoutConfigType, SplitPanelViewControllerProps } from './SplitPanelTypes';

const SplitPanelViewControllerFC: React.FC<SplitPanelViewControllerProps> = ({
  config,
  children,
  index,
  isAllowResize,
  readingPaneStateHorizonKey,
  readingPaneStateVerticalKey,
  split,
  style,
  viewModel,
}) => {
  const { saveValue, validateSize, generateLayoutConfig, getSplitDirection } = viewModel;
  const windowSize = useWindowSize();
  let splitPanelConfig: SplitPanelConfigType = config;
  let defaultSize: number | string = '';

  if (!config) {
    const layoutConfig: SplitPanelLayoutConfigType = generateLayoutConfig(
      windowSize,
      readingPaneStateHorizonKey,
      readingPaneStateVerticalKey,
    );

    const splitDirection = getSplitDirection();
    splitPanelConfig = layoutConfig[splitDirection as string];

    split = split || splitDirection;
  }

  if (splitPanelConfig) {
    const offModeSize: number[] | string[] = splitPanelConfig?.offModeSize;

    if (index >= splitPanelConfig.offModeSize.length) {
      throw new Error('[SplitPanelViewControllerFC] Index is out of range of the offModeSize config property.');
    }

    // If we are not allowed to resize, then we default to a full height or width;
    defaultSize = isAllowResize ? validateSize(index, splitPanelConfig) : offModeSize[index as number];
  } else {
    // If we don't have a config (Hide Option Selected), then we don't need to render the split panel.
    // We simply return the children ReactElement.
    return <>{children}</>;
  }

  return (
    <SplitPanelTemplate
      config={splitPanelConfig}
      defaultSize={defaultSize}
      index={index}
      isAllowResize={isAllowResize}
      saveValue={saveValue}
      split={split}
      style={style}
    >
      {children}
    </SplitPanelTemplate>
  );
};

const SplitPanelViewController = observer(SplitPanelViewControllerFC);

export default SplitPanelViewController;
