/* eslint-disable quotes */
import { action, makeObservable, observable, toJS } from 'mobx';
import { singleton } from 'tsyringe';

import { AssetTableType } from '@/components/AssetManagement/AssetManagementTypes';
import { Asset } from '@/types/AssetTypes';

@singleton()
class AssetManagementStore {
  public assetName = '';
  public contentTableItems: AssetTableType[];
  public isLoadingAsset = false;
  public selectedAsset: Asset | null;

  constructor() {
    this.assetName = '';
    this.contentTableItems = [];
    this.isLoadingAsset = false;
    this.selectedAsset = null;

    makeObservable(this, {
      // These values are persisted.
      assetName: observable,
      contentTableItems: observable,
      isLoadingAsset: observable,
      selectedAsset: observable,

      // Actions modify the state.
      setAssetName: action,
      setContentTableItems: action,
      setLoadingAsset: action,
      setSelectedAsset: action,
    });
  }

  public setAssetName = (value: string): string => {
    this.assetName = value;

    return this.assetName;
  };

  public setContentTableItems = (value: AssetTableType[]): AssetTableType[] => {
    this.contentTableItems = value;

    return this.contentTableItems;
  };

  public setLoadingAsset = (value: boolean): boolean => {
    this.isLoadingAsset = value;

    return this.isLoadingAsset;
  };

  public setSelectedAsset = (value: Asset | null): Asset | null => {
    this.selectedAsset = value;

    return this.selectedAsset;
  };
}

export default AssetManagementStore;
