import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { t } from 'i18next';

import { Buttons } from '@/constants/LabsConstants';
import { EventKeys, FailGroupIds, Namespaces as NS } from '@/constants/SystemConstants';
import { Common, LabDetails } from '@/constants/TranslationConstants';
import { TagPickerComponent } from '@/partials/TagPicker/TagPickerComponent';
import { TagPickerConstants } from '@/partials/TagPicker/TagPickerConstants';
import { ganymedeTagRequestService } from '@/services/request-services/TagRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { HandleError } from '@/utils/_labs/HandleError';

import '@/styles/Labs.css';
import style from './AddSystemTag.module.css';

const AddSystemTagFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { isDarkMode } = appSettingsStore;
  const [tagOptions, setTagOptions] = React.useState<any[]>([]);
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const fetchExistingTags = React.useCallback(async () => {
    await ganymedeTagRequestService
      .getTags()
      .then((result: any) => {
        setTagOptions(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  }, []);

  React.useEffect(() => {
    fetchExistingTags();
  }, [fetchExistingTags]);

  const handleChanges = async (e: any) => {
    props.handleTagChanges(e, selectedValues);
    props.hideModal(true);
  };

  const allAvailableTags: string[] = tagOptions?.map((tag) => tag.TagName);

  const doesAlreadyNotExist = (event: React.KeyboardEvent<HTMLDivElement>, newValue: string): boolean => {
    if (event.key.toLowerCase() === EventKeys.ENTER) {
      return newValue?.length > 0;
    }

    return false;
  };

  return (
    <div>
      <TagPickerComponent
        data={allAvailableTags}
        size={TagPickerConstants.SIZE_MEDIUM}
        isDarkMode={isDarkMode}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>, newValue: string): boolean => {
          return doesAlreadyNotExist(event, newValue);
        }}
        failMessageGroupId={FailGroupIds.LAB_CONTROL}
        showMessageInPopup={true}
        placeholder={t(LabDetails.ASSIGN_SYSTEM_TAG_PLACEHOLDER, { ns: NS.LAB_DETAILS })}
        showAvatar={false}
      />
      <div className={style.row}>
        <PrimaryButton className={style.button} disabled={selectedValues?.length == 0} onClick={handleChanges}>
          {t(Common.SAVE, { ns: NS.COMMON })}
        </PrimaryButton>
        <DefaultButton className={style.button} onClick={props.hideModal}>
          {Buttons.cancel}
        </DefaultButton>
      </div>
    </div>
  );
};

export const AddSystemTag = observer(AddSystemTagFC);
