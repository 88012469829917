import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

enum CopyType {
  CAN_COPY = 'yes-copy',
  CANNOT_COPY = 'no-copy',
}

enum ChartType {
  DONUT = 'donut',
  LINE = 'line',
}

const Cosmos = {
  DATABASE_ID: 'Settings',
};

const DataType = {
  NULL: 'null',
  NUMBER: 'number',
  OBJECT: 'object',
  STRING: 'string',
  UNDEFINED: 'undefined',
};

enum ExperimentEditorKeys {
  SELECTED_LAB_ID = 'experiment-editor-selected-lab-id',
}

const ExperimentLoggingOptions = {
  MAX_STEP_LENGTH: 30,
};

const FileType = {
  CSV: 'csv',
  FILE: 'file',
  FOLDER: 'folder',
  JSON: 'json',
  LOG: 'log',
  SEVEN_ZIP: '7-Zip',
  ZIP: 'zip',
};

const FileOptions = {
  BYTES_IN_MB: 1024 * 1024,
  CSV_FILE_SIZE: 3,
};

const FilterOptions = {
  ALL: 'all',
  CUSTOM: 'custom',
  LAST_THIRTY_DAYS: '720',
  NA: 'n/a',
  NO_STATUS: '-',
  TEAM_NAME: 'CRC AIR',
};

const Emails = {
  SUPPORT_EMAIL: 'crc-support@microsoft.com',
};

const Files = {
  CRC_LABS_CONFIG_JSON: 'CRCLabsConfig.json',
  CRC_LABS_SETUP_MSI: 'CRCLabsSetup.msi',
  LAB_INSTALLER_EXE: 'LabInstaller.exe',
  LOGS_ZIP: 'Logs.zip',
  TOOL_KIT: 'toolkit.json',
};

const HealthDiffInMins = {
  UNHEALTHY: 10,
  WARNING: 5,
};

const IconNames = {
  PREVIEW: 'Preview',
  CHEVRON_DOWN: 'ChevronDown',
  FABRIC_NETWORK_FOLDER: 'FabricNetworkFolder',
  FABRIC_OPEN_FOLDER_HORIZONTAL: 'FabricOpenFolderHorizontal',
  PAGE_ADD: 'PageAdd',
  SAVE: 'Save',
  SAVE_AS: 'SaveAs',
  SEARCH: 'Search',
  QUEUE: 'BuildQueueNew',
};

const Labels = {
  ADMIN_COMPANY: 'Microsoft',
  HEALTHY: 'Healthy',
  LAB_HEALTH: 'Lab Health: ',
  LAB_HEALTHY: 'lab-healthy',
  LAB_NEUTRAL: 'lab-neutral',
  LAB_UNKNOWN: 'lab-unknown',
  LAB_UNHEALTHY: 'lab-unhealthy',
  LAB_WARNING: 'lab-warning',
  LAST_HEARTBEAT: 'Last Heartbeat: ',
  NA: 'N/A',
  NOT_AVAILABLE: 'Not Available',
  SKYPE_CIRCLE_CHECK: 'SkypeCircleCheck',
  STATUS: 'status',
  STATUS_ERROR_FULL: 'StatusErrorFull',
  STATUS_UNKNOWN: 'StatusUnknown',
  UNHEALTHY: 'Unhealthy',
  UNKNOWN: 'Unknown',
  WARNING: 'Warning',
};

const HealthStatusOptions: KeyTextPair[] = [
  { key: Labels.HEALTHY, text: Labels.HEALTHY },
  { key: Labels.UNHEALTHY, text: Labels.UNHEALTHY },
  { key: Labels.UNKNOWN, text: Labels.UNKNOWN },
  { key: Labels.WARNING, text: Labels.WARNING },
];

const Namespaces = {
  AIR: 'air',
  ASSETS: 'assets',
  AUTH: 'auth',
  COMMON: 'common',
  COMPANIES: 'companies',
  COSMOS: 'cosmos',
  DEFAULT: 'translations',
  DEV_ENVIRONMENTS: 'dev-environments',
  DOCUMENTATION: 'documentation',
  EDITOR: 'editor',
  ERRORS: 'errors',
  EXPERIMENTS: 'experiments',
  GRAPHS: 'graphs',
  HELP: 'help',
  INSIGHTS: 'insights',
  JUNO: 'air',
  LAB_DETAILS: 'lab-details',
  LABS: 'labs',
  LOGS_VIEW: 'logs-view',
  NAVIGATION: 'navigation',
  PAGINATION: 'pagination',
  RESULT_EXPLORER: 'result-explorer',
  RESULTS: 'results',
  TABLE: 'table',
  TEMPLATES: 'templates',
  TITLES: 'titles',
  PLAYGROUND: 'playground',
  SESSIONS: 'sessions',
  VEGA: 'labs',
  WIDGETS: 'widgets',
};

const PartnerDomains = {
  INTEL: 'intel',
  LENOVO: 'lenovo',
  MICROSOFT: 'microsoft',
  VSEI: 'vsei',
  WIWYNN: 'wiwynn',
  ZT: 'zt',
};

const PortalAliases = {
  AIR: 'air',
  GANYMEDE: 'ganymede',
  LABS: 'labs',
};

enum ReadingPaneCssKeys {
  BOTTOM = 'reading-pane-bottom',
  HIDDEN = '' /* Empty string */,
  RIGHT = 'reading-pane-right',
}

enum ReadingPaneKeys {
  HIDE = 'hide-reading-pane',
  SHOW_BOTTOM = 'show-bottom-pane',
  SHOW_RIGHT = 'show-right-pane',
}

// Keep track of the Application's Reading Pane Position
enum ReadingPanePositionType {
  BOTTOM = 'bottom',
  HIDE = 'hide',
  RIGHT = 'right',
}

// Reading Pane keys for LocalStorage persistence.
enum ReadingPaneStateKeys {
  EXPERIMENT_EDITOR_VERTICAL = 'experiment-editor-vertical',
  LAB_SYSTEMS_HORIZONTAL = 'lab-systems-pane-horizontal',
  LAB_SYSTEMS_VERTICAL = 'lab-systems-pane-vertical',
  SESSION_DETAILS_COORDINATES = 'session-details-pane-coordinates',
  SESSION_DETAILS_MAIN = 'session-details-pane-main',
  SESSION_DETAILS_PROPERTIES = 'session-details-pane-properties',
  SESSION_LIST_COORDINATES = 'session-list-pane-coordinates',
  SESSION_LIST_HORIZONTAL = 'session-list-pane-horizontal',
  SESSION_LIST_VERTICAL = 'session-list-pane-vertical',
}

enum SplitPanelDirectionType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

const SystemPortals = {
  AIR: 'juno',
  GANYMEDE: 'ganymede',
  LABS: 'vega',
};

const AliasMappings = {
  [PortalAliases.GANYMEDE]: SystemPortals.GANYMEDE,
  [PortalAliases.AIR]: SystemPortals.AIR,
  [PortalAliases.LABS]: SystemPortals.LABS,
};

const PortalAliasList = [
  PortalAliases.GANYMEDE, // In arrays, we will represent the main portal first.
  PortalAliases.AIR,
  PortalAliases.LABS,
];

const SystemPortalList = [
  SystemPortals.GANYMEDE, // In arrays, we will represent the main portal first.
  SystemPortals.AIR,
  SystemPortals.LABS,
];

const DEFAULT_PORTAL: string = SystemPortals.GANYMEDE;
const MS_COMPANY_ID = 1;

const SystemConstants = {
  ALIAS_LIST: PortalAliasList,
  ALIAS_MAPPINGS: AliasMappings,
  ALIASES: PortalAliases,
  DEFAULT_PORTAL,
  MS_COMPANY_ID,
  NS: Namespaces,
  PORTAL_LIST: SystemPortalList,
  PORTALS: SystemPortals,
};

const MimeType = {
  BINARY_FILE: 'application/octet-stream;charset=utf-8;',
  VND_LOTUS: 'application/vnd.lotus-1-2-3',
};

const TemplateSchema = {
  AIR: 'AIR',
  VEGA: 'Vega',
};

const PageSizeOptions: IDropdownOption[] = [
  { key: 100, text: '100' },
  { key: 250, text: '250' },
  { key: 500, text: '500' },
  { key: 1000, text: '1000' },
  { key: 2500, text: '2500' },
  { key: 5000, text: '5000' },
];

const Pagination = {
  CURRENT_PAGE: 0,
  DEFAULT_PAGE_SIZE: 250,
  DEFAULT_SESSION_PAGE_SIZE: 100,
  EXPERIMENTS_STEPS_PAGE_SIZE: 10,
  SESSIONS_FILTER_TEMPLATE_KEY: 'sessions-filter-template',
  SESSIONS_INFO_TEMPLATE_KEY: 'sessions-info-template',
  STANDARD_FILTER_TEMPLATE_KEY: 'standard-filter-template',
  STANDARD_INFO_TEMPLATE_KEY: 'standard-info-template',
  TOTAL_ITEMS: 0,
};

const EnablePagination = {
  EXPERIMENTS: true,
  EXPERIMENTS_STEPS: true,
  LAB_DETAILS_SYSTEM: true,
  LABS: true,
  RESULT_EXPLORER: true,
  SESSIONS: false,
};

enum SystemType {
  AIR = 'air',
  LABS = 'labs',
}

const TreeNodeType = {
  BRANCH: 'branch',
  LEAF: 'leaf',
};

enum TrueFalse {
  TRUE = 'true',
  FALSE = 'false',
}

type KeyTextPair = {
  key: string;
  text: string;
};

const FailGroupIds = {
  ADD_RULE: 'add-rule-fail',
  EXPERIMENT_STEP: 'experiment-steps-fail',
  LAB: 'lab-fail',
  LAB_CONTROL: 'lab-control-fail',
  LAB_CREATE: 'lab_create_fail',
  LAB_DELETE: 'lab_delete_fail',
  LAB_DETAILS: 'lab_details',
  LAB_DOWNLOAD: 'labs-download-fail',
  LAB_OS_IMAGES: 'lab_osimage-fail',
  LAB_SYSTEMS: 'lab-systems-fail',
  LOG_DOWNLOAD: 'log-download-fail',
  PEOPLE_PICKER: 'people-picker-fail',
  RESULT_EXPLORER: 'result-explorer-fail',
  RULE_PAGE: 'rule-page-fail',
  SARM_IP: 'sarm-ip-fail',
  SESSION_DETAILS: 'session-detail-fail',
  SESSION_PANEL_ERROR: 'session-panel-error',
  UPLOAD_PACKAGE: 'upload-package-fail',
  VIEW_PACKAGE: 'view-package-fail',
};

// Info / Waring Message Ids
const InfoGroupIds = {
  LAB: 'lab-info',
  LAB_DOWNLOAD: 'lab-download-info',
  LAB_DETAILS: 'lab_details',
  LAB_NEW_VERSION: 'lab-new-version-info',
  LOG_DOWNLOAD: 'log-download-info',
};

const SuccessGroupIds = {
  ADD_RULE: 'add-rule-success',
  EXPERIMENT_STEP: 'experiment-steps-success',
  LAB: 'lab-success',
  LAB_CONTROL: 'lab-control-success',
  LAB_CREATE: 'lab-create-success',
  LAB_DETAILS: 'lab-details-success',
  LAB_DOWNLOAD: 'labs-download-success',
  LOG_DOWNLOAD: 'log-download-success',
  PEOPLE_PICKER: 'people-picker-success',
  RESULT_EXPLORER: 'result-explorer-success',
  RULE_PAGE: 'rule-page-success',
  SESSION_DETAILS: 'session-detail-success',
  SESSION_VIEW: 'session-view-success',
  UPLOAD_PACKAGE: 'upload-package-success',
};

const PageMemoryKeys = {
  DOCUMENTATION: 'documentation',
  EXPERIMENT_DETAILS: 'experiment-instance-modal',
  LAB_DETAILS: 'lab-details',
  LAB_SYSTEMS: 'lab-systems',
  LOGS_VIEW: 'logs-view',
  RESULT_EXPLORER: 'results-explorer-template',
  RESULTS_TRIAGE: 'results-triage',
  SYSTEM_DETAILS: 'system-details',
};

const SortedColumnPageKeys = {
  EXPERIMENT_PAGE: 'experiment-page',
  LAB_DETAILS_SYSTEMS_PAGE: 'lab-details-systems-page',
  LAB_LIST_PAGE: 'lab-list-page',
  SESSION_PAGE: 'session-page',
};

const ColumnEditorKeys = {
  ALL_RESULTS: 'FILTER_ALLRESULTS_COLUMN_KEY',
  EXPERIMENTS: 'EXPERIMENTS_COLUMN_KEY',
  LAB_SYSTEMS: 'LAB_SYSTEMS_COLUMN_KEY',
  LABS: 'LABS_COLUMN_KEY',
  MYLAB_RESULTS: 'FILTER_MYLABRESULTS_COLUMN_KEY',
  MY_RESULTS: 'FILTER_MYRESULTS_COLUMN_KEY',
  SESSIONS: 'SESSIONS_COLUMN_KEY',
  SESSION_STEP: 'SESSION_STEP_COLUMN_KEY',
};

const UniqueListKey = {
  IP_ADDRESS: 'ipAddress',
  STATUS: 'status',
  STEP_TYPE: 'stepType',
};

const Units = {
  Eighty_Percent: '80%',
};

const Delimiters = {
  COLON: ':',
  COMMA_WITH_SPACE: ', ',
  DASH: '-',
  FORWARD_SLASH: '/',
  UNDERSCORE: '_',
};

const RegExpPatterns = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  IP_ADDRESS:
    /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/,
  NUMERIC: /^[0-9]*$/,
};

const EventKeys = {
  ENTER: 'enter',
  KEYDOWN: 'keydown',
  SCROLL: 'scroll',
};

const MachineTypes = {
  MACHINE: 'Machine',
  RACK: 'Rack',
  VIRTUALMACHINE: 'Virtual Machine', // VIRTUALMACHINE - Do not modify the key as this is written purposely with no underscore so as to match with the API response
};

const FormLimits = {
  LAB_NAME_MAX_LENGTH: 64,
  // Add other limits here as needed
};

const ExportExcelFileName = {
  FAILED_INSTANCE: 'FailedInstances',
  SUCCESSFUL_INSTANCE: 'SuccessfulInstances',
};

export {
  AliasMappings,
  ChartType,
  ColumnEditorKeys,
  CopyType,
  Cosmos,
  DataType,
  DEFAULT_PORTAL,
  Delimiters,
  Emails,
  EnablePagination,
  EventKeys,
  ExperimentEditorKeys,
  ExperimentLoggingOptions,
  ExportExcelFileName,
  FailGroupIds,
  Files,
  FileOptions,
  FileType,
  FilterOptions,
  FormLimits,
  HealthDiffInMins,
  HealthStatusOptions,
  IconNames,
  InfoGroupIds,
  KeyTextPair,
  Labels,
  MachineTypes,
  MimeType,
  Namespaces,
  PageMemoryKeys,
  PageSizeOptions,
  Pagination,
  PartnerDomains,
  PortalAliases,
  PortalAliasList,
  ReadingPaneCssKeys,
  ReadingPaneKeys,
  ReadingPanePositionType,
  ReadingPaneStateKeys,
  RegExpPatterns,
  SortedColumnPageKeys,
  SplitPanelDirectionType,
  SuccessGroupIds,
  SystemConstants,
  SystemPortalList,
  SystemPortals,
  SystemType,
  TemplateSchema,
  TreeNodeType,
  TrueFalse,
  UniqueListKey,
  Units,
};
