import React from 'react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';

const tooltip: Partial<ITooltipHostStyles> = {
  root: {
    display: 'inline-block',
  },
};

const dropdown = {
  label: { fontWeight: 'normal' },
  caretDownWrapper: { height: '30px', lineHeight: '30px', top: '1px', paddingTop: '0px' },
  caretDown: { height: '30px', lineHeight: '30px' },
  dropdownItemsWrapper: {
    maxHeight: '50vh',
  },
};

const styles = { tooltip, dropdown };

export default styles;
