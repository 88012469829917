import React from 'react';
import { observer } from 'mobx-react-lite';
import { ICommandBarItemProps } from '@fluentui/react/lib/components/CommandBar';
import { t } from 'i18next';

import AssetTreeViewerTemplate from '@/components/AssetTreeViewer/AssetTreeViewerTemplate';
import { AssetNode, AssetTreeViewerVMType } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { newAssetNode } from '@/constants/AssetConstants';
import { IconNames, Namespaces as NS } from '@/constants/SystemConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { AssetContent } from '@/types/AssetTypes';

const AssetTreeViewerViewControllerFC: React.FC<{ viewModel: AssetTreeViewerVMType }> = (props: {
  viewModel: AssetTreeViewerVMType;
}) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { assetTreeViewerStore } = rootStore;
  const { assetsTreeData, filterTreeData, setAssetTreeData, setFilterTreeData } = assetTreeViewerStore;
  const { viewModel } = props;
  const { filter, loadAssetsMetadata, loadAsset } = viewModel;

  const [showPrompt, setShowPrompt] = React.useState(false);

  React.useEffect(() => {
    loadAssetsMetadata();
  }, []);

  const generateActions = (node: AssetNode) => {
    return [
      {
        key: 'copy-json-' + node.metadata.name,
        text: t('copy-json', { ns: NS.EDITOR }),
        title: t('copy-json', { ns: NS.EDITOR }),
        onClick: async () => {
          if (node.metadata.versions.length === 0) {
            console.error(
              '[AssetTreeViewerViewControllerFC:generateActions] No content IDs available for node',
              node.metadata.name,
            );
            return;
          }

          await assetsRequestService
            .getContent(node.metadata.id, node.metadata.versions[node.metadata.versions.length - 1].content)
            .then((data: AssetContent) => {
              navigator.clipboard.writeText(JSON.stringify(data.data, null, 4)).catch((err) => {
                console.error('[AssetTreeViewerViewControllerFC:generateActions]Failed to copy to clipboard', err);
              });
            });
        },
      },
      {
        key: 'download-json-' + node.metadata.name,
        text: t('download-json', { ns: NS.EDITOR }),
        title: t('download-json', { ns: NS.EDITOR }),
        onClick: async () => {
          if (node.metadata.versions.length === 0) {
            console.error(
              '[AssetTreeViewerViewControllerFC:generateActions] No content IDs available for node',
              node.metadata.name,
            );
            return;
          }

          await assetsRequestService
            .getContent(node.metadata.id, node.metadata.versions[node.metadata.versions.length - 1].content)
            .then((data: AssetContent) => {
              const blob = new Blob([JSON.stringify(data.data, null, 4)], { type: 'text/plain' });
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${node.metadata.name}.json`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(url);
            });
        },
      },
    ];
  };

  const _menuItems: ICommandBarItemProps[] = [];

  // buildRightNavMenuItems : a local function for constructing the file navigation menu buttons
  const buildRightNavMenuItems = () => {
    // newButton : button for creating a "new" file. Basically just clears out your experiment data.
    const newButton: ICommandBarItemProps = {
      key: 'new-menu',
      text: t('new', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.PAGE_ADD },
      disabled: false,
      onClick: () => {
        const newAssetTreeData: AssetNode[] = [...assetsTreeData, newAssetNode];
        const newFIlterTreeData: AssetNode[] = [...filterTreeData, newAssetNode];

        loadAsset(newAssetNode);
        setAssetTreeData(newAssetTreeData);
        setFilterTreeData(newFIlterTreeData);
        setShowPrompt(true);
      },
    };

    _menuItems.push(newButton);

    return _menuItems;
  };

  const farItems: ICommandBarItemProps[] = buildRightNavMenuItems();

  const openAsset = (node: AssetNode) => {
    if (node.isLeaf) {
      loadAsset(node);
    }
  };

  return (
    <AssetTreeViewerTemplate
      farItems={farItems}
      showLeavePrompt={showPrompt}
      treeData={filterTreeData}
      filter={filter}
      generateActions={generateActions}
      selectTemplate={openAsset}
    />
  );
};

const AssetTreeViewerViewController = observer(AssetTreeViewerViewControllerFC);

export default AssetTreeViewerViewController;
