import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChoiceGroup, CommandBarButton, DatePicker, DefaultButton, SearchBox } from '@fluentui/react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Divider } from '@fluentui/react-components';
import { t } from 'i18next';

import { TimeRangeOptions } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { FilterOptions, Namespaces as NS, UniqueListKey } from '@/constants/SystemConstants';
import { PageCommandBar, Session } from '@/constants/TranslationConstants';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import { formatDate } from '@/utils/Dates';

import SessionDetailsStepsFilterViewController from './SessionDetailsStepsFilterViewController';

import '@/styles/SplitPanel.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

export type SessionDetailsStepsFilterBarType = {
  viewController: SessionDetailsStepsFilterViewController;
};

const SessionDetailsStepsFilterBarFC: React.FC<SessionDetailsStepsFilterBarType> = (props: SessionDetailsStepsFilterBarType) => {
  const { viewController } = props;

  const { filters, timeZone } = viewController;

  const searchBox = (
    <SearchBox
      placeholder={t(Session.SEARCH_STEP_NAME, { ns: NS.EXPERIMENTS })}
      title={t(Session.SEARCH_STEP_NAME, { ns: NS.EXPERIMENTS })}
      role={'none'}
      value={filters.searchValue}
      iconProps={{ iconName: SystemIcons.SEARCH }}
      onChange={viewController.setSearchTerm}
      onSearch={viewController.setSearchTerm}
      className={filterBarStyles['pagefilterbar-item']}
      styles={filterBar.searchBox}
      spellCheck="false"
    />
  );

  const ipAddressSearchBox = (
    <SearchBox
      placeholder={t(Session.SEARCH_IP_ADDRESS, { ns: NS.EXPERIMENTS })}
      title={t(Session.SEARCH_IP_ADDRESS, { ns: NS.EXPERIMENTS })}
      role={'none'}
      value={filters.searchIpAddress}
      iconProps={{ iconName: SystemIcons.SEARCH }}
      onChange={viewController.setIpAddressSearchTerm}
      onSearch={viewController.setIpAddressSearchTerm}
      className={filterBarStyles['pagefilterbar-item']}
      styles={filterBar.searchBox}
      spellCheck="false"
    />
  );

  const statusFilter = (
    <Dropdown
      placeholder={t('select-status', { ns: NS.COMMON })}
      title={t('select-status', { ns: NS.COMMON })}
      multiSelect
      dropdownWidth="auto"
      selectedKeys={filters.statusValues}
      options={viewController.uniqueStepsList(UniqueListKey.STATUS) || [t('no-status', { ns: NS.DEFAULT })]}
      className={filterBarStyles['pagefilterbar-item']}
      styles={filterBar.dropdown}
      onChange={viewController.statusFilterChanged}
    />
  );

  const ipAddressFilter = (
    <Dropdown
      placeholder={t(Session.IP_ADDRESS, { ns: NS.EXPERIMENTS })}
      title={t(Session.IP_ADDRESS, { ns: NS.EXPERIMENTS })}
      multiSelect
      dropdownWidth="auto"
      selectedKeys={filters.ipAddresses}
      options={viewController.uniqueStepsList(UniqueListKey.IP_ADDRESS)}
      className={filterBarStyles['pagefilterbar-item']}
      styles={filterBar.dropdown}
      onChange={viewController.ipAddressFilterChanged}
    />
  );

  const stepTypeFilter = (
    <Dropdown
      placeholder={t('select-step-type', { ns: NS.EXPERIMENTS })}
      title={t('select-step-type', { ns: NS.EXPERIMENTS })}
      multiSelect
      dropdownWidth="auto"
      selectedKeys={filters.typeValues}
      options={viewController.uniqueStepsList(UniqueListKey.STEP_TYPE)}
      className={filterBarStyles['pagefilterbar-item']}
      styles={filterBar.dropdown}
      onChange={viewController.stepFilterChanged}
    />
  );

  const dateFilterMenuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: () => timeChoices,
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            {startDatePicker}
            {endDatePicker}
          </div>
        ),
      },
    ],
  };

  const dateFilter = (
    <DefaultButton
      text={viewController.timePickerLabel}
      title={viewController.timePickerLabel}
      className={filterBarStyles['pagefilterbar-item']}
      styles={filterBar.defaultButton}
      menuProps={dateFilterMenuProps}
    />
  );

  const timeChoices = (
    <ChoiceGroup
      className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
      styles={filterBar.choiceGroup}
      selectedKey={filters.timeRange}
      options={TimeRangeOptions}
      onChange={viewController.onSelectTime}
    />
  );

  const startDatePicker = (
    <DatePicker
      label={t('start-date', { ns: NS.TABLE })}
      disabled={filters.timeRange !== FilterOptions.CUSTOM}
      value={filters.startDate}
      styles={filterBar.datePicker}
      formatDate={() => formatDate(filters.startDate.toString(), timeZone)}
      onSelectDate={viewController.onSelectStartDate}
    />
  );

  const endDatePicker = (
    <DatePicker
      label={t('end-date', { ns: NS.TABLE })}
      disabled={filters.timeRange !== FilterOptions.CUSTOM}
      value={filters.endDate}
      styles={filterBar.datePicker}
      formatDate={() => formatDate(filters.endDate.toString(), timeZone)}
      onSelectDate={viewController.onSelectEndDate}
    />
  );

  const resetButton = (
    <DefaultButton
      text={t('reset', { ns: NS.COMMON })}
      title={t('reset', { ns: NS.COMMON })}
      iconProps={{ iconName: SystemIcons.RESET }}
      className={filterBarStyles['pagefilterbar-button']}
      styles={filterBar.defaultButton}
      onClick={viewController.resetButtonClicked}
    />
  );

  const editColumns = (
    <DefaultButton
      key={'edit-columns'}
      text={t('edit-columns', { ns: NS.EXPERIMENTS })}
      title={t('edit-columns', { ns: NS.EXPERIMENTS })}
      iconProps={{ iconName: SystemIcons.EDIT_COLUMNS }}
      className={filterBarStyles['pagefilterbar-button']}
      styles={filterBar.defaultButton}
      onClick={viewController.openColumnEditor}
    />
  );

  const groupByColumnButton = (
    <CommandBarButton
      text={t(viewController.groupByButtonLabel, { ns: NS.TABLE }) || t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON })}
      title={t('group-by-column', { ns: NS.EXPERIMENTS })}
      className={filterBarStyles['pagefilterbar-item']}
      iconProps={{ iconName: SystemIcons.GROUP_LIST }}
      menuProps={{ items: viewController.groupByColumnItems }}
    />
  );

  const filterItems: IOverflowSetItemProps[] = [
    {
      key: 'panel-search-filter',
      onRender: () => searchBox,
    },
    {
      key: 'panel-ipAddress-search-filter',
      onRender: () => ipAddressSearchBox,
    },
    {
      key: 'status-filter',
      onRender: () => statusFilter,
    },
    {
      key: 'ip-address-filter',
      onRender: () => ipAddressFilter,
    },
    {
      key: 'step-type-filter',
      onRender: () => stepTypeFilter,
    },
    {
      key: 'date-filter',
      onRender: () => dateFilter,
    },
    {
      key: 'reset',
      onRender: () => resetButton,
    },
    {
      key: 'divider',
      onRender: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: 'edit-columns',
      onRender: () => editColumns,
    },
    {
      key: 'group-by-column',
      onRender: () => {
        return groupByColumnButton;
      },
    },
  ];

  return <PageFilterBar items={filterItems} farItems={[]} />;
};

const SessionDetailsStepsFilterTemplate = observer(SessionDetailsStepsFilterBarFC);

export default SessionDetailsStepsFilterTemplate;
