import React from 'react';
import { observer } from 'mobx-react-lite';
import { MessageBarType } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Divider } from '@fluentui/react-components';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { PeoplePickerComponent } from '@/components/_labs/LabsHelper/ModalComponents/PeoplePickerComponent';
import { LabDetailsVMType } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import { LabGeneralTemplate } from '@/components/ManageLab/LabGeneral/LabGeneralTemplate';
import { IComboBoxOption, InitialValuesType, LabGeneralVMType } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { LabIcons, SystemIcons } from '@/constants/IconConstants';
import { Defaults } from '@/constants/LabDetailsConstants';
import { Buttons, Labels } from '@/constants/LabsConstants';
import { Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { LabDetails, Labs } from '@/constants/TranslationConstants';
import PageContent from '@/partials/PageContent/PageContent';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { labDownloadHelper } from '@/utils/Helpers';

import style from '@/components/ManageLab/LabGeneral/LabGeneral.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface LabGeneralViewControllerProps {
  viewModel: LabGeneralVMType;
  parentViewModel: LabDetailsVMType;
}

const LabGeneralViewControllerFC: React.FC<LabGeneralViewControllerProps> = ({ viewModel, parentViewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labDetailsStore, labGeneralStore, systemMessageStore } = rootStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const { isPartnerMode } = appSettingsStore;
  const { selectedLab } = labDetailsStore;
  const {
    isDirty,
    labDetailsData,
    labUserList,
    osImageValue,
    selectedTags,
    setIsDirty,
    setLastRefresh,
    setLabDetailsData,
    setOsImageValue,
    setSelectedTags,
  } = labGeneralStore;

  const { fetchExistingTags, getLabUser, getOsImages, mapLabDetailsData, updateLabDetail } = viewModel;
  const { fetchLabs } = parentViewModel;

  const [isAdminMenu, setAdminMenu] = React.useState<boolean>(true);
  const [isLabAddUserModalOpen, { setTrue: showLabAddUserModal, setFalse: hideLabAddUserModal }] = useBoolean(false);

  const [initialValues, setInitialValues] = React.useState<InitialValuesType>({
    labName: '',
    program: '',
    location: '',
    osImage: '',
  });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    fetchExistingTags();
    getOsImages();
  }, [fetchExistingTags, getOsImages]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      mapLabDetailsData(selectedLab);
      setLastRefresh();
    }, TimeInterval.FIVE_MINS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    mapLabDetailsData(selectedLab);
    getLabUser();
  }, [selectedLab, mapLabDetailsData, getLabUser]);

  React.useEffect(() => {
    // Store initial values when component mounts
    setInitialValues({
      labName: labDetailsData?.labName || '',
      program: labDetailsData?.program || '',
      location: labDetailsData?.location || '',
      osImage: osImageValue || '',
    });
  }, [labDetailsData, osImageValue]);

  const handleInputChange = (field: string, value: string) => {
    setLabDetailsData({ ...labDetailsData, [field]: value });
    checkIfDirty(field, value);
  };

  const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => {
    setOsImageValue(String(option.key));
    checkIfDirty(Defaults.OSIMAGE, String(option.key));
  };

  const checkIfDirty = (field: string, value: string) => {
    if (initialValues[field as string] !== value) {
      setIsDirty(true);
    } else {
      const isFormDirty = Object.keys(initialValues).some(
        (key: string) => initialValues[key as string] !== labDetailsData[key as string] || initialValues.osImage !== osImageValue,
      );
      setIsDirty(isFormDirty);
    }
  };

  const handleTagDelete = (_e, { value }) => {
    setSelectedTags([...selectedTags].filter((tag) => tag.key !== value));
  };

  const handleTagChange = (items: IComboBoxOption[]) => {
    const newTags = items.filter((item) => !selectedTags.some((tag) => tag.key === item.key));
    setSelectedTags([...selectedTags, ...newTags]);
  };

  const addSuccessMessage = (message: string) => {
    const successMessage: SystemMessageType = {
      message: message,
      type: MessageBarType.success,
      groupId: SuccessGroupIds.LAB_DETAILS,
    };

    addGlobalMessage(successMessage);
  };

  const labAdminTooltipContent = labDetailsData ? (
    <div>
      {labDetailsData.labAdminList?.length > 0 ? (
        labDetailsData.labAdminList.map((admin, index) => <div key={index}>{admin.UserName}</div>)
      ) : (
        <div>{Labels.noAdmin}</div>
      )}
      <div className={style.manageUserLink} onClick={showLabAddUserModal}>
        {Labels.manageUser}
      </div>
    </div>
  ) : null;

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: Labs.MANAGE_USERS,
      text: Buttons.manageUsers,
      title: Buttons.manageUsers,
      iconProps: { iconName: SystemIcons.MANAGE_USER },
      onClick: showLabAddUserModal,
      hidden: isPartnerMode || !isAdminMenu,
    },
    {
      key: Labs.DOWNLOAD_INSTALLER,
      text: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      title: t(Labs.DOWNLOAD_INSTALLER, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      //   disabled: actionsDisabled,
      subMenuProps: {
        items: [
          {
            key: Labs.DOWNLOAD_EXE,
            text: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_EXE, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_EXE },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, true),
          },
          {
            key: Labs.DOWNLOAD_MSI,
            text: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            title: t(Labs.DOWNLOAD_MSI, { ns: NS.LABS }),
            iconProps: { iconName: LabIcons.DOWNLOAD_MSI },
            onClick: () => labDownloadHelper(selectedLab.LabId, systemMessageStore, false),
          },
        ],
      },
    },
    {
      key: 'divider',
      commandBarButtonAs: () => <Divider vertical className={filterBarStyles['pagefilterbar-divider']} />,
    },
    {
      key: Labs.SAVE_LAB,
      text: t(Labs.SAVE_LAB, { ns: NS.LABS }),
      title: t(Labs.SAVE_LAB, { ns: NS.LABS }),
      iconProps: { iconName: SystemIcons.SAVE },
      onClick: (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        updateLabDetail().then((response) => {
          if (response) {
            addSuccessMessage(t(LabDetails.SAVE_LAB_DETAILS, { ns: NS.LAB_DETAILS }));
            setIsDirty(false);
            fetchLabs();
          }
        });
      },
      disabled: !isDirty,
    },
  ];

  const getModalComponent = () => {
    if (isLabAddUserModalOpen) {
      return (
        <PeoplePickerComponent
          action={Labels.addLabUser}
          getLabUser={getLabUser}
          hideModal={hideLabAddUserModal}
          isModalOpen={isLabAddUserModalOpen}
          labUserList={labUserList}
          selectedItemKey={selectedLab.LabId}
          title={t(Labs.MANAGE_USERS, { ns: NS.TITLES })}
          titleName={selectedLab.LabName}
        />
      );
    }
  };

  return (
    <PageContent scrollable={false}>
      <div className="fullscreen">
        <LabGeneralTemplate
          commandBarItems={commandBarItems}
          handleDropdownChange={handleDropdownChange}
          handleInputChange={handleInputChange}
          handleTagChange={handleTagChange}
          handleTagDelete={handleTagDelete}
          labAdminTooltipContent={labAdminTooltipContent}
          selectedTags={selectedTags}
        />
        {getModalComponent()}
      </div>
    </PageContent>
  );
};

const LabGeneralViewController = observer(LabGeneralViewControllerFC);

export default LabGeneralViewController;
