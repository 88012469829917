/* eslint-disable quotes */
import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { KeyTextPair } from '@/constants/SystemConstants';

@singleton()
class AssetTreeViewerStore {
  public filterType = '';
  public filterTypeOptions: KeyTextPair[] = [];
  public filterValue = '';
  public loadingAssets = false;

  public assetsTreeData: AssetNode[] = [];

  public filterTreeData: AssetNode[] = this.assetsTreeData;

  constructor() {
    this.filterValue = '';
    this.loadingAssets = false;

    makeObservable(this, {
      // These values are persisted.
      assetsTreeData: observable,
      filterTreeData: observable,
      filterType: observable,
      filterTypeOptions: observable,
      filterValue: observable,
      loadingAssets: observable,

      // Actions modify the state.
      setAssetTreeData: action,
      setFilterTreeData: action,
      setFilterType: action,
      setFilterTypeOptions: action,
      setFilterValue: action,
      setLoadingAsset: action,
    });
  }

  public setAssetTreeData = (value: AssetNode[]): AssetNode[] => {
    this.assetsTreeData = value;

    return this.assetsTreeData;
  };

  public setFilterTreeData = (value: AssetNode[]): AssetNode[] => {
    this.filterTreeData = value;

    return this.filterTreeData;
  };

  public setFilterType = (value: string): string => {
    this.filterType = value;

    return this.filterType;
  };

  public setFilterTypeOptions = (value: KeyTextPair[]): KeyTextPair[] => {
    this.filterTypeOptions = value;

    return this.filterTypeOptions;
  };

  public setFilterValue = (value: string): string => {
    this.filterValue = value;

    return this.filterValue;
  };

  public setLoadingAsset = (value: boolean): boolean => {
    this.loadingAssets = value;

    return this.loadingAssets;
  };
}

export default AssetTreeViewerStore;
