import { action, computed, makeObservable, observable } from 'mobx';
import { t } from 'i18next';
import { inject, singleton } from 'tsyringe';

import { TenantIds } from '@/constants/AirConstants';
import { Namespaces as NS, PartnerDomains } from '@/constants/SystemConstants';
import { PartnerThemes } from '@/constants/ThemeConstants';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { BooleanTypeAny } from '@/types/AppSettingsTypes';

@singleton()
class UserInfoStore {
  protected _isAdvancedMode = false;
  protected _isDebugMode = false;
  protected _isDeveloperMode = false;
  protected _isPartnerMode = true;

  public userId = t('default-user-id', { ns: NS.AUTH });
  public name = t('default-name', { ns: NS.AUTH });
  public userName = t('default-user-name', { ns: NS.AUTH });
  public companyName = t('default-company-name', { ns: NS.AUTH });
  public tenantId = t('default-tenant-id', { ns: NS.AUTH });
  public isInternalUser: boolean;

  constructor(@inject(AppSettingsStore) protected _appSettingsStore: AppSettingsStore) {
    const { isAdvancedMode, isDebugMode, isDeveloperMode, isPartnerMode } = _appSettingsStore;

    this._isAdvancedMode = isAdvancedMode;
    this._isDebugMode = isDebugMode;
    this._isDeveloperMode = isDeveloperMode;
    this._isPartnerMode = isPartnerMode;

    this.isInternalUser = false;

    makeObservable(this, {
      // Observables fields that stores state.
      name: observable,
      userName: observable,
      userId: observable,
      companyName: observable,
      tenantId: observable,
      isInternalUser: observable,

      // Computed values that derive information from other observables.
      hasAirPermissions: computed,
      hasLabsPermissions: computed,
      partnerBrandName: computed,
      partnerBackground: computed,

      // Actions modify the state.
      setCompanyName: action,
      setUserInfo: action,
      setInternalUser: action,
    });
  }

  public get hasAirPermissions(): boolean {
    return true;
  }

  public get hasLabsPermissions(): boolean {
    // This is the equivalent of performing the following, without explicitly referring to Microsoft:
    // UserInfoStore.isMicrosoftTenant = this.tenantId === tenantId;

    return this.tenantId === TenantIds.MS;
  }

  public get partnerBrandName(): string {
    const userName = this.userName;

    if (this._isPartnerMode) {
      if (userName.includes(PartnerDomains.LENOVO)) {
        return PartnerDomains.LENOVO;
      } else if (userName.includes(PartnerDomains.ZT)) {
        return PartnerDomains.ZT;
      } else if (userName.includes(PartnerDomains.INTEL)) {
        return PartnerDomains.INTEL;
      } else if (userName.includes(PartnerDomains.WIWYNN)) {
        return PartnerDomains.WIWYNN;
      } else if (userName.includes(PartnerDomains.VSEI)) {
        return PartnerDomains.VSEI;
      }
    }

    return PartnerDomains.MICROSOFT;
  }

  public get partnerBackground(): string {
    const brand = this.partnerBrandName;
    let theme = PartnerThemes[PartnerDomains.MICROSOFT]; // Our default theme.

    if (brand === PartnerDomains.LENOVO) {
      theme = PartnerThemes[PartnerDomains.LENOVO];
    } else if (brand === PartnerDomains.ZT) {
      theme = PartnerThemes[PartnerDomains.ZT];
    } else if (brand === PartnerDomains.INTEL) {
      theme = PartnerThemes[PartnerDomains.INTEL];
    } else if (brand === PartnerDomains.WIWYNN) {
      theme = PartnerThemes[PartnerDomains.WIWYNN];
    } else if (brand === PartnerDomains.VSEI) {
      theme = PartnerThemes[PartnerDomains.VSEI];
    }

    return theme;
  }

  public get isMicrosoftAccount(): boolean {
    // NOTE: This would need to be refined, as there are possible domains that could get around this,
    // however we will have implemented better security will will change this implementation in the future.
    // Gets the primary domain after the "@" and checks for a full match with the Microsoft Domain Name.
    return this.userName?.toLowerCase().split('@').pop().includes(PartnerDomains.MICROSOFT);
  }

  public get isPartnerAccount(): boolean {
    // If the account is not a Microsoft account, we designate it a Partner account.
    return !this.isMicrosoftAccount;
  }

  public setCompanyName = (companyName: string | null): void => {
    this._isAdvancedMode && console.log('[UserInfoStore] setCompanyName()');

    this.companyName = companyName || t('default-company-name', { ns: NS.AUTH });

    return;
  };

  public setUserInfo = (account: any): void => {
    this._isAdvancedMode && console.log('[UserInfoStore] setUserInfo()');

    this.userName = account.username || t('default-name', { ns: NS.AUTH });
    this.name = account.name || t('default-name', { ns: NS.AUTH });
    this.userId = account.localAccountId || t('default-user-id', { ns: NS.AUTH });
    this.tenantId = account.idTokenClaims.tid || t('default-tenant-id', { ns: NS.AUTH });

    return;
  };

  public setInternalUser: BooleanTypeAny = (isInternalUser: boolean) => {
    this._isAdvancedMode && console.log('[UserInfoStore] setInternalUser()');

    this.isInternalUser = isInternalUser;

    return;
  };
}

export default UserInfoStore;
