import { AccountInfo, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { container } from 'tsyringe';

import { GanymedeApiScope, msalConfig } from '@/authConfig';
import ILogger from '@/components/AppInsights/ILogger';
import TelemetryLogger from '@/components/AppInsights/TelemetryLogger';
import { getAppInsights } from '@/components/AppInsights/TelemetryService';
import BaseRequestService from '@/services/request-services/RequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';

const msalInstance = new PublicClientApplication(msalConfig);

abstract class MsalAuthorization {
  private static _isAdvancedMode: boolean;
  private static _isDebugMode: boolean;
  private static _isDeveloperMode: boolean;
  private static _logger: ILogger;

  public static initialize() {
    const appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);
    const { isAdvancedMode, isDebugMode, isDeveloperMode } = appSettingsStore;
    msalInstance.initialize();

    this._isAdvancedMode = isAdvancedMode;
    this._isDebugMode = isDebugMode;
    this._isDeveloperMode = isDeveloperMode;

    this._isAdvancedMode && console.log('[MsalAuthorization] intialized()');
  }

  public static getUserInfo(): typeof AccountInfo | undefined {
    this.generateToken();
    this._isAdvancedMode && console.log('[MsalAuthorization] getUserInfo()');
    const activeAccount = msalInstance.getActiveAccount();

    return activeAccount || null;
  }

  public static async getToken() {
    return await this.generateToken();
  }

  public static async generateToken() {
    this.initializeLogger();
    this._isAdvancedMode && console.log('[MsalAuthorization] generateToken():\n', await msalInstance);

    let activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount) {
      const accounts = msalInstance.getAllAccounts();
      activeAccount = accounts[0];
      msalInstance.setActiveAccount(activeAccount);
    }

    try {
      const authResult: typeof AuthenticationResult = await msalInstance.acquireTokenSilent({
        ...GanymedeApiScope,
        account: activeAccount,
      });

      const token = authResult.accessToken;
      const shortToken = BaseRequestService.shortenToken(token);

      this._isAdvancedMode && console.log(`[MsalAuthorization] generateToken(): Received token: "${shortToken}"`);

      return token;
    } catch (error: any) {
      console.error(error);

      const authResult: typeof AuthenticationResult = await msalInstance.acquireTokenRedirect({
        ...GanymedeApiScope,
        account: activeAccount,
      });
      const token = authResult.accessToken;
      const shortToken = BaseRequestService.shortenToken(token);

      this._isAdvancedMode &&
        console.log(`[MsalAuthorization] generateToken(): Received token after acquireTokenSilent() error: "${shortToken}"`);

      return token;
    }
  }

  private static initializeLogger() {
    const appInsights = getAppInsights();

    if (this._logger === undefined && appInsights) {
      this._logger = new TelemetryLogger(appInsights);
    }
  }
}

MsalAuthorization.initialize();

export default MsalAuthorization;
