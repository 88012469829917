import React from 'react';
import { observer } from 'mobx-react-lite';

import { SessionDetailsVMType } from '@/components/SessionDetails/SessionDetailsTypes';
import SessionDetailsViewController from '@/components/SessionDetails/SessionDetailsViewController';
import SessionDetailsViewModel from '@/components/SessionDetails/SessionDetailsViewModel';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const SessionDetailsFC: React.FC<SessionDetailsVMType> = ({ sessionId }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionDetailsStore } = rootStore;
  const { isAdvancedMode } = appSettingsStore;
  const { unmountSessionDetails } = sessionDetailsStore;

  const viewModel = React.useMemo(() => new SessionDetailsViewModel(sessionId), [rootStore, sessionId]);

  React.useEffect(() => {
    // Perform page initialization tasks.
    isAdvancedMode && console.log('[SessionDetailsFC] Mounting SessionDetails');

    return () => {
      // Perform page cleanup tasks.
      isAdvancedMode && console.log('[SessionDetailsFC] Unmounting SessionDetails');
      unmountSessionDetails();
    };
  }, []);

  return <SessionDetailsViewController viewModel={viewModel} />;
};

const SessionDetails = observer(SessionDetailsFC);

export default SessionDetails;
