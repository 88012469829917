import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { ChartData, GetListType, SetListType } from './UseGraphType';

@singleton()
class UseGraphStore {
  public graphData: Array<ChartData> = [];

  constructor() {
    makeObservable(this, {
      graphData: observable,
      setGraphData: action,
    });
  }

  public getGraphData: GetListType = () => {
    return this.graphData;
  };

  public setGraphData: SetListType = (data: Array<ChartData>) => {
    if (data) {
      this.graphData = data;
    }
  };
}

export default UseGraphStore;
