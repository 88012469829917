import { LabType } from '@/components/Experiments/ExperimentsTypes';
import { OSImagesType } from '@/components/MachineSelect/MachineSelectTypes';
import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import LabGeneralStore from '@/components/ManageLab/LabGeneral/LabGeneralStore';
import { Company, LabDetailConfigType, LabDetailsType, OsImageType } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';
import { Defaults } from '@/constants/LabsConstants';
import { FailGroupIds, KeyTextPair, Namespaces as NS, SystemConstants, TrueFalse } from '@/constants/SystemConstants';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabConfigurationRequestService } from '@/services/request-services/LabConfigurationRequestService';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { ganymedeTagRequestService } from '@/services/request-services/TagRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { HandleError } from '@/utils/_labs/HandleError';
import { populateHealthStatus } from '@/utils/Helpers';

class LabGeneralViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected labDetailsStore: LabDetailsStore;
  protected labGeneralStore: LabGeneralStore;
  protected systemMessageStore: SystemMessageStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, labDetailsStore, labGeneralStore, systemMessageStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.labDetailsStore = labDetailsStore;
    this.labGeneralStore = labGeneralStore;
    this.systemMessageStore = systemMessageStore;
  }

  getOsImages = async (): Promise<void> => {
    const { setOsImageList } = this.labGeneralStore;

    await ganymedeLabRequestService
      .getOSImages()
      .then((result: OSImagesType[]) => {
        if (result?.length > 0) {
          const mappedData: OsImageType[] = this.buildDataMapping(result);

          setOsImageList(mappedData);
          this.populateOSDropdown(mappedData);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          appSettingsStore: this.appSettingsStore,
          failGroupId: FailGroupIds.LAB_OS_IMAGES,
        };

        HandleError(handleErrorProps);
      });
  };

  getCompanyNameById = async (companyId: number): Promise<string> => {
    const companies: Company[] = await ganymedeLabRequestService.getCompanies();

    const labCompany: Company = companies.find((activeCompany) => activeCompany.CompanyId === companyId);

    return labCompany.CompanyName;
  };

  fetchCompanyName = async (companyId: number): Promise<string> => {
    const companyName = await this.getCompanyNameById(companyId);

    return companyName;
  };

  getOsFriendlyNameById = (id: number): string => {
    const { osImageList } = this.labGeneralStore;

    const os = osImageList.filter((osImage) => osImage.imageId === id);

    return os.length > 0 ? os[0].friendlyName : '';
  };

  fetchExistingTags = async (): Promise<void> => {
    const { setTagOptions } = this.labGeneralStore;

    await ganymedeTagRequestService
      .getTags()
      .then((result) => {
        setTagOptions(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          appSettingsStore: this.appSettingsStore,
          failGroupId: FailGroupIds.LAB_CONTROL,
        };

        HandleError(handleErrorProps);
      });
  };

  getLastHeartBeat = async (labId: number): Promise<Date | null> => {
    try {
      const response = await labsRequestService.getAgentHeartbeat(labId.toString());

      return response.lastModified;
    } catch {
      return null;
    }
  };

  getLabUser = async (): Promise<void> => {
    const { setLabUserList, setAdminMenu } = this.labGeneralStore;

    const { selectedLab } = this.labDetailsStore;

    ganymedeLabRequestService
      .getLabsUser(selectedLab.LabId)
      .then((response) => {
        if (response) {
          setLabUserList(response);
        }
      })
      .catch(() => {
        setAdminMenu(false);
      });
  };

  getLabAdmins = async (labId): Promise<any> => {
    const response = await ganymedeLabRequestService.getLabsUser(labId);

    if (response) {
      const labAdminList = response.filter((user) => user.RoleName === Defaults.LAB_ADMIN);

      return labAdminList;
    } else {
      return [];
    }
  };

  fetchLabDetailConfig = async (labId: number): Promise<LabDetailConfigType> => {
    const response: LabDetailConfigType = await ganymedeLabConfigurationRequestService.getLabDetail(labId);

    if (response) {
      return response;
    } else {
      return null;
    }
  };

  mapLabDetailsData = async (selectedLab: LabType): Promise<void> => {
    const { setLabDetailsData, setLabHealthStatus, setOsImageValue } = this.labGeneralStore;

    setLabDetailsData(null);
    const companyName = await this.fetchCompanyName(selectedLab.CompanyId);
    const labAdminList = await this.getLabAdmins(selectedLab.LabId);
    const lastHeartBeat = await this.getLastHeartBeat(selectedLab.LabId);

    const labDetailConfig = await this.fetchLabDetailConfig(selectedLab.LabId);

    setOsImageValue(selectedLab.DefaultOsImageId.toString());

    if (!selectedLab.IsVirtualLab) {
      const { healthStatus } = populateHealthStatus(lastHeartBeat);
      setLabHealthStatus(healthStatus);
    }

    const mappedData: LabDetailsType = {
      companyName,
      defaultOsImage: this.getOsFriendlyNameById(selectedLab.DefaultOsImageId),
      isVirtualLab: selectedLab.IsVirtualLab,
      labAdminList,
      labId: selectedLab.LabId,
      labName: selectedLab.LabName,
      labStatus: selectedLab.LabStatus,
      lastHeartBeat,
      version: selectedLab.Version,
      defaultOsImageId: selectedLab.DefaultOsImageId,
      program: labDetailConfig?.program,
      location: labDetailConfig?.location,
    };

    setLabDetailsData(mappedData);
  };

  updateLabDetail = async (): Promise<boolean> => {
    const { labDetailsData, osImageValue } = this.labGeneralStore;
    const { setSelectedLabId } = this.labDetailsStore;

    const updateLabDetailData: LabDetailConfigType = {
      labId: labDetailsData.labId,
      labName: labDetailsData.labName,
      defaultOsImageId: parseInt(osImageValue),
      location: labDetailsData.location,
      program: labDetailsData.program,
    };

    setSelectedLabId(labDetailsData.labId);

    const response = await ganymedeLabConfigurationRequestService.updateLabDetail(updateLabDetailData);

    return response;
  };

  protected populateOSDropdown = (imageList?: OsImageType[]): void => {
    const { setOsImages } = this.labGeneralStore;
    const { selectedLab } = this.labDetailsStore;

    const options: KeyTextPair[] = [];

    if (selectedLab.CompanyId !== SystemConstants.MS_COMPANY_ID && imageList) {
      imageList = imageList.filter((osImage) => !osImage.isInternal);
    }

    imageList?.map((image: OsImageType) => options.push({ key: image.imageId.toString(), text: image.friendlyName }));
    setOsImages(options);
  };

  protected buildDataMapping = (results: OSImagesType[]): OsImageType[] => {
    const mappedData: OsImageType[] = results.map((result: OSImagesType) => ({
      imageId: result.OsImageId,
      imageName: result.OsImageName,
      friendlyName: result.OsImageFriendlyName,
      imageBuild: result.OsImageBuild,
      description: result.OsImageDescription,
      isInternal: result.IsInternal,
    }));

    return mappedData;
  };
}

export default LabGeneralViewModel;
