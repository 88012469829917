import React from 'react';

import { TableColumnType } from '@/types/TableTypes';
import { translateName } from '@/utils/Helpers';

const processColumns = (
  storedColumns: string[],
  allColumns: TableColumnType[],
): {
  userColumns: TableColumnType[];
  allColumns: TableColumnType[];
} => {
  const entireColumns: TableColumnType[] = translateName(allColumns);

  // Initialize userColumns with sessionStepsEntireColumns
  let userColumns: TableColumnType[] = allColumns;

  // Filter columns based on storedColumns
  if (storedColumns?.length > 0) {
    const filteredColumns = allColumns
      .filter((item: TableColumnType) => {
        return storedColumns.includes(item.key);
      })
      .map((item: TableColumnType) => {
        item.isOptional = false;

        if (item.isHidden === undefined) {
          item.isHidden = false;
        }

        return item;
      });

    userColumns = filteredColumns;
  }

  return { userColumns, allColumns: entireColumns };
};

export { processColumns };
