import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { PageHeaderType } from '@/partials/PageHeader/PageHeaderTypes';

import { TITLE_DIVIDER } from './PageHeaderConstants';

import styles from './PageHeader.module.css'; // Apply any Navigation level CSS Module styling here.

const PageHeaderFC: React.FC<PageHeaderType> = (props: PageHeaderType): React.ReactElement => {
  const { icon, subTitle, children: title, iconLink } = props;
  const showSubtitle = typeof subTitle === 'string' ? subTitle.trim() : subTitle;

  const iconElement = iconLink ? (
    <Link className={styles['icon']} to={iconLink}>
      <FontIcon iconName={icon} />
    </Link>
  ) : (
    <FontIcon className={styles['icon']} iconName={icon} />
  );

  return (
    <div className={styles['title']}>
      {icon && iconElement}
      <div className={styles['text']}>{title}</div>
      {showSubtitle && (
        <div className={styles['subtitle']}>
          {TITLE_DIVIDER} {subTitle}
        </div>
      )}
    </div>
  );
};

const PageHeader = observer(PageHeaderFC);

export default PageHeader;
