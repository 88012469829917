import React from 'react';
import { observer } from 'mobx-react-lite';

import SidePanel from '@/partials/SidePanel/SidePanelTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const LabDetailsPanelTemplateFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labDetailsPanelStore } = rootStore;
  const { pageContent, userSettings } = labDetailsPanelStore;

  return <SidePanel userSettings={userSettings} content={pageContent}></SidePanel>;
};

const LabDetailsPanelTemplate = observer(LabDetailsPanelTemplateFC);

export default LabDetailsPanelTemplate;
