import { action, makeObservable, observable } from 'mobx';
import { inject, singleton } from 'tsyringe';

import { FilterOptions, KeyTextPair } from '@/constants/SystemConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { TableColumnType } from '@/types/TableTypes';

import { ResultExplorerType } from './ResultExplorerTypes';

@singleton()
class ResultExplorerStore {
  protected now = new Date();
  protected startDate = new Date();

  public allData: ResultExplorerType[] = [];
  public filteredData: ResultExplorerType[] = [];
  public columnsList: TableColumnType[] = [];
  public displayColumns: string[] = [];
  public executedByEmails: string[] = [];
  public executedByList: KeyTextPair[] = [];
  public experimentNameList: KeyTextPair[] = [];
  public experimentNames: string[] = [];
  public hostNameList: KeyTextPair[] = [];
  public hostNames: string[] = [];
  public isSummary: boolean;
  public labIdList: KeyTextPair[] = [];
  public labIds: string[] = [];
  public resultAnalyzerDisabled: boolean;
  public resultExplorerEndDate: Date;
  public resultExplorerLastRunTime: string = FilterOptions.LAST_THIRTY_DAYS;
  public resultExplorerStartDate: Date;
  public runStatusList: KeyTextPair[] = [];
  public searchValue: string;
  public selectedCompanyValue: string;
  public selectedId: string;
  public selectedName: string;
  public statuses: string[] = [];

  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    makeObservable(this, {
      // Values persisted
      resultExplorerLastRunTime: observable,
      selectedCompanyValue: observable,
      searchValue: observable,
      resultExplorerStartDate: observable,
      resultExplorerEndDate: observable,

      // Values not persisted
      selectedId: observable,
      selectedName: observable,
      isSummary: observable,
      displayColumns: observable,
      resultAnalyzerDisabled: observable,
      columnsList: observable,
      allData: observable,
      filteredData: observable,
      experimentNames: observable,
      hostNames: observable,
      executedByEmails: observable,
      labIds: observable,
      statuses: observable,
      experimentNameList: observable,
      hostNameList: observable,
      executedByList: observable,
      labIdList: observable,
      runStatusList: observable,

      setResultExplorerLastRunTime: action,
      setSelectedCompanyValue: action,
      setSearchValue: action,
      setResultExplorerStartDate: action,
      setResultExplorerEndDate: action,
      doReset: action,

      // Not persisted
      setSelectedId: action,
      setSelectedName: action,
      setIsSummary: action,
      setDisplayColumns: action,
      setResultAnalyzerDisabled: action,
      setColumnsList: action,
      setAllData: action,
      setFilteredData: action,
      setExperimentNames: action,
      setHostNames: action,
      setExecutedByEmails: action,
      setLabIds: action,
      setStatuses: action,
      setExperimentNameList: action,
      setHostNameList: action,
      setExecutedByList: action,
      setLabIdList: action,
      setRunStatusList: action,
    });

    const startDateValue = this.localStorage.getValue(AppSettingsService.FILTER_ALLRESULTS_STARTDATE_KEY);
    const endDateValue = this.localStorage.getValue(AppSettingsService.FILTER_ALLRESULTS_ENDDATE_KEY);

    this.startDate.setHours(this.now.getHours() - Number(FilterOptions.LAST_THIRTY_DAYS));
    this.resultExplorerStartDate = startDateValue ? new Date(startDateValue) : this.startDate;
    this.resultExplorerEndDate = endDateValue ? new Date(endDateValue) : this.now;
    this.resultExplorerLastRunTime =
      this.localStorage.getValue(AppSettingsService.FILTER_ALLRESULTS_RUNTIME_KEY) || FilterOptions.LAST_THIRTY_DAYS;
    this.selectedCompanyValue = this.localStorage.getValue(AppSettingsService.FILTER_RESULT_EXPLORER_COMPANY_KEY);
    this.searchValue = this.localStorage.getValue(AppSettingsService.FILTER_RESULT_EXPLORER_SEARCH_KEY) || '';
  }

  public setResultExplorerLastRunTime = (time: string) => {
    this.resultExplorerLastRunTime = time;
    this.localStorage.setValue(AppSettingsService.FILTER_ALLRESULTS_RUNTIME_KEY, time);
  };

  public setSelectedCompanyValue = (value: string) => {
    this.selectedCompanyValue = value;
    this.localStorage.setValue(AppSettingsService.FILTER_RESULT_EXPLORER_COMPANY_KEY, value);
  };

  public setSearchValue = (value: string) => {
    this.searchValue = value;
    this.localStorage.setValue(AppSettingsService.FILTER_RESULT_EXPLORER_SEARCH_KEY, value);
  };

  public setResultExplorerStartDate = (date: Date) => {
    this.resultExplorerStartDate = new Date(date);
    this.localStorage.setValue(AppSettingsService.FILTER_ALLRESULTS_STARTDATE_KEY, date);
  };

  public setResultExplorerEndDate = (date: Date) => {
    this.resultExplorerEndDate = new Date(date);
    this.localStorage.setValue(AppSettingsService.FILTER_ALLRESULTS_ENDDATE_KEY, date);
  };

  public setDisplayColumns = (columns: string[]) => {
    this.displayColumns = columns;
  };

  public setResultAnalyzerDisabled = (disable: boolean) => {
    this.resultAnalyzerDisabled = disable;
  };

  public setSelectedId = (id: string) => {
    this.selectedId = id;
  };

  public setSelectedName = (name: string) => {
    this.selectedName = name;
  };

  public setIsSummary = (isSummary: boolean) => {
    this.isSummary = isSummary;
  };

  public setColumnsList = (columnsList: TableColumnType[]) => {
    this.columnsList = columnsList || [];
  };

  public setAllData = (data: ResultExplorerType[]) => {
    this.allData = data || [];
  };

  public setFilteredData = (data: ResultExplorerType[]) => {
    this.filteredData = data || [];
  };

  public setExperimentNames = (names: string[]) => {
    this.experimentNames = names || [];
  };

  public setHostNames = (names: string[]) => {
    this.hostNames = names || [];
  };

  public setExecutedByEmails = (mails: string[]) => {
    this.executedByEmails = mails || [];
  };
  public setLabIds = (ids: string[]) => {
    this.labIds = ids;
  };

  public setStatuses = (statuses: string[]) => {
    this.statuses = statuses || [];
  };

  public setExperimentNameList = (names: KeyTextPair[]) => {
    this.experimentNameList = names || [];
  };

  public setHostNameList = (names: KeyTextPair[]) => {
    this.hostNameList = names || [];
  };

  public setExecutedByList = (emails: KeyTextPair[]) => {
    this.executedByList = emails || [];
  };

  public setLabIdList = (labIds: KeyTextPair[]) => {
    this.labIdList = labIds || [];
  };

  public setRunStatusList = (statuses: KeyTextPair[]) => {
    this.runStatusList = statuses || [];
  };

  public doReset = () => {
    this.setSearchValue('');
    this.setSelectedCompanyValue(null);
    this.setExperimentNames([]);
    this.setLabIds([]);
    this.setStatuses([]);
    this.setHostNames([]);
    this.setExecutedByEmails([]);
  };
}

export default ResultExplorerStore;
