import { IDateExtendedChoiceGroupOption } from '@/utils/Extensions';

const DateFormats = {
  DEFAULT_DATE_TIME: 'YYYY-MM-DD 00:00:00',
  DEFAULT_TIME: '00:00:00',
  HOUR24_MIN: 'HH:mm',
  LONG_DATE_FORMAT: 'MMMM Do, YYYY [at] h:mm:ss a',
  LONG_INFO_DATE_FORMAT: 'dddd, MMMM D, YYYY [at] h:mm:ss a',
  MEDIUM_INFO_DATE_FORMAT: 'MMM Do YY, h:mm a',
  MONTH_DAY: 'MM/DD',
  MONTH_DAY_HOUR24_MIN: 'MM/DD HH:mm',
  SHORT_DATE_FORMAT: 'MMM Do [at] h:mm a',
  SHORT_INFO_DATE_FORMAT: 'dddd, MMMM D, YYYY',
  STANDARD_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  TIME_FORMAT: 'HH:mm:ss',
};

// Conversion to MilliSeconds
const TimeInterval = {
  FIVE_MINS: 1000 * 5 * 60, // 300000
  NINE_MINS: 1000 * 9 * 60, // 540000
  NINETY_SECONDS: 1000 * 90, // 90000
  ONE_DAY: 1000 * 60 * 60 * 24, // 86400000
  ONE_MINUTE: 1000 * 60, // 60000
  ONE_SECOND: 1000, // 1000
  THREE_HUNDRED_MILLISECONDS: 300,
  THREE_SECONDS: 1000 * 3, // 3000
  TWO_SECONDS: 1000 * 2, // 2000
};

const TimeRangeOptions: IDateExtendedChoiceGroupOption[] = [
  { key: 'all', text: 'All', isDay: true },
  { key: '1', text: 'Last 1 Hour', isDay: false },
  { key: '6', text: 'Last 6 Hours', isDay: false },
  { key: '12', text: 'Last 12 Hours', isDay: false },
  { key: '24', text: 'Last 24 Hours', isDay: false },
  { key: '168', text: 'Last 7 Days', isDay: true },
  { key: '720', text: 'Last 30 Days', isDay: true },
  { key: '2160', text: 'Last 90 Days', isDay: true },
  { key: 'custom', text: 'Custom', isDay: true },
];

const TimeRangeRemoveOptions = {
  ALL: 'all',
  LAST_90_DAYS: '2160',
};

export { DateFormats, TimeInterval, TimeRangeOptions, TimeRangeRemoveOptions };
