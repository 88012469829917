import React from 'react';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ChoiceGroup, DatePicker, DefaultButton, Icon, MessageBarType, SearchBox } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { Selection, SelectionMode } from '@fluentui/react/lib/Selection';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';
import ls from 'local-storage';

import { TimeInterval, TimeRangeOptions, TimeRangeRemoveOptions } from '@/constants/DateFormatConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { ColumnNames, PivotItems, Statuses, StatusIcons, TemplateConstants } from '@/constants/ResultExplorerConstants';
import {
  ColumnEditorKeys,
  Delimiters,
  EnablePagination,
  FailGroupIds,
  FilterOptions,
  Labels,
  Namespaces as NS,
  PageMemoryKeys,
  Pagination,
  SuccessGroupIds,
} from '@/constants/SystemConstants';
import { Results } from '@/constants/TranslationConstants';
import filterBar from '@/partials/PageFilterBar/PageFilterBarStyles';
import { getPaginationDefaults } from '@/partials/Pagination/Pagination';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { logManagementRequestService } from '@/services/request-services/LogManagementRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { TableColumnType } from '@/types/TableTypes';
import { formatDate, formatDateTime, getRunTimeRangeDates } from '@/utils/Dates';
import { getResultExplorerAuthData } from '@/utils/Env';
import { logDownloadHelper } from '@/utils/Helpers';
import { loginRequest } from '@/utils/ResultExplorerAuthConfig';

import config from './ResultExplorer.config.json';
import { ResultExplorerTemplate } from './ResultExplorerTemplate';
import { ResultExplorerType, ResultExplorerVMType } from './ResultExplorerTypes';

import styles from './ResultExplorer.module.css';
import filterBarStyles from '@/partials/PageFilterBar/PageFilterBar.module.css';

interface ResultExplorerViewControllerProps {
  viewModel: ResultExplorerVMType;
}

let requestIdCounter = 0;

const generateRequestId = () => {
  return ++requestIdCounter;
};

const ResultExplorerViewControllerFC: React.FC<ResultExplorerViewControllerProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const {
    appSettingsStore,
    editColumnsStore,
    logsViewPanelStore,
    paginationStore,
    resultExplorerStore,
    systemMessageStore,
    userSettingsStore,
    tableViewStore,
  } = rootStore;
  const { isPartnerMode, tabMemory } = appSettingsStore;
  const { editorColumns } = editColumnsStore;
  const { openLogsViewPanel, closeLogsViewPanel } = logsViewPanelStore;
  const { setPaginationType } = paginationStore;
  const {
    allData,
    doReset,
    executedByEmails,
    executedByList,
    experimentNameList,
    experimentNames,
    hostNameList,
    hostNames,
    labIdList,
    labIds,
    resultExplorerEndDate,
    resultExplorerLastRunTime,
    resultExplorerStartDate,
    runStatusList,
    searchValue,
    selectedCompanyValue,
    setAllData,
    setColumnsList,
    setDisplayColumns,
    setExecutedByEmails,
    setExperimentNames,
    setHostNames,
    setIsSummary,
    setLabIds,
    setResultAnalyzerDisabled,
    setResultExplorerEndDate,
    setResultExplorerLastRunTime,
    setResultExplorerStartDate,
    setSearchValue,
    setSelectedCompanyValue,
    setSelectedId,
    setSelectedName,
    setStatuses,
    setFilteredData,
    statuses,
  } = resultExplorerStore;
  const { addGlobalMessage, deleteGroupMessages, clearNonPersistentGlobalMessages } = systemMessageStore;
  const { timeZone } = userSettingsStore;
  const { doResetTableSort } = tableViewStore;

  const { copyTestRunURL, validateDates, buildDropDownData, searchData } = viewModel;

  const [showSpan, setShowSpan] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [companyList, setCompanyList] = React.useState<any[]>();
  const [entireColumns, setEntireColumns] = React.useState<TableColumnType[]>([]);
  const [columnEditorKey, setColumnEditorKey] = React.useState<string>(ColumnEditorKeys.MY_RESULTS);
  const [selectedRowItem, setSelectedRowItem] = React.useState<ResultExplorerType>();
  const [isColumnEditorOpen, { setTrue: showColumnEditor, setFalse: hideColumnEditor }] = useBoolean(false);
  const [token, setToken] = React.useState<boolean>(false);
  const [actionsDisabled, setActionsDisabled] = React.useState<boolean>(true);

  let failMessage: SystemMessageType;
  const pageName = PageMemoryKeys.RESULT_EXPLORER;
  const latestRequestIdRef = React.useRef(0);
  const activeTab: string = tabMemory[pageName as string] || PivotItems.MY_RESULTS;
  const activeTabRef = React.useRef(activeTab);
  const tabHeaders = [
    t('my-results', { ns: NS.RESULT_EXPLORER }),
    t('my-lab-results', { ns: NS.RESULT_EXPLORER }),
    t('all-results', { ns: NS.RESULT_EXPLORER }),
  ];
  const tabPanesKey = [PivotItems.MY_RESULTS, PivotItems.MYLAB_RESULTS, PivotItems.ALL_RESULTS];
  const copyUrl = t(Results.COPY_URL, { ns: NS.RESULTS });
  const downloadLogs = t(Results.DOWNLOAD_LOGS, { ns: NS.RESULTS });
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const authData = getResultExplorerAuthData();
  const searchInstanceText = t('search-name-instance', { ns: NS.RESULT_EXPLORER });
  const searchDefinitionText = t('search-name-definition', { ns: NS.RESULT_EXPLORER });
  const searchText = activeTab === PivotItems.MYLAB_RESULTS ? searchDefinitionText : searchInstanceText;

  const fetchAllCompanies = React.useCallback(async () => {
    try {
      const result = await logManagementRequestService.getAllCompanies();

      if (result?.length === 0) {
        setShowSpan(false);
      } else {
        const companyList = result.map((item) => ({ key: item.CompanyName, text: item.CompanyName }));

        setCompanyList(companyList);

        if (!isPartnerMode) {
          setShowSpan(true);
        }
      }
    } catch (error) {
      setShowSpan(false);
      setIsLoading(false);
    }
  }, []);

  const fetchCompanyEmail = React.useCallback(async (storedCompanyValue?: string) => {
    try {
      let company: string = await logManagementRequestService.getCompanyNameByUserId();

      if (company.toLowerCase() === Labels.ADMIN_COMPANY.toLowerCase()) {
        fetchAllCompanies();
      }

      company = storedCompanyValue || company;
      setSelectedCompanyValue(company);
      fetchAllLogs(company);
    } catch (error) {
      failMessage = {
        message: t('company-name-error', { ns: NS.ERRORS }),
        type: MessageBarType.error,
        groupId: FailGroupIds.RESULT_EXPLORER,
      };

      addGlobalMessage(failMessage);
      setIsLoading(false);
    }
  }, []);

  const onTabChange = (currentTabKey) => {
    let resultColumns: any[] = [];
    let filterColumnKey = '';
    let tabDisplayColumns: string[] = [];
    const excludeField: string[] = [ColumnNames.EXECUTED_BY];
    const summaryFlag: boolean = activeTabRef.current !== PivotItems.MYLAB_RESULTS;

    currentTabKey = currentTabKey || PivotItems.MY_RESULTS;
    setActionsDisabled(true);
    setResultAnalyzerDisabled(true);
    closeLogsViewPanel();

    if (currentTabKey === PivotItems.MY_RESULTS) {
      resultColumns = config.instanceResults.filter((column) => !excludeField.includes(column.fieldName));
      filterColumnKey = ColumnEditorKeys.MY_RESULTS;
      tabDisplayColumns = config.myResultsDisplayColumns;
    } else if (currentTabKey === PivotItems.MYLAB_RESULTS) {
      resultColumns = config.definitionResults;
      filterColumnKey = ColumnEditorKeys.MYLAB_RESULTS;
      tabDisplayColumns = config.myResultsDisplayColumns;
    } else if (currentTabKey === PivotItems.ALL_RESULTS) {
      resultColumns = config.instanceResults;
      filterColumnKey = ColumnEditorKeys.ALL_RESULTS;
      tabDisplayColumns = config.allResultsDisplayColumns;
    }

    setEntireColumns(resultColumns);
    setColumnEditorKey(filterColumnKey);
    setDisplayColumns(tabDisplayColumns);
    setIsSummary(summaryFlag);

    const storedColumns = editorColumns(filterColumnKey);

    if (storedColumns && storedColumns.length > 0) {
      const filteredColumns = resultColumns
        .filter((item) => storedColumns.includes(item.key))
        .map((item) => {
          item.isOptional = false;
          return item;
        });

      setColumnsList(filteredColumns);
    } else {
      setColumnsList(resultColumns);
    }

    fetchAllLogs(selectedCompanyValue);
  };

  const buildDataMapping = (results: any[]) => {
    let mappedData: ResultExplorerType[] = [];

    if (activeTabRef.current === PivotItems.MYLAB_RESULTS) {
      mappedData = results.map((result: any) => ({
        id: result.Id,
        lastExecutedBy: result.LastExecutedBy,
        experimentName: result.ExperimentName,
        lastExecutedTime: result.LastExecutedTime,
        lastStatus: result.LastStatus,
        displayStatus: populateResultStatus(result.LastStatus),
        labId: result.LabId,
      }));
    } else {
      mappedData = results.map((result: any) => ({
        id: result.Id.split(Delimiters.UNDERSCORE)[0],
        testName: result.TestName,
        hostName: result.HostName,
        status: result.Status,
        displayStatus: populateResultStatus(result.Status),
        executedBy: result.ExecutedBy,
        startTime: formatDate(result.StartTime, timeZone),
        endTime: formatDate(result.EndTime, timeZone),
        adoHyperLink: result.ADOHyperLink,
      }));
    }

    return mappedData;
  };

  const populateResultStatus = (status: string) => {
    let iconName;

    const myStatus = status?.toLowerCase();

    if (Object.values(Statuses).includes(myStatus)) {
      iconName = StatusIcons[myStatus as string];
    } else {
      iconName = StatusIcons[Statuses.OTHER];
    }

    return (
      <div className={`${styles['status']} ${styles[`status-${myStatus}`]}`}>
        <Icon className={styles['status-icon']} iconName={iconName} />
        <span className={`${styles['status-value']}`}>{status}</span>
      </div>
    );
  };

  const setDataEmpty = () => {
    setAllData([]);
    setFilteredData([]);
    setIsLoading(false);
  };

  const isDateDifferenceValid = () => {
    if (
      activeTabRef.current === PivotItems.ALL_RESULTS &&
      resultExplorerLastRunTime === FilterOptions.CUSTOM &&
      resultExplorerStartDate &&
      resultExplorerEndDate
    ) {
      const isValidDate = validateDates(resultExplorerStartDate, resultExplorerEndDate);

      if (!isValidDate) {
        setDataEmpty();
        return false;
      }
    }

    return true;
  };

  const fetchAllLogs = React.useCallback(
    async (companyName: string) => {
      const isValidDate = isDateDifferenceValid();

      if (companyName && isValidDate) {
        try {
          let results: any[] = [];
          const currentTab = activeTabRef.current;
          const requestId = generateRequestId();

          latestRequestIdRef.current = requestId;
          setIsLoading(true);

          if (currentTab === activeTabRef.current) {
            if (activeTabRef.current === PivotItems.MY_RESULTS) {
              results = await logManagementRequestService.getUserLogs(companyName);
            } else {
              let labs = await ganymedeLabRequestService.getUserLabs();

              if (labs?.length !== 0) {
                labs = labs.map((item) => item.LabControllerId);
                labs = labs.toString();

                if (activeTabRef.current === PivotItems.ALL_RESULTS) {
                  let startDate: Date = null;
                  let endDate: Date = null;

                  if (resultExplorerLastRunTime !== FilterOptions.CUSTOM) {
                    const { runTimeStartDate, runTimeEndDate } = getRunTimeRangeDates(resultExplorerLastRunTime, timeZone);

                    startDate = runTimeStartDate;
                    endDate = runTimeEndDate;
                  }

                  const timeStampDateFrom = Math.floor(
                    (startDate?.getTime() || resultExplorerStartDate.getTime()) / TimeInterval.ONE_SECOND,
                  );
                  const timeStampDateTo = Math.floor(
                    (endDate?.getTime() || resultExplorerEndDate.getTime()) / TimeInterval.ONE_SECOND,
                  );

                  results = await logManagementRequestService.getLogs(companyName, labs, timeStampDateFrom, timeStampDateTo);
                } else {
                  results = await logManagementRequestService.getUserLabLogs(companyName, labs);
                }
              }
            }

            if (latestRequestIdRef.current === requestId) {
              setIsLoading(false);

              if (results?.length > 0) {
                const mappedData: ResultExplorerType[] = buildDataMapping(results);

                setAllData(mappedData);
                searchData();
              } else {
                setDataEmpty();
              }
            }
          }
        } catch (error) {
          failMessage = {
            message: t('no-records', { ns: NS.DEFAULT }),
            type: MessageBarType.error,
            groupId: FailGroupIds.RESULT_EXPLORER,
          };

          addGlobalMessage(failMessage);
          setDataEmpty();
        }
      }
    },
    [activeTabRef, selectedCompanyValue, resultExplorerLastRunTime, resultExplorerStartDate, resultExplorerEndDate],
  );

  const clearAll = () => {
    deleteGroupMessages(FailGroupIds.RESULT_EXPLORER);
    doReset();
    fetchCompanyEmail();

    if (activeTabRef.current === PivotItems.ALL_RESULTS) {
      setResultExplorerLastRunTime(FilterOptions.LAST_THIRTY_DAYS);
      onSelectRange(FilterOptions.LAST_THIRTY_DAYS);
    }

    tabPanesKey.forEach((key) => {
      doResetTableSort(`${pageName}_${key}`);
    });
  };

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.error('Error from Results token creation ', e);
    });
  }, [instance]);

  const setTokenData = React.useCallback(async () => {
    await instance
      .acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', authData.scope],
        account: accounts[0],
      })
      .then((response) => {
        (ls as any).set('resultsToken', response.accessToken);
        setToken(true);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect({ scopes: ['openid', authData.scope] });
        }
      });
  }, [accounts, authData.scope, instance]);

  const selectRow = (item: Selection) => {
    const selectedRowItems: IObjectWithKey[] = item.getSelection();
    const rowSelected = selectedRowItems?.length === 1 || 0;

    if (rowSelected) {
      const selection: ResultExplorerType = selectedRowItems[0] as ResultExplorerType;
      const selectedName = activeTabRef.current === PivotItems.MYLAB_RESULTS ? selection?.experimentName : selection?.testName;

      setSelectedRowItem(selection);
      setSelectedName(selectedName);
      setSelectedId(selection.id);

      if (activeTabRef.current !== PivotItems.MYLAB_RESULTS) {
        setResultAnalyzerDisabled(false);
      }

      setActionsDisabled(false);
      openLogsViewPanel();
    }
  };

  React.useEffect(() => {
    const paginationDefaults = getPaginationDefaults(handlePaginationChange, EnablePagination.RESULT_EXPLORER, true);

    setPaginationType(paginationDefaults);
  }, []);

  React.useEffect(() => {
    if (token) {
      fetchCompanyEmail(selectedCompanyValue);
    }
  }, [token]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, activeTab]);

  React.useEffect(() => {
    searchData(Pagination.CURRENT_PAGE);
  }, [searchValue, experimentNames, hostNames, executedByEmails, labIds, statuses, allData]);

  React.useEffect(() => {
    activeTabRef.current = activeTab;
    onTabChange(activeTabRef.current);
  }, [activeTab]);

  React.useEffect(() => {
    buildDropDownData(activeTabRef.current);
  }, [activeTabRef.current, selectedCompanyValue, allData]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  // Default token expiration time is 10 mins so renewing token every 9 mins
  React.useEffect(() => {
    setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, TimeInterval.NINE_MINS);
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  React.useEffect(() => {
    fetchAllLogs(selectedCompanyValue);
  }, [fetchAllLogs, selectedCompanyValue, resultExplorerLastRunTime, resultExplorerStartDate, resultExplorerEndDate]);

  const allResultsTimeRange = TimeRangeOptions.filter(
    (e) => e.key !== TimeRangeRemoveOptions.ALL && e.key !== TimeRangeRemoveOptions.LAST_90_DAYS,
  );

  const handleCompanyChange = (e, item) => {
    setSelectedCompanyValue(item.key);
    fetchAllLogs(item.key);
  };

  const onSelectRange = (key: string) => {
    setResultExplorerLastRunTime(key);

    if (key !== FilterOptions.CUSTOM) {
      const { runTimeStartDate, runTimeEndDate } = getRunTimeRangeDates(key, timeZone);

      setResultExplorerStartDate(runTimeStartDate);
      setResultExplorerEndDate(runTimeEndDate);
    }
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => {
      return selectRow(selection);
    },
  });

  const handlePaginationChange = (currentPage: number): void => {
    searchData(currentPage);
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: Results.COPY_URL,
      text: copyUrl,
      title: copyUrl,
      iconProps: { iconName: SystemIcons.COPY_URL },
      onClick: () => {
        copyTestRunURL(
          selectedRowItem.id,
          TemplateConstants.EXPERIMENT_DEFINITION_ID,
          SuccessGroupIds.RESULT_EXPLORER,
          selectedCompanyValue,
        );
      },
      disabled: actionsDisabled,
    },
    {
      key: Results.DOWNLOAD_LOGS,
      text: downloadLogs,
      title: downloadLogs,
      iconProps: { iconName: SystemIcons.DOWNLOAD },
      onClick: () => {
        logDownloadHelper(selectedRowItem.id, systemMessageStore, selectedCompanyValue);
      },
      disabled: actionsDisabled,
    },
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: 'edit-columns',
      text: t('edit-columns', { ns: NS.COMMON }),
      title: t('edit-columns', { ns: NS.COMMON }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        showColumnEditor();
      },
    },
  ];

  const dateFilterProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    calloutProps: {
      className: filterBarStyles['pagefilterbar-custom-menu'],
    },
    items: [
      {
        key: 'time-choices',
        onRender: (item, dismissMenu) => (
          <ChoiceGroup
            className={filterBarStyles['pagefilterbar-choice-group-wrapper']}
            styles={filterBar.choiceGroup}
            selectedKey={resultExplorerLastRunTime}
            options={allResultsTimeRange}
            onChange={(ev, option) => {
              onSelectRange(option.key);
            }}
          />
        ),
      },
      {
        key: 'custom-date-pickers',
        onRender: () => (
          <div className={filterBarStyles['pagefilterbar-date-picker-wrapper']}>
            <DatePicker
              label={t('start-date', { ns: NS.TABLE })}
              disabled={resultExplorerLastRunTime !== FilterOptions.CUSTOM}
              onSelectDate={setResultExplorerStartDate}
              value={resultExplorerStartDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDateTime(resultExplorerStartDate.toString(), resultExplorerLastRunTime, timeZone)}
            />
            <DatePicker
              label={t('end-date', { ns: NS.TABLE })}
              disabled={resultExplorerLastRunTime !== FilterOptions.CUSTOM}
              onSelectDate={setResultExplorerEndDate}
              value={resultExplorerEndDate}
              styles={filterBar.datePicker}
              formatDate={() => formatDateTime(resultExplorerEndDate.toString(), resultExplorerLastRunTime, timeZone)}
            />
          </div>
        ),
      },
    ],
  };

  const searchTextFilter: IOverflowSetItemProps[] = [
    {
      key: 'search-filter',
      onRender: () => (
        <SearchBox
          placeholder={searchText}
          value={searchValue}
          iconProps={{ iconName: SystemIcons.SEARCH }}
          onChange={(e) => {
            if (e && e.target) {
              setSearchValue(e.target.value);
            }
          }}
          onSearch={(value: any) => {
            setSearchValue(value);
          }}
          className={`${filterBarStyles['pagefilterbar-item']} ${filterBarStyles['pagefilterbar-searchbox']}`}
          onClear={() => {
            setSearchValue('');
          }}
          spellCheck="false"
        />
      ),
    },
  ];

  const companyFilter: IOverflowSetItemProps[] = [
    {
      key: 'company-filter',
      onRender: () => (
        <Dropdown
          aria-label={t('choose-si', { ns: NS.RESULT_EXPLORER })}
          title={t('choose-si', { ns: NS.RESULT_EXPLORER })}
          dropdownWidth="auto"
          selectedKey={selectedCompanyValue}
          options={companyList}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={handleCompanyChange}
        />
      ),
      disabled: !showSpan,
    },
  ];

  const dateFilter: IOverflowSetItemProps[] = [
    {
      key: 'date-filter',
      onRender: () => (
        <DefaultButton
          text={t('select-last-runtime', { ns: NS.COMMON })}
          className={`${filterBarStyles['pagefilterbar-item']} ${filterBarStyles['pagefilterbar-datefilter']}`}
          menuProps={dateFilterProps}
          onMenuClick={(ev, option) => {
            onSelectRange(resultExplorerLastRunTime);
          }}
        />
      ),
    },
  ];

  const clearFilters: IOverflowSetItemProps[] = [
    {
      key: 'clear-all-filters',
      onRender: () => (
        <DefaultButton
          text={t('reset', { ns: NS.COMMON })}
          title={t('reset', { ns: NS.COMMON })}
          iconProps={{ iconName: SystemIcons.CLEAR_FILTER }}
          onClick={handleClearAllFilters}
          className={filterBarStyles['pagefilterbar-button']}
          styles={filterBar.defaultButton}
        />
      ),
    },
  ];

  const createFilter = ({
    key,
    presentInTabs,
    onRender,
  }: {
    key: string;
    presentInTabs: string[];
    onRender: () => JSX.Element;
  }): IOverflowSetItemProps | undefined => {
    if (presentInTabs.includes(activeTab)) {
      return {
        key,
        onRender: onRender,
      };
    }

    return undefined;
  };

  const tableDataFilters: IOverflowSetItemProps[] = [
    createFilter({
      key: 'experiment-name-filter',
      presentInTabs: [PivotItems.MY_RESULTS, PivotItems.MYLAB_RESULTS, PivotItems.ALL_RESULTS],
      onRender: () => (
        <Dropdown
          placeholder={t('experiment-name', { ns: NS.TABLE })}
          title={t('experiment-name', { ns: NS.TABLE })}
          dropdownWidth="auto"
          multiSelect
          options={experimentNameList}
          selectedKeys={experimentNames}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setExperimentNames(
              option.selected ? [...experimentNames, option.key as string] : experimentNames.filter((name) => name !== option.key),
            )
          }
        />
      ),
    }),
    createFilter({
      key: 'host-name-filter',
      presentInTabs: [PivotItems.MY_RESULTS, PivotItems.ALL_RESULTS],
      onRender: () => (
        <Dropdown
          placeholder={t('host', { ns: NS.TABLE })}
          title={t('host', { ns: NS.TABLE })}
          dropdownWidth="auto"
          multiSelect
          options={hostNameList}
          selectedKeys={hostNames}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setHostNames(option.selected ? [...hostNames, option.key as string] : hostNames.filter((name) => name !== option.key))
          }
        />
      ),
    }),
    createFilter({
      key: 'executed-by-filter',
      presentInTabs: [PivotItems.MYLAB_RESULTS, PivotItems.ALL_RESULTS],
      onRender: () => (
        <Dropdown
          placeholder={t('last-executed-by', { ns: NS.TABLE })}
          title={t('last-executed-by', { ns: NS.TABLE })}
          dropdownWidth="auto"
          multiSelect
          options={executedByList}
          selectedKeys={executedByEmails}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setExecutedByEmails(
              option.selected
                ? [...executedByEmails, option.key as string]
                : executedByEmails.filter((email) => email !== option.key),
            )
          }
        />
      ),
    }),
    createFilter({
      key: 'lab-id-filter',
      presentInTabs: [PivotItems.MYLAB_RESULTS],
      onRender: () => (
        <Dropdown
          placeholder={t('lab-id', { ns: NS.TABLE })}
          title={t('lab-id', { ns: NS.TABLE })}
          dropdownWidth="auto"
          multiSelect
          options={labIdList}
          selectedKeys={labIds}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setLabIds(option.selected ? [...labIds, option.key as string] : labIds.filter((id) => id !== option.key))
          }
        />
      ),
    }),
    createFilter({
      key: 'status-filter',
      presentInTabs: [PivotItems.MY_RESULTS, PivotItems.MYLAB_RESULTS, PivotItems.ALL_RESULTS],
      onRender: () => (
        <Dropdown
          placeholder={t('status', { ns: NS.TABLE })}
          title={t('status', { ns: NS.TABLE })}
          dropdownWidth="auto"
          multiSelect
          options={runStatusList}
          selectedKeys={statuses}
          className={filterBarStyles['pagefilterbar-item']}
          styles={filterBar.dropdown}
          onChange={(event, option) =>
            setStatuses(option.selected ? [...statuses, option.key as string] : statuses.filter((status) => status !== option.key))
          }
        />
      ),
    }),
  ].filter(Boolean); // Remove undefined entries

  const handleClearAllFilters = () => {
    clearAll();
  };

  const filters = [...searchTextFilter, ...companyFilter, ...tableDataFilters];
  const filterItems = activeTabRef.current === PivotItems.ALL_RESULTS ? [...filters, ...dateFilter] : filters;
  filterItems.push(...clearFilters);

  return (
    <ResultExplorerTemplate
      commandBarItems={commandBarItems}
      filterItems={filterItems}
      farItems={farItems}
      token={token}
      tabHeaders={tabHeaders}
      tabPanesKey={tabPanesKey}
      entireColumns={entireColumns}
      isLoading={isLoading}
      selection={selection}
      selectionMode={SelectionMode.single}
      hideColumnEditor={hideColumnEditor}
      isColumnEditorOpen={isColumnEditorOpen}
      columnEditorKey={columnEditorKey}
    ></ResultExplorerTemplate>
  );
};

const ResultExplorerViewController = observer(ResultExplorerViewControllerFC);

export default ResultExplorerViewController;
