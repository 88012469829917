import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, DetailsRow, PrimaryButton } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Selection } from '@fluentui/react/lib/Selection';
import { useBoolean } from '@fluentui/react-hooks';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import { utils, write } from 'xlsx';

import MachineSelectTemplate from '@/components/MachineSelect/MachineSelectTemplate';
import { AccordionKeys, LabsType, MachineSelectVMType, MachinesType } from '@/components/MachineSelect/MachineSelectTypes';
import { QueueOptions } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { HealthDiffInMins } from '@/constants/LabsConstants';
import { ExperimentEditorKeys, Namespaces as NS } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { ActionTypeVoid } from '@/types/AppSettingsTypes';
import { RenderRowType } from '@/types/TableTypes';
import { populateHealthStatus } from '@/utils/Helpers';

import styles from './MachineSelect.module.css';

const MachineSelectViewControllerFC: React.FC<{
  viewModel: MachineSelectVMType;
  closeModal: ActionTypeVoid;
  labId?: number;
}> = (props: { viewModel: MachineSelectVMType; closeModal: ActionTypeVoid; labId?: number }) => {
  const { labId, closeModal, viewModel } = props;
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { localStorage } = rootStore;
  const experimentViewModel: MachineSelectVMType = viewModel;
  const {
    labs,
    loadLabs,
    isLabsLoading,
    machines,
    machinesGroups,
    loadMachines,
    isMachinesLoading,
    createExperiment,
    isExperimentLoading,
    createdName,
    creationMessage,
    successfulExperiments,
    failedExperiments,
  } = experimentViewModel;

  const [selectedLabId, setSelectedLabId] = React.useState<number>(labId);
  const [selectedMachines, setSelectedMachines] = React.useState<MachinesType[]>([]);
  const [windowState, setWindowState] = React.useState<number>(0);
  const [labExperiment, setLabExperiment] = React.useState<boolean>(true);
  const [accordionOpenItems, setAccordionOpenItems] = React.useState<AccordionKeys[]>([AccordionKeys.LABS, AccordionKeys.MACHINES]);
  const [isConfirmModalOpen, { setTrue: showConfirmModalOpen, setFalse: hideConfirmModalOpen }] = useBoolean(false);

  // Container for the menu items
  const successReport: ICommandBarItemProps = {
    key: t('download-success-report', { ns: NS.EDITOR }),
    text: t('download-success-report', { ns: NS.EDITOR }),
    ariaLabel: t('download-success-report', { ns: NS.EDITOR }),
    iconOnly: false,
    iconProps: { iconName: SystemIcons.DOWNLOAD },
    title: t('download-success-report', { ns: NS.EDITOR }),
    disabled: successfulExperiments.length === 0,
    onClick: (event) => {
      exportToExcel(successfulExperiments, 'SuccessfulInstances');
    },
  };

  const failedReport: ICommandBarItemProps = {
    key: t('download-failed-report', { ns: NS.EDITOR }),
    text: t('download-failed-report', { ns: NS.EDITOR }),
    ariaLabel: t('download-failed-report', { ns: NS.EDITOR }),
    iconOnly: false,
    iconProps: { iconName: SystemIcons.DOWNLOAD },
    title: t('download-failed-report', { ns: NS.EDITOR }),
    disabled: failedExperiments.length === 0,
    onClick: (event) => {
      exportToExcel(failedExperiments, 'FailedInstances');
    },
  };

  const _commandItems: ICommandBarItemProps[] = [];
  windowState === 1 && _commandItems.push(successReport, failedReport);

  const labsColumns: IColumn[] = [
    {
      key: 'id',
      name: 'id',
      fieldName: 'LabId',
      minWidth: 25,
      maxWidth: 50,
    },
    {
      key: 'name',
      name: 'name',
      fieldName: 'LabName',
      minWidth: 200,
    },
    {
      key: 'status',
      name: 'status',
      fieldName: 'LabStatus',
      minWidth: 100,
    },
    {
      key: 'health',
      name: 'health',
      fieldName: 'LastHeartBeat',
      minWidth: 100,
      onRender: (item) => populateHealthStatus(item.LastHeartBeat, 'td', styles)?.displayHealthStatus,
    },
  ];

  const machinesColumns: IColumn[] = [
    {
      key: 'slot',
      name: 'slot',
      fieldName: 'SlotNumber',
      minWidth: 25,
      maxWidth: 35,
      isResizable: true,
    },
    {
      key: 'host-name',
      name: 'host-name',
      fieldName: 'Name',
      minWidth: 130,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'ip-address',
      name: 'ip-address',
      fieldName: 'IPAddress',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: 'tags',
      name: 'tags',
      fieldName: 'Tags',
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'mac-address',
      name: 'mac-address',
      fieldName: 'MacAddress',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: 'os-image-name',
      name: 'os-image-name',
      fieldName: 'OsImageName',
      minWidth: 120,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'status',
      name: 'status',
      fieldName: 'Status',
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: 'health',
      name: 'health',
      fieldName: 'LastHeartBeat',
      minWidth: 80,
      maxWidth: 100,
      onRender: (item) => populateHealthStatus(item.LastHeartBeat, 'td', styles)?.displayHealthStatus,
    },
  ];

  const machinesGroupColumns: IColumn[] = [
    {
      key: 'rackName',
      name: 'rackName',
      fieldName: 'RackName',
      minWidth: 130,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const checkHealth = (item: Date | string) => {
    const currentDate = new Date().getTime();
    const heartbeat = new Date(`${item}Z`).getTime();
    const diffInMs = Math.abs(currentDate - heartbeat) / 60000;

    if (diffInMs <= HealthDiffInMins.unhealthy) {
      return true;
    } else {
      return false;
    }
  };

  const accordionToggle = (event, data) => {
    setAccordionOpenItems(data.openItems);
  };

  const [labSelection] = React.useState(
    new Selection({
      onItemsChanged: () => {
        const index = labSelection.getItems().findIndex((item) => (item as LabsType).LabId == selectedLabId);

        if (index !== -1) {
          labSelection.setIndexSelected(index, true, false);
        }
      },
      onSelectionChanged: () => {
        return setSelectedLab(labSelection);
      },
    }),
  );

  const setSelectedLab = (item: Selection) => {
    const selectedLab = item.getSelection();

    if (selectedLab[0]) {
      const id = (selectedLab[0] as LabsType).LabId;
      setSelectedLabId(id);
      setSelectedMachines([]);

      // Checking selectedLabId doesn't work here as it nevers gets updated inside here since this is inside a useSate
      if (localStorage.getValue(ExperimentEditorKeys.SELECTED_LAB_ID) !== id) {
        loadMachines(id, machinesSelection);
      }
    } else {
      setSelectedLabId(-1);
    }
  };

  const [machinesSelection] = React.useState(
    new Selection({
      onSelectionChanged: () => {
        return selectMachines(machinesSelection);
      },
    }),
  );

  const selectMachines = (item: Selection) => {
    const selectedData: MachinesType[] = item.getSelection() as MachinesType[];

    const selectedMachines = [];

    for (let i = 0; i < selectedData.length; i++) {
      selectedMachines.push(selectedData[i as number].MachineId);
    }

    setSelectedMachines(selectedMachines);
  };

  // Gather the machine information of the given lab, including the rack information
  // Set initial state the component has a lab passed to it
  React.useEffect(() => {
    loadLabs(labSelection);

    if (labId !== -1) {
      loadMachines(labId, machinesSelection);
    }
  }, []);

  React.useEffect(() => {
    localStorage.setValue(ExperimentEditorKeys.SELECTED_LAB_ID, selectedLabId);
  }, [selectedLabId]);

  const exportToExcel = (data, fileName) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(excelBlob, `${fileName}.xlsx`);
  };

  const updateWindowState = (state: number) => {
    if (state < 0) {
      state = 0;
    }

    if (windowState !== state) {
      setWindowState(state);
    }
  };

  const createExperimentForConfirm = () => {
    if (labExperiment) {
      createExperiment(true, selectedLabId, []);
    } else {
      createExperiment(false, selectedLabId, selectedMachines);
    }
  };

  const buttons = (
    <div>
      {windowState == 0 && (
        <PrimaryButton
          onClick={(event) => {
            const selectedLab = labs.find((item) => item.LabId === selectedLabId);

            if (selectedMachines.length !== 0) {
              setLabExperiment(false);

              if (checkHealth(selectedLab.LastHeartBeat)) {
                setWindowState(1);
                createExperiment(false, selectedLabId, selectedMachines);
              } else {
                showConfirmModalOpen();
              }
            } else {
              setLabExperiment(true);

              if (checkHealth(selectedLab.LastHeartBeat)) {
                setWindowState(1);
                createExperiment(true, selectedLabId, []);
              } else {
                showConfirmModalOpen();
              }
            }
          }}
          disabled={selectedLabId === -1 || isMachinesLoading || (machines?.length !== 0 && selectedMachines.length === 0)}
          className={styles.button}
          text={t('queue', { ns: NS.EDITOR })}
        />
      )}
      <DefaultButton onClick={closeModal} className={styles.button} text={t('close', { ns: NS.COMMON })} />
    </div>
  );

  const labsOnRenderRow: RenderRowType = (props: any) => {
    if (props) {
      return (
        <div data-selection-disabled={isMachinesLoading}>
          <DetailsRow {...props} />
        </div>
      );
    }

    return null;
  };

  return (
    <MachineSelectTemplate
      windowState={windowState}
      setWindowState={updateWindowState}
      labs={labs}
      labsColumns={labsColumns}
      labSelected={selectedLabId !== -1}
      selectedLabId={selectedLabId}
      labExperiment={labExperiment}
      labSelection={labSelection}
      labsOnRenderRow={labsOnRenderRow}
      machines={machines}
      selectedMachines={selectedMachines}
      machinesColumns={machinesColumns}
      machinesGroupsColumns={machinesGroupColumns}
      machinesGroups={machinesGroups}
      machinesSelection={machinesSelection}
      isConfirmModalOpen={isConfirmModalOpen}
      hideConfirmModal={hideConfirmModalOpen}
      createExperiment={createExperimentForConfirm}
      createdName={createdName}
      creationMessage={creationMessage}
      successfulExperiments={successfulExperiments}
      failedExperiments={failedExperiments}
      maxReportSize={QueueOptions.MAX_REPORT_SIZE}
      labLoading={isLabsLoading}
      machineLoading={isMachinesLoading}
      experimentLoading={isExperimentLoading}
      commandItems={_commandItems}
      buttons={buttons}
      accordionToggle={accordionToggle}
      accordionOpenItems={accordionOpenItems}
    />
  );
};

const MachineSelectViewController = observer(MachineSelectViewControllerFC);

export default MachineSelectViewController;
