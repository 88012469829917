import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@fluentui/react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { CopyType, Delimiters, Namespaces as NS } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import { copyToClipboard } from '@/utils/Helpers';

import { GeneralConfigItemType, GeneralInfoBlockType } from './GenearalInfoBlockTypes';

import styles from './GeneralInfoBlock.module.css';

const GenearalInfoBlockFC: React.FC<GeneralInfoBlockType> = (props: GeneralInfoBlockType): React.ReactElement => {
  const { generalInfo, configFields, isDualColumnLayout, translationMap } = props;
  const tooltipId = useId('tooltip');
  const columnIndices: number[] = [1, 2];

  const copyButton = (value: string): React.ReactElement => {
    const title = t(Common.COPY_TO_CLIPBOARD, { ns: NS.COMMON });
    const icon = { iconName: SystemIcons.COPY };
    const onClick = () => copyToClipboard(value);

    return (
      <div className={styles['copy-button']} role="button">
        <TooltipHost content={title} id={tooltipId}>
          <IconButton aria-label={title} iconProps={icon} onClick={onClick} />
        </TooltipHost>
      </div>
    );
  };

  const buildRow = (key: string, value: string, canCopy: CopyType = CopyType.CANNOT_COPY): React.ReactElement => {
    const text = value;
    const title = t(key, { ns: NS.EXPERIMENTS });

    // console.log('title:', title);
    // console.log('text:', text);

    return (
      <div className={styles['row']} key={key}>
        <div className={styles['label']}>{t(title, { ns: translationMap || NS.COMMON })}</div>
        <div className={styles['separator']}>{Delimiters.COLON}</div>
        <div className={styles['value']}>
          <div className={styles['text']}>
            {text}
            {canCopy === CopyType.CAN_COPY && copyButton(value)}
          </div>
        </div>
      </div>
    );
  };

  const buildGrid = (item: any, columnNumber?: number): React.ReactElement<any, string | React.JSXElementConstructor<any>>[] => {
    if (!item) {
      return null;
    }

    let columnConfig: any = configFields;

    const buildColumn = (
      columnConfig: GeneralConfigItemType[],
    ): React.ReactElement<any, string | React.JSXElementConstructor<any>>[] =>
      columnConfig.map((configItem: GeneralConfigItemType) => {
        const data = item[configItem.fieldName];

        return buildRow(
          configItem.key,
          data ?? Delimiters.DASH, // Nullish is used to exclude null and undefined and print '0'
          configItem.doesShowCopy ? CopyType.CAN_COPY : CopyType.CANNOT_COPY,
        );
      });

    if (isDualColumnLayout) {
      const middleIndex = Math.ceil(configFields.length / 2);

      if (columnNumber === 1) {
        columnConfig = configFields.slice(0, middleIndex);
      } else {
        columnConfig = configFields.slice(middleIndex);
      }
    }

    return buildColumn(columnConfig);
  };

  return (
    <div className={styles['block-container']}>
      <div className={styles['block-columns']}>
        {isDualColumnLayout ? (
          <>
            {columnIndices.map((columnIndex) => (
              <div className={styles['block-column']} key={`column-${columnIndex}`}>
                <div className={styles['block-grid']}>{buildGrid(generalInfo, columnIndex)}</div>
              </div>
            ))}
          </>
        ) : (
          <div className={styles['block-column']}>
            <div className={styles['block-grid']}>{buildGrid(generalInfo)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const GenearalInfoBlock = observer(GenearalInfoBlockFC);

export default GenearalInfoBlock;
