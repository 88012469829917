import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { CheckboxVisibility } from '@fluentui/react/lib/DetailsList';
import { IObjectWithKey, Selection } from '@fluentui/react/lib/Selection';
import axios, { CancelToken } from 'axios';
import { t } from 'i18next';
import { container } from 'tsyringe';

import SessionDetailsStepsFilters from '@/components/SessionDetails/Sections/SessionDetailsSteps/Components/Filter/SessionDetailsStepsFilters';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import SessionStepModel from '@/components/SessionDetails/SessionStepModel';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import {
  ColumnEditorKeys,
  FailGroupIds,
  Namespaces as NS,
  ReadingPaneStateKeys,
  SplitPanelDirectionType,
} from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import ColumnEditorStore from '@/partials/ColumnEditor/ColumnEditorStore';
import { ColumnEditorUserSettingsType } from '@/partials/ColumnEditorPanel/ColumnEditorPanelTypes';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import AppSettingsService from '@/services/AppSettingsService';
import EventBus from '@/services/EventBus';
import LocalStorageService from '@/services/LocalStorageService';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { TableColumnType } from '@/types/TableTypes';
import { formatDate, formatDateToISOStandard, getDuration } from '@/utils/Dates';
import { setTableDataGroupBy } from '@/utils/GroupBy';
import {
  checkIfProviderIsLoggingOnStepName,
  getLabId,
  getLogPath,
  getSpecificLogFolderName,
  getToolkitCommand,
  modifiedColumnConfiguration,
} from '@/utils/Helpers';
import { processColumns } from '@/utils/Tables';
import { filterDateAgent, filterIpAddress, filterStatus, filterStepType, filterText } from '@/utils/Text';

import styles from '@/components/SessionDetails/SessionDetails.module.css';

class SessionDetailsStepsViewController {
  private appSettings: AppSettingsStore = container.resolve(AppSettingsStore);
  private sessionsStore: SessionsStore = container.resolve(SessionsStore);
  private sessionDetailsStore: SessionDetailsStore = container.resolve(SessionDetailsStore);
  private localStorage: LocalStorageService = container.resolve(LocalStorageService);
  private columnsEditorStore: ColumnEditorStore = container.resolve(ColumnEditorStore);
  private userSettingsStore: UserSettingsStore = container.resolve(UserSettingsStore);
  private eventBus: EventBus = container.resolve(EventBus);

  @observable filters = new SessionDetailsStepsFilters();

  @computed get session() {
    return this.sessionsStore.selectedSession;
  }

  constructor() {
    makeObservable(this);
    this.setEventListeners();

    // Initial init
    if (this.sessionsStore.selectedSession) {
      this.init(this.sessionsStore.selectedSession);
    }

    // Check https://mobx.js.org/reactions.html for how to use them. They work similar to useEffect()
    reaction(
      () => this.sessionsStore.selectedSession,
      async (session) => {
        await this.init(session);
      },
    );

    this.configureColumns();
  }

  // region init
  @action.bound
  public async init(session): Promise<void> {
    if (!session) {
      return;
    }

    try {
      this.reset();
      const steps: SessionStepModel[] = await SessionStepModel.load();

      // Find the lowest startTime and highest endTime to calculate the duration of the session.
      const { timeZone } = this.userSettingsStore;
      let { minStartTime, maxEndTime } = steps.reduce(
        (acc, step) => {
          const startTime = new Date(step.startTime).getTime();
          const endTime = new Date(step.endTime).getTime();
          return {
            minStartTime: Math.min(acc.minStartTime, startTime),
            maxEndTime: Math.max(acc.maxEndTime, endTime),
          };
        },
        { minStartTime: Infinity, maxEndTime: -Infinity },
      );

      if (minStartTime === Infinity) {
        minStartTime = session.started;
      }

      if (maxEndTime === -Infinity) {
        maxEndTime = session.lastModified;
      }

      session.setSteps(steps);
      session.setDuration(
        getDuration(
          minStartTime ? formatDate(formatDateToISOStandard(new Date(minStartTime)), timeZone) : '',
          maxEndTime ? formatDate(formatDateToISOStandard(new Date(maxEndTime)), timeZone) : '',
        ),
      );
      this.selectSessionStepsPane();
    } catch (error) {
      console.error(error);

      session.setSteps([]);

      const failMessage: SystemMessageType = {
        message: error.response?.data || error.message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        groupId: FailGroupIds.SESSION_PANEL_ERROR,
      };
      this.sessionDetailsStore.addSessionPanelMessage(failMessage);
    }

    this.rebuildSteps(session.steps);
  }

  private setEventListeners() {
    this.eventBus.on('closeSessionStepsColumnEditor', this.closeColumnEditor);
  }

  public destroy() {
    this.eventBus.off('closeSessionStepsColumnEditor', this.closeColumnEditor);
  }

  @action.bound
  private reset() {
    this.stepDisplayItems = [];
    this.filters.reset();
    this.selectedStep = undefined;
  }

  @computed
  get isLoading(): boolean {
    return !this.sessionsStore.isSessionDataLoaded || !this.stepDisplayItems || this.stepDisplayItems.length === 0;
  }
  // endregion

  // region columns
  @computed
  get columns() {
    return modifiedColumnConfiguration(this.columnsList);
  }

  @observable
  public isStepsColumnEditorOpen = false;

  @action.bound
  public openColumnEditor() {
    const { closeSettings } = this.appSettings;

    this.isStepsColumnEditorOpen = true;
    this.eventBus.emit('sessionDetailsStepsColumnEditor', true);
    closeSettings();
  }

  @action.bound
  public closeColumnEditor() {
    this.isStepsColumnEditorOpen = false;
    this.eventBus.emit('sessionDetailsStepsColumnEditor', false);
  }

  /**
   * Configure our Columns for the Step Details Table Column Editor.
   * @private
   */
  private configureColumns() {
    const storedColumns = this.columnsEditorStore.getEditorColumns(this.columnsEditorKey);

    const { userColumns, allColumns } = processColumns(storedColumns, this.entireColumns);

    this.setColumnsList(userColumns);
    this.setEntireColumns(allColumns);
  }

  @observable
  public columnsList: TableColumnType[] = [];

  @action.bound
  setColumnsList(value: TableColumnType[]): void {
    this.columnsList = value;
  }

  @observable
  public entireColumns: TableColumnType[] = SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS;

  @action.bound
  public setEntireColumns(value: TableColumnType[]) {
    this.entireColumns = value;
  }

  @observable
  public columnsEditorKey: string = ColumnEditorKeys.SESSION_STEP;
  // endregion

  // region middle panel
  @computed
  get showStepTitle(): boolean {
    return !this.appSettings.isOutlookMode;
  }

  @computed
  get allowResizePanel(): boolean {
    return this.appSettings.isOutlookMode || this.sessionsStore.isViewingDeepLink;
  }

  @computed
  get selectedStepJson(): object {
    return this.sessionsStore.isSessionSelected ? (this.selectedStep?.stepJSON as object) : {};
  }

  @computed
  get labCompanyName(): string {
    return this.sessionsStore.labCompanyName;
  }

  @computed
  get showFailureAnalysisLink(): boolean {
    return this.session?.isFailed ?? false;
  }

  @computed
  get showLogs(): boolean {
    return this.isLogsPaneSelected && !!this.selectedStep?.experimentId;
  }

  @computed
  get stepName(): string {
    return this.selectedStep?.name || '';
  }
  // endregion

  // region selected pane
  @observable
  public _isStepsPaneSelected = false;

  get isStepsPaneSelected() {
    return this._isStepsPaneSelected;
  }

  set isStepsPaneSelected(value: boolean) {
    this._isStepsPaneSelected = value;
    this.eventBus.emit('isStepsPaneSelected', value);
  }

  @observable
  public _isLogsPaneSelected = false;

  get isLogsPaneSelected() {
    return this._isLogsPaneSelected;
  }

  set isLogsPaneSelected(value: boolean) {
    this._isLogsPaneSelected = value;
    this.eventBus.emit('isLogsPaneSelected', value);
  }

  @observable
  public _isFailurePaneSelected = false;

  get isFailurePaneSelected() {
    return this._isFailurePaneSelected;
  }

  set isFailurePaneSelected(value: boolean) {
    this._isFailurePaneSelected = value;
    this.eventBus.emit('isFailurePaneSelected', value);
  }

  @action.bound
  public selectSessionStepsPane() {
    this.isStepsPaneSelected = true;
    this.isLogsPaneSelected = false;
    this.isFailurePaneSelected = false;
  }

  @action.bound
  public selectLogWindowPane() {
    this.isStepsPaneSelected = false;
    this.isLogsPaneSelected = true;
    this.isFailurePaneSelected = false;
  }

  @action.bound
  public selectFailureWindowPane() {
    this.isStepsPaneSelected = false;
    this.isLogsPaneSelected = false;
    this.isFailurePaneSelected = true;
  }

  // endregion

  // region failure analysis
  @computed
  /**
   * Shows on right pane
   */
  get showFailureAnalysisTable(): boolean {
    return this.session?.isFailed && this.selectedExperimentFailure !== null;
  }

  @computed
  get sessionExperimentFailureColumns(): any {
    return modifiedColumnConfiguration(SessionDetailsStore.SESSION_EXPERIMENT_FAILURE_COLUMN_DEFINITIONS);
  }
  // endregion

  // region filtering
  public tableGroups: any[] = this.session?.steps ?? [];

  @observable
  public stepDisplayItems: any[] = this.sessionsStore.selectedSession?.steps;

  @observable
  public groupStepsBy = this.localStorage.getValue(AppSettingsService.FILTER_STEPS_GROUPBY_KEY) || PageCommandBar.GROUPBY_NONE;

  @action.bound
  public setGroupStepsBy(value: string): string {
    this.groupStepsBy = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_STEPS_GROUPBY_KEY, value);
  }

  @action.bound
  public setGroupByData(tableData: any[]) {
    const stepsColumnDefinitions = SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS;

    const { returnData, groups } = setTableDataGroupBy(tableData, this.groupStepsBy, stepsColumnDefinitions);

    this.stepDisplayItems = returnData;
    this.tableGroups = groups;
  }

  @action.bound
  handleGroupBySelected(columnKey: string) {
    this.setGroupStepsBy(columnKey);
    this.setGroupByData(this.stepDisplayItems);
  }

  @computed
  get groupByColumns() {
    return SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS;
  }

  @computed
  public get filteredSteps() {
    const steps: any[] = this.stepDisplayItems;

    if (!steps || steps?.length === 0) {
      return [];
    }

    let results = [...steps];

    results = filterText(results, this.filters.searchValue);
    results = filterStatus(results, this.filters.statusValues);
    results = filterIpAddress(results, this.filters.ipAddresses, this.filters.searchIpAddress);
    results = filterStepType(results, this.filters.typeValues);
    results = filterDateAgent(results, this.filters.startDate, this.filters.endDate, this.filters.timeRange);

    return results;
  }

  @action.bound
  public rebuildSteps(steps: any[]) {
    if (steps.length) {
      this.stepDisplayItems = steps;
      this.setGroupByData(steps);
    }
  }

  // endregion

  // region display data
  @computed
  get stepsLinkClassName(): string {
    return `${styles['title']}${this.isStepsPaneSelected ? ` ${styles['hot']}` : ''}`;
  }

  @computed
  get logLinkClassName(): string {
    return `${styles['title']}${this.isLogsPaneSelected ? ` ${styles['hot']}` : ''}`;
  }

  @computed
  get failureAnalysisLinkClassName(): string {
    return `${styles['title']}${this.isFailurePaneSelected ? ` ${styles['hot']}` : ''}`;
  }

  get checkboxVisibility(): number {
    return CheckboxVisibility.hidden;
  }

  @computed
  get detailsSplitDirection(): SplitPanelDirectionType {
    const { isOutlookMode, isReadingPaneBottom } = this.appSettings;

    return isOutlookMode && isReadingPaneBottom ? SplitPanelDirectionType.VERTICAL : SplitPanelDirectionType.HORIZONTAL;
  }

  get splitPanelConfig(): SplitPanelConfigType {
    const mainPaneKey = ReadingPaneStateKeys.SESSION_DETAILS_MAIN;
    const detailsPanePropertiesKey = ReadingPaneStateKeys.SESSION_DETAILS_PROPERTIES;
    const mainPaneSize = this.localStorage.getValue(mainPaneKey) ?? 350;
    const detailsPaneSize = this.localStorage.getValue(detailsPanePropertiesKey) ?? 400;

    return {
      defaultSize: [mainPaneSize, detailsPaneSize],
      minSize: [200, 350],
      maxSize: [-200, -200], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
      keys: [mainPaneKey, detailsPanePropertiesKey],
      offModeSize: ['50%', '50%'],
      padding: 300, // Padding to prevent the pane from being resized to the full height of the window.
    };
  }

  get columnEditorUserSettings(): ColumnEditorUserSettingsType {
    return {
      headerText: t('choose-steps-columns', { ns: NS.TITLES }),
      styles: SidePanelStyles.sessionStepColumnEditorPanel,
    };
  }

  // endregion

  // region selection
  @observable
  public selectedStep: SessionStepModel;

  @computed
  get isStepSelected(): boolean {
    return !!this.selectedStep;
  }

  @observable
  public selectedExperimentFailure = null;

  @observable
  public logPath: string;

  selection: Selection = new Selection({
    onSelectionChanged: () => {
      return this.selectStep(this.selection);
    },
  });

  @action.bound
  private async selectStep(item: Selection) {
    const selectedData: IObjectWithKey[] = item.getSelection();
    const selection: SessionStepModel | null = selectedData[0] as SessionStepModel | null;
    const cancellationToken = axios.CancelToken.source().token;

    if (selection) {
      this.selectedStep = selection as SessionStepModel;
      await this.getLogPathFromExperimentInstance(cancellationToken);
      await this.getExperimentFailures(cancellationToken);
      this.eventBus.emit('sessionStepSelected', this.selectedStep);
    }
  }

  private async getExperimentFailures(cancelToken: CancelToken): Promise<void> {
    const { selectedStep } = this;
    const experimentId: any = selectedStep.experimentId;

    try {
      const response = await ganymedeExperimentRequestService.getExperimentFailureAnalysis(experimentId, cancelToken);

      if (response) {
        this.selectedExperimentFailure = response;
      } else {
        this.selectedExperimentFailure = {};
      }
    } catch (error) {
      this.selectedExperimentFailure = {};
    }
  }

  private async getLogPathFromExperimentInstance(cancellationToken: CancelToken): Promise<void> {
    const sessionStepJSON: any = this.selectedStep.stepJSON;
    const toolkitCommand: string = getToolkitCommand(sessionStepJSON);
    let labId: string = this.sessionsStore.selectedSession?.location?.labId?.toString();
    const providerType = sessionStepJSON?.definition?.type;

    if (!labId) {
      labId = getLabId(sessionStepJSON);
    }

    const response = await ganymedeExperimentRequestService.getExperimentInstanceDetails(
      labId,
      this.selectedStep?.experimentId,
      cancellationToken,
    );

    if (toolkitCommand) {
      try {
        this.logPath = getLogPath(response, toolkitCommand);
      } catch (error) {
        this.logPath = null;
      }
    } else if (checkIfProviderIsLoggingOnStepName(providerType)) {
      this.logPath = getSpecificLogFolderName(response, sessionStepJSON);
    } else {
      this.logPath = null;
    }
  }

  // endregion
}

export default SessionDetailsStepsViewController;
