import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { Cosmos } from '@/constants/SystemConstants';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';

@singleton()
class CosmosStore {
  public databaseId: string;

  public getEntryKey = '';
  public getEntryValue = '';
  public createEntryKey = '';
  public createEntryValue = '';
  public updateEntryKey = '';
  public updateEntryValue = '';
  public deleteEntryKey = '';
  public isGlobal = false;

  constructor() {
    makeObservable(this, {
      getEntryKey: observable,
      getEntryValue: observable,
      createEntryKey: observable,
      createEntryValue: observable,
      updateEntryKey: observable,
      updateEntryValue: observable,
      deleteEntryKey: observable,
      isGlobal: observable,

      setGetEntryKey: action,
      setGetEntryValue: action,
      setCreateEntryKey: action,
      setCreateEntryValue: action,
      setUpdateEntryKey: action,
      setUpdateEntryValue: action,
      setDeleteEntryKey: action,
      setIsGlobal: action,
      toggleIsGlobal: action,
    });

    this.databaseId = Cosmos.DATABASE_ID;
  }

  public setGetEntryKey: ActionTypeWithParam = (key: string) => {
    this.getEntryKey = key;
  };

  public setGetEntryValue: ActionTypeWithParam = (value: string) => {
    this.getEntryValue = value;
  };

  public setCreateEntryKey: ActionTypeWithParam = (key: string) => {
    this.createEntryKey = key;
  };

  public setCreateEntryValue: ActionTypeWithParam = (value: string) => {
    this.createEntryValue = value;
  };

  public setUpdateEntryKey: ActionTypeWithParam = (key: string) => {
    this.updateEntryKey = key;
  };

  public setUpdateEntryValue: ActionTypeWithParam = (value: string) => {
    this.updateEntryValue = value;
  };

  public setDeleteEntryKey: ActionTypeWithParam = (key: string) => {
    this.deleteEntryKey = key;
  };

  public setIsGlobal: ActionTypeWithParam = (global: boolean) => {
    this.isGlobal = global;
  };

  public toggleIsGlobal: ActionTypeWithParam = () => {
    this.isGlobal = !this.isGlobal;
  };
}

export default CosmosStore;
