import React from 'react';
const choiceGroup = {
  root: { padding: '10px 10px' },
};

const datePicker = {
  root: { width: '100%' },
  readOnlyTextField: { height: '30px' },
};

const defaultButton = {
  label: { fontWeight: 'normal', color: 'var(--buttonBorderColor)' },
  root: { padding: '0px 4px 0px 4px', height: '32px', borderColor: 'var(--buttonBorderColor)' },
  roothovered: { borderColor: 'var(--buttonBorderColor)' },
};

const dropdown = {
  label: { fontWeight: 'normal' },
  title: { maxWidth: '200px' },
  caretDownWrapper: { height: '30px', lineHeight: '30px', top: '1px', paddingTop: '0px' },
  caretDown: { height: '30px', lineHeight: '30px' },
  dropdownItemsWrapper: {
    maxHeight: '50vh',
  },
};

const filterBar = {
  root: { display: 'flex', padding: '0px', margin: '0px', height: '44px' },
};

const searchBox = {
  root: { minWidth: '250px' },
  field: { minWidth: '200px' },
};

const styles = { choiceGroup, datePicker, defaultButton, dropdown, filterBar, searchBox };

export default styles;
