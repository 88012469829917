import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

import { AnnotationsChartData } from './ErrorsGraphType';

@singleton()
class ErrorsGraphStore {
  public selectedAnnotation: AnnotationsChartData = { timeGenerated: '' };

  constructor() {
    makeObservable(this, {
      selectedAnnotation: observable,
      setSelectedAnnotation: action,
    });
  }

  public getSelectedAnnotation = (): AnnotationsChartData => {
    return this.selectedAnnotation;
  };

  public setSelectedAnnotation = (selectedAnnotation: AnnotationsChartData) => {
    this.selectedAnnotation = selectedAnnotation;
  };
}

export default ErrorsGraphStore;
