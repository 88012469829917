import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { LabSystemDetailsGeneralViewController } from '@/components/ManageLab/LabSystemDetailsGeneral/LabSystemDetailsGeneralViewController';
import LabSystemDetailsGeneralViewModel from '@/components/ManageLab/LabSystemDetailsGeneral/LabSystemDetailsGeneralViewModel';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { LabSystemDetailsTemplate } from './LabSystemDetailsTemplate';
import { LabSystemDetailsComponentType, LabSystemDetailsVMType } from './LabSystemDetailsType';

interface LabSystemDetailsViewControllerProps {
  viewModel: LabSystemDetailsVMType;
}

const LabSystemDetailsViewControllerFC: React.FC<LabSystemDetailsViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labSystemDetailsStore, systemMessageStore } = rootStore;
  const { setComponents } = labSystemDetailsStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;

  // Other Const
  const labSystemDetailsGeneralViewModel = React.useMemo(() => new LabSystemDetailsGeneralViewModel(rootStore), [rootStore]);

  React.useEffect(() => {
    const systemDetailsComponents: LabSystemDetailsComponentType[] = [
      {
        headerText: t(Common.GENERAL, { ns: NS.COMMON }),
        viewModel: labSystemDetailsGeneralViewModel,
        component: LabSystemDetailsGeneralViewController,
      },
    ];

    setComponents(systemDetailsComponents);
  }, []);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  return <LabSystemDetailsTemplate></LabSystemDetailsTemplate>;
};

export const LabSystemDetailsViewController = observer(LabSystemDetailsViewControllerFC);
