import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

@singleton()
class LabPanelStore {
  public isLabPanelOpen: boolean;

  constructor() {
    makeObservable(this, {
      openLabPanel: action,
      closeLabPanel: action,
      isLabPanelOpen: observable,
    });

    this.isLabPanelOpen = false;
  }

  public openLabPanel = () => {
    this.setLabPanelOpen(true);
  };

  public closeLabPanel = () => {
    this.setLabPanelOpen(false);
  };

  public setLabPanelOpen = (open: boolean) => {
    this.isLabPanelOpen = open;
  };
}

export default LabPanelStore;
