import { action, makeObservable, observable } from 'mobx';

import { FilterOptions } from '@/constants/SystemConstants';

export default class SessionDetailsStepsFilters {
  @observable searchValue = '';
  @observable searchIpAddress = '';
  @observable statusValues: string[] = [];
  @observable typeValues: string[] = [];
  @observable ipAddresses: string[] = [];
  @observable startDate: Date = new Date();
  @observable endDate: Date = new Date();
  @observable timeRange: string = FilterOptions.ALL;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  reset() {
    this.searchValue = '';
    this.searchIpAddress = '';
    this.statusValues = [];
    this.ipAddresses = [];
    this.typeValues = [];
    this.endDate = new Date();
    this.endDate = new Date();
    this.timeRange = FilterOptions.ALL;
  }
}
