import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { SplitPanelWrapperType } from './SplitPanelTypes';
import SplitPanelViewController from './SplitPanelViewController';
import SplitPanelViewModel from './SplitPanelViewModel';

const SplitPanelFC: React.FC<SplitPanelWrapperType> = (props: SplitPanelWrapperType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { children, config, index, isAllowResize, readingPaneStateHorizonKey, readingPaneStateVerticalKey, split, style } = props;

  const viewModel = new SplitPanelViewModel(rootStore);

  return (
    <SplitPanelViewController
      viewModel={viewModel}
      config={config}
      index={index}
      isAllowResize={isAllowResize}
      split={split}
      style={style}
      readingPaneStateHorizonKey={readingPaneStateHorizonKey}
      readingPaneStateVerticalKey={readingPaneStateVerticalKey}
    >
      {children}
    </SplitPanelViewController>
  );
};

const SplitPanel = observer(SplitPanelFC);

export default SplitPanel;
