import { CancelToken } from 'axios';

import BaseRequestService from './RequestService';

class AssetsRequestService extends BaseRequestService {
  async getAllAssets(cancelToken?: CancelToken): Promise<any[]> {
    const url = 'assets';

    try {
      const response = await this.get(url, null, { cancelToken });

      return response?.data;
    } catch (error) {
      console.error('[AssetsRequestService.getAllAssets] An error has occurred:', error);

      throw error;
    }
  }

  async getContent(assetId: string, contentId: string, cancelToken?: CancelToken): Promise<any> {
    const url = `assets/${assetId}/content/${contentId}`;

    try {
      const response = await this.get(url, null, { cancelToken });

      return response?.data;
    } catch (error) {
      console.error('[AssetsRequestService.getContent] An error has occurred:', error);

      throw error;
    }
  }
}

export const assetsRequestService = new AssetsRequestService();
