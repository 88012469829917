import { action, computed, makeObservable, observable } from 'mobx';
import { t } from 'i18next';
import { inject, singleton } from 'tsyringe';

import { CancellableStatuses } from '@/constants/ExperimentConstants';
import { FilterOptions, Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { ActionTypeNull, NumberTypeAny } from '@/types/AppSettingsTypes';

import { ExperimentType } from './ExperimentsTypes';

@singleton()
class ExperimentStore {
  protected defaultValue = 0;
  protected groupByDefault = t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });

  protected now = new Date();

  public experiments: ExperimentType[] = [];
  public selectedExperiment: ExperimentType | null = null;
  public selectedExperiments: ExperimentType[] = [];
  public pageSize: number;

  public searchValue = '';
  public locationValue = '';
  public statusValues: string[] = [];
  public ipAddressValues: string[] = [];
  public groupByValue: string = this.groupByDefault;
  public lastRunTimeRange = FilterOptions.ALL;
  public startDate: Date = this.now;
  public endDate: Date = this.now;

  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    makeObservable(this, {
      // Observables for Experiments configuration not persisted.
      experiments: observable,
      selectedExperiment: observable,
      selectedExperiments: observable,

      // These values are persisted.
      pageSize: observable,
      searchValue: observable,
      locationValue: observable,
      statusValues: observable,
      ipAddressValues: observable,
      groupByValue: observable,
      startDate: observable,
      endDate: observable,
      lastRunTimeRange: observable,

      // Actions modify the state.
      setExperiments: action,
      setSelectedExperiment: action,
      setSelectedExperiments: action,
      setPageSize: action,
      setSearchValue: action,
      setLocationValue: action,
      setStatusValues: action,
      setIpAddressValues: action,
      setGroupByValue: action,
      setStartDate: action,
      setEndDate: action,
      setLastRunTimeRange: action,
      doReset: action,
      clearSelectedExperiment: action,
      clearSelectedExperiments: action,

      // Set computed properties.
      isAirExperimentSelected: computed,
      isLabsExperimentSelected: computed,
      canCancelSelectedExperiments: computed,
      hasSelectedExperiments: computed,
      hasMultipleSelectedExperiments: computed,
    });

    this.pageSize = this.localStorage.getValue(AppSettingsService.PAGE_SIZE_KEY) || AppSettingsService.DEFAULT_PAGE_SIZE;
    this.searchValue = this.localStorage.getValue(AppSettingsService.FILTER_NAME_SEARCH_KEY) || '';
    this.locationValue = this.localStorage.getValue(AppSettingsService.FILTER_LOCATION_KEY) || '';
    this.statusValues = JSON.parse(this.localStorage.getValue(AppSettingsService.FILTER_STATUS_KEY)) || [];
    this.ipAddressValues = JSON.parse(this.localStorage.getValue(AppSettingsService.FILTER_IPADDRESS_KEY)) || [];
    this.groupByValue = this.localStorage.getValue(AppSettingsService.FILTER_EXPERIMENTS_GROUPBY_KEY) || this.groupByDefault;
    this.startDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_START_DATE_KEY)) || this.now;
    this.endDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_END_DATE_KEY)) || this.now;
    this.lastRunTimeRange = this.localStorage.getValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY) || FilterOptions.ALL;

    this.save();
  }

  public setExperiments = (rows: ExperimentType[] | null): ExperimentType[] | null => {
    this.experiments = rows;

    return this.experiments;
  };

  public setSelectedExperiment = (row: ExperimentType | null): ExperimentType | null => {
    this.selectedExperiment = row;

    return this.selectedExperiment;
  };

  public setSelectedExperiments = (rows: ExperimentType[]): ExperimentType[] => {
    this.selectedExperiments = rows || [];

    return this.selectedExperiments;
  };

  public setPageSize: NumberTypeAny = (value: number) => {
    this.pageSize = value;

    return this.localStorage.setValue(AppSettingsService.PAGE_SIZE_KEY, value);
  };

  public setSearchValue = (value: string) => {
    this.searchValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_NAME_SEARCH_KEY, value);
  };

  public setLocationValue = (value: string) => {
    this.locationValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_LOCATION_KEY, value);
  };

  public setStatusValues = (value: string[]) => {
    this.statusValues = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_STATUS_KEY, JSON.stringify(value));
  };

  public setIpAddressValues = (value: string[]) => {
    this.ipAddressValues = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_IPADDRESS_KEY, JSON.stringify(value));
  };

  public setGroupByValue = (value: string) => {
    this.groupByValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_EXPERIMENTS_GROUPBY_KEY, value);
  };

  public setStartDate = (value: Date) => {
    this.startDate = new Date(value);
    this.localStorage.setValue(AppSettingsService.FILTER_START_DATE_KEY, value);
  };

  public setEndDate = (value: Date) => {
    this.endDate = new Date(value);
    this.localStorage.setValue(AppSettingsService.FILTER_END_DATE_KEY, value);
  };

  public setLastRunTimeRange = (value: string) => {
    this.lastRunTimeRange = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY, value);
  };

  public doReset: ActionTypeNull = () => {
    this.setSelectedExperiment(null);
    this.setSearchValue('');
    this.setStatusValues([]);
    this.setIpAddressValues([]);
    this.setGroupByValue(this.groupByDefault);
    this.setStartDate(this.now);
    this.setEndDate(this.now);
    this.setLastRunTimeRange(FilterOptions.ALL);
    this.save();

    return null;
  };

  protected save: ActionTypeNull = () => {
    this.localStorage.setValue(AppSettingsService.PAGE_SIZE_KEY, this.pageSize);

    this.localStorage.setValue(AppSettingsService.FILTER_NAME_SEARCH_KEY, this.searchValue);
    this.localStorage.setValue(AppSettingsService.FILTER_LOCATION_KEY, this.locationValue);
    this.localStorage.setValue(AppSettingsService.FILTER_STATUS_KEY, JSON.stringify(this.statusValues));
    this.localStorage.setValue(AppSettingsService.FILTER_IPADDRESS_KEY, JSON.stringify(this.ipAddressValues));
    this.localStorage.setValue(AppSettingsService.FILTER_EXPERIMENTS_GROUPBY_KEY, this.groupByValue);
    this.localStorage.setValue(AppSettingsService.FILTER_START_DATE_KEY, this.startDate);
    this.localStorage.setValue(AppSettingsService.FILTER_END_DATE_KEY, this.endDate);
    this.localStorage.setValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY, this.lastRunTimeRange);

    return null;
  };

  public get isAirExperimentSelected(): boolean {
    if (this.selectedExperiments?.length < 1) {
      return false;
    }

    const match = this.selectedExperiments.some((row: ExperimentType) => row.type === SystemType.AIR);

    return match;
  }

  public get isLabsExperimentSelected(): boolean {
    if (this.selectedExperiments?.length < 1) {
      return false;
    }

    const match = this.selectedExperiments.some((row: ExperimentType) => row.type === SystemType.LABS);

    return match;
  }

  public get hasSelectedExperiments(): boolean {
    // This is true is we have a non-zero amount of selected sessions.
    const match = !!this.selectedExperiments?.length || false;

    return match;
  }

  public get hasMultipleSelectedExperiments(): boolean {
    const match = this.selectedExperiments?.length > 1 || false;

    return match;
  }

  public get canCancelSelectedExperiments(): boolean {
    if (!this.hasSelectedExperiments) {
      return false;
    }

    const match = this.selectedExperiments.every((row: ExperimentType) => {
      return row.type !== SystemType.AIR && CancellableStatuses.includes(row.status.toLowerCase());
    });

    return match;
  }

  public clearSelectedExperiment = (): void => {
    // Clear only the selected Experiment.
    this.setSelectedExperiment(null);

    return;
  };

  public clearSelectedExperiments = (): void => {
    // Clear both the selected experiment objects.
    this.setSelectedExperiment(null);
    this.setSelectedExperiments([]);

    return;
  };
}

export default ExperimentStore;
