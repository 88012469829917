import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { PartialTheme } from '@fluentui/react/lib/Theme';
import { t } from 'i18next';
import format from 'string-template';
import { inject, singleton } from 'tsyringe';

import { LayoutItemType, ReactGridDropItemType, SetDropItemType } from '@/components/ReactGrid/ReactGridTypes';
import { Navigation } from '@/constants/NavigationConstants';
import {
  Namespaces as NS,
  PartnerDomains,
  PortalAliases,
  ReadingPaneCssKeys,
  ReadingPanePositionType,
  SystemConstants,
} from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import { AIR_LINKS, DEFAULT_PORTAL_NAVLINKS, DEVELOPER_LINKS, LABS_LINKS, PARTNER_LINKS, ROOT_LINKS } from '@/routes/RouteLinks';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import SystemMessageStore from '@/stores/SystemMessageStore';
import {
  ActionTypeBoolean,
  ActionTypeNull,
  ActionTypeVoid,
  ActionTypeWithTParam,
  BooleanTypeAny,
  GroupByColumnType,
} from '@/types/AppSettingsTypes';
import { TabMemoryType } from '@/types/AppSettingsTypes';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { WidgetMappingType } from '@/mocks/Dashboards/LayoutWidgets';

import packageJson from '../../package.json';

@singleton()
class AppSettingsStore {
  // MobX Observable Boolean Declarations
  public hasUserSelectedPartnerMode: boolean;
  public isAdvancedMode: boolean;
  public isAirHidden: boolean;
  public isCompressedMode: boolean;
  public isConsoleLogging: boolean;
  public isCopilotOpen: boolean;
  public isDarkMode: boolean;
  public isDebugMode: boolean;
  public isDeveloperMode: boolean;
  public isEditMode: boolean;
  public isLabsHidden: boolean;
  public isLegacyMode: boolean;
  public isMockDataMode: boolean;
  public isOfflineMode: boolean;
  public isOutlookMode: boolean;
  public isPageLoading: boolean;
  public isPartnerMode: boolean;
  public isSettingsOpen: boolean;
  public isSidebarCollapsed: boolean;

  // Other MobX Observable Declarations
  public consoleSeverityLevel: number;
  public droppingItem: ReactGridDropItemType | undefined;
  public enableAirNotificationHub: boolean;
  public layout: LayoutItemType[];
  public readingPanePosition: ReadingPanePositionType;
  public telemetrySeverityLevel: number;
  public v8theme: PartialTheme;
  public v9theme: PartialTheme;
  public widgetMappings: WidgetMappingType;

  // Observables used in Result Explorer & Experiments
  public previewFilePath: string;
  public previewFileName: string;
  public filePreviewContent: any;
  public fileContentLoading: boolean;
  public isLogError: boolean;

  // MobX Action
  public groupByColumn: GroupByColumnType;
  public portal: string;
  public portalLinks: NavLinkItemType[];
  public tabMemory: TabMemoryType;
  public version: string;

  constructor(
    @inject(LocalStorageService) protected localStorage: LocalStorageService,
    @inject(SystemMessageStore) public systemMessageStore: SystemMessageStore,
  ) {
    makeObservable(this, {
      // Make the Message Store observable.
      systemMessageStore: observable,

      // Observables fields that stores state.
      layout: observable,
      droppingItem: observable,
      enableAirNotificationHub: observable,
      readingPanePosition: observable,
      tabMemory: observable,
      widgetMappings: observable,
      v8theme: observable,
      v9theme: observable,
      groupByColumn: observable,

      // Observables for boolean user configuration.
      hasUserSelectedPartnerMode: observable,
      isAdvancedMode: observable,
      isAirHidden: observable,
      isCompressedMode: observable,
      isConsoleLogging: observable,
      isCopilotOpen: observable,
      isDarkMode: observable,
      isDebugMode: observable,
      isDeveloperMode: observable,
      isEditMode: observable,
      isLabsHidden: observable,
      isLegacyMode: observable,
      isMockDataMode: observable,
      isOfflineMode: observable,
      isOutlookMode: observable,
      isPageLoading: observable,
      isPartnerMode: observable,
      isSettingsOpen: observable,
      isSidebarCollapsed: observable,

      // Observables for system-wide variables.
      consoleSeverityLevel: observable,
      portal: observable,
      portalLinks: observable,
      version: observable,
      telemetrySeverityLevel: observable,

      // Used in Result Explorer & Experiments
      filePreviewContent: observable,
      fileContentLoading: observable,
      isLogError: observable, // Result Explorer & Experiments

      // Actions modify the state.
      addWidgetMapping: action,
      clearDroppingItem: action,
      closeSettings: action,
      deleteWidgetMapping: action,
      doDashboardReset: action,
      doReset: action,

      // Boolean action toggles.
      toggleAdvancedMode: action,
      toggleAirHidden: action,
      toggleAirNotificationHub: action,
      toggleCompressedMode: action,
      toggleConsoleLogging: action,
      toggleCopilotOpen: action,
      toggleDarkMode: action,
      toggleDebugMode: action,
      toggleDeveloperMode: action,
      toggleEditMode: action,
      toggleLabsHidden: action,
      toggleLegacyMode: action,
      toggleMockDataMode: action,
      toggleOfflineMode: action,
      toggleOutlookMode: action,
      togglePageLoading: action,
      togglePartnerMode: action,
      toggleSettingsOpen: action,
      toggleSidebarCollapsed: action,

      // Boolean action setters.
      setAdvancedMode: action,
      setAirHidden: action,
      setAirNotificationHub: action,
      setCompressedMode: action,
      setConsoleLogging: action,
      setCopilotOpen: action,
      setDarkMode: action,
      setDebugMode: action,
      setDeveloperMode: action,
      setEditMode: action,
      setLabsHidden: action,
      setLegacyMode: action,
      setMockDataMode: action,
      setOfflineMode: action,
      setOutlookMode: action,
      setPageLoading: action,
      setPartnerMode: action,
      setSettingsOpen: action,
      setSidebarCollapsed: action,

      // Other Application-Wide Setters
      setGroupByColumn: action,
      setDroppingItem: action,
      setFileContentLoading: action,
      setFilePreviewContent: action,
      setIsLogError: action,
      setLayout: action,
      setPortal: action,
      setPortalLinks: action,
      setPreviewFileName: action,
      setPreviewFilePath: action,
      setPreviewPanePosition: action,
      setTabMemory: action,
      setVersion: action,
      setV8Theme: action,
      setV9Theme: action,
      setWidgetMappings: action,

      // Computed values that derive information from other observables.
      isAirPortal: computed,
      isGanymedePortal: computed,
      isLabsPortal: computed,
      isReadingPaneBottom: computed,
      isReadingPaneHidden: computed,
      isReadingPaneRight: computed,
      isReadingPaneVisible: computed,
      readingPaneKey: computed,

      // Computed values used for messaging and debugging purposes.
      advancedModeMessage: computed,
      compressedModeMessage: computed,
      copilotModeMessage: computed,
      darkModeMessage: computed,
      debugModeMessage: computed,
      developerModeMessage: computed,
      editModeMessage: computed,
      mockDataMessage: computed,
      legacyModeMessage: computed,
      offlineModeMessage: computed,
      outlookModeMessage: computed,
      pageLoadingMessage: computed,
      settingsModeMessage: computed,
    });

    // Load saved preset values from LocalStorage. Default values are provided for first-hit scenarios.
    // When creating new system-wide variables, it is essential to add them here to load correctly.
    this.droppingItem = undefined;
    this.enableAirNotificationHub =
      this.localStorage.getValue(AppSettingsService.AIR_NOTIFICATIONS_STATE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;

    this.groupByColumn =
      this.localStorage.getValue(AppSettingsService.GROUPBY_COLUMN_KEY) || AppSettingsService.DEFAULT_GROUP_BY_COLUMN_VALUE;
    this.hasUserSelectedPartnerMode =
      this.localStorage.getValue(AppSettingsService.USER_SELECTED_PARTNER_MODE) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isAdvancedMode =
      this.localStorage.getValue(AppSettingsService.ADVANCED_MODE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isAirHidden = this.localStorage.getValue(AppSettingsService.AIR_HIDDEN_KEY) ?? AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isCompressedMode =
      this.localStorage.getValue(AppSettingsService.COMPRESSED_MODE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isConsoleLogging =
      this.localStorage.getValue(AppSettingsService.CONSOLE_LOGGING_KEY) ?? AppSettingsService.DEFAULT_CONSOLE_LOGGING_VALUE;
    this.isCopilotOpen = this.localStorage.getValue(AppSettingsService.COPILOT_OPEN_KEY) ?? AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isDarkMode = this.localStorage.getValue(AppSettingsService.DARK_MODE_KEY) ?? AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isDebugMode = this.localStorage.getValue(AppSettingsService.DEBUG_MODE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isDeveloperMode =
      this.localStorage.getValue(AppSettingsService.DEVELOPER_MODE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isEditMode = this.localStorage.getValue(AppSettingsService.EDIT_MODE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isLabsHidden = this.localStorage.getValue(AppSettingsService.LABS_HIDDEN_KEY) ?? AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isLegacyMode = this.localStorage.getValue(AppSettingsService.LEGACY_MODE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isMockDataMode = this.localStorage.getValue(AppSettingsService.MOCK_DATA_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isOfflineMode = this.localStorage.getValue(AppSettingsService.OFFLINE_MODE_KEY) || AppSettingsService.DEFAULT_TRUE_VALUE;
    this.isOutlookMode = this.localStorage.getValue(AppSettingsService.OUTLOOK_MODE_KEY) || AppSettingsService.DEFAULT_TRUE_VALUE;
    this.isPageLoading = this.localStorage.getValue(AppSettingsService.PAGE_LOADING_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isPartnerMode = true; // Special case where we are not persisting.
    this.isSettingsOpen =
      this.localStorage.getValue(AppSettingsService.SETTINGS_OPEN_KEY) ?? AppSettingsService.DEFAULT_FALSE_VALUE;
    this.isSidebarCollapsed =
      this.localStorage.getValue(AppSettingsService.SIDEBAR_STATE_KEY) || AppSettingsService.DEFAULT_FALSE_VALUE;

    this.layout = this.localStorage.getValue(AppSettingsService.LAYOUT_KEY) || AppSettingsService.DEFAULT_LAYOUT_VALUE;
    this.portal = this.localStorage.getValue(AppSettingsService.PORTAL_KEY) ?? AppSettingsService.DEFAULT_PORTAL_VALUE;
    this.portalLinks = DEFAULT_PORTAL_NAVLINKS;
    this.readingPanePosition =
      this.localStorage.getValue(AppSettingsService.READING_PANE_POSITION_KEY) || AppSettingsService.DEFAULT_READING_PAGE_POSITION;
    this.tabMemory = this.localStorage.getValue(AppSettingsService.TAB_MEMORY_KEY) || AppSettingsService.DEFAULT_TAB_MEMORY_VALUE;
    this.version = this.localStorage.getValue(AppSettingsService.VERSION_KEY) ?? '';
    this.widgetMappings =
      this.localStorage.getValue(AppSettingsService.WIDGET_MAPPINGS_KEY) || AppSettingsService.DEFAULT_WIDGET_MAPPINGS_VALUE;

    this.consoleSeverityLevel =
      this.localStorage.getValue(AppSettingsService.CONSOLE_SEVERITY_LEVEL_KEY) ??
      AppSettingsService.DEFAULT_CONSOLE_SEVERITY_LEVEL_VALUE;
    this.telemetrySeverityLevel =
      this.localStorage.getValue(AppSettingsService.TELEMETRY_SEVERITY_LEVEL_KEY) ??
      AppSettingsService.DEFAULT_TELEMETRY_SEVERITY_LEVEL_VALUE;

    // TODO: Check if user is authenticated for Labs
    // if user is authenticated to view Labs pages,
    // this.portalLinks[PortalAliases.VEGA] = VEGA_LINKS;

    this.save(); // We shall persist for first-hit scenarios.
  }

  public checkIsPartnerMode = (userName: string): boolean => {
    return userName?.split('@').pop().includes(PartnerDomains.MICROSOFT) ? false : true;
  };

  public updateNavigationLinks = (items: NavLinkItemType[], links: NavLinkItemType[]): NavLinkItemType[] => {
    items.forEach((item: { key: string; position: number }) => {
      const index = links.findIndex((link: NavLinkItemType) => link.key === item.key);

      // If condition is true, add the object to the array at specified position.
      // Otherwise, remove it from the item if it is already in there.
      if (index === -1) {
        links.splice(item.position, 0, { key: item.key });
      } else if (index > -1) {
        links.splice(index, 1);
      }
    });

    return links;
  };

  public updateLabsLinks = (isMicrosoftTenant: boolean, isPartnerMode: boolean): NavLinkItemType[] => {
    // Only Microsoft Tenants can see AIR pages. Partners cannot see AIR pages.
    const items: NavLinkItemType[] = [...ROOT_LINKS];

    if (isMicrosoftTenant && !isPartnerMode) {
      items.push(...LABS_LINKS);
      items.push(...AIR_LINKS);
    } else if (isPartnerMode) {
      items.push(...PARTNER_LINKS);
    }

    if (this.isDeveloperMode) {
      const index = items.findIndex((link: NavLinkItemType) => link.key === Navigation.LABS.MANAGE_PACKAGES);

      if (index > -1) {
        items.splice(index, 0, DEVELOPER_LINKS[2]);
      } else {
        items.push(DEVELOPER_LINKS[2]);
      }
    }

    const links = this.updateLegacyLinks(items);

    return links;
  };

  public updateLegacyLinks = (links: NavLinkItemType[]): NavLinkItemType[] => {
    if (this.isLegacyMode) {
      const index = links.findIndex((link: NavLinkItemType) => link.key === Navigation.GANYMEDE.EXPERIMENTS);

      if (index === -1) {
        links.splice(2, 0, DEVELOPER_LINKS[1]);
      }
    } else {
      const index = links.findIndex((link: NavLinkItemType) => link.key === Navigation.GANYMEDE.EXPERIMENTS);

      if (index > -1) {
        links.splice(index, 1);
      }
    }

    return links;
  };

  // Boolean Toggle implementations.

  public toggleAdvancedMode: ActionTypeBoolean = (): boolean => {
    this.isAdvancedMode = !this.isAdvancedMode;
    this.save();

    return this.isAdvancedMode;
  };

  public toggleAirHidden: ActionTypeBoolean = (): boolean => {
    this.isAirHidden = !this.isAirHidden;
    this.save();

    return this.isAirHidden;
  };

  public toggleAirNotificationHub: ActionTypeBoolean = (): boolean => {
    this.enableAirNotificationHub = !this.enableAirNotificationHub;
    this.save();

    return this.enableAirNotificationHub;
  };

  public toggleCompressedMode: ActionTypeBoolean = (): boolean => {
    this.isCompressedMode = !this.isCompressedMode;
    this.save();

    return this.isCompressedMode;
  };

  public toggleConsoleLogging: ActionTypeBoolean = (): boolean => {
    this.isConsoleLogging = !this.isConsoleLogging;
    this.save();

    return this.isConsoleLogging;
  };

  public toggleCopilotOpen: ActionTypeBoolean = (): boolean => {
    this.isCopilotOpen = !this.isCopilotOpen;
    this.save();

    return this.isCopilotOpen;
  };

  public toggleDarkMode: ActionTypeBoolean = (): boolean => {
    this.isDarkMode = !this.isDarkMode;
    this.save();

    return this.isDarkMode;
  };

  public toggleDebugMode: ActionTypeBoolean = (): boolean => {
    this.isDebugMode = !this.isDebugMode;
    this.save();

    return this.isDebugMode;
  };

  public toggleDeveloperMode: ActionTypeBoolean = (): boolean => {
    // NOTE: This method has a special case where we update the navigation links.
    this.isDeveloperMode = !this.isDeveloperMode;
    const links = this.portalLinks;

    if (this.isDeveloperMode) {
      const index = links.findIndex((link: NavLinkItemType) => link.key === Navigation.LABS.MANAGE_PACKAGES);

      if (index > -1) {
        links.splice(index, 0, DEVELOPER_LINKS[2]);
      } else {
        links.push(DEVELOPER_LINKS[2]);
      }
    } else {
      const index = links.findIndex((link: NavLinkItemType) => link.key === Navigation.GANYMEDE.ASSET_MANAGEMENT);

      if (index > -1) {
        links.splice(index, 1);
      }
    }

    this.setPortalLinks(links);
    this.save();

    return this.isDeveloperMode;
  };

  public toggleEditMode: ActionTypeBoolean = (): boolean => {
    this.isEditMode = !this.isEditMode;
    this.save();

    return this.isEditMode;
  };

  public toggleLabsHidden: ActionTypeBoolean = (): boolean => {
    this.isLabsHidden = !this.isLabsHidden;
    this.save();

    return this.isLabsHidden;
  };

  public toggleLegacyMode: ActionTypeBoolean = (): boolean => {
    this.isLegacyMode = !this.isLegacyMode;

    this.updateLegacyLinks(this.portalLinks);
    this.save();

    return this.isLegacyMode;
  };

  public toggleMockDataMode: ActionTypeBoolean = (): boolean => {
    this.isMockDataMode = !this.isMockDataMode;
    this.save();

    return this.isMockDataMode;
  };

  public toggleOfflineMode: ActionTypeBoolean = (): boolean => {
    this.isOfflineMode = !this.isOfflineMode;
    this.save();

    return this.isOfflineMode;
  };

  public toggleOutlookMode: ActionTypeBoolean = (): boolean => {
    this.isOutlookMode = !this.isOutlookMode;
    this.save();

    return this.isOutlookMode;
  };

  public togglePageLoading: ActionTypeBoolean = (): boolean => {
    this.isPageLoading = !this.isPageLoading;
    this.save();

    return this.isPageLoading;
  };

  public togglePartnerMode: ActionTypeBoolean = (): boolean => {
    this.hasUserSelectedPartnerMode = !this.hasUserSelectedPartnerMode;
    this.save();

    return this.hasUserSelectedPartnerMode;
  };

  public toggleSettingsOpen: ActionTypeBoolean = (): boolean => {
    this.isSettingsOpen = !this.isSettingsOpen;
    this.save();

    return this.isSettingsOpen;
  };

  public toggleSidebarCollapsed: ActionTypeBoolean = (): boolean => {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.save();

    return this.isSidebarCollapsed;
  };

  // Boolean Setter implementations.

  public setAdvancedMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isAdvancedMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.ADVANCED_MODE_KEY, value);
  };

  public setAirHidden: BooleanTypeAny = (value: boolean): boolean => {
    this.isAirHidden = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.AIR_HIDDEN_KEY, value);
  };

  public setAirNotificationHub: BooleanTypeAny = (value: boolean): boolean => {
    this.enableAirNotificationHub = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.AIR_NOTIFICATIONS_STATE_KEY, value);
  };

  public setCompressedMode: BooleanTypeAny = (value: boolean) => {
    this.isCompressedMode = value;
  };

  public setConsoleLogging: BooleanTypeAny = (value: boolean): boolean => {
    this.isConsoleLogging = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.CONSOLE_LOGGING_KEY, value);
  };

  public setCopilotOpen: BooleanTypeAny = (value: boolean): boolean => {
    this.isCopilotOpen = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.COPILOT_OPEN_KEY, value);
  };

  public setDarkMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isDarkMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.DARK_MODE_KEY, value);
  };

  public setDebugMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isDebugMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.DEBUG_MODE_KEY, value);
  };

  public setDeveloperMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isDeveloperMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.DEVELOPER_MODE_KEY, value);
  };

  public setEditMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isEditMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.EDIT_MODE_KEY, value);
  };

  public setLabsHidden: BooleanTypeAny = (value: boolean): boolean => {
    this.isLabsHidden = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.LABS_HIDDEN_KEY, value);
  };

  public setLegacyMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isLegacyMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.LEGACY_MODE_KEY, value);
  };

  public setMockDataMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isMockDataMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.MOCK_DATA_KEY, value);
  };

  public setOfflineMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isOfflineMode = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.OFFLINE_MODE_KEY, value);
  };

  public setOutlookMode: BooleanTypeAny = (value: boolean): boolean => {
    this.isOutlookMode = value;

    return this.localStorage.setValue(AppSettingsService.OUTLOOK_MODE_KEY, value);
  };

  public setPageLoading: BooleanTypeAny = (value: boolean): boolean => {
    this.isPageLoading = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.PAGE_LOADING_KEY, value);
  };

  public setPartnerMode = (value: boolean): void => {
    this.isPartnerMode = value; // Since this is something decide when the user logs in do not cache the value in storage

    return;
  };

  public setSettingsOpen: BooleanTypeAny = (value: boolean): boolean => {
    this.isSettingsOpen = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.SETTINGS_OPEN_KEY, value);
  };

  public setSidebarCollapsed: BooleanTypeAny = (value: boolean): boolean => {
    this.isSidebarCollapsed = value;

    return this.localStorage.setValue(AppSettingsService.SIDEBAR_STATE_KEY, value);
  };

  // Other Setter implementations.

  public setConsoleSeverityLevel: ActionTypeWithTParam<number> = (value: number) => {
    this.consoleSeverityLevel = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.CONSOLE_SEVERITY_LEVEL_KEY, value);
  };

  public setFilePreviewContent = (value: any) => {
    this.filePreviewContent = value;
  };

  public setFileContentLoading = (value: boolean) => {
    this.fileContentLoading = value;
  };

  public setGroupByColumn = (page: string, groupByColumn: string): void => {
    this.groupByColumn[page as string] = groupByColumn;
    this.localStorage.setValue(AppSettingsService.GROUPBY_COLUMN_KEY, this.groupByColumn);
  };

  public setIsLogError = (value: boolean) => {
    this.isLogError = value;
  };

  public setLayout = (value: LayoutItemType[]) => {
    // NOTE: If we start seeing a flashing effect in the grid, the Dashboard will need to
    // rethink how layout is updated/modified/stored during user usage.
    this.layout = value;

    return this.localStorage.setValue(AppSettingsService.LAYOUT_KEY, value);
  };

  public setPortal = (value: string): string => {
    const portals = SystemConstants.ALIAS_LIST; // All allowed portal aliases.

    if (portals.includes(value.toLowerCase())) {
      this.portal = value.toLowerCase();
    } else {
      this.portal = SystemConstants.DEFAULT_PORTAL; // Default to the first portal value.
    }

    return this.localStorage.setValue(AppSettingsService.PORTAL_KEY, value);
  };

  public setPortalLinks = (links: NavLinkItemType[]): NavLinkItemType[] => {
    this.portalLinks = links;

    return this.portalLinks;
  };

  // Used in Result Explorer & Experiments
  public setPreviewFilePath = (value: string) => {
    this.previewFilePath = value;
  };

  // Used in Result Explorer & Experiments
  public setPreviewFileName = (value: string) => {
    this.previewFileName = value;
  };

  // This does not need to be set as an Action since it does not modify an observable value.
  public setPreviewPaneBottom = (): ReadingPanePositionType => {
    return this.setPreviewPanePosition(ReadingPanePositionType.BOTTOM);
  };

  public setPreviewPanePosition = (value: ReadingPanePositionType): ReadingPanePositionType => {
    this.readingPanePosition = value;

    return this.localStorage.setValue(AppSettingsService.READING_PANE_POSITION_KEY, this.readingPanePosition);
  };

  // This does not need to be set as an Action since it does not modify an observable value.
  public setPreviewPaneRight = (): ReadingPanePositionType => {
    return this.setPreviewPanePosition(ReadingPanePositionType.RIGHT);
  };

  public setTabMemory = (page: string, currentActiveTab: string): void => {
    this.tabMemory[page as string] = currentActiveTab;
    this.localStorage.setValue(AppSettingsService.TAB_MEMORY_KEY, this.tabMemory);

    return;
  };

  public setTelemetrySeverityLevel: ActionTypeWithTParam<number> = (value: number) => {
    this.telemetrySeverityLevel = value; // NOTE: Do not remove yet.

    return this.localStorage.setValue(AppSettingsService.TELEMETRY_SEVERITY_LEVEL_KEY, value);
  };

  public setVersion = (value: string): string => {
    this.version = value;

    return this.localStorage.setValue(AppSettingsService.VERSION_KEY, value);
  };

  public setV8Theme = (v8theme: PartialTheme): PartialTheme => {
    this.v8theme = v8theme;
    this.save();

    return this.v8theme;
  };

  public setV9Theme = (v9theme: PartialTheme): PartialTheme => {
    this.v9theme = v9theme;
    this.save();

    return this.v9theme;
  };

  public addWidgetMapping: (value: WidgetMappingType) => WidgetMappingType = (value: WidgetMappingType) => {
    const newWidgetMappings: WidgetMappingType = { ...this.widgetMappings, ...value };

    this.widgetMappings = newWidgetMappings;
    this.localStorage.setValue(AppSettingsService.WIDGET_MAPPINGS_KEY, newWidgetMappings);

    return newWidgetMappings;
  };

  public deleteWidgetMapping: (value: string) => WidgetMappingType = (value: string) => {
    const newWidgetMappings: WidgetMappingType = { ...this.widgetMappings };

    delete newWidgetMappings[value as string];

    this.widgetMappings = newWidgetMappings;
    this.localStorage.setValue(AppSettingsService.WIDGET_MAPPINGS_KEY, newWidgetMappings);

    return newWidgetMappings;
  };

  public setWidgetMappings = (value: WidgetMappingType) => {
    this.widgetMappings = value;

    return this.localStorage.setValue(AppSettingsService.WIDGET_MAPPINGS_KEY, value);
  };

  public setDroppingItem: SetDropItemType = (value: ReactGridDropItemType) => {
    this.droppingItem = toJS(value);
  };

  public closeSettings = () => {
    this.setSettingsOpen(false);
    this.save();

    return;
  };

  public clearDroppingItem: ActionTypeNull = () => {
    this.droppingItem = undefined;

    return null;
  };

  public checkVersion = (): void => {
    const oldVersion = this.version;
    const currentVersion = packageJson.version;

    // If the user has a version stored in local storage which matches package.json, no need to reset.
    if (oldVersion && oldVersion === currentVersion) {
      return;
    }

    const basicMessage: SystemMessageType = {
      mode: MessageBarMode.rememberState,
      type: FluentMessageBarType.success,
      id: `check-version-${currentVersion}`,
      persistent: true,
      message: '',
    };

    let message = '';

    // If the user does not have a version stored in local storage, do a reset.
    if (!oldVersion) {
      message = format(t('reset-to-version', { ns: NS.TEMPLATES }), {
        version: currentVersion,
      });
    }

    // If the user version does not match package.json, do a reset.
    else {
      message = format(t('reset-from-to-version', { ns: NS.TEMPLATES }), {
        from: oldVersion,
        to: currentVersion,
      });
    }

    const globalMessage = { ...basicMessage, message };

    console.warn(message);
    this.doReset();

    // Only show the message in the message bar if in debug, advanced, or developer mode.
    if (this.isDebugMode || this.isAdvancedMode || this.isDeveloperMode) {
      this.systemMessageStore.addGlobalMessage(globalMessage);
    }
  };

  // This Reset operation is called in several scenarios:
  // - when a version change is detected
  // - when cookies have been erased/cleared
  public doReset: ActionTypeVoid = (): void => {
    this.droppingItem = undefined;
    this.layout = this.setLayout(AppSettingsService.DEFAULT_LAYOUT_VALUE);
    this.localStorage.setValue(AppSettingsService.MESSAGEBAR_SEEN_KEY, ''); // We need to reset any seen MessageBars.
    this.widgetMappings = this.setWidgetMappings(AppSettingsService.DEFAULT_WIDGET_MAPPINGS_VALUE);
    this.setPortal(AppSettingsService.DEFAULT_PORTAL_VALUE);
    this.setPortalLinks(DEFAULT_PORTAL_NAVLINKS);
    this.setPreviewPanePosition(ReadingPanePositionType.BOTTOM);
    this.setVersion(packageJson.version);

    // Reset all system-wide boolean actions.
    this.setAdvancedMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setAirHidden(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setAirNotificationHub(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setCompressedMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setCopilotOpen(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setDarkMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setDebugMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setDeveloperMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setEditMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setLabsHidden(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setLegacyMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setMockDataMode(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setOfflineMode(AppSettingsService.DEFAULT_TRUE_VALUE);
    this.setOutlookMode(AppSettingsService.DEFAULT_TRUE_VALUE);
    this.setPageLoading(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setSettingsOpen(AppSettingsService.DEFAULT_FALSE_VALUE);

    this.save();

    return;
  };

  // This Dashboard reset operation is a subset of a full reset operation.
  public doDashboardReset: ActionTypeVoid = (): void => {
    this.layout = this.setLayout(AppSettingsService.DEFAULT_LAYOUT_VALUE);
    this.widgetMappings = this.setWidgetMappings(AppSettingsService.DEFAULT_WIDGET_MAPPINGS_VALUE);
    this.droppingItem = undefined;
    this.setPageLoading(AppSettingsService.DEFAULT_FALSE_VALUE);
    this.setSettingsOpen(AppSettingsService.DEFAULT_FALSE_VALUE);

    this.save();

    return;
  };

  protected save: ActionTypeVoid = (): void => {
    // Save all system-wide boolean actions to LocalStorage.
    this.localStorage.setValue(AppSettingsService.ADVANCED_MODE_KEY, this.isAdvancedMode);
    this.localStorage.setValue(AppSettingsService.AIR_HIDDEN_KEY, this.isAirHidden);
    this.localStorage.setValue(AppSettingsService.AIR_NOTIFICATIONS_STATE_KEY, this.enableAirNotificationHub);
    this.localStorage.setValue(AppSettingsService.COMPRESSED_MODE_KEY, this.isCompressedMode);
    this.localStorage.setValue(AppSettingsService.COPILOT_OPEN_KEY, this.isCopilotOpen);
    this.localStorage.setValue(AppSettingsService.DARK_MODE_KEY, this.isDarkMode);
    this.localStorage.setValue(AppSettingsService.DEBUG_MODE_KEY, this.isDebugMode);
    this.localStorage.setValue(AppSettingsService.DEVELOPER_MODE_KEY, this.isDeveloperMode);
    this.localStorage.setValue(AppSettingsService.EDIT_MODE_KEY, this.isEditMode);
    this.localStorage.setValue(AppSettingsService.LABS_HIDDEN_KEY, this.isLabsHidden);
    this.localStorage.setValue(AppSettingsService.LEGACY_MODE_KEY, this.isLegacyMode);
    this.localStorage.setValue(AppSettingsService.MOCK_DATA_KEY, this.isMockDataMode);
    this.localStorage.setValue(AppSettingsService.OFFLINE_MODE_KEY, this.isOfflineMode);
    this.localStorage.setValue(AppSettingsService.OUTLOOK_MODE_KEY, this.isOutlookMode);
    this.localStorage.setValue(AppSettingsService.PAGE_LOADING_KEY, this.isPageLoading);
    this.localStorage.setValue(AppSettingsService.SETTINGS_OPEN_KEY, this.isSettingsOpen);
    this.localStorage.setValue(AppSettingsService.SIDEBAR_STATE_KEY, this.isSidebarCollapsed);
    this.localStorage.setValue(AppSettingsService.USER_SELECTED_PARTNER_MODE, this.hasUserSelectedPartnerMode);

    // Save all other system-wide variables to LocalStorage.
    this.localStorage.setValue(AppSettingsService.LAYOUT_KEY, this.layout);
    this.localStorage.setValue(AppSettingsService.PORTAL_KEY, this.portal);
    this.localStorage.setValue(AppSettingsService.WIDGET_MAPPINGS_KEY, this.widgetMappings);

    return;
  };

  // Computed value implementations.

  public get isAirPortal(): boolean {
    return this.portal === PortalAliases.AIR;
  }

  public get isGanymedePortal(): boolean {
    return this.portal === PortalAliases.GANYMEDE;
  }

  public get isLabsPortal(): boolean {
    return this.portal === PortalAliases.LABS;
  }

  public get isReadingPaneBottom(): boolean {
    return this.readingPanePosition === ReadingPanePositionType.BOTTOM;
  }

  public get isReadingPaneHidden(): boolean {
    return this.readingPanePosition === ReadingPanePositionType.HIDE;
  }

  public get isReadingPaneRight(): boolean {
    return this.readingPanePosition === ReadingPanePositionType.RIGHT;
  }

  public get isReadingPaneVisible(): boolean {
    return this.readingPanePosition !== ReadingPanePositionType.HIDE;
  }

  // Computed value messaging implementations.

  public get advancedModeMessage(): string {
    return `ADV: ${this.isAdvancedMode ? 'ON' : 'OFF'}`;
  }

  public get compressedModeMessage(): string {
    return `CMP: ${this.isCompressedMode ? 'ON' : 'OFF'}`;
  }

  public get copilotModeMessage(): string {
    return `COP: ${this.isCopilotOpen ? 'ON' : 'OFF'}`;
  }

  public get darkModeMessage(): string {
    return `DRK: ${this.isDarkMode ? 'ON' : 'OFF'}`;
  }

  public get debugModeMessage(): string {
    return `DBG: ${this.isDebugMode ? 'ON' : 'OFF'}`;
  }

  public get developerModeMessage(): string {
    return `DEV: ${this.isDeveloperMode ? 'ON' : 'OFF'}`;
  }

  public get editModeMessage(): string {
    return `EDT: ${this.isEditMode ? 'ON' : 'OFF'}`;
  }

  public get legacyModeMessage(): string {
    return `LEG: ${this.isLegacyMode ? 'ON' : 'OFF'}`;
  }

  public get mockDataMessage(): string {
    return `DEV: ${this.isMockDataMode ? 'ON' : 'OFF'}`;
  }

  public get offlineModeMessage(): string {
    return `OFF: ${this.isOfflineMode ? 'ON' : 'OFF'}`;
  }

  public get outlookModeMessage(): string {
    return `OUT: ${this.isOutlookMode ? 'ON' : 'OFF'}`;
  }

  public get pageLoadingMessage(): string {
    return `LOAD: ${this.isPageLoading ? 'ON' : 'OFF'}`;
  }

  public get settingsModeMessage(): string {
    return `SET: ${this.isSettingsOpen ? 'ON' : 'OFF'}`;
  }

  public get readingPaneKey(): string {
    if (this.isReadingPaneHidden) {
      return ReadingPaneCssKeys.HIDDEN;
    }

    const match = this.isReadingPaneRight ? ReadingPaneCssKeys.RIGHT : ReadingPaneCssKeys.BOTTOM;

    return match;
  }
}

export default AppSettingsStore;
