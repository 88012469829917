import { action, makeObservable, observable } from 'mobx';
import { inject, singleton } from 'tsyringe';

import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';

@singleton()
class ColumnEditorStore {
  constructor(@inject(LocalStorageService) protected localStorage: LocalStorageService) {
    makeObservable(this, {
      // Observables.
      editorColumns: observable, // DEPRECATE
      getEditorColumns: observable,

      // Actions modify the state.
      setEditorColumns: action,
    });
  }

  // This is to be deprecated as we have getEditorColumns which returns an array of strings.
  public editorColumns = (key: string): string => {
    const columns: string = this.localStorage.getValue(AppSettingsService[key as string]);

    return columns;
  };

  public getEditorColumns = (key: string): string[] => {
    const columns: string = this.editorColumns(key);
    const columnsList: string[] = JSON.parse(columns);

    return columnsList;
  };

  public setEditorColumns = (key: string, value: string[]): string => {
    this.editorColumns(key);

    return this.localStorage.setValue(AppSettingsService[key as string], JSON.stringify(value));
  };
}

export default ColumnEditorStore;
