import { action, makeObservable, observable } from 'mobx';
import { singleton } from 'tsyringe';

@singleton()
class LogsViewPanelStore {
  public isLogsViewPanelOpen: boolean;

  constructor() {
    this.isLogsViewPanelOpen = false;

    makeObservable(this, {
      openLogsViewPanel: action,
      closeLogsViewPanel: action,
      isLogsViewPanelOpen: observable,
    });
  }

  public openLogsViewPanel = () => {
    this.setIsLogsViewPanelOpen(true);
  };

  public closeLogsViewPanel = () => {
    this.setIsLogsViewPanelOpen(false);
  };

  public setIsLogsViewPanelOpen = (open: boolean) => {
    this.isLogsViewPanelOpen = open;
  };
}

export default LogsViewPanelStore;
