import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pivot, PivotItem } from '@fluentui/react';

import { PageMemoryKeys } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from './LabSystemDetails.module.css';

const LabSystemDetailsTemplateFC = () => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labSystemDetailsStore, labSystemsStore } = rootStore;
  const { setTabMemory, tabMemory } = appSettingsStore;
  const { components } = labSystemDetailsStore;
  const { selectedSystemTitle } = labSystemsStore;

  // Other Const
  const pageName = PageMemoryKeys.SYSTEM_DETAILS;
  const activeTab: string = tabMemory[pageName as string];

  return (
    <div className={styles['modal-body-content']}>
      <div className={`${styles['header-row']}`} title={selectedSystemTitle}>
        <div className={styles['title-text']}>
          <div className={styles['title-truncate']}>{selectedSystemTitle}</div>
        </div>
      </div>
      <Pivot defaultSelectedKey={activeTab} onLinkClick={(item) => setTabMemory(pageName, item.props.itemKey)}>
        {components.map(({ headerText, viewModel, component: Component }, index) => (
          <PivotItem key={index} itemKey={headerText} headerText={headerText}>
            <Component viewModel={viewModel} />
          </PivotItem>
        ))}
      </Pivot>
    </div>
  );
};

export const LabSystemDetailsTemplate = observer(LabSystemDetailsTemplateFC);
