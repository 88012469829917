import React from 'react';
import { observer } from 'mobx-react-lite';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { t } from 'i18next';

import { LabEntity, LabManifest, LabSystemType } from '@/components/ManageLab/LabSystems/LabSystemsType';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { LabDetails } from '@/constants/TranslationConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { LabSystemDetailsGeneralTemplate } from './LabSystemDetailsGeneralTemplate';
import { LabSystemDetailsGeneralVMType } from './LabSystemDetailsGeneralType';

interface LabSystemDetailsGeneralViewControllerProps {
  viewModel: LabSystemDetailsGeneralVMType;
}

const LabSystemDetailsGeneralViewControllerFC: React.FC<LabSystemDetailsGeneralViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labSystemsStore, labSystemDetailsGeneralStore, systemMessageStore } = rootStore;
  const { setSystemGeneralInfo } = labSystemDetailsGeneralStore;
  const { selectedLabManifest, selectedSystem } = labSystemsStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;

  // Props Const
  const { refreshRackManagerInfo } = viewModel;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    const system: LabSystemType = selectedSystem;
    const labManifest: LabManifest = selectedLabManifest;

    if (labManifest && system) {
      const labEntity: LabEntity = labManifest.labEntities?.find(
        (labEntity: LabEntity) => labEntity?.id.toUpperCase() === system.agentId.toUpperCase(),
      );
      const sarmIpAddress: string = labEntity?.metadata?.acCycleRackId;

      system.sarmIpAddress = sarmIpAddress;
      setSystemGeneralInfo(system);
    } else {
      setSystemGeneralInfo(selectedSystem);
    }
  }, [selectedLabManifest, selectedSystem]);

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: LabDetails.REFRESH_RACK_MANAGER_INFO,
      text: t(LabDetails.REFRESH_RACK_MANAGER_INFO, { ns: NS.LAB_DETAILS }),
      title: t(LabDetails.REFRESH_RACK_MANAGER_INFO, { ns: NS.LAB_DETAILS }),
      iconProps: { iconName: SystemIcons.REFRESH },
      onClick: () => {
        refreshRackManagerInfo();
      },
    },
  ];

  return <LabSystemDetailsGeneralTemplate commandBarItems={commandBarItems}></LabSystemDetailsGeneralTemplate>;
};

export const LabSystemDetailsGeneralViewController = observer(LabSystemDetailsGeneralViewControllerFC);
