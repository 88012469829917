import React from 'react';
import { ThemeKeys } from 'react-json-view';
import { Link } from 'react-router-dom';
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { observer } from 'mobx-react-lite';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Icon, IconButton, MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import retry from 'async/retry';
import { t } from 'i18next';
import ls from 'local-storage';
import { container } from 'tsyringe';

import { loginRequest } from '@/components/_labs/SignIn/resultAuthConfig';
import SessionDetailsViewModel from '@/components/SessionDetails/SessionDetailsViewModel';
import SessionsStore from '@/components/Sessions/SessionsStore';
import UserInfoStore from '@/components/UserInfo/UserInfoStore';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { RetrySettings, TabMemoryKeys } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { useCancellationToken } from '@/services/_labs/screen-service';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { getResultExplorerAuthData } from '@/utils/Env';

import SessionDetailsStore from './SessionDetailsStore';
import SessionDetailsTemplate from './SessionDetailsTemplate';

import styles from './SessionDetails.module.css';

interface SessionDetailsVCProps {
  viewModel: SessionDetailsViewModel;
}

const SessionDetailsViewControllerFC: React.FC<SessionDetailsVCProps> = ({ viewModel }) => {
  const appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);
  const sessionDetailsStore: SessionDetailsStore = container.resolve(SessionDetailsStore);
  const sessionsStore: SessionsStore = container.resolve(SessionsStore);
  const userInfoStore: UserInfoStore = container.resolve(UserInfoStore);

  const {
    isDarkMode,
    isDebugMode,
    isReadingPaneBottom,
    isReadingPaneRight,
    setPreviewPaneBottom,
    setPreviewPaneRight,
    setTabMemory,
  } = appSettingsStore;
  const { clearSelectedSession, selectedSession, isSessionSelected, selectedSessionIsCancellable, selectedSessionTitle } =
    sessionsStore;
  const {
    canCancelSession,
    clearSessionPanelMessages,
    isCancelButtonDisabled,
    isDownloadButtonDisabled,
    selectedSessionRoutePath,
    setIsSessionLoading,
  } = sessionDetailsStore;

  const { companyName } = userInfoStore;

  const { logDownloader, loadSession, sessionId } = viewModel;

  const cancellationToken = useCancellationToken();

  // Configuration for our retry logic.
  const retrySettings = { times: RetrySettings.MAX_RETRIES, interval: RetrySettings.RETRY_INTERVAL };

  const [content, setContent] = React.useState<React.ReactElement>(<></>);
  const [rawJsonStyle, setRawJsonStyle] = React.useState<any>(vs);
  const [compiledJsonStyle, setCompiledJsonStyle] = React.useState<ThemeKeys>('bright:inverted');

  const attemptLoadSession = async (callback) => {
    try {
      const result = await loadSession(sessionId, cancellationToken);

      if (!result) {
        const message = t('session-load-failure', { ns: NS.EXPERIMENTS });
        const errorMessage = new Error(message);

        // The service call returned false. We will retry, if allowed.
        isDebugMode &&
          console.log(
            `[SessionDetailsViewControllerFC:attemptLoadSession] Fetch failed without errors, retry if possible: '${sessionId}'`,
          );
        return callback(errorMessage, null);
      }

      console.log(`[SessionDetailsViewControllerFC:attemptLoadSession] Successful fetch: '${sessionId}'`);
      return () => result;
    } catch (error) {
      const message = t('session-load-failure', { ns: NS.EXPERIMENTS });
      const errorMessage = new Error(message);

      // We had an error during the attempt to fetch the session data. We will retry, if allowed.
      console.error(
        `[SessionDetailsViewControllerFC:attemptLoadSession] Fetch failed with errors, retry if possible: '${sessionId}'`,
      );
      return callback(errorMessage, null);
    }
  };

  React.useEffect(() => {
    const { setIsLoadingError } = sessionsStore;
    const { setIsSessionLoading } = sessionDetailsStore;

    if (sessionId) {
      retry(retrySettings, attemptLoadSession, (err, callback) => {
        if (err || !callback) {
          // We were unable to fetch the session data due to API errors in the final fetch.
          setIsSessionLoading(false);
          setIsLoadingError(true);
          throw err;
        }

        const result = callback();

        if (result) {
          // We were able to fetch the session data without errors (within the number of retries).
          setIsLoadingError(false);
        } else {
          // We were unable to fetch the session data without errors (within the number of retries).
          setIsLoadingError(true);
        }

        setIsSessionLoading(false);
      });
    }
  }, [sessionId]);

  // TODO move
  React.useEffect(() => {
    setRawJsonStyle(isDarkMode ? vs2015 : vs);
    setCompiledJsonStyle(isDarkMode ? 'bright' : 'bright:inverted');
  }, [isDarkMode]);

  const cancelConfirmation = t('cancel-session-confirmation', { ns: NS.EXPERIMENTS });

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const data = getResultExplorerAuthData();
  const [isToken, setIsTokenGenerated] = React.useState<boolean>(false);
  const nineMinsToMillisec = TimeInterval.NINE_MINS;

  const setTokenData = React.useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: ['openid', data.scope],
        account: accounts[0],
      });
      setIsTokenGenerated(true);
      (ls as any).set('resultsToken', response.accessToken);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          await instance.acquireTokenRedirect({ scopes: ['openid', data.scope] });
        } catch (redirectError) {
          console.error('[SessionDetailsViewControllerFC:setTokenData] Error acquiring token via redirect:', redirectError);
        }
      } else {
        console.error('[SessionDetailsViewControllerFC:setTokenData] Error acquiring token silently:', error);
      }
    }
  }, [accounts, data.scope, instance]);

  const acquireTokenSilent = React.useCallback(() => {
    instance.acquireTokenSilent(loginRequest).catch((e) => {
      console.error('Error from Results token creation ', e);
    });
  }, [instance]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setTokenData();
    }
  }, [isAuthenticated, setTokenData]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        setTokenData();
      } else {
        acquireTokenSilent();
      }
    }, nineMinsToMillisec);

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticated, setTokenData, acquireTokenSilent]);

  const cancelOnClick = () => {
    // Cancel session.
    // TODO: Implement cancel session via API (28393226).
    const { addSessionPanelMessage, enableCancelButton, disableCancelButton } = sessionDetailsStore;
    const cancelConfirmation = t('cancel-session-confirmation', { ns: NS.EXPERIMENTS });

    const cancelMessage: SystemMessageType = {
      message: t('cancel-not-implemented', { ns: NS.EXPERIMENTS }),
      namespace: NS.EXPERIMENTS,
      type: FluentMessageBarType.info,
      groupId: SuccessGroupIds.SESSION_VIEW,
    };

    const simulateCancelOperation = () => {
      enableCancelButton();
    };

    if (confirm(cancelConfirmation)) {
      disableCancelButton();
      setTimeout(simulateCancelOperation, TimeInterval.THREE_SECONDS);
      addSessionPanelMessage(cancelMessage);
    }
  };

  const generalCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'download-all-logs',
      text: t('download-all-logs', { ns: NS.EXPERIMENTS }),
      title: t('download-all-logs', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.EDIT_COLUMNS },
      onClick: () => {
        // Download all logs.
        // TODO: Implement download all logs via API (28288607).
        const { disableDownloadButton } = sessionDetailsStore;

        if (isSessionSelected) {
          // NOTE: This is a temporary solution to pass the id to the logDownloader function.
          const id = '5d413ec8-db36-4a92-82fd-7dbeb7c32112'; // selectedSession.id;

          disableDownloadButton();
          logDownloader(id, companyName);
        }
      },
      disabled: isDownloadButtonDisabled,
      hidden: true, // TODO: remove once download all logs via API (28288607) is implemented.
    },
    {
      key: 'cancel-session',
      text: t('cancel-session', { ns: NS.EXPERIMENTS }),
      title: t('cancel-session', { ns: NS.EXPERIMENTS }),
      iconProps: { iconName: SystemIcons.CANCEL },
      onClick: cancelOnClick,
      disabled: !selectedSessionIsCancellable || isCancelButtonDisabled,
    },
  ];

  const onLinkClick = (item: React.ReactElement) => {
    const key = item.props.itemKey;

    setTabMemory(TabMemoryKeys.SESSION_DETAILS_MODAL, key);
  };

  const onJsonLinkClick = (item: React.ReactElement) => {
    const key = item.props.itemKey;

    setTabMemory(TabMemoryKeys.SESSION_DETAILS_MODAL_JSON, key);
  };

  const resetPanel = () => {
    clearSessionPanelMessages();
  };

  React.useEffect(() => {
    selectedSession && resetPanel();
  }, [selectedSession?.id, canCancelSession]);

  const detailsHeader = (
    <div className={`${styles['auto-collapsing-header']}`}>
      <div className={`${styles['header-row']}`} title={selectedSessionTitle}>
        <div className={styles['title-text']}>
          <div className={styles['title-truncate']}>{selectedSessionTitle}</div>
        </div>
        {isSessionSelected && (
          <Link
            className={styles['title-link']}
            to={{
              pathname: selectedSessionRoutePath,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={styles['title-icon']} iconName={SystemIcons.OPEN_IN_NEW_WINDOW} />
          </Link>
        )}
      </div>
      <div className={styles['close-button']}>
        {isReadingPaneBottom && (
          <IconButton
            title={t('show-right-pane', { ns: NS.EXPERIMENTS })}
            aria-label={t('show-right-pane', { ns: NS.EXPERIMENTS })}
            iconProps={{ iconName: SystemIcons.MOVE_RIGHT }}
            onClick={setPreviewPaneRight}
          />
        )}
        {isReadingPaneRight && (
          <IconButton
            title={t('show-bottom-pane', { ns: NS.EXPERIMENTS })}
            aria-label={t('show-bottom-pane', { ns: NS.EXPERIMENTS })}
            iconProps={{ iconName: SystemIcons.MOVE_DOWN }}
            onClick={setPreviewPaneBottom}
          />
        )}

        <IconButton
          title={t('close-session', { ns: NS.EXPERIMENTS })}
          aria-label={t('close-session', { ns: NS.EXPERIMENTS })}
          iconProps={{ iconName: SystemIcons.CANCEL }}
          onClick={clearSelectedSession}
        />
      </div>
    </div>
  );

  return (
    <SessionDetailsTemplate
      compiledJsonStyle={compiledJsonStyle}
      content={content}
      generalCommandBarItems={generalCommandBarItems}
      onJsonLinkClick={onJsonLinkClick}
      onLinkClick={onLinkClick}
      pageHeader={detailsHeader}
      rawJsonStyle={rawJsonStyle}
      setContent={setContent}
    />
  );
};

const SessionDetailsViewController = observer(SessionDetailsViewControllerFC);

export default SessionDetailsViewController;
